import React, { useRef, useState } from 'react'
import { columnsheadId } from '../../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../../system/WebUniversalNewEx';
import { Button, Popconfirm } from 'antd';
import MenuImSpecInventoryEdit from './MenuImSpecInventoryEdit';
import fetchApi from '../../../../utils/api/fetchApi';
import system from "../../../../config/systematic";


export default function MenuImSpecInventory(props: any) {
  const ref = useRef<any>(undefined);
  /**后端数据wmService */
  const wmService = system.wmService;
  const apiId="menuImSpecInventory"
  //表头
  const columnsHead = [
    {
      title: '菜品',
      dataIndex: 'branchStoreMenuWarehouseSpec',
      render: (text: any) => (text ? text.branchStoreMenuWarehouse.foodName : "")
    },
    {
      title: '规格',
      dataIndex: 'branchStoreMenuWarehouseSpec',
      render: (text: any) => (text ? text.branchStoreMenuWarehouseSpecName : "")
    },
    {
      title: '数量(库存)',
      dataIndex: 'quantity',
    },
    {
      title: '定时模式',
      dataIndex: 'timingModeName',
    },
  ];
  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '菜品固定库存',
    apiId,
    // showAdd: false,
    columns,
    pagination: true,
    EditComponent: MenuImSpecInventoryEdit,
    mountDataOptions:{
      userMount:{
         isDept:true,
      }
 },

  };
  const cleanMenuInventory = () => {
    fetchApi.POST(wmService, {
      apiId,
      apiExtend: "cleanMenuInventory",
      apiData:{
        mountDataOptions: {
          userMount: {
              isDept: true,
          }
        }}
    }).then((res) => {
      if (res.success) {
        ref.current.initData()
      }


    }).catch((err) => {
      console.log("err", err);

    })
  }
  return (
    <>
      {/* <Popconfirm title="是否清空库存?" onConfirm={cleanMenuInventory}>
        <Button >清空库存</Button>
      </Popconfirm> */}
      {/* <span>  </span> */}
      <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
    </>
  )
}
