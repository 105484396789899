import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import DeviceEdit from './DeviceEdit';
import { Button, Select, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import dayjs from 'dayjs';
import UserLoginDeviceEdit from '../user/UserLoginDeviceEdit';

const WebComponent = (props: any) => {

    const ref = useRef<any>(undefined);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    /** 门店数据 */
    const [branchStoreData, setBranchStoreData] = useState<any>();
    const [branchStoreIdData, setBranchStoreIdData] = useState<any>();

    /** 设备类型数据 */
    const [deviceTypeData, setDeviceTypeData] = useState<any>();
    const [deviceTypeIdData, setDeviceTypeIdData] = useState<any>();
    const [modalUserLoginDeviceEdit, setModalUserLoginDeviceEdit] = useState(false);
    const [loginDeviceEditData, setLoginDeviceEditData] = useState<any>();
    const [refreshObj, setRefreshObj] = useState<any>();

    const [a, setA] = useState(false);

    /** api服务id */
    const apiId = 'device';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // wmService = ref.current.wmService;
        }
        //同步获取数据 async - await
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "deviceType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setDeviceTypeData([{ id: "999", deviceTypeName: "所有类型" }, ...res.data]);
                }
            });
            fetchApi.GET(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", branchStoreName: "所有门店" }, ...res.data];
                    setBranchStoreData(newData);
                }
            });
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStore',
            render: (text: any) => (text ? text.branchStoreName : ''),
        },
        {
            title: '类型',
            dataIndex: 'deviceType',
            render: (text: any) => (text ? text.deviceTypeName : ''),
            sorter: true,
        },
        {
            title: '名称',
            dataIndex: 'deviceName',
            sorter: true,
        },
        {
            title: 'mac地址',
            dataIndex: 'macAddress',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 12 ? text.substring(0, 12) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: 'ip地址',
            dataIndex: 'ipAddress',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
        {
            title: '临时使用',
            dataIndex: 'isInterimUse',
            render: (text: any, record: any) => {
                return (
                    <>
                        {text === true ?
                            <Tooltip placement="topLeft"
                                title={`开始：${dayjs(record.startDate).format('YYYY-MM-DD HH:mm:ss')}；结束：${dayjs(record.endDate).format('YYYY-MM-DD HH:mm:ss')}`}>
                                <p style={{ color: 'blue' }}>是</p> </Tooltip> :
                            '否'}
                    </>
                )
            }
        }
    ];

    const expandableColumns = [
        {
            title: '用户',
            dataIndex: 'user',
            render: (text: any) => (text ? text.userName : ''),
        },
        {
            title: '临时登录',
            dataIndex: 'isInterimLogin',
            render: (text: any) => {
                return (
                    <>
                        {text === true ? <p style={{ color: 'blue' }}>是</p> : '否'}
                    </>
                )
            }
        },
        {
            title: '开始日期',
            dataIndex: 'startDate',
            render: (text: any) => {
                return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: '结束日期',
            dataIndex: 'endDate',
            render: (text: any) => {
                return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: '编辑',
            dataIndex: 'id',
            render: (text: any, record: any) => {
                return (<Button onClick={() => {
                    /** 刷新数据 */
                    const refreshData: initDataType = {
                        apiId,
                        sendPageData: { branchStoreId: branchStoreIdData, deviceTypeId: deviceTypeIdData }
                    }
                    setRefreshObj({
                        excuteFun: ref.current.initData,
                        refreshData
                    })
                    setLoginDeviceEditData({
                        type: 'EDIT',
                        id: record.id,
                        sendPageData: {
                            userId: record.userId
                        }
                    })
                    setModalUserLoginDeviceEdit(true);
                }}>编辑</Button>)
            }
        },
        {
            title: '状态',
            dataIndex: 'isUse',
            render: (text: any) => {
                return (
                    <>
                        {text === true ? '启用' : <p style={{ color: 'red' }}>关闭</p>}
                    </>
                )
            }
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '设备',
        apiId: 'device',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: DeviceEdit,
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'userLoginDevices',
        showLogger: {
            logger: {
                id: 'loginLogger'
            },
            loggerDisplay: true,
            loggerSingleDisplay: true,
            loggerQueryType: 'device'
        }
    };

    //查看不同门店
    const branchStoreChange = (e: any) => {
        setBranchStoreIdData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { branchStoreId: e, deviceTypeId: deviceTypeIdData }
        }
        ref.current.initData(currentData);
    }

    //查看不同设备类型
    const deviceTypeChange = (e: any) => {
        setDeviceTypeIdData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { branchStoreId: branchStoreIdData, deviceTypeId: e }
        }
        ref.current.initData(currentData);
    }

    return (
        <>
            <label>门店:  </label>
            <Select
                style={{ width: 150 }}
                showSearch
                defaultValue="所有门店"
                onChange={branchStoreChange}
                placeholder="选择门店"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.generalEx(branchStoreData, {
                    includeNull: false,
                    itemName: 'branchStoreName'
                })}
            </Select>
            <span>&nbsp;&nbsp;</span>
            <label>设备类型:  </label>
            <Select
                style={{ width: 150 }}
                showSearch
                defaultValue="所有类型"
                onChange={deviceTypeChange}
                placeholder="选择设备类型"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.generalEx(deviceTypeData, {
                    includeNull: false,
                    itemName: 'deviceTypeName'
                })}
            </Select>
            <span>&nbsp;&nbsp;</span>
            <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
            {modalUserLoginDeviceEdit ? (<UserLoginDeviceEdit
                closeModal={() => {
                    setModalUserLoginDeviceEdit(false);
                    const { excuteFun, refreshData } = refreshObj;
                    if (excuteFun) {
                        excuteFun(refreshData);
                    }
                }}
                data={loginDeviceEditData} />) : null}
        </>
    )
}

export default WebComponent