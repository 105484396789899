import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';

export default function PayDetailsEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    // const [branchStoreData, setBranchStoreData] = useState<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {


            // fetchApi.GET(wmService, {
            //     apiId: "branchStore",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setBranchStoreData(res.data)
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "menuHead",
            //     apiExtend: 'getBranchStoreId'
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.key !== 'manager') {
            //             form.setFieldsValue({ branchStoreId: res.data.branchStore.id });
            //         }
            //     }
            // });

        }
        fetchData()
    }, [])

  

  

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'payDetails'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '支付详情'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        {/* <Form.Item
                        label="所属门店"
                        name="branchStoreId"
                        rules={[{ required: true, message: '请选择所属门店!' }]}>
                        <Select
                            showSearch
                            disabled={true}
                            placeholder="所属门店"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(branchStoreData, 'branchStoreName')}
                        </Select>
                    </Form.Item> */}

                      

                        
                        <Col span={24}>
                            <Form.Item
                                label="金额"
                                name="payAmount"
                                rules={[{ required: true, message: '请输入支付名称!' }]}>
                                <InputNumber />
                            </Form.Item>
                        </Col>
                       

                 

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}