import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewExEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import WebProductFormListEx from '../../generalComponents/WebProductFormListEx';
export default function ApplyPurchaseEdit(props: any) {


    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [deleteData, setDeleteData] = useState<any>();

    /**部门数据 */
    const [deptData, setDeptData] = useState<any>();





    const [formList, setFormList] = useState<any>({ formName: "applyPurchaseProducts", form, type: data.type, ref,orderType:"applyPurchase", setDeleteData })
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (data.type === "NEW") {
          
        }
        if (ref.current) {
            ref.current.row.then((res: any) => {
                if (typeof res === 'undefined') return
                // if (res.success) {
                //     form.setFieldsValue({
                //         userName: res.data.userLogin.userName,
                //         orderTime: dayjs(res.data.sysDate.createdAt).format("YYYY-MM-DD")
                //     })
                // }
            })

            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            if (data.type !== "NEW") {
                httpGet(wmService, {
                    apiId: "dept",
                    apiExtend: 'showList',
                    apiData: {

                    }
                }).then(res => {

                    setDeptData(res.data)
                })
            }

        }
        fetchData()
    }, [])




    const handleOk = (e: any) => {

        setLoading(true)
       
        ref.current.handleOk();
       
    };

    const handleCancel = (e: any) => {

        ref.current.handleCancel();
    };
    // const onChangeEntrepotIdToBuyerData = () => {
    //     if (!selectAreaId) return false;


    //     httpGet(wmService, {
    //         apiId: "buyerType",
    //         apiExtend: 'getAreaIdToBuyer',
    //         areaId: selectAreaId
    //     }).then((res) => {
    //         setBuyerData(res.data)

    //     })
    //     form.resetFields(["buyerId"])

    // }




    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'applyPurchase',
        formListName: "applyPurchaseProducts",
        mountDataOptions:{
            userMount: {
                isDept:true,
                isUserAreas:true
            }
           },
    };

    // const children = (isLoading: boolean) => {
    //     return
    // }
    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} totalData={deleteData} setLoading={setLoading}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : data.type === "EDIT" ? "编辑" : "查看") + '订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                okButtonProps={{ disabled: data.type === 'VIEW' ? true : false }}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={"70%"}
                confirmLoading={loading}
            >
                <Form
                    form={form}
                    name="Edit"
                    onKeyDown={(e: any) => {
                        if (e.altKey) {
                            if (e.key === "s" && e.altKey) {
                                if (loading === false) {
                                    handleOk(e)

                                }
                            }
                        }

                    }}
                >
                    <Row >
                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="申购人"
                                name="userName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="部门"
                                name="deptId"
                                rules={[{ required: true, message: '请输入部门!' }]}>
                                <Select
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    placeholder="部门"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.searchEx('dept', setDeptData, e, undefined,
                                        {
                                            form,
                                            setObj: ["deptId"],
                                            setObjValue: ["id"],
                                        }

                                    )}
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}>
                                    {getList.general(deptData, 'deptName')}
                                </Select>
                            </Form.Item>
                        </Col>
                      
                      
                        <Col span={24}>
                            <WebProductFormListEx props={formList}></WebProductFormListEx>
                        </Col>


                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}


