import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row, Select, Spin, Transfer } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import system from "../../../config/systematic";
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification';
import { SocketContext } from '../../../App';

export default function CopyMenuWarehouse(props: any) {

    const [form] = Form.useForm(); //form 

    /**后端数据wmService */
    const wmService = system.wmService;

    const { closeModal, data } = props;

    const [brandData, setBrandData] = useState<any>();

    const [branchStoreData, setBranchStoreData] = useState<any>();

    const [loadingData, setLoadingData] = useState<any>(false);

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    // const [menuData, setMenuData] = useState<any>();

    // const [menuData, setMenuData] = useState<any>();

    // const [menuWarehouseData, setMenuWarehouseData] = useState<any>([]);
    // const [shopMenuKeys, setShopMenuKeys] = useState<any>([]);

    let title;
    // let transferTitle;
    // let transferDataSource;
    // let transferTarget;

    switch (data.path) {
        case 'MenuWarehouse':
            title = "将【 " + (data.foodName !== '' ? data.foodName : '所选择的菜式') + " 】复制到.....";
            // transferTitle = ['菜式仓库', '门店菜式'];
            // transferDataSource = menuWarehouseData;
            // transferTarget = branchStoreMenuKeys;
            break;
        case 'BranchStoreMenuWarehouse':
            title = "将所有的菜式复制到.....";
            // transferTitle = ['门店菜式', '现有菜式'];
            // transferDataSource = branchStoreMenuWarehouseData;
            // transferTarget = shopMenuKeys;
            break;
    };

    useEffect(() => {

        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "brand",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    // setBrandData(res.data);
                    setBrandData(newData(res.data));
                }
            })

            fetchApi.GET(wmService, {
                apiId: "branchStoreMenuWarehouse",
                apiExtend: 'getBranchStoreToBrand',
                apiData: {
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isBranchStore: true
                        }
                    }
                }
            }).then(res => {
                if (res.success) {
                    setBranchStoreData(newData(res.data.branchStores));
                }
            })

        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const newData = (rows: any) => {

        let newData: Array<any> = [];
        for (var j = 0; j < rows.length; j++) {
            if (data.path === "MenuWarehouse") {
                newData.push({
                    value: rows[j].id,
                    label: rows[j].brandName,
                    disabled: rows[j].id !== data.brandId ? false : true
                })
            }

            if (data.path === "BranchStoreMenuWarehouse") {
                newData.push({
                    value: rows[j].id,
                    label: rows[j].branchStoreName,
                })
            }
        }
        return newData;
    }

    const handleOk = (e: any) => {
        setLoadingData(true);

        fetchApi.POST(wmService, {
            apiId: 'menuWarehouse',
            apiExtend: 'copy',
            apiData: {
                menuWarehouseId: data.id,
                branchStoreId: props.branchStoreId,
                brandsId: form.getFieldsValue()['brandsId'],
                branchStoresId: form.getFieldsValue()['branchStoresId'],
                mountDataOptions: {
                    userMount: {
                        isBranchStore: true
                    }
                }
            }
        }).then(res => {
            if (res.success) {
                antMessage('success', res.data.message ? res.data.message : '添加成功', 'success');
                closeModal();
            }
        })

    };

    const handleCancel = (e: any) => {
        closeModal();
    };

    // const onChange = (newBranchStoreMenuKeys: any, direction: any, moveKeys: any) => {
    //     setShopMenuKeys(newBranchStoreMenuKeys);
    //     setMenuData(newBranchStoreMenuKeys);
    // };

    return (
        <>
            <AntDraggableModal
                title={title}
                open={true}
                okText='确定'
                cancelText='取消'
                cancelButtonProps={{ disabled: loadingData }}
                okButtonProps={{ disabled: loadingData }}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                {loadingData ? (
                    <Spin size="large" tip="Loading...">
                        <Form
                            form={form}
                            name="Edit"
                            onFinish={handleOk}
                            style={{ height: 150 }}
                        >
                            <Row gutter={[16, 0]}>
                                <Col span={data.path === 'MenuWarehouse' ? 24 : 0}>
                                    <Form.Item
                                        label="选择品牌"
                                        name="brandsId"
                                        rules={[{ required: data.path === 'MenuWarehouse' ? true : false, message: '请选择品牌!' }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder="选择品牌"
                                            // onChange={(e) => changeCategory(e, setMealCategoryData)}
                                            options={brandData}
                                        >
                                        </Select>

                                        {/* <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder="选择品牌"
                                            optionFilterProp="children"
                                            // onChange={(e) => changePrinter(e, 'defaultPrinterId', setDefaultPrinterData)}
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(brandData, 'brandName', 'id', undefined, false)}
                                        </Select> */}
                                    </Form.Item>
                                </Col>

                                <Col span={data.path === 'BranchStoreMenuWarehouse' ? 24 : 0}>
                                    <Form.Item
                                        label="选择门店"
                                        name="branchStoresId"
                                        rules={[{ required: data.path === 'BranchStoreMenuWarehouse' ? true : false, message: '请选择门店!' }]}>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder="选择门店"
                                            // onChange={(e) => changeCategory(e, setMealCategoryData)}
                                            options={branchStoreData}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </Spin>
                ) :
                    (
                        <Form
                            form={form}
                            name="Edit"
                            onFinish={handleOk}
                            style={{ height: 150 }}
                        >
                            <Row gutter={[16, 0]}>
                                <Col span={data.path === 'MenuWarehouse' ? 24 : 0}>
                                    <Form.Item
                                        label="选择品牌"
                                        name="brandsId"
                                        rules={[{ required: data.path === 'MenuWarehouse' ? true : false, message: '请选择品牌!' }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder="选择品牌"
                                            // onChange={(e) => changeCategory(e, setMealCategoryData)}
                                            options={brandData}
                                        >
                                        </Select>

                                        {/* <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder="选择品牌"
                                            optionFilterProp="children"
                                            // onChange={(e) => changePrinter(e, 'defaultPrinterId', setDefaultPrinterData)}
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(brandData, 'brandName', 'id', undefined, false)}
                                        </Select> */}
                                    </Form.Item>
                                </Col>

                                <Col span={data.path === 'BranchStoreMenuWarehouse' ? 24 : 0}>
                                    <Form.Item
                                        label="选择门店"
                                        name="branchStoresId"
                                        rules={[{ required: data.path === 'BranchStoreMenuWarehouse' ? true : false, message: '请选择门店!' }]}>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder="选择门店"
                                            // onChange={(e) => changeCategory(e, setMealCategoryData)}
                                            options={branchStoreData}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    )}

                {/* <Transfer
                    dataSource={menuWarehouseData}
                    titles={['菜式仓库', '现有菜式' ]}
                    showSearch
                    targetKeys={shopMenuKeys}
                    onChange={onChange}
                    render={item => `${item.foodName}`}
                    pagination
                /> */}
            </AntDraggableModal>
        </>
    );
};