import { useEffect, useRef, useState } from 'react';
import DevelopTableEdit from './DevelopTableEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import DevelopColumn from './DevelopColumn';
import { Button, Space } from 'antd';

/**
 * 组件 DevelopTable 
 * @param props 
 * @returns 
 */
const DevelopTable = (props: any) => {
    const apiId = 'developTable';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 刷新数据 */
    let initData: any;

    //开发column窗口
    const [modalDevelopColumnopen,
        setModalDevelopColumnopen] = useState(false);
    const [sendData, setSendData] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    const closeDevelopColumn = () => {
        setModalDevelopColumnopen(false);
    };

    const openDevelopColumn = (id: any) => {
        setSendData({
            id
        });
        setModalDevelopColumnopen(true);
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'tableName',
        },
        {
            title: '数据库',
            dataIndex: 'developDatabase',
            render: (text: any) => (typeof text !== 'undefined' ?
                text.databaseName : '')
        },
        {
            title: 'column',
            dataIndex: 'productPrice',
            key: 'productPrice',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => openDevelopColumn(record.id)}>详情</Button>
                </Space>
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '开发数据表',
        apiId,
        columns,
        showStates: true,
        EditComponent: DevelopTableEdit
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalDevelopColumnopen
                ? (<DevelopColumn
                    onClose={closeDevelopColumn}
                    data={sendData} />)
                : null}
        </>
    )
}

export default DevelopTable;