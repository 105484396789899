import { Button, Checkbox, DatePicker, List, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi'
import systemConfig from '../../../config/systematic';
import moment from 'moment';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification';
export default function MenuTester(props: any) {
    const { data, closeModal } = props;
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [checkedIds, setCheckedIds] = useState<Array<string>>([]);

    //打印机数据
    const [printData, setPrintData] = useState<Array<any>>([])
    useEffect(() => {
        initData({})
        const fetchData = async () => {
        }
        fetchData()
    }, [])


    const initData = (apiData: any) => {
        fetchApi.GET(wmService, {
            apiId: "printer",
            apiExtend: 'showList',
            apiData:{
                mountDataOptions:{
                    userMount: {
                        isDept:true,
                    }
                   },
            }

        }).then(res => {

            if (res.success === true) {
                setPrintData(res.data)
                let ids: Array<string> = [];
                res.data.forEach((item: any) => { ids.push(item.id) })
                setCheckedIds(ids)
                // setTimeTypeData(res.data.rows)
                //   console.log("res",res);


            }
        })
    }

    const handleCheckboxChange = (checked: any, itemId: string) => {
        const newCheckedIds = checked ? [...checkedIds, itemId] : checkedIds.filter(id => id !== itemId);
        setCheckedIds(newCheckedIds);
    };

    const onOk = () => {
        if (checkedIds.length === 0) {
            antMessage("warning", "警告", "请选择打印机")
            return false
        }

        fetchApi.GET(wmService, {
            apiId: "printer",
            apiExtend: 'printTest',
            apiData: {
                ids: checkedIds,
                mountDataOptions:{
                    userMount: {
                        isDept:true
                    }
                   },
            }
                

        }).then(res => {
            antMessage("success", "成功", "已发起打印请求")
            closeModal(true)
        }).catch((err) => {
            console.log("err", err);
            antMessage("error", "错误", err)
        })


    }
    return (
        <Modal

            title={<div style={{ fontSize: "30px", display: "flex", justifyContent: "space-between" }}>
                <div>请选择打印机</div>


            </div>}
            open={true}
            onCancel={() => closeModal(false)}
            onOk={onOk}
            width={"60%"}
            centered
        >

            <List
                size="large"
                dataSource={printData}
                renderItem={(item: any) => (
                    <List.Item key={item.id}>
                        <List.Item.Meta
                            title={<div style={{ fontSize: "30px" }}>{item.windowsPrinterName}</div>}
                        />
                        <Checkbox
                            onChange={e => handleCheckboxChange(e.target.checked, item.id)}
                            checked={checkedIds.includes(item.id)}
                        >
                        </Checkbox>
                    </List.Item>
                )}
            />
        </Modal>
    )
}
