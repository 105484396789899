import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import ReserveMenuCategoryEdit from './ReserveMenuCategoryEdit';


export default function ReserveMenuCategory(props: any) {

    //表头
    const columnsHead = [
        {
            title: '预订菜单分类',
            dataIndex: 'reserveMenuCategoryName',
        },
        {
            title: '预订菜单分类英文',
            dataIndex: 'reserveMenuCategoryNameEn',
        },
        {
            title: '专属id(简码)',
            dataIndex: 'selfId',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '预订菜单分类',
        apiId: 'reserveMenuCategory',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: ReserveMenuCategoryEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}