import React, { useContext, useEffect, useRef, useState } from 'react';
import WebUniversal, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';
import { Button, DatePicker, Dropdown, MenuProps, Select, Space, Tooltip } from 'antd';
import { useLocation } from 'react-router';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import ReplaceReserveDateRoom from './ReplaceReserveDateRoom';
import dayjs from 'dayjs';
import { DownOutlined } from '@ant-design/icons';
import InvitationLetter from './InvitationLetter';
import ReserveMenu from '../../internal/reserveMenu/ReserveMenu';
import antMessage from '../../../utils/extend/AntdNotification'
import CompleteRoom from './CompleteRoom';
import SendMessage from '../messageManage/SendMessage';
import { SocketContext } from '../../../App';

//组件 CustomerReserve 
const CustomerReserve = (props: any) => {
  const ref = useRef<any>(undefined);
  const { state } = useLocation();
  /** state数据 */
  let data = state as any;
  /**打开信息发送窗口 */

  const [modalSendMessagelopen, setModalSendMessageopen] = useState<Boolean>(false)

  /**日期 */
  const [date, setDate] = useState<any>();

  /**数据来源 */
  const [dataSource, setDataSource] = useState<any>();
  const [selectDataSourcs, setSelectDataSourcs] = useState<number>();

  /**打开分店房间选择窗口 */
  const [modalReplaceReserveDateRoomopen, setModalReplaceReserveDateRoomopen] = useState<Boolean>(false)

  /**打开邀请函列表 */
  const [invitationModal, setInvitationModal] = useState<boolean>(false)

  const [completeRoomModal, setCompleteRoomModal] = useState<boolean>(false)

  /**邀请函数据列表 */
  const [invitationData, setInvitationData] = useState<any>();

  //分配房间数据
  const [distributeData, setDistributeData] = useState<any>()
  // const [modalChooseReserveRoomopen, setModalChooseReserveRoomopen] = useState<Boolean>(false)
  const useContextData = useContext(SocketContext);
  const { contextData, setContextData } = useContextData;
  /** api服务id */
  const apiId = 'customerReserve';
  const [selectReserveStatus, setSelectReserveStatusa] = useState();

  const [reserveStatus, setReserveStatus] = useState<any>();

  const [customerReserveId, setCustomerReserveId] = useState<string>();
  const [reserveMenuModal, setReserveMenuModal] = useState(false);
  /**选中的所有数据 */
  const [selectData, setSelectData] = useState<any>();
  //表头
  const columnsHead = [
    {
      title: '市别',
      dataIndex: 'timeType',
      render: (text: any) => (text ? `${text.bookingDate}(${text.typeName})` : ""),
    },
    {
      title: '到达时间',
      dataIndex: 'arriveTime',
      render: (text: any) => (text ? moment(text).format("HH:mm") : "")
    },
    {
      title: "宴会",
      dataIndex: "banquet",
      render: (text: any) => (text ? text.banquetName : "")
    },
    {
      title: '人数',
      dataIndex: 'bookingNum',
    },
    {
      title: '联系人',
      dataIndex: 'contact',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 5 ? text.substring(0, 5) + '......' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '称呼',
      dataIndex: 'appellationName',
    },
    {
      title: '电话',
      dataIndex: 'contactCall',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 6 ? text.substring(0, 6) + '......' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 6 ? text.substring(0, 6) + '......' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '备注',
      dataIndex: 'memo',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 15 ? text.substring(0, 15) + '......' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '经手人',
      dataIndex: 'handlers',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 10 ? text.substring(0, 10) + '......' : text)
          : ''
      }</Tooltip>
    },

    {
      title: "预订状态",
      dataIndex: "reserveStatusName",
      // render: (text: any, record: any) => {

      //   return <div>{text}{Number(record.reserveStatus) === 0 && record.tempRooms.length > 0 ? <Button size='small'>确认分配</Button> : null}</div>
      // }
    },
    {
      title: "定金状态",
      dataIndex: "depositStatusName",
    },

    {
      title: "来源",
      dataIndex: "dataSourceName"
    },
    // {
    //   title: '预订菜单',
    //   dataIndex: 'reserveMenu',
    //   key: 'reserveMenu',
    //   render: (text: any, record: any) => {
    //     return (
    //       <Space size="small">
    //         <Button size={"small"} type="primary" onClick={() => handleReserveMenu(record)}>预订菜单</Button>
    //       </Space>
    //     )
    //   }
    // },
    {
      title: "处理",
      render: (text: any, record: any) => {
        return <>
          <Dropdown menu={{ items: manageItems(record), onClick: (key) => onManageItemsClick(record, key) }} trigger={["hover"]}>
            <Space>
              <Button size={"small"} type="dashed" >处理</Button>
              <DownOutlined />
            </Space>
          </Dropdown>
        </>
      }
    }
  ];
  /**
    * 点击触发
    * @param param0 
    */
  const onManageItemsClick = (record: any, { key }: any) => {

    let keyJson = JSON.parse(key);
    const { id } = keyJson;
    switch (id) {
      //分配房间
      case "handleRoom":
        handleRoom(record)
        break;
      case "handleInvitation":
        handleInvitation(record);
        break;
      case "cancelRoom":
        cancelRoom(record)
        break;
      case "handleReserveMenu":
        handleReserveMenu(record)
        break;
      case "completeRoom":
        openCompleteRoom(record);
        break;
      case "sendMessage":
        opneSendMessage(record)
        // openCompleteRoom(record)

        break;
    }

  };

  /**查看预订菜单 */
  const handleReserveMenu = (record: any) => {
    setCustomerReserveId(record.id);
    setReserveMenuModal(true);
  }

  /** 关闭预订菜单 */
  const closeReserveMenu = (status: boolean) => {
    setReserveMenuModal(false);
  };

  /**关闭发送信息 */
  const closeSendMessage = (state: boolean) => {
    setModalSendMessageopen(false)
    if (state === true) {
      //     closeModal(true)
    }
  }

  /**打开发送信息 */
  const opneSendMessage = (record: any) => {
    setCustomerReserveId(record.id);
    setModalSendMessageopen(true)
  }

  /** 管理菜单项 */
  const manageItems = (item: any) => {
    const { id, reserveStatus, tempRooms } = item;
    // console.log("item",item);

    const items: MenuProps['items'] = [
      {
        // label: <Button disabled={Number(reserveStatus)===0?false:true} size={"small"} type="dashed" danger>分配</Button>,
        label: "分配",
        key: '{"customerReserveId":"' + id + '","id":"handleRoom"}',
        danger: true,
        disabled: Number(reserveStatus) === 0 ? false : true
      },
      {
        // label: <Button disabled={Number(reserveStatus)===0?false:true} size={"small"} type="dashed" danger>分配</Button>,
        label: "取消分配",
        key: '{"customerReserveId":"' + id + '","id":"cancelRoom"}',
        danger: true,
        // disabled: Number(reserveStatus) === 0 ? false : true
      },
      {
        // label: <Button disabled={Number(reserveStatus)===0?false:true} size={"small"} type="dashed" danger>分配</Button>,
        label: "分配完成",
        key: '{"customerReserveId":"' + id + '","id":"completeRoom"}',
        danger: true,
        disabled: Number(reserveStatus) === 0 && tempRooms.length > 0 ? false : true,
      },
      {
        label: '邀请函',
        key: '{"customerReserveId":"' + id + '","id":"handleInvitation"}',
        // danger: true,
        // disabled:Number(reserveStatus)===0?false:true
      },
      {
        label: '预订菜单',
        key: '{"customerReserveId":"' + id + '","id":"handleReserveMenu"}',
        // danger: true,
        // disabled:Number(reserveStatus)===0?false:true
      },
      {
        label: '发送信息',
        key: '{"customerReserveId":"' + id + '","id":"sendMessage"}',

      },
      // 
    ];
    return items;
  };
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  useEffect(() => {

    if (ref.current) {
      wmService = ref.current.wmService;
    }

    fetchApi.GET(wmService,
      {
        apiId: 'generalData',
        apiVariable: 'reserveStatus@dataSource',
        apiExtend: 'showLists'
      }).then(res => {

        if (res.success) {
          const { reserveStatus, dataSource } = res.data
          setReserveStatus(reserveStatus);
          setDataSource(dataSource)
        }
      })
    if (contextData?.currentBranchStoreId && contextData?.refresh) {
      const currentData: initDataType = {
        apiId,
        sendPageData: { bookingDate: date, reserveStatus: selectReserveStatus, system: data ? data.system : "wm", limit: data ? data.limit : null, phoneNumber: data ? data.phoneNumber : null, operation: data ? data.operation : null, dataSource: selectDataSourcs, branchStoreId: contextData?.currentBranchStoreId }
      }
      ref.current.initData(currentData);

      setContextData({ ...contextData, refresh: false })
      // onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextData?.currentBranchStoreId]);
  //传关参数
  let sendData = {
    type: "",
    customerReserveId: "",
    timeTypeId: "",
    bookingNum: "",
    guestRoomTypes: "",
    reserveTempRoomId: "",
    roomTypeId: "",
  }
  //分配房间
  const handleRoom = (e: any) => {

    sendData.bookingNum = e.bookingNum;
    sendData.customerReserveId = e.id;
    sendData.timeTypeId = e.timeTypeId;
    sendData.type = "distribute";
    setDistributeData(sendData)
    setModalReplaceReserveDateRoomopen(true)
  }

  /**查看邀请函信息 */
  const handleInvitation = (e: any) => {
    let data = {
      id: e.id
    }
    setInvitationModal(true)
    setInvitationData(data)

  }
  /**关闭邀请函信息窗口 */
  const closeInvitation = (status: boolean) => {
    setInvitationModal(false)
    if (status === true) {
      const currentData: initDataType = {
        apiId,


      }
      ref.current.initData(currentData);
    }
  }
  //关闭分配窗口并刷新数据
  const closeModal = (status: Boolean) => {
    setModalReplaceReserveDateRoomopen(false)
    if (status === true) {
      const currentData: initDataType = {
        apiId,
        sendPageData: { bookingDate: date, reserveStatus: selectReserveStatus, system: data ? data.system : "wm", limit: data ? data.limit : null, phoneNumber: data ? data.phoneNumber : null, operation: data ? data.operation : null, dataSource: selectDataSourcs, branchStoreId: contextData?.currentBranchStoreId }

      }
      ref.current.initData(currentData);
    }
  }
  const columns = columnsheadId
    .concat(columnsHead as []);


  /** 子表头内容 */
  const expandableColumnsHead: Array<any> = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 6 ? text.substring(0, 6) + '......' : text)
          : ''
      }</Tooltip>
    },
    // {
    //   title: '预订日期',
    //   dataIndex: 'timeType',
    //   render: (text: any) => (text ? text.bookingDate : ""),
    // },
    // {
    //   title: '市别',
    //   dataIndex: 'timeType',
    //   render: (text: any) => (text ? text.typeName : ""),
    // },

    {
      title: "房间类型",
      dataIndex: "room",
      render: (text: any, record: any) => (text ? text.roomType?.roomType : "")
    },
    {
      title: "房间名称",
      dataIndex: "room",
      render: (text: any) => (text ? text.roomName : "")
    },
    {
      title: "房间号",
      dataIndex: "room",
      render: (text: any) => (text ? text.roomNum : "")
    },
    {
      title: "预坐人数",
      dataIndex: "sitNumber",
      render: (text: any) => (text ? text : "")

    },

    // {
    //   title: "预留状态",
    //   dataIndex: "reservedStateName"
    // },
    // {
    //   title: "备注",
    //   dataIndex: "memo"
    // },


  ]

  /** 子表头 */
  const expandableColumns = expandableColumnsHead;
  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '客户预订表',
    apiId,
    columns,
    showStates: false,
    pagination: true,
    showEdit: false,
    showAdd: false,
    mountDataOptions: {
      userMount: {
        isDept: true,
      }
    },
    expandableRowSelection: {
      type: "checkbox",
      onChange: (fatherId: any, selectedRowKeys: React.Key[]) => {
        let obj = {
          [fatherId]: selectedRowKeys
        }
        setSelectData({ ...selectData, ...obj })
      },
    },
    // showDate: true,
    //子表格
    isExpandable: true,
    expandableDataName: 'tempRooms',
    expandableColumns,
    sendPageData: { bookingDate: date, reserveStatus: selectReserveStatus, system: data ? data.system : "wm", limit: data ? data.limit : null, phoneNumber: data ? data.phoneNumber : null, operation: data ? data.operation : null, dataSource: selectDataSourcs, branchStoreId: contextData?.currentBranchStoreId }
  };
  /**查看不同状态的预订状态 */
  const reserveStatusChange = (e: any) => {

    setSelectReserveStatusa(e)
    const currentData: initDataType = {
      apiId,
      sendPageData: { bookingDate: date, reserveStatus: e, dataSource: selectDataSourcs, branchStoreId: contextData?.currentBranchStoreId }
    }
    ref.current.initData(currentData);
  }



  /**选择日期 */
  const handleDate = (e: any) => {

    if (e) {
      setDate(dayjs(e).format("YYYY-MM-DD"))
    } else {
      setDate(undefined)
    }
    const currentData: initDataType = {
      apiId,
      sendPageData: { bookingDate: e ? dayjs(e).format("YYYY-MM-DD") : undefined, reserveStatus: selectReserveStatus, dataSource: selectDataSourcs, branchStoreId: contextData?.currentBranchStoreId }
    }
    ref.current.initData(currentData);


  }
  /**选择数据来源 */
  const dataSourceChange = (e: any) => {
    setSelectDataSourcs(e)
    const currentData: initDataType = {
      apiId,
      sendPageData: { bookingDate: date, reserveStatus: selectReserveStatus, dataSource: e, branchStoreId: contextData?.currentBranchStoreId }
    }
    ref.current.initData(currentData);

  }

  /**取消分配暂存房间 */
  const cancelRoom = (record: any) => {

    const { id, tempRooms } = record
    if (!id) return false;
    let data;
    if (!selectData) {
      antMessage("error", "失败", "选中数据不存在")
      return false
    }
    data = selectData[id];
    if (!data || data.length === 0) {
      antMessage("error", "失败", "选中数据不存在")
      return false
    }
    let cancelType = tempRooms.length === data.length ? 1 : 2


    fetchApi.PUT(
      wmService,
      {
        apiId: "tempRoom",
        apiExtend: "cancelAllRoom",
        apiData: {
          customerReserveId: id,
          cancelType,
          tempRoomIds: data
        }
      }
    ).then(res => {

      if (res.success === true) {

        const currentData: initDataType = {
          apiId,
          sendPageData: { bookingDate: date, reserveStatus: selectReserveStatus, dataSource: selectDataSourcs, branchStoreId: contextData?.currentBranchStoreId }
        }
        ref.current.initData(currentData);
        antMessage('success', '退房成功', 'success');

      }
    }).catch(err => {
      console.log(err);

    })
  }

  /**打开确定完成窗口 */
  const openCompleteRoom = (record: any) => {
    setCustomerReserveId(record.id);
    setCompleteRoomModal(true)
  }
  /**关闭确认完成窗口 */
  const onCloseCompleteRoom = (status: boolean) => {
    if (status === true) {
      /**进行刷新操作 */
      const currentData: initDataType = {
        apiId,
        sendPageData: { bookingDate: date, reserveStatus: selectReserveStatus, dataSource: selectDataSourcs, branchStoreId: contextData?.currentBranchStoreId }
      }
      ref.current.initData(currentData);
    }
    setCompleteRoomModal(false)
  }
  return (
    <>

      <label>预订状态 :  </label>
      <Select
        allowClear
        showSearch
        onChange={reserveStatusChange}
        popupMatchSelectWidth={100}
        placeholder="预订状态"
        optionFilterProp="children"
        filterOption={(input, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {getList.general(reserveStatus)}
      </Select>
      <span>   </span>
      <label>预订日期 :  </label>
      <DatePicker
        // defaultValue={moment(data.bookingDate)}
        // value={date}
        format="YYYY-MM-DD"
        onChange={handleDate}
      />
      <span>   </span>
      <label>来源 :  </label>
      <Select
        showSearch
        allowClear
        popupMatchSelectWidth={90}
        onChange={dataSourceChange}
        placeholder="来源"
        optionFilterProp="children"
        filterOption={(input, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {getList.general(dataSource)}
      </Select>
      <span>   </span>
      <WebUniversal ref={ref} props={extendProps} />
      {/* {modalChooseReserveRoomopen ? (<AddRoom data={branchStoreRoomData} getData={initData} closeModal={closeModal}
      />)
        : null} */}
      {modalReplaceReserveDateRoomopen ? (<ReplaceReserveDateRoom data={distributeData} closeModal={closeModal} />)
        : null}

      {invitationModal ? (<InvitationLetter data={invitationData} closeModal={closeInvitation} />)
        : null}

      {reserveMenuModal ? (<ReserveMenu closeModal={closeReserveMenu} customerReserveId={customerReserveId} isModel={true} />) : null}
      {/* completeRoomModal */}

      {completeRoomModal ? (<CompleteRoom customerReserveId={customerReserveId} closeModal={onCloseCompleteRoom} />)
        : null}
      {/**发送信息 */}
      {modalSendMessagelopen ? (<SendMessage data={{ customerReserveId }} closeModal={closeSendMessage}
      />)
        : null}
    </>

  )
}

export default CustomerReserve;
