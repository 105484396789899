import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import system from '../../../config/systematic';
import { Form, Row, Col, DatePicker, Checkbox, Select } from 'antd';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification'

import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import { handleData } from '../warehouse/EntrepotReport/DailyReport';
import getList from '../../universal/getList';


const { RangePicker } = DatePicker;

export default function DelData(props: any) {
        
    /** 后端数据wmService */
    const wmService = system.wmService;
    const [form] = Form.useForm(); //form 
    const { data, closeModal } = props;

    


    const options = [
        { label: '收货单', value: '2'  },
        { label: '调拨单', value: '3' },
    ];


    const [loading, setLoading] = useState<boolean>(false)
    const [entrepotData, setEntrpotData] = useState<any>();

    const [modalopen, setModalopen] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            form.setFieldValue("time", [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')])
            httpGet(wmService, {
                apiId: "entrepot",
                apiExtend: 'showListEx',
                apiData:{
                    mountDataOptions:{
                        userMount: {
                            isUserEntrepots:true
                        }
                       },
                }
            }).then(res => {
                if (res.success) {

                    setEntrpotData(res.data)
                }
            })
        }
        fetchData()
    }, [])
    const handleOk = async (e: any) => {
        setLoading(true)
        form.validateFields()
            .then(async (values: any) => {
                fetchApi.GET(
                    wmService,
                    {
                        apiId: "dataHandle",
                        apiExtend: "delData",
                        apiData: {
                            dataInsert: data ? data.dataInsert : "cost",
                            time: values.time ? {
                                startTime: dayjs(values.time[0]).format("YYYY-MM-DD"),
                                endTime: dayjs(values.time[1]).format("YYYY-MM-DD"),
                            } : null,
                            orders: values.orders.length > 0 ? handleData(values.orders, "orderType") : null,
                            entrepots: values.entrepots?.length > 0 ? handleData(values.entrepots, "entrepotId") : null,
                            entrepotId: data ? data.entrepotId : null,
                        }
                    }
                ).then((res) => {

                    setLoading(false)
                    setModalopen(false);

                    if (closeModal) closeModal(true)
                    antMessage('success', '删除成功', 'success');
                }).catch((err) => {
                    if (closeModal) closeModal(false)
                    setLoading(false)
                    antMessage('error', '错误,删除失败', err.message + ' | status：' + err.errors.status);
                    console.log("err", err);
                })


            })
            .catch((err) => {
                console.log("err", err);

                if (closeModal) closeModal(false)

                setLoading(false)
                antMessage('warning', '无法通过数据校验', '请输入所需数据！');

            })


    }

    //取消
    const handleCancel = (e: any) => {
        if (closeModal) closeModal(false)

        setModalopen(false);
    }
    return (
        <>
            <AntDraggableModal
                title={'订单数据删除'}
                open={modalopen}
                okText='确定'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={450}
                confirmLoading={loading}
            >
                <Form

                    name="Edit"
                    form={form}
                    onFinish={handleOk}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label=""
                                name="orders"
                                rules={[{ required: true, message: '请选择订单类型!' }]}
                            >
                                <Checkbox.Group options={options} />
                            </Form.Item>

                        </Col>

                        {data?.dataInsert === "entrepot" ? <Col span={24} >
                            <Form.Item
                                label="仓库"
                                name="entrepots"
                                rules={[{ required: true, message: '请选择仓库!' }]}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择仓库"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(entrepotData, 'entrepotName', "entrepotId")}
                                </Select>
                            </Form.Item>

                        </Col> : null}


                        <Col span={24}>
                            <Form.Item
                                label="删除日期"
                                name="time"
                                rules={[{ required: true, message: '请选择删除日期!' }]}
                            >
                                <RangePicker
                                    defaultValue={[dayjs().startOf('month'), dayjs()]}
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>






                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}
