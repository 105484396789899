import React, { useEffect, useRef } from 'react';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import PayDetailsEdit from './PayDetailsEdit';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';

const PayDetails = (props: any) => {

    const ref = useRef<any>(undefined);
    const { data,closeModal } = props;
    
    
    /** api服务id */
    const apiId = 'payDetails';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // handleChangeOne = ref.current.handleChangeOne;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '订单id',
            dataIndex: 'cateringOrderId',
        },
        {
            title: '支付方式名称',
            dataIndex: 'paymentMethodName',
        },
        {
            title: '支付金额',
            dataIndex: 'payAmount',
        },
        {
            title: '退款金额(原路)',
            dataIndex: 'refundAmount',
        },
        {
            title: '退款金额(现金)',
            dataIndex: 'cashRefundAmount',
        },

    ];

    const handleOk = (e: any) => {
        closeModal(true);
    };

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '支付详情',
        apiId,
        columns,
        sendPageData: { id:data?.id,dataStatusType:data?.dataStatusType },
        showStates: true,
        pagination: true,
        showAdd:data?.isAdd===1? false:true,
        showDelete:data?.isDelete===1? false:true,
        showEdit:data?.isEdit===1? false:true,
        EditComponent: PayDetailsEdit,
    };

    return (
        <>
        {data?.isModel?<AntDraggableModal
            title={'支付详情'}
            open={true}
            okText='确定'
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={handleOk}
            closable={false}
            maskClosable={false}
            width={1000}>
            <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
        </AntDraggableModal>: <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>}
            
        </>
    )
}

export default PayDetails