import { Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import dayjs from 'dayjs';

const { TextArea } = Input;

const EditComponent = (props: any) => {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    const [deviceTypeData, setDeviceTypeData] = useState<any>();
    const [branchStoreData, setBranchStoreData] = useState<any>();
    const [protocolData, setProtocolData] = useState<any>();
    const [isInterimUseData, setIsInterimUseData] = useState(false);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    /** 后端数据wmService */
    let wmService: any = "";
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (res) {
                    if (res.success === true) {
                        if (data.type === "EDIT") {
                            let ipAddressRow = res.data.ipAddress.split('.');
                            for (var i = 0; i < ipAddressRow.length; i++) {
                                let str = "ipAddress_" + i
                                form.setFieldValue([str], ipAddressRow[i]);
                            }
                            const { isInterimUse } = res.data;
                            setIsInterimUseData(isInterimUse);
                        }
                    }
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "deviceType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setDeviceTypeData(res.data)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBranchStoreData(res.data)
                }
            })
            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'protocol',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setProtocolData(res.data)
                }
            })
        }
        fetchData()
    }, [])


    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'device',
        dateColumnName: ['startDate', 'endDate']
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={`${data.type === 'NEW' ? '新增' : '编辑'}设备`}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={750}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label='id'
                                name='id'
                                tooltip='如不输入，自动生成'>
                                <Input disabled={data.type === 'NEW' ? false : true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="门店"
                                name="branchStoreId">
                                <Select
                                    showSearch
                                    placeholder="门店"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(branchStoreData, {
                                        itemName: 'branchStoreName',
                                        includeNull: true
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="设备码"
                                name="deviceCode"
                                tooltip='设备码，设备识别信息'
                                rules={[{ required: true, message: '请输入设备码!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="名称"
                                name="deviceName"
                                rules={[{ required: true, message: '请输入设备名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="类型"
                                name="deviceTypeId">
                                <Select
                                    showSearch
                                    placeholder="设备类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(deviceTypeData, {
                                        itemName: 'deviceTypeName',
                                        includeNull: true
                                    }
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item
                                label="mac地址"
                                name="macAddress">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="校检mac"
                                name="isVerifyMacAddress"
                                valuePropName="checked"
                                initialValue={false}
                                tooltip='设备登录时校检mac地址是否匹配'>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item label="IP地址" >
                                <Form.Item
                                    name="ipAddress_0"
                                    style={{ display: 'inline-block' }}>
                                    <InputNumber min={0} max={255} />
                                </Form.Item>
                                &nbsp;
                                .
                                &nbsp;
                                <Form.Item
                                    name="ipAddress_1"
                                    style={{ display: 'inline-block' }}
                                >
                                    <InputNumber min={0} max={255} />
                                </Form.Item>
                                &nbsp;
                                .
                                &nbsp;
                                <Form.Item
                                    name="ipAddress_2"
                                    style={{ display: 'inline-block' }}
                                >
                                    <InputNumber min={0} max={255} />
                                </Form.Item>
                                &nbsp;
                                .
                                &nbsp;
                                <Form.Item
                                    name="ipAddress_3"
                                    style={{ display: 'inline-block' }}
                                >
                                    <InputNumber min={0} max={255} />
                                </Form.Item>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="校检ip"
                                name="isVerifyIpAddress"
                                valuePropName="checked"
                                initialValue={false}
                                tooltip='设备登录时校检ip地址是否匹配'>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="ip版本"
                                name="protocolId">
                                <Select
                                    showSearch
                                    placeholder="设备类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(protocolData, { includeNull: true })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="临时"
                                name="isInterimUse"
                                valuePropName="checked"
                                tooltip='在特定时间内临时使用'
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否"
                                    onChange={(e) => { setIsInterimUseData(e); }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="开始时间"
                                name="startDate"
                                rules={[{ required: isInterimUseData, message: '请输入开始时间!' }]}
                                initialValue={dayjs(new Date())}>
                                <DatePicker
                                    disabled={!isInterimUseData}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="结束时间"
                                name="endDate"
                                rules={[{ required: isInterimUseData, message: '请输入结束时间!' }]}>
                                <DatePicker
                                    disabled={!isInterimUseData}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo"
                            >
                                <TextArea
                                    placeholder="备注"
                                    autoSize={{ minRows: 1, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>


                        <Divider >规则</Divider>

                        <Col span={7}>
                            <Form.Item
                                label="检测mac地址"
                                name="isCheckMacAddress"
                                tooltip='新增数据时检测在同分店下是否存在相同mac地址'
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="检测ip地址"
                                name="isCheckIpAddress"
                                tooltip='新增数据时检测在同分店下是否存在相同ip地址'
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;