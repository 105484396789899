import React, { useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import BranchStoreMenuWarehouseSpecEdit from './BranchStoreMenuWarehouseSpecEdit';
import BranchStoreMenuWarehouseSpecSpecial from './BranchStoreMenuWarehouseSpecSpecial';
import { Button, Popconfirm, Space, Switch } from 'antd';
import Synchronize from './Synchronize';
import SetInventory from './inventory/SetInventory';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';

export default function BranchStoreMenuWarehouseSpec(props: any) {

    const ref = useRef<any>(undefined);

    const { closeModal, data } = props;

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    //同步窗口
    const [synchronizeVisible, setSynchronizeVisible] = useState(false);

    //同步数据
    const [synchronizeData, setSynchronizeData] = useState<any>();

    //规格窗口
    const [branchStoreMenuWarehouseSpecSpecialVisible, setBranchStoreMenuWarehouseSpecSpecialVisible] = useState(false);

    //规格数据
    const [branchStoreMenuWarehouseSpecSpecialData, setBranchStoreMenuWarehouseSpecSpecialData] = useState<any>();

    const [inventoryOpen, setInventoryOpen] = useState<boolean>(false);
    const [inventoryData, setInventoryData] = useState<any>();

    /** api服务id */
    const apiId = 'branchStoreMenuWarehouseSpec';

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //打开特殊属性
    const handleBranchStoreMenuWarehouseSpecSpecial = (record: any) => {
        //console.log(record)
        sendData.branchStoreMenuWarehouseSpecId = record.id;
        sendData.branchStoreMenuWarehouseSpecName = record.branchStoreMenuWarehouseSpecName;
        sendData.foodType = data.foodType;
        setBranchStoreMenuWarehouseSpecSpecialData(sendData);
        setBranchStoreMenuWarehouseSpecSpecialVisible(true);
    };

    //关闭门店菜式特殊规格页面
    const onCloseBranchStoreMenuWarehouseSpecSpecial = async (status: boolean) => {
        setBranchStoreMenuWarehouseSpecSpecialVisible(false);
        if (status) {
            await initData();
        }
    }

    // 打开同步/关联
    const handleSynchronize = () => {
        // console.log(data);        

        sendData.branchStoreMenuWarehouseId = data.id;
        sendData.foodName = data.foodName;
        sendData.path = 'BranchStoreMenuWarehouseSpec';
        sendData.key = 'ToMenuSpec'
        setSynchronizeData(sendData);
        setSynchronizeVisible(true);
    };

    //关闭同步页面
    const onCloseSynchronize = async (status: boolean) => {
        setSynchronizeVisible(false);
    }

    //打开库存设置
    const handleSetInventory = (record: any) => {
        let type = record.inventory ? "EDIT" : "NEW";
        let id = record.inventory?.id;
        let exId = record.id;

        if (record.isSpecial) return
        setInventoryOpen(true)
        setInventoryData({ apiId: "menuSpecInventory", type, id, exId, exType: "branchStoreMenuWarehouseSpecId" })

    }

    //关闭库存设置
    const onCloseSetInventory = (status: boolean) => {
        setInventoryOpen(false)
        if (status) {
            ref.current.initData()
        }
    }

    /**库存一键设置 */
    const handleInventory = (apiData: any) => {
        const { apiId, id, columnName, value } = apiData;
        if (!id) return false
        fetchApi.PUT(wmService, {
            apiId,
            apiVariable: id,
            apiExtend: "updateOne",
            apiData: {
                columnName,
                value
            }
        }).then((res) => {
            ref.current.initData()
        }).catch((err) => {

        })
    }

    //表头
    const columnsHead = [
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                // console.log(record);
                return (
                    <div>{record.branchStoreMenuWarehouse.foodName}</div>
                )
            }
        },
        {
            title: data.foodType === 'setMeal' ? '规格/套餐类别' : '规格',
            dataIndex: 'branchStoreMenuWarehouseSpecName',
        },
        {
            title: '基础价',
            dataIndex: 'price',
        },
        {
            title: '库存',
            dataIndex: 'inventory',
            render: (text: any, record: any) => {
                return <div>{text?.quantity}</div>
            }

        },
        {
            title: '库存设置',
            dataIndex: 'price',
            render: (text: any, record: any) => {
                if (record.isSpecial === false) {
                    return <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleSetInventory(record)}>{record.inventory ? '修改' : "设置"}库存</Button>
                        {
                            record.inventory ?
                                <>
                                    <Popconfirm title="确定要库存清零吗？" onConfirm={() => handleInventory({ apiId: "menuSpecInventory", id: record.inventory?.id, columnName: "quantity", value: 0 })} okText="确定" cancelText="取消">
                                        <Button size={"small"} danger>清零</Button>
                                    </Popconfirm>
                                    <Popconfirm title="确定要将该库存设置为99999吗?" onConfirm={() => handleInventory({ apiId: "menuSpecInventory", id: record.inventory?.id, columnName: "quantity", value: 99999 })} okText="确定" cancelText="取消">
                                        <Button size={"small"} danger>最大</Button>
                                    </Popconfirm>
                                </>
                                : null
                        }
                    </Space>
                }
            }
        },
        {
            title: data.foodType === 'setMeal' ? '添加/查看套餐菜式' : '添加属性',
            dataIndex: 'menuSpecSpecial',
            key: 'menuSpeSpecialc',
            render: (text: any, record: any) => {
                if (record.isSpecial) {
                    return (
                        <Space size="small">
                            <Button size={"small"} type={record.branchStoreMenuWarehouseSpecSpecials.length > 0 ? "default" : "primary"} onClick={() => handleBranchStoreMenuWarehouseSpecSpecial(record)}>{data.foodType === 'setMeal' ? (record.branchStoreMenuWarehouseSpecSpecials.length > 0 ? '查看套餐菜式' : '添加套餐菜式') : '添加属性'}</Button>
                        </Space>
                    )
                }
            }
        },
        {
            title: data.foodType === 'setMeal' ? '套餐类别' : '特殊规格',
            dataIndex: 'isSpecial',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];

    const initData = async () => {
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                branchStoreMenuWarehouseId: data.id,
                menuWarehouseId: data.menuWarehouseId,
                foodType: data.foodType
            }
        }
        ref.current.initData(currentData);
    }

    //传关参数
    let sendData = {
        type: 'EDIT',
        branchStoreMenuWarehouseId: '',
        foodName: '',
        branchStoreMenuWarehouseSpecId: '',
        branchStoreMenuWarehouseSpecName: '',
        foodType: '',
        path: '',
        key: ''
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    // const handleOk = (status: Boolean) => {
    //     closeModal(false);
    //     if (status === true) {
    //         getData(true);
    //     }
    // };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: data.foodType === 'setMeal' ? '门店菜式仓库规格 / 套餐类别' : '门店菜式仓库规格',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: BranchStoreMenuWarehouseSpecEdit,
        sendPageData: {
            branchStoreMenuWarehouseId: data.id,
            menuWarehouseId: data.menuWarehouseId,
            foodType: data.foodType
        },
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <>
            <AntDraggableModal
                title={data.foodType === 'setMeal' ? '门店菜式仓库规格 / 套餐类别' : '门店菜式仓库规格'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}            
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={() => closeModal(true)}
                closable={false}
                maskClosable={false}
                width={1200}>
                <Button type="primary" onClick={handleSynchronize}>同步到菜谱</Button>
                <span>&nbsp;&nbsp;</span>
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>

            {synchronizeVisible
                ? (<Synchronize
                    closeModal={onCloseSynchronize} data={synchronizeData} />)
                : null}

            {
                branchStoreMenuWarehouseSpecSpecialVisible
                    ? (<BranchStoreMenuWarehouseSpecSpecial
                        closeModal={onCloseBranchStoreMenuWarehouseSpecSpecial} data={branchStoreMenuWarehouseSpecSpecialData} />)
                    : null
            }
            {
                inventoryOpen
                    ? (<SetInventory
                        closeModal={onCloseSetInventory} data={inventoryData} />)
                    : null
            }

        </>
    )
}