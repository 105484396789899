import { useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ProductSpecsEdit from './ProductSpecsEdit';
import systemConfig from '../../../config/systematic';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';

export default function ProductSpecs(props: any) {

    const ref = useRef<any>(undefined);
    const { data, closeModal } = props;
    
    // const [form] = Form.useForm();
    const {productId}=data

    // const [menuHeadData, setMenuHeadData] = useState<any>();

    /** api服务id */
    const apiId = 'productSpec';
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    //表头
    const columnsHead = [

        {
            title: '规格名称',
            dataIndex: 'productSpecName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
      
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品规格',
        apiId,
        showStates: true,
        columns,
        pagination: true,
        sendPageData: { productId },
        // extendIdName: "deptId",
        EditComponent: ProductSpecsEdit,
    };

    const handleOk = (e: any) => {
        closeModal(true);
    };

    return (
        <>
          <AntDraggableModal
                title={`产品规格`}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal>

        </>
    )
}

