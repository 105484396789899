import React, { useRef } from 'react'
import BackupConfigurationEdit from './BackupConfigurationEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';

export default function BackupConfiguration(props:any) {
     /** api服务id */
     const apiId = 'backupConfiguration';
     const ref = useRef<any>(undefined);
     //表头
     const columnsHead = [
         {
             title: '表名',
             dataIndex: 'tableName',
         },
         {
            title: '备份天数',
            dataIndex: 'backupTime',
        },
        {
            title: "删除数据源",
            dataIndex: "isDeleteSource",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isDeleteSource', e, apiId)}
                />
            )
        },
     ];
 
     const columns = columnsheadId
         .concat(columnsHead as []);
 
     let extendProps: WUExtendPropsType = {
         ...props,
         titleName: '备份配置',
         apiId,
         columns,
         showStates: true,
         pagination:true,
         EditComponent: BackupConfigurationEdit
     };
 
     return (
         <WebUniversal ref={ref} props={extendProps} />
     )
 }

