import React, { useContext, useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import PromoCategoryEdit from './PromoCategoryEdit';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import ShowMenu from '../menu/ShowMenu';
import { Button, Space, Switch } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';
import { SocketContext } from '../../../App';

export default function PromoCategory(props: any) {

    const ref = useRef<any>(undefined);

    const { closeModal, isModel, banquetId } = props;
    const [showMenuVisible, setShowMenuVisible] = useState(false);
    const [showMenuData, setShowMenuData] = useState<any>();

    /** api服务id */
    const apiId = 'promoCategory';

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                branchStoreId
            }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '推广分类名称',
            dataIndex: 'promoCategoryName',
        },
        {
            title: '查看推广菜式',
            dataIndex: 'showMenu',
            key: 'showMenu',
            render: (text: any, record: any) => {
                return <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleShowMenu(record)}>查看推广菜式</Button>
                </Space>
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
        {
            title: '默认推广',
            dataIndex: 'isProDefault',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isProDefault', e)}
                />
            )
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '推广分类',
        apiId,
        showStates: true,
        columns,
        pagination: true,
        EditComponent: PromoCategoryEdit,
        // sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId"), banquetId },
        sendPageData: { branchStoreId: contextData?.currentBranchStoreId, banquetId },
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    const handleOk = (e: any) => {
        closeModal(true);
    };

    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {
        const bodys = {
            apiId: "promoCategory",
            apiVariable: id,
            apiExtend: "updateSingle",
            apiData: {
                columnName,
                isValue
            }
        }
        fetchApi.PUT(wmService, bodys).then(res => {
            antMessage('success', '编辑成功', 'success');
            initData();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    const initData = async () => {
        const currentData: initDataType = {
            apiId,
            // sendPageData: {
            //     shopTypeId: shopTypeIdData,
            //     menuHeadId: menuHeadId,
            //     promoCategoryId: promoCategoryId
            // }
        }
        ref.current.initData(currentData);
    }

    //打开推广菜式页面
    const handleShowMenu = (e: any) => {
        sendData.key = 'menuToPromo';
        sendData.promoCategoryId = e.id;
        sendData.promoCategoryName = e.promoCategoryName;
        sendData.banquetId = e.banquetId;
        sendData.brandId = e.branchStore.brand.id;
        sendData.brandName = e.branchStore.brand.brandName;
        sendData.branchStoreId = e.branchStore.id;
        sendData.branchStoreName = e.branchStore.branchStoreName;
        setShowMenuData(sendData);
        setShowMenuVisible(true);
    };

    //关闭推广菜式页面
    const onCloseShowMenu = async (status: boolean) => {
        setShowMenuVisible(false);
        // if (status) {
        //     await initData();
        // }
    }

    //传关参数
    let sendData = {
        type: 'EDIT',
        key: '',
        promoCategoryId: '',
        promoCategoryName: '',
        banquetId: '',
        brandId: '',
        brandName: '',
        branchStoreId: '',
        branchStoreName: ''
    }

    return (
        <>
            {isModel ? <AntDraggableModal
                title={'推广分类'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={1000}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal> : <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>}

            {showMenuVisible
                ? (<ShowMenu
                    closeModal={onCloseShowMenu} data={showMenuData} />)
                : null}
        </>
    )
}