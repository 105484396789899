import { Col, DatePicker, Form, Input, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';

//布局
const layout = {
    // labelCol: { span: 4 },
    // wrapperCol: { span: 4 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const { sendPageData } = data;

    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";
    const [deviceData, setDeviceData] = useState<any>();
    const [isInterimLoginData, setIsInterimLoginData] = useState(false);

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (res) {
                    if (res.success === true) {
                        if (data.type === "EDIT") {
                            const { isInterimLogin } = res.data;
                            setIsInterimLoginData(isInterimLogin);
                        }
                    }
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: 'device',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    console.log(res.data);

                    setDeviceData(res.data)
                }
            })
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'userLoginDevice',
        sendPageData,
        dateColumnName: ['startDate', 'endDate']
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={`${data.type === 'NEW' ? '新增' : '编辑'}用户登录设备`}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                width={750}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="设备"
                                name="deviceId"
                                rules={[{ required: true, message: '请选择设备!' }]}>
                                <Select
                                    showSearch
                                    placeholder="设备"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(deviceData, {
                                        itemName: 'deviceName',
                                        setItemNameFun: (item: any) => {
                                            let name = '';
                                            if (item) {
                                                const { branchStore, deviceType } = item;
                                                if (branchStore) {
                                                    name = `${branchStore.branchStoreName} - `;
                                                }
                                                if (deviceType) {
                                                    name += `${deviceType.deviceTypeName} - `;
                                                }
                                                name += item.deviceName;
                                            }
                                            return name;
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="临时登录"
                                name="isInterimLogin"
                                valuePropName="checked"
                                tooltip='在特定时间内登录'
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否"
                                    onChange={(e) => { setIsInterimLoginData(e); }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="开始时间"
                                name="startDate"
                                rules={[{ required: isInterimLoginData, message: '请输入开始时间!' }]}
                                initialValue={dayjs(new Date())}>
                                <DatePicker
                                    disabled={!isInterimLoginData}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="结束时间"
                                name="endDate"
                                rules={[{ required: isInterimLoginData, message: '请输入结束时间!' }]}>
                                <DatePicker
                                    disabled={!isInterimLoginData}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;