import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd'
import { useEffect, useState } from 'react';
import antMessage from '../../utils/extend/AntdNotification'
import getList from '../universal/getList';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import fetchApi from '../../utils/api/fetchApi';
import systemConfig from '../../config/systematic';
import './WebProductFormList.css'
//产品formList通用组件
export default function WebProductFormList(props: any) {
    const { formName, form, num, type, ref, setDeleteData, orderType, data, apiId } = props.props;

    const [cycle, setCycle] = useState<boolean>(true)

    const formListData = Form.useWatch(formName, form) as any;






    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;


    const [listDeleteData, setListDeleteData] = useState<Array<any>>([]);

    /**产品数据 */
    const [productSpeData, setProductSpeData] = useState<any>();


    //统计总价格
    const statisticsPrice = () => {
        let totalPrice = 0;
        if (formListData) {
            formListData.map((item: any) => {
                if (item.totalPrice && item.productId) {
                    totalPrice += Number(item.totalPrice)
                }
            })
        }


        return totalPrice.toFixed(2)
    }

    //remove 删除
    const removeList = (remove: Function, num: number, key: number) => {
        //即将删除的数据
        let removeData = form.getFieldsValue()[formName!][num];
        if (removeData && removeData.id) {
            setListDeleteData([...listDeleteData, { ...removeData, state: "delete" }])
            setDeleteData({
                [formName]: [...listDeleteData, { ...removeData, state: "delete" }]
            })
        }
        remove(num)
        delete productSpeData[key]
    }

    const customizeAdd = (add: Function) => {
        add()
        form.setFieldValue([formName, form.getFieldsValue()[formName].length - 1], { state: "insert" })
    }

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.row.then(async (res: any) => {                  
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    let productSpeObj: any = {};
                    //自定义formList数据初始状态
                    let newData: Array<any> = [];

                    for (var i = 0; i < res.data[formName].length; i++) {
                      let  productSpec=res.data[formName][i].productSpec;
                        if (productSpec) {
                            //重构数据
                             let areaProducts=productSpec.product.areaProducts;
                             let id=productSpec.id;
                              let productSpecName=productSpec.productSpecName
                             let productName=productSpec.product.productName;
                             let selfId=productSpec.product.selfId;
                             if(areaProducts&&areaProducts.length===1) productName=areaProducts[0].productName
                            productSpeObj[i] = [
                                {
                                    id,productSpecName,productName,selfId
                                }
                            ]
                            if (type === "EDIT" || type === "VIEW") {
                                newData.push({
                                    ...res.data[formName][i],
                                    state: "origin",

                                })

                            }

                        }


                    }
         
                    form.setFieldsValue({
                        ...res.data,
                        [formName]: newData 
                    })
                    setProductSpeData(productSpeObj)

                }
            })
        }
        if (data && data.id && apiId) {


            fetchApi.GET(wmService, {
                apiId,
                apiVariable: data.id,
                apiExtend: "showSingle"
            }).then((res) => {
                let productObj: any = {};
                //自定义formList数据初始状态
                let newData: Array<any> = []
                for (var i = 0; i < res.data[formName].length; i++) {

                    if (res.data[formName][i].product) {
                        productObj[i] = [res.data[formName][i].product];
                        if (type === "EDIT" || type === "CANCEL") {

                            newData.push({
                                isCal: true,
                                ...res.data[formName][i],
                                state: "origin",
                                productUnitId: res.data[formName][i].product.productUnit.productUnitName,
                                selfId: res.data[formName][i].product.selfId
                            })
                        }
                    }

                }



                form.setFieldsValue({
                    ...res.data,
                })
                form.setFieldsValue({ [formName]: newData })

                setProductSpeData(productObj)

            })
        }
        if (type === "NEW") initFields(num ? Number(num) : 8)

    }, [])

    useEffect(() => {
        if (type === "EDIT") addFields(num ? Number(num) : 8)
        if (type === "CANCEL") addFields(num ? Number(num) : 8)

    }, [formListData])

    const initFields = (num: number) => {

        if (num === 0) return false
        if (cycle) {

            let arr = [];
            for (var i = 0; i < num; i++) {
                arr.push({
                    name: i,
                    key: i,
                    fieldKey: i,
                    isListField: true,
                    state: "insert",
                    isCal: true,
                })
            }

            form.setFieldsValue({
                [formName]: arr
            })
            setCycle(false)
        }

    }
    const addFields = (num: number) => {

        if (formListData && formListData.length > 0) {
            if (num === 0) return false
            if (cycle) {

                let arr = [];
                for (var i = formListData.length; i < num; i++) {
                    arr.push({
                        name: i,
                        key: i,
                        fieldKey: i,
                        isListField: true,
                        state: "insert",
                        isCal: true,
                    })
                }

                form.setFieldsValue({
                    [formName]: [...formListData, ...arr]
                })
                setCycle(false)


            }
        }


    }
   

    return (
        <>

            <Row style={{ width: "100%" }} gutter={1} align="middle" justify="space-between">

                <Col className="gutter-row" span={4}>
                    <div style={{ textAlign: "center" }}>品名</div>
                </Col>
                {/* <Col className="gutter-row" span={2}>
                    <div style={{ textAlign: "center" }}>品号</div>
                </Col> */}
                {/* {orderType === "processingOrder" ? null : <Col className="gutter-row" span={3}>
                    <div style={{ textAlign: "center" }}>规格</div>
                </Col>
                } */}

                {
                    orderType === "incomeStatement" ? null : <Col className="gutter-row" span={2}>
                        <div style={{ textAlign: "center" }}>数量</div>
                    </Col>
                }
                {orderType === "processingOrder" ? null : <Col className="gutter-row" span={4}>
                    <div style={{ textAlign: "center" }}>备注</div>
                </Col>}





                <Col className="gutter-row" span={1}>
                    <div style={{ textAlign: "center" }}></div>
                </Col>

            </Row>
            <Form.List
                name={formName}
                rules={[
                    {
                        validator: async (_, names) => {

                            if (!names || names.length < 1) {

                                //   let message: any;
                                //   if (names.length < 1) {
                                //     message = "至少要一个商品"
                                //   }
                                //   if (type === "EDIT") {
                                //     if (names.length > 8) {

                                //       message = "至多8个商品"
                                //     }
                                //   }

                                antMessage('error', "至少要一个商品", 'error');
                                return Promise.reject(new Error("至少要一个商品"));
                            }
                        },
                    },
                ]}
            >
                {(fields, { add, remove }) => {
                    return (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row style={{ width: "100%" }} gutter={4} justify="space-between">
                                    {/* <Row align={"middle"} justify="center" style={{ display: "flex", justifyContent: "space-between" }}> */}
                                    <Col className="gutter-row" span={4}>
                                        <Form.Item

                                            {...restField}
                                            label=""
                                            name={[name, 'productSpecId']}
                                            rules={[{ required: formListData?.length > 0 && formListData[name] ? formListData[name].productId ? true : false : false, message: '请输入产品!' }]}
                                        >
                                            <Select
                                                // optionLabelProp="label"
                                                style={{ minWidth: 150 }}
                                                popupMatchSelectWidth={260}
                                                showSearch
                                                allowClear
                                                onSearch={(e) => getList.searchEx('productSpec', setProductSpeData, e, productSpeData, {
                                                    keyId: key,
                                                    isFormList: true,
                                                    form,
                                                    // setObj: ["productId", "productUnitId", "selfId", "miniRate"],
                                                    // setObjValue: ["id", "productUnit#productUnitName", "selfId", "miniRate"],
                                                    FormListName: formName,
                                                    name,
                                                    type,
                                                    andWhereObj: {
                                                        mountDataOptions: {
                                                            userMount: {
                                                                isDept: true,
                                                                isUserAreas: true
                                                            }
                                                        }
                                                    },
                                                    // changeFun: chooseUnit
                                                })}
                                              
                                                placeholder="品名"
                                                optionFilterProp="children"
                                                // onInputKeyDown={(e:any) => getList.search('product', setProductData, e, productData, key, true)}
                                                filterOption={(input, option: any) => {
                                                    return true
                                                }}
                                            >
                                                {getList.generalOption(productSpeData ? productSpeData[key] ? productSpeData[key] : [] : [], {
                                                    itemId: "id", noId: false, children: customizeProduct
                                                })}
                                            </Select>


                                        </Form.Item>
                                    </Col>
                                    {/* <Col className="gutter-row" span={2}>
                                        <Form.Item

                                            {...restField}
                                            label=""
                                            name={[name, 'selfId']}

                                        >


                                            <Input disabled placeholder="品号" />


                                        </Form.Item>
                                    </Col> */}

                                    {/* {
                                        orderType === "processingOrder" ? null :
                                            <Col className="gutter-row" span={3}>
                                                <Form.Item
                                                    {...restField}
                                                    // label="规格"
                                                    name={[name, 'productSpecifications']}
                                                >
                                                    <Input onChange={(e) => changeSpecifications(form, e, name, true, formName, type)} placeholder="请输入规格" />
                                                </Form.Item>
                                            </Col>
                                    } */}

                                    <Col className="gutter-row" span={2}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'bNum']}
                                            // label="数量"
                                            rules={[{ required: formListData?.length > 0 && formListData[name] ? formListData[name].productSpecId ? true : false : false, message: '请输入数量' }]}
                                        >
                                            <InputNumber onChange={(e) => bNumChange(form, e, name, true, formName, type)} placeholder="数量" />
                                        </Form.Item>
                                    </Col>


                                  
                                

                                    {
                                        orderType === "processingOrder" ? null :
                                            <Col span={4}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'memo']}
                                                // label="备注"
                                                >
                                                    <Input onChange={(e) => changeMemo(form, e, name, true, formName, type)} placeholder="备注" />
                                                </Form.Item>
                                            </Col>
                                    }


                                    {type === "EDIT" || type === "CANCEL" ?
                                        <Col span={1}>
                                            <MinusCircleOutlined onClick={() => removeList(remove, name, key)} /></Col> : <Col className="gutter-row" span={1}>
                                            <div style={{ textAlign: "center" }}></div>
                                        </Col>}

                                </Row>
                            ))}
                            { <Col span={24}>
                                <Form.Item>
                                    <Button type="dashed" onClick={() => customizeAdd(add)} block icon={<PlusOutlined />}>
                                        添加商品
                                    </Button>
                                </Form.Item>
                            </Col> }

                        </>
                    )
                }
                }
            </Form.List>
            <Row style={{ width: "100%" }} gutter={1} align="middle" justify="space-between">

                <Col className="gutter-row" span={4}>
                    <div style={{ textAlign: "center" }}></div>
                </Col>
                <Col className="gutter-row" span={2}>
                    <div style={{ textAlign: "center" }}></div>
                </Col>
                <Col className="gutter-row" span={2}>
                    <div style={{ textAlign: "center" }}></div>
                </Col>
                {/* <Col className="gutter-row" span={3}>
          <div style={{ textAlign: "center" }}></div>
        </Col> */}
                {
                    orderType === "incomeStatement" ? null : <Col className="gutter-row" span={2}>
                        <div style={{ textAlign: "center" }}></div>
                    </Col>
                }
                {
                    orderType === "incomeStatement" ? null : <Col className="gutter-row" span={2}>
                        <div style={{ textAlign: "center" }}></div>
                    </Col>
                }

                {
                    orderType === "processingOrder" ? null : <Col className="gutter-row" span={2}>
                        <div style={{ textAlign: "center" }}>{statisticsPrice()}</div>
                    </Col>
                }

                <Col className="gutter-row" span={4}>
                    <div style={{ textAlign: "center" }}></div>
                </Col>


                <Col className="gutter-row" span={3}>
                    <div style={{ textAlign: "center" }}></div>
                </Col>
                <Col className="gutter-row" span={2}>
                    <div style={{ textAlign: "center" }}></div>
                </Col>
                <Col className="gutter-row" span={1}>
                    <div style={{ textAlign: "center" }}></div>
                </Col>
            </Row>

        </>

    )
}
/**自定义显示产品数据 */
export let customizeProduct = (item: any) => {
        

    return <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{`${item.productName}`}</div>
        <div>{item.productSpecName}</div>
    </div>
}





/**改变bNum */
export const bNumChange = (form: any, e: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string) => {
    if (isFormList) {
       


        if (type === "EDIT") form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })


    } 



}



/**改变规格 */
const changeSpecifications = (form: any, e: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string) => {
    if (type === "EDIT") form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })
}

/**改变备注 */
const changeMemo = (form: any, e: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string) => {
    if (type === "EDIT") form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })
}