import AntDraggableModal from '../../utils/extend/AntDraggableModal'
import Logger, { loggerType } from './logger/Logger';

const WebUniversalLogger = (props: any) => {
    const { data, closeModal } = props;
    const { logger, queryType, queryId } = data;
    let currentLogger: loggerType = {
        id: 'systemLogger',
        name: '系统日志'
    }
    if (logger) {
        switch (logger.id) {
            case 'opLogger':
                currentLogger = {
                    id: 'opLogger',
                    name: '操作日志'
                }
                break;
            case 'loginLogger':
                currentLogger = {
                    id: 'loginLogger',
                    name: '登录日志'
                }
                break;
            case 'menuOpLogger':
                currentLogger = {
                    id: 'menuOpLogger',
                    name: '菜单操作日志'
                }
                break;
        }
    }

    const dataEx = { ...data, logger: currentLogger };

    return (
        <>
            <AntDraggableModal
                title={`${currentLogger.name}【${queryType}${queryId ? '-' + queryId : ''}】日志`}
                open={true}
                okText='关闭'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={closeModal}
                closable={false}
                maskClosable={false}
                width={1080}
            >
                <Logger data={dataEx} />
            </AntDraggableModal>
        </>
    )
}

export default WebUniversalLogger;