import { Button, Select, Space } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import getList from '../../universal/getList';
import system from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import dayjs from 'dayjs';
import { SocketContext } from '../../../App';

export default function Queuing(props: any) {

    const ref = useRef<any>(undefined);

    const [queuingTypeData, setQueuingTypeData] = useState<any>();
    const [queuingTypeIdData, setQueuingTypeIdData] = useState<any>();

    const [sbData, setSbData] = useState<any>();
    const [sbIdData, setSbIdData] = useState<any>();

    const [statusData, setStatusData] = useState<any>();
    const [queuingStatusData, setQueuingStatusData] = useState<any>([
        {
            value: 'N',
            label: "非候位中"
        },
        {
            value: 'F',
            label: "候位中"
        },
        {
            value: 'T',
            label: "已就餐"
        },
        {
            value: 'O',
            label: "已过号"
        },
        {
            value: 'C',
            label: "用户已取消"
        },
        {
            value: 'A',
            label: "所有状态"
        },
    ]);

    /** api服务id */
    const apiId = 'queuingList';

    /** 后端数据wmService */
    let wmService: any = system.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "queuingType",
                apiExtend: 'showList',
                apiData: {
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    }
                }
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", queuingTypeName: "所有类型" }, ...res.data];
                    setQueuingTypeData(newData);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "sb",
                apiExtend: 'showList',
                apiData: {
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    }
                }
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", sbName: "自动检测市别" }, ...res.data];
                    setSbData(newData);
                }
            });

            //拿号

            // fetchApi.POST(wmService, {
            //     apiId: "queuingList",
            //     apiExtend: "addToList",
            //     apiData: {
            //         // selectDay:'2023-01-19 11:11:10',
            //         // sbId:'17d279c022ec11eea7cbb7054e45f08f',
            //         people: 6,
            //         // phoneNumber:'13100000000'
            //     }
            // }).then(res => {
            //     if (res.success) {
            //         console.log(res.data);
            //     }
            // })


            //查询手机号码
            // fetchApi.POST(wmService, {
            //     apiId: "queuingList",
            //     apiExtend: "checkPhoneNum",
            //     apiData: {
            //         phoneNumber:'18922768050'
            //     }
            // }).then(res => {
            //     if (res.success) {
            //         console.log(res.data);
            //     }
            // })


            //就餐

            // fetchApi.PUT(wmService, {
            //     apiId: "queuingList",
            //     apiExtend: 'onFinish',
            //     apiData: {
            //         queuingListId: '88b939002b8c11ee9ea8bfacf6fb8a3c',
            //         key: 'finish'
            //     }
            // }).then(res => {
            //     if (res.success) {
            //         console.log(res.data);
            //     }
            // })


            // 排队列表

            // fetchApi.GET(wmService, {
            //     apiId: "queuingType",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         console.log(res.data);
            //     }
            // });

            // 指定类别的排队列表

            // fetchApi.GET(wmService, {
            //     apiId: "queuingType",
            //     apiExtend: 'showTypeList',
            //     apiVariable: 'f51f0f00277e11ee9dc52d7f8d52b45f',
            //     apiData: {
            //         sbId: 'c7a4e4600a5e11ee992cebc127994b87'
            //         // status: 'T'
            //     }
            // }).then(res => {
            //     if (res.success) {
            //         console.log(res.data);
            //     }
            // });
        }

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                sbId: sbIdData,
                queuingTypeId: queuingTypeIdData,
                status: statusData,
                branchStoreId: branchStoreId
            }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    //查看不同市别
    const sbChange = (e: any) => {
        setSbIdData(e);
        const currentData: initDataType = {
            apiId,
            // sendPageData: { sbId: e, queuingTypeId: queuingTypeIdData, status: statusData, branchStoreId: localStorage.getItem("currentBranchStoreId") }
            sendPageData: {
                sbId: e,
                queuingTypeId: queuingTypeIdData,
                status: statusData,
                branchStoreId: contextData?.currentBranchStoreId
            }
        }
        ref.current.initData(currentData);
    }

    //查看不同类型
    const queuingTypeChange = (e: any) => {
        setQueuingTypeIdData(e);
        const currentData: initDataType = {
            apiId,
            // sendPageData: { sbId: sbIdData, queuingTypeId: e, status: statusData, branchStoreId: localStorage.getItem("currentBranchStoreId") }
            sendPageData: {
                sbId: sbIdData,
                queuingTypeId: e,
                status: statusData,
                branchStoreId: contextData?.currentBranchStoreId
            }
        }
        ref.current.initData(currentData);
    }

    //查看不同状态
    const queuingStatusChange = (e: any) => {
        setStatusData(e);
        const currentData: initDataType = {
            apiId,
            // sendPageData: { sbId: sbIdData, queuingTypeId: queuingTypeIdData, status: e, branchStoreId: localStorage.getItem("currentBranchStoreId") }
            sendPageData: {
                sbId: sbIdData,
                queuingTypeId: queuingTypeIdData,
                status: e,
                branchStoreId: contextData?.currentBranchStoreId
            }
        }
        ref.current.initData(currentData);
    }

    //  功能操作
    const handleAction = (record: any, key: any) => {
        fetchApi.PUT(wmService, {
            apiId: "queuingList",
            apiExtend: 'onFinish',
            apiData: {
                queuingListId: record.id,
                key: key
            }
        }).then(res => {
            if (res.success) {
                const currentData: initDataType = {
                    apiId,
                    // sendPageData: { sbId: sbIdData, queuingTypeId: queuingTypeIdData, status: statusData, branchStoreId: localStorage.getItem("currentBranchStoreId") }
                    sendPageData: {
                        sbId: sbIdData,
                        queuingTypeId: queuingTypeIdData,
                        status: statusData,
                        branchStoreId: contextData?.currentBranchStoreId
                    }
                }
                ref.current.initData(currentData);
            }
        })
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.queuingType.branchStore.brand.brandName + " " + record.queuingType.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '类型',
            dataIndex: 'queuingTypeName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.queuingType.queuingTypeName}</div>
                )
            }
        },
        {
            title: '时间',
            dataIndex: 'sysDate',
            render: (text: any, record: any) => {
                return (
                    <div>{dayjs(record.sysDate.createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>
                )
            }
        },
        {
            title: '市别',
            dataIndex: 'sb',
            render: (text: any, record: any) => {
                return (
                    <div>{record.sb.sbName}</div>
                )
            }
        },
        {
            title: '人数',
            dataIndex: 'people',
        },
        {
            title: '号码',
            dataIndex: 'queuingNum',
        },
        {
            title: '渠道',
            dataIndex: 'queuingChannel',
            render: (text: any, record: any) => {
                return (
                    <div>{record.queuingChannelName + (record.queuingChannel === 1 ? '(' + record.phoneNumber + ')' : '')}</div>
                )
            }
        },
        {
            title: '微信帐号',
            dataIndex: 'wxAccountId',
        },
        {
            title: '叫号',
            dataIndex: 'numberOfCalls',
            render: (text: any, record: any) => {
                return (
                    <div>{record.numberOfCalls}</div>
                )
            }
        },
        {
            title: '打印',
            dataIndex: 'numberOfPrints',
            render: (text: any, record: any) => {
                return (
                    <div>{record.numberOfPrints}</div>
                )
            }
        },
        {
            title: '备注',
            dataIndex: 'memo',
            render: (text: any, record: any) => {
                return (
                    <div>{record.memo}</div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (text: any, record: any) => {
                return (
                    <div>{record.status === 'F' ? "候位中" : record.status === 'T' ? "已就餐" : record.status === 'O' ? "已过号" : record.status === 'C' ? "用户已取消" : ""}</div>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                // if (record.status === 'F') {
                //     return (
                //         <Space size="small">
                //             <Button size={"small"} type="primary" onClick={() => handleAction(record, 'finish')}>就餐</Button>
                //             <Button size={"small"} type="primary" onClick={() => handleAction(record, 'ot')}>过号</Button>
                //         </Space>
                //     )
                // }
                if (record.status === 'T' || record.status === 'O') {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleAction(record, 'back')}>撤销</Button>
                        </Space>
                    )
                }

            }
        },
    ];

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: ''
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    // const onRefresh = (status: Boolean) => {
    //     const currentData: initDataType = {
    //         apiId,
    //     }
    //     ref.current.initData(currentData);
    // }

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '排队列表',
        apiId,
        columns,
        // showImage: true,
        // showUploadFile: true,
        showStates: false,
        showEdit: false,
        showQuery: true,
        showOperation: false,
        pagination: true,
        isAutomaticRefresh: true,
        showDelete: false,
        showAdd: false,
        sendPageData: {
            queuingTypeId: queuingTypeIdData,
            branchStoreId: contextData?.currentBranchStoreId
        },
        // sendPageData: { queuingTypeId: queuingTypeIdData, branchStoreId: localStorage.getItem("currentBranchStoreId") },
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <>
            <label>市别 :  </label>
            <Select
                style={{ width: 150 }}
                showSearch
                defaultValue="自动检测市别"
                onChange={sbChange}
                placeholder="市别"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(sbData, "sbName")}
            </Select>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <label>排队类型 :  </label>
            <Select
                style={{ width: 150 }}
                showSearch
                defaultValue="所有类型"
                onChange={queuingTypeChange}
                placeholder="排队类型"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(queuingTypeData, "queuingTypeName")}
            </Select>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <label>排队状态 :  </label>
            <Select
                style={{ width: 150 }}
                showSearch
                defaultValue="非候位中"
                onChange={queuingStatusChange}
                placeholder="排队状态"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={queuingStatusData}>
                {/* {getList.general(queuingStatusData, "queuingStatusName")} */}
            </Select>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
        </>
    )
}