import { Badge, Button, Card, Drawer, Input, List, Modal, Select, Space, Switch, Tooltip } from 'antd'
import React, { useEffect, useState, useContext, useRef } from 'react'
import TempRoomList from '../../generalComponents/TempRoomList';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import RoomCateringOrder from '../cateringOrder/RoomCateringOrder';
import moment from 'moment';
import PendingDetail from '../cateringOrder/PendingDetail';
import { rtnType } from '../../../types/general';
import { SocketContext } from '../../../App';

export default function RejectionList(props: any) {
    const [rows, setRows] = useState<any>();

    const useContextData = useContext(SocketContext);
    const { wsServer } = useContextData;


    const apiData = useRef(undefined);

    /**楼层Id */
    const [floorId, setfloorId] = useState<any>("998")
    /**
     * 房间类型Id
     */
    const [roomTypeId, setRoomTypeId] = useState<any>("999")
    /** 房间状态*/
    const [roomStatus, setRoomStatus] = useState<any>(-1)

    const [floorData, setFloorData] = useState<any>();
    const [roomTypeData, setRoomTypeData] = useState<any>();
    const [roomStatusData, setRoomStatusData] = useState<any>();
    const [currentData, setCurrentData] = useState(1);
    const [pageSizeData, setPageSizeData] = useState(12);
    const [totalData, setTotalData] = useState(0);
    const [queryData, setQueryData] = useState('');
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    /** 数据刷新中 */
    let loading = false;
    const [roomCateringOrderOpen, setRoomCateringOrderOpen] = useState<boolean>(false)
    const [roomCateringOrderData, setRoomCateringOrderData] = useState<any>([]);

    const [pendingDetailOpen, setPendingDetailOpen] = useState<boolean>(false)
    const [pendingDetailData, setPendingDetailData] = useState<any>();
    //当前市别名称
    const [timeTypeName, setTimeTypeName] = useState<any>()

    //当前市别id
    const [currentTimeTypeId, setCurrentTimeTypeId] = useState<any>(undefined);

    //是否显示市别弹窗
    const [timeTypeOpen, setTimeTypeOpen] = useState<boolean>(false);


    //市别数据
    const [timeTypeData, setTimeTypeData] = useState<Array<any>>([]);


    useEffect(() => {
        initData()

        const fetchData = async () => {
        

            httpGet(wmService, {
                apiId: "roomType",
                apiExtend: 'showList',
                apiData: {
                    extendObj:{
                        menuHeadsIdNum:-1,

                    },
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }
            }).then(res => {
                if (res.success) {


                    let newData = [{ id: "999", roomType: "所有类型" }, ...res.data]
                    setRoomTypeData(newData)
                }
            })

            httpGet(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "roomStatus",
            }).then(res => {
                if (res.success) {

                    setRoomStatusData(res.data)
                }
            })
            fetchApi.GET(wmService, {
                apiId: "roomReserve",
                apiExtend: 'getDateToTimeData',
                apiData: {
                    bookingDate: moment().format("YYYY-MM-DD"),
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }
            }).then(res => {
                if (res.success === true) {
                    setTimeTypeData(res.data.rows)
                }
            })


        }
        fetchData()

        if (wsServer.connected) {
            wsServer.addListener(["rejectionList"], { rejectionList: wsListener }
            )
        }

        return () => {
            wsServer.removeListener(["rejectionList"])
        }
    }, [])







    /**
       * ws监听
       * @param payload 接收到的数据
       * @param callback 回复服务器
       */
    const wsListener = (payload: rtnType, callback: Function) => {
        if (payload.success === true) {
            const { data } = payload;
            const { apiId } = data;
            switch (apiId) {
                case 'wxCateringOrder':
                    const { apiExtend } = data;
                    switch (apiExtend) {
                        case "rejectionList":
                            const {queryString,timeTypeId,roomTypeId,roomStatus,floorId}=apiData.current as any;
                            getData(roomTypeId, roomStatus, floorId, currentData, pageSizeData, queryString, timeTypeId)

                            break;
                    }
                    break;
                default:
                    break;
            }
        } else {
        }
    };


    //获取数据
    const getData = (roomTypeId: string, roomStatus: number, floorId: string, current: number,
        pageSize: number,
        queryString: string, timeTypeId?: string) => {
        if (loading === true) { return }
        loading = true;
        fetchApi.GET(
            wmService,
            {
                apiId: "tempRoom",
                apiExtend: 'query',
                apiData:{
                    roomTypeId,
                    roomStatus: Number(roomStatus),
                    floorId,
                    current,
                    pageSize,
                    queryString,
                    orderByString: "roomA.roomSort@ASC",
                    timeTypeId: timeTypeId ? timeTypeId : currentTimeTypeId,
                    isOrderSb: true,
                    isShowPending:true,
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }
               

            }).then(res => {

                if (res.success === true) {
                
                    loading = false;
                    setCurrentTimeTypeId(res.data.findTime.id);
                    setTimeTypeName(res.data.findTime.typeName);
                    setRows(res.data.rows)
                    setCurrentData(res.data.current);
                    setPageSizeData(res.data.pageSize);
                    setTotalData(res.data.total);
                    setQueryData(res.data.queryString);
                    setRoomTypeId(res.data.roomTypeId);
                    setfloorId(res.data.floorId);
                    setRoomStatus(res.data.roomStatus);
                    let obj: any = {
                        queryString:res.data.queryString,
                        timeTypeId:res.data.findTime.id,
                        roomTypeId:res.data.roomTypeId,
                        roomStatus:res.data.roomStatus,
                        floorId:res.data.floorId,
                    }
                    apiData.current = obj




                }

            })
    }

    const initData = (timeTypeId?: string) => {
        let timeTypeIdEx = timeTypeId ? timeTypeId : currentTimeTypeId;

        getData(
            roomTypeId,
            roomStatus,
            floorId,
            currentData,
            pageSizeData,
            queryData,
            timeTypeIdEx
        )
    }

    const openTimeType = (status: boolean, timeTypeId?: string) => {
        setTimeTypeOpen(false)
        if (status) {
            initData(timeTypeId)
        }
    }





    //关闭查看房间订单
    const closeRoomCateringOpen = (state: boolean) => {
        setRoomCateringOrderOpen(false)
        if (state === true) {
            initData()
        }
    }

    const handleRoomCateringOrder = (item: any, caOrderStatus: number) => {

        //  console.log("???",item);
        if (item.roomStatus === 0) return false
        setRoomCateringOrderData({ roomId: item.roomId, timeTypeId: item.timeTypeId, caOrderStatus })
        setRoomCateringOrderOpen(true)
    }


    /**打开待处理页面 */
    const hanldePendingDetailOpen = (item: any) => {

        setPendingDetailOpen(true)
        setPendingDetailData({ roomId: item.roomId, sbId: item.timeType.scheduledTime.sbId, tempRoomId: item.id })

    }

    /**关闭待处理页面 */
    const closePendingDetailOpen = (state: boolean) => {
        setPendingDetailOpen(false)
        if (state === true) {
            initData()
        }
    }

    //更改房间类型
    const roomTypeChange = (e: any) => {
        setRoomTypeId(e)
        getData(
            e,
            roomStatus,
            floorId,
            1,
            pageSizeData,
            queryData
        )
    }

    ///更改房间状态
    const reserveStatusChange = (e: any) => {
        setRoomStatus(e)
        getData(
            roomTypeId,
            e,
            floorId,
            1,
            pageSizeData,
            queryData
        )
    }
    const floorChange = (e: any) => {
        setfloorId(e)
        getData(
            roomTypeId,
            roomStatus,
            e,
            1,
            pageSizeData,
            queryData
        )
    }
    
    // const RefreshData = (checked: boolean) => {

    //     setIsRefresh(checked)
    // }

    const renderOption = (value: any, option: any) => {
        return (
            <div style={{ fontSize: '25px' }}>
                {value.label}
            </div>
        );
    };



    return (
        <>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: 30 }}>
                    <div>
                        <label>房间类型 :  </label>
                        <Select
                            style={{ width: 200, height: 50 }}
                            size="large"
                            onChange={roomTypeChange}
                            optionRender={renderOption}
                            placeholder="房间类型"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            options={roomTypeData}
                            fieldNames={
                                { value: "id", label: "roomType" }
                            }

                        >

                            {/* {getList.general(roomTypeData, "roomType")} */}
                        </Select>
                        <span> - </span>
                        <label>房间状态 :  </label>
                        <Select
                            style={{ width: 200, height: 50, }}
                            size="large"
                            optionRender={renderOption}
                            onChange={reserveStatusChange}
                            placeholder="房间状态"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            options={roomStatusData}
                            fieldNames={
                                { value: "id", label: "name" }
                            }

                        >
                            {/* {getList.general(roomStatusData, "name")} */}
                        </Select>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <label>房间楼层 :  </label>
                        <Select
                            style={{ width: 200, height: 50 }}
                            size="large"
                            optionRender={renderOption}
                            options={floorData}
                            fieldNames={
                                { value: "id", label: "floor" }
                            }
                            onChange={floorChange}
                            placeholder="楼层"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>

                        </Select>

                        <span> - </span>
                        <label>查询 :  </label>
                        <Space>
                            <Input
                                size='large'
                                style={{ width: 200, height: 50, fontSize: 30 }}
                               onKeyDown={(e: any) => {
                                    if (e.key === 'Enter')
                                        getData(roomTypeId,
                                            roomStatus,
                                            floorId,
                                            currentData,
                                            pageSizeData,
                                            queryData)
                                }}
                                onChange={(e: any) => {

                                    setQueryData(e.target.value)
                                }
                                }
                            />
                        </Space>

                        {/* <label style={{ marginLeft: 20 }}>自动刷新 :  </label> */}
                        {/* <Switch style={{ transform: 'scale(2)', marginLeft: 20 }} onClick={RefreshData} checked={isRefresh} checkedChildren="开启" unCheckedChildren="关闭" ></Switch> */}
                    </div>





                </div>
                <div style={{ fontSize: 30 }} onClick={() => setTimeTypeOpen(true)}>
                    <Tooltip title="点击可修改市别">
                        当前市别:{timeTypeName}
                    </Tooltip>
                </div>



            </div>
            <div style={{ marginTop: "20px" }}>
                <TempRoomList props={{
                    listObj: {
                        itemLayout: "horizontal",
                        grid: { gutter: 20, column: 4 },
                        dataSource: rows,
                        renderItem: (item: any) => {
                      
                            // let result = item.room.cateringOrders.some((item: any) => item.cateringOrderDetails.length > 0);
                            return (
                                <List.Item>
                                    <Card
                                        style={{ backgroundColor: item.isPending  ? "red" : item.color }}
                                        title={<div style={{ fontSize: 30, textAlign: "center" }} onClick={() => handleRoomCateringOrder(item, 2)}>{item.room.roomName}</div>}
                                        actions={!item.isPending ? [
                                            <div style={{ fontSize: "25px", color: "#141414" }}>开台</div>

                                        ] : [

                                            // <div onClick={() => handleRoomCateringOrder(item, 2)}>已接订单</div>
                                            <div style={{ width: "100%", color: item.isPending  ? "red" : "", fontSize: "25px" }} onClick={() => hanldePendingDetailOpen(item)}>待处理</div>

                                        ]}

                                    >
                                        <div onClick={() => handleRoomCateringOrder(item, 2)} style={{ fontSize: "20px", width: "100%", height: "100%" }}>{item.isPending ? "待处理" : item.roomStatusName}</div>
                                    </Card>
                                </List.Item>
                            )
                        }

                    }
                }}>

                </TempRoomList>
            </div>

            {/**查看房间订单 */}
            {roomCateringOrderOpen ? (<RoomCateringOrder data={roomCateringOrderData} closeModal={closeRoomCateringOpen}
            />)
                : null}

            {/**查看当前房间需要处理的数据 */}
            {pendingDetailOpen ? (<PendingDetail data={pendingDetailData} closeModal={closePendingDetailOpen}
            />)
                : null}

            {/**查看当天市别 */}

            {timeTypeOpen ? <Modal

                title={<div style={{ fontSize: "30px" }}>请选择市别</div>}
                open={true}
                onCancel={() => openTimeType(true)}
                onOk={() => openTimeType(false)}
                footer={null}
                width={"60%"}
                centered
            >

                <List
                    size="large"
                    dataSource={timeTypeData}
                    renderItem={(item) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                title={<div style={{ fontSize: "35px" }}>{item.typeName}</div>}
                            />
                            <Button type="primary" size="large" onClick={() => openTimeType(true, item.id)}>
                                选择
                            </Button>
                        </List.Item>
                    )}
                />
            </Modal> : null}
        </>

    )
}
