import React, { useRef, useState } from 'react'
import { columnsheadId } from '../../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../../system/WebUniversalNewEx';
import { Button, Popconfirm } from 'antd';
import ImCondimentInventoryEdit from './ImCondimentInventoryEdit';
import fetchApi from '../../../../utils/api/fetchApi';
import system from "../../../../config/systematic";


export default function ImCondimentInventory(props: any) {
  const ref = useRef<any>(undefined);
  /**后端数据wmService */
  const wmService = system.wmService;
  const apiId="imCondimentInventory"
  //表头
  const columnsHead = [
    {
        title: '加料',
        dataIndex: 'condimentWarehouse',
        render: (text: any) => (text ? text.condimentWarehouseName : "")
      },
   
    {
      title: '数量(库存)',
      dataIndex: 'quantity',
    },
    {
      title: '定时模式',
      dataIndex: 'timingModeName',
    },
  ];
  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '加料固定库存',
    apiId,
    // showAdd: false,
    columns,
    pagination: true,
    EditComponent: ImCondimentInventoryEdit,
    mountDataOptions:{
      userMount:{
         isDept:true,
      }
 },

  };
  const cleanMenuInventory = () => {
    fetchApi.POST(wmService, {
      apiId,
      apiExtend: "cleanMenuInventory",
      mountDataOptions:{
        userMount:{
           isDept:true,
        }
   },

    }).then((res) => {
      if (res.success) {
        ref.current.initData()
      }


    }).catch((err) => {
      console.log("err", err);

    })
  }
  return (
    <>
      {/* <Popconfirm title="是否清空库存?" onConfirm={cleanMenuInventory}>
        <Button >清空库存</Button>
      </Popconfirm> */}
      {/* <span>  </span> */}
      <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
    </>
  )
}
