import dayjs from "dayjs";
import ExcelJS from "exceljs"
import moment from "moment";
//const ExcelJS = require('exceljs');
//import { FileSaver } from 'file-saver';
const FileSaver = require('file-saver');

//组件 DownloadExcel 
const DownloadExcel = (rows: any, apiId: any, queryCriteria?: any) => {
    if (rows.length === 0 || typeof rows === "undefined") return
    //供应商申购单
    if (apiId === "suPurchaseApplication") {
        console.log(rows.supplierName);
        console.log("该内容是" + rows.supplierName);
        console.log(rows);
        let abc: Array<any> = []
        let newData: Array<any> = []
        //修改rows.dept数据结构
        rows.depts.forEach((item: any) => {
            let oider = {
                id: item.productId,
                productName: item.product.productName,
                depts: [],
                total: 0
            }
            if (abc.indexOf(item.productId) === -1) {
                abc.push(item.productId)

                newData.push(oider)


            }
            newData.forEach((v3: any) => {
                if (v3.id === item.productId) {
                    oider.total += item.payCount
                    v3.depts.push(item)
                }
            })
        })
        // 数据
        let data = rows.depts
        // console.log("获取表格内容", data)
        let dataTable: Array<any> = [];
        let dataAB: Array<any> = []
        data.map((item: any) => {
            if (dataAB.indexOf(item.deptId) === -1) {
                dataAB.push(item.deptId)
            }
        })
        dataAB.forEach((v) => {
            let order = {
                header: null,
                key: v,
                width: 20
            }
            data.forEach((item: any) => {
                if (v === item.deptId) {
                    order.header = item.dept.deptName
                }
            })
            dataTable.push(order)
        })

        // dataTable.push({ header: item.dept.deptName, key: item.deptId, width: 20 })

        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('申购订单', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */
        const dataA = [{ header: '产品名称', key: 'productName', width: 25 },
        { header: '单位', key: 'productUnitName', width: 25 },
        ...dataTable,
        { header: '合计', key: 'total', width: 15 }]
        worksheet.columns = dataA

        // 添加行数据
        let aa: Array<any> = []
        let bb: Array<any> = []
        newData.forEach((item) => {

            item.depts.forEach((item1: any) => {

                if (aa.indexOf(item.id) === -1) {
                    aa.push(item.id)
                    let oider = {
                        id: item.id,
                        productUnitName: item1.product.productUnit.productUnitName,
                        productName: item.productName,
                        [item1.deptId]: item1.payCount,
                        total: 0,
                    }
                    bb.push(oider)
                }
                bb.forEach((item2: any) => {

                    if (item2.id === item1.productId) {
                        item2[item1.deptId] = item1.payCount
                        item2.total += item1.payCount
                    }
                })
            })

        });
        // worksheet.addRow(["xxx"]);
        worksheet.addRows(bb);
        worksheet.insertRow(1, ["xxx"]);
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = `致:${rows.supplierName}`; // A1 的值设置为"销售报表"
        // // 设置标题行的行高
        titlerow.height = 40;


        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };
        //  // 合并单元格
        worksheet.mergeCells('A1:J1');

        for (var i = 1; i <= dataA.length; i++) {

            // 设置每个单元格的周围设置单个细边框
            if (i === 1) {
                worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            }
            else {

                if (i === dataA.length) {

                    worksheet.getRow(i).height = 20;

                }
                //设置边框
                worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            }
            //设置字体
            worksheet.getCell('A' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };
            worksheet.getCell('B' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };
            worksheet.getCell('C' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };
            worksheet.getCell('D' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };
            worksheet.getCell('E' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };



            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
        }

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";

        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '申购表.xlsx');
        })

    }


    //部门申购单
    if (apiId === "dePurchaseApplication") {
        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('部门下单表', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };
        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '品名', key: "productName", width: 16 },
            { header: '数量', key: "payCount", width: 30 },
            { header: '单位', key: "productUnitName", width: 16 },
        ];
        // 数据
        let data = rows.products;
        worksheet.addRows(data);
        worksheet.insertRow(1, [rows.deptName]);
        worksheet.insertRow(2, ["以下品种需" + rows.orderTime + "下单"]);
        worksheet.insertRow(3, [rows.classificationWarehouseName]);

        const titlerowOne = worksheet.getRow(1);
        titlerowOne.height = 20;
        const titlerowTwo = worksheet.getRow(2);
        titlerowTwo.height = 20;
        const titlerowThree = worksheet.getRow(3);
        titlerowThree.height = 20;

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 5,
            size: 10,
            bold: true
        };
        worksheet.getCell('A2').font = {
            name: '宋体',
            family: 4,
            size: 10,
            bold: true
        };
        worksheet.getCell('A3').font = {
            name: '宋体',
            family: 3,
            size: 10,
            bold: true
        };
        //  // 合并单元格
        worksheet.mergeCells('A1:C1');
        worksheet.mergeCells('A2:C2');
        worksheet.mergeCells('A3:C3');


        for (var i = 1; i <= data.length; i++) {

            // 设置每个单元格的周围设置单个细边框
            if (i === 1) {
                worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            }
            else {

                if (i === data.length) {

                    worksheet.getRow(i).height = 20;

                }
                //设置边框
                worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            }
            //设置字体
            worksheet.getCell('A' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };
            worksheet.getCell('B' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };
            worksheet.getCell('C' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };
            worksheet.getCell('D' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };
            worksheet.getCell('E' + i + '').font = { name: '宋体', family: 4, size: 15, bold: true };



            //居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
        }




        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
        worksheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };



        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '部门申购表.xlsx');
        })
    }


    //票据报表
    if (apiId === "moonReport") {
        console.log("该内容是" + apiId);
        console.log(rows);


        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('票据报表', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 25 },
            { header: '', width: 25 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 20 },
            { header: '', width: 20 },
            { header: '', width: 10 },
        ];
        // 数据
        let data = rows;
        //总兑换数量
        let totalChanged = 0;
        //总售出数量
        let totalSolded = 0;
        //折后价
        let totalAmount = 0;
        //原价（售出）
        let toTalOriginAmount = 0;
        //原价（兑换）

        let toTalOriginExAmount = 0;

        let dataTable: any = []
        data.map((item: any) => {
            dataTable.push([
                item.branchStoreName, item.moonType, item.disCount, item.solded, item.changed, item.originAmount, item.originExAmount, item.amount
            ])
            if (item.changed) totalChanged += item.changed
            if (item.solded) totalSolded += item.solded;
            if (item.amount) totalAmount += item.amount;
            if (item.originAmount) toTalOriginAmount += item.originAmount;
            if (item.originExAmount) toTalOriginExAmount += item.originExAmount;

        })

        // 将数据插入
        // queryCriteria
        worksheet.addRow([`${queryCriteria.timeNameA}: ${moment(queryCriteria.startTime).format('YYYY-MM-DD')}  至 ${moment(queryCriteria.endTime).format('YYYY-MM-DD')}   分店:${queryCriteria.branchStoreName}   票据状态: ${queryCriteria.moonStatusName}     票据类型: ${queryCriteria.moonType} `]);
        worksheet.addRow(['分店', '票据类型', '折扣', '已售出', '已兑换', '原总价(售出)', '原总价(兑换)', '折后价']);
        worksheet.addRows(dataTable);
        worksheet.addRow(['合计', '', '', '', '', '', ""]);
        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "票据报表"; // A1 的值设置为"票据报表"

        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 30;
        const totalrow = worksheet.getRow(rows.length + 4);
        totalrow.getCell('D').value = totalSolded;   //  将值设置为总售出数量
        totalrow.getCell('E').value = totalChanged;  // 将值设置为总兑换数量
        totalrow.getCell('F').value = toTalOriginAmount.toFixed(2);  // 将值设置为原总价(售出）
        totalrow.getCell('G').value = toTalOriginExAmount.toFixed(2);  // 将值设置为原总价(兑换)

        totalrow.getCell('H').value = totalAmount.toFixed(2);  // 将值设置为总折后价

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };

        let sumEnd = rows.length + 4
        //  // 合并单元格
        for (var i = 1; i <= sumEnd; i++) {
            // if (i === 1) {
            //     worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            // }
            // else {

            worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            // }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };


        }

        // 合并单元格
        worksheet.mergeCells('A1:H1');
        worksheet.mergeCells('A2:H2');
        worksheet.mergeCells('A' + sumEnd + ':C' + sumEnd + '');

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };
        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '票据报表.xlsx');
        })

    }

    //仓库收货统计报表
    if (apiId === "enPurStatistics") {
        console.log("该内容是" + apiId);
        console.log(rows);


        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('收货统计报表', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 25 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 20 },

        ];
        // 数据
        let data = rows;


        let dataTable: any = []
        data.map((item: any) => {
            dataTable.push([
                item.selfId, item.productName, item.classificationWarehouseName, item.productUnitName, item.bNum, item.univalence, item.amount,
            ])


        })

        // 将数据插入
        worksheet.addRow([`时间: ${queryCriteria.date}  部门:${queryCriteria.deptName} 分类:${queryCriteria.classificationWarehouses} 品号: ${queryCriteria.selfs} 采购商: ${queryCriteria.buyerName} `]);
        worksheet.addRow(['品号', '品名', "分类", '单位', '数量', '均价', '金额']);
        worksheet.addRows(dataTable);
        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "收货统计表"; // A1 的值设置为"收货统计表"

        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;
        // const totalrow = worksheet.getRow(rows.length + 4);
        // totalrow.getCell('D').value = totalSolded;   //  将值设置为总售出数量
        // totalrow.getCell('E').value = totalChanged;  // 将值设置为总兑换数量
        // totalrow.getCell('F').value = toTalOriginAmount.toFixed(2);  // 将值设置为原总价(售出）
        // totalrow.getCell('G').value = toTalOriginExAmount.toFixed(2);  // 将值设置为原总价(兑换)

        // totalrow.getCell('H').value = totalAmount.toFixed(2);  // 将值设置为总折后价

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };

        let sumEnd = rows.length + 3;
        //  // 合并单元格
        for (var i = 1; i <= sumEnd; i++) {
            // if (i === 1) {
            //     worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            // }
            // else {

            worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            // }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };


        }

        // 合并单元格
        worksheet.mergeCells('A1:G1');
        worksheet.mergeCells('A2:G2');

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };
        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '收货统计表.xlsx');
        })

    }

    //仓库调拨统计报表
    if (apiId === "enTrStatistics") {
        console.log("该内容是" + apiId);
        console.log(rows);


        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('调拨统计报表', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 25 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 20 },

        ];
        // 数据
        let data = rows;


        let dataTable: any = []
        data.map((item: any) => {
            dataTable.push([
                item.selfId, item.productName, item.classificationWarehouseName, item.productUnitName, item.bNum, item.univalence, item.amount,
            ])


        })

        // 将数据插入
        worksheet.addRow([`时间: ${queryCriteria.date}  部门:${queryCriteria.deptName} 产品: ${queryCriteria.selfs} 分类:${queryCriteria.classificationWarehouses} 部门模式: ${queryCriteria.deptModel} `]);
        worksheet.addRow(['品号', '品名', "分类", '单位', '数量', '均价', '金额']);
        worksheet.addRows(dataTable);
        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "调拨统计表"; // A1 的值设置为"收货统计表"

        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;
        // const totalrow = worksheet.getRow(rows.length + 4);
        // totalrow.getCell('D').value = totalSolded;   //  将值设置为总售出数量
        // totalrow.getCell('E').value = totalChanged;  // 将值设置为总兑换数量
        // totalrow.getCell('F').value = toTalOriginAmount.toFixed(2);  // 将值设置为原总价(售出）
        // totalrow.getCell('G').value = toTalOriginExAmount.toFixed(2);  // 将值设置为原总价(兑换)

        // totalrow.getCell('H').value = totalAmount.toFixed(2);  // 将值设置为总折后价

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };

        let sumEnd = rows.length + 3;
        //  // 合并单元格
        for (var i = 1; i <= sumEnd; i++) {
            // if (i === 1) {
            //     worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            // }
            // else {

            worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            // }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };


        }

        // 合并单元格
        worksheet.mergeCells('A1:G1');
        worksheet.mergeCells('A2:G2');

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };
        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '调拨统计表.xlsx');
        })

    }

    // productQuery
    //商品查询
    if (apiId === "productQuery") {
        console.log("该内容是" + apiId);
        console.log(rows);


        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('商品查询', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 15 },
            { header: '', width: 15 },
            { header: '', width: 15 },
            { header: '', width: 25 },
            { header: '', width: 15 },
        ];
        // 数据
        let data = rows;


        let dataTable: any = []
        data.map((item: any) => {
            dataTable.push([
                item.areaName, item.classificationWarehouseName, item.selfId, item.productName, item.productUnitName,
            ])


        })

        // 将数据插入
        worksheet.addRow([`区域: ${queryCriteria.areaName}  分类:${queryCriteria.classificationWarehouses}`]);
        worksheet.addRow(['区域', '分类', "品号", "品名", "单位"]);
        worksheet.addRows(dataTable);
        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "商品查询"; // A1 的值设置为"商品查询"

        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;
        // const totalrow = worksheet.getRow(rows.length + 4);
        // totalrow.getCell('D').value = totalSolded;   //  将值设置为总售出数量
        // totalrow.getCell('E').value = totalChanged;  // 将值设置为总兑换数量
        // totalrow.getCell('F').value = toTalOriginAmount.toFixed(2);  // 将值设置为原总价(售出）
        // totalrow.getCell('G').value = toTalOriginExAmount.toFixed(2);  // 将值设置为原总价(兑换)

        // totalrow.getCell('H').value = totalAmount.toFixed(2);  // 将值设置为总折后价

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };

        let sumEnd = rows.length + 4
        //  // 合并单元格
        for (var i = 1; i <= sumEnd; i++) {
            // if (i === 1) {
            //     worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            // }
            // else {

            worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            // }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };


        }

        // 合并单元格
        worksheet.mergeCells('A1:E1');
        worksheet.mergeCells('A2:E2');

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };
        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '商品查询.xlsx');
        })

    }


    //成本收货统计报表
    if (apiId === "costPurStatistics") {
        console.log("该内容是" + apiId);
        console.log(rows);


        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('成本收货统计报表', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 15 },
            { header: '', width: 15 },
            { header: '', width: 6 },
            { header: '', width: 15 },
            { header: '', width: 6 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 6 },
            { header: '', width: 6 },

        ];
        // 数据
        let data = rows;


        let dataTable: any = []
        data.map((item: any) => {
            dataTable.push([
                item.buyerName, item.classificationWarehouseName, item.selfId, item.productName, item.productUnitName, item.univalence, item.cost, item.count, item.bNum, item.amount,
            ])


        })

        // 将数据插入
        worksheet.addRow([`时间: ${queryCriteria.date}  部门:${queryCriteria.deptName} 分类:${queryCriteria.classificationWarehouses} 品号: ${queryCriteria.selfs} 采购商: ${queryCriteria.buyerName} `]);
        worksheet.addRow(['采购商', '分类', "品号", '品名', '单位', '平均单价', '平均成本', "购买次数", "数量", "金额"]);
        worksheet.addRows(dataTable);
        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "成本收货统计报表"; // A1 的值设置为"收货统计表"

        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;
        // const totalrow = worksheet.getRow(rows.length + 4);
        // totalrow.getCell('D').value = totalSolded;   //  将值设置为总售出数量
        // totalrow.getCell('E').value = totalChanged;  // 将值设置为总兑换数量
        // totalrow.getCell('F').value = toTalOriginAmount.toFixed(2);  // 将值设置为原总价(售出）
        // totalrow.getCell('G').value = toTalOriginExAmount.toFixed(2);  // 将值设置为原总价(兑换)

        // totalrow.getCell('H').value = totalAmount.toFixed(2);  // 将值设置为总折后价

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };

        let sumEnd = rows.length + 3
        //  // 合并单元格
        for (var i = 1; i <= sumEnd; i++) {
            // if (i === 1) {
            //     worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            // }
            // else {

            worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            // }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('I' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('J' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };

            // worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };


        }

        // 合并单元格
        worksheet.mergeCells('A1:J1');
        worksheet.mergeCells('A2:J2');

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };
        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '成本收货统计报表.xlsx');
        })

    }

    //成本调拨统计报表
    if (apiId === "costTrStatistics") {
        console.log("该内容是" + apiId);
        console.log(rows);


        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('调拨统计报表', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 10 },
            { header: '', width: 15 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },

        ];
        // 数据
        let data = rows;


        let dataTable: any = []
        data.map((item: any) => {
            dataTable.push([
                item.selfId, item.productName, item.productUnitName, item.bNum, item.univalence, item.amount,
            ])


        })

        // 将数据插入
        worksheet.addRow([`时间: ${queryCriteria.date}  部门:${queryCriteria.deptName} 产品: ${queryCriteria.selfs}  部门模式: ${queryCriteria.deptModel} `]);
        worksheet.addRow(['品号', '品名', '单位', '数量', '均价', '金额']);
        worksheet.addRows(dataTable);
        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "调拨统计表"; // A1 的值设置为"收货统计表"

        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;
        // const totalrow = worksheet.getRow(rows.length + 4);
        // totalrow.getCell('D').value = totalSolded;   //  将值设置为总售出数量
        // totalrow.getCell('E').value = totalChanged;  // 将值设置为总兑换数量
        // totalrow.getCell('F').value = toTalOriginAmount.toFixed(2);  // 将值设置为原总价(售出）
        // totalrow.getCell('G').value = toTalOriginExAmount.toFixed(2);  // 将值设置为原总价(兑换)

        // totalrow.getCell('H').value = totalAmount.toFixed(2);  // 将值设置为总折后价

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };

        let sumEnd = rows.length + 3;
        //  // 合并单元格
        for (var i = 1; i <= sumEnd; i++) {
            // if (i === 1) {
            //     worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            // }
            // else {

            worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            // }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };


        }

        // 合并单元格
        worksheet.mergeCells('A1:F1');
        worksheet.mergeCells('A2:F2');

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };
        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '调拨统计表.xlsx');
        })

    }

    //成本收货对比表
    if (apiId === "costPurContrast") {
        console.log("该内容是" + apiId);
        console.log(rows);


        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('成本收货对比表', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 6 },
            { header: '', width: 10 },
            { header: '', width: 6 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },

        ];
        // 数据
        let data = rows;


        let dataTable: any = []
        data.map((item: any) => {
            dataTable.push([
                item.selfId, item.productName, item.productUnitName, item.lastBNum, item.lastUnivalence, item.lastAmount,
                item.nowBNum, item.nowUnivalence, item.nowAmount, item.bNumDifference, item.univalenceDifference, item.amountDifference,
            ])


        })

        // 将数据插入
        worksheet.addRow([`部门:${queryCriteria.deptName} 分类:${queryCriteria.classificationWarehouses} 品号: ${queryCriteria.selfs} 采购商: ${queryCriteria.buyerName} 上次日期: ${queryCriteria.lastTime} 本次日期: ${queryCriteria.nowTime} `]);
        worksheet.addRow(['品号', '品名', "单位", '上次数量', '上次均价', '上次金额', '本次数量', "本次均价", "本次金额", "数量差异", "单价差异", "金额差异"]);
        worksheet.addRows(dataTable);
        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "成本收货对比表"; // A1 的值设置为"成本收货对比表"

        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;
        // const totalrow = worksheet.getRow(rows.length + 4);
        // totalrow.getCell('D').value = totalSolded;   //  将值设置为总售出数量
        // totalrow.getCell('E').value = totalChanged;  // 将值设置为总兑换数量
        // totalrow.getCell('F').value = toTalOriginAmount.toFixed(2);  // 将值设置为原总价(售出）
        // totalrow.getCell('G').value = toTalOriginExAmount.toFixed(2);  // 将值设置为原总价(兑换)

        // totalrow.getCell('H').value = totalAmount.toFixed(2);  // 将值设置为总折后价

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };

        let sumEnd = rows.length + 4
        //  // 合并单元格
        for (var i = 1; i <= sumEnd; i++) {

            // if (i === 1) {
            //     worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            // }
            // else {


            worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('K' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('L' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            // }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('I' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('J' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('K' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('L' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };


        }

        // 合并单元格
        worksheet.mergeCells('A1:L1');
        worksheet.mergeCells('A2:L2');

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };
        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '成本收货对比表.xlsx');
        })

    }
    /**出品部门统计 */
    if (apiId === "makeDept") {
        console.log("该内容是" + apiId);
        console.log(rows);
        let branchStoreName = localStorage.getItem("currentBranchStoreName")
        let brandName = localStorage.getItem("currentBrandName")
        

        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('出品部门统计', {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 8 },
            { header: '', width: 17 },
            { header: '', width: 10 },
            { header: '', width: 15 },
            { header: '', width: 15 },
            { header: '', width: 15 },

            // { header: '', width: 15 },
            // { header: '', width: 10 },
            { header: '', width: 15 },
            { header: '', width: 10 },
            { header: '', width: 15 },
            { header: '', width: 10 },



        ];
        // 数据
        let data = rows;


        let dataTable: any = []
        data.map((item: any, index: number) => {
            dataTable.push([
                index + 1,
                item.deptName,
                item.amount,
                item.advancePrice,
                item.discountPrice,
                item.reducedPrice,

                // item.foodBackPrice,
                // item.foodBackAmount,
                item.refundPrice,
                item.refundAmount,
                item.giftPrice,
                item.giftAmount,
            ])


        })
        let { date, sbName, userName, caCoMonStatusName } = queryCriteria;


        let startTime;
        let endTime;


        if (date.length > 0) {
            startTime = dayjs(date[0]).format("YYYY-MM-DD")
            endTime = dayjs(date[1]).format("YYYY-MM-DD")

        }

        // 将数据插入
        worksheet.addRow([`${date ? `日期:${startTime}至${endTime}` : ""}`]);
        worksheet.addRow([`单位:${brandName}${branchStoreName}`, '', '', `市别:${sbName}`, '', '']);
        worksheet.addRow([`菜状态:${caCoMonStatusName}`]);


        worksheet.addRow(['序号', '部门', '数量', "金额(折前)", '折扣', '金额(折后)', '金额(退货)', '数量(退货)', '金额(赠送)', '数量(赠送)']);
        worksheet.addRows(dataTable);
        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "出品部门统计"; // A1 的值设置为"出品部门统计"

        // 设置标题行的行高
        titlerow.height = 40;
        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;
        // const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        // searchParameterrow.height = 40;
        // const totalrow = worksheet.getRow(rows.length + 4);
        // totalrow.getCell('D').value = totalSolded;   //  将值设置为总售出数量
        // totalrow.getCell('E').value = totalChanged;  // 将值设置为总兑换数量
        // totalrow.getCell('F').value = toTalOriginAmount.toFixed(2);  // 将值设置为原总价(售出）
        // totalrow.getCell('G').value = toTalOriginExAmount.toFixed(2);  // 将值设置为原总价(兑换)

        // totalrow.getCell('H').value = totalAmount.toFixed(2);  // 将值设置为总折后价

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };
        // 设置字体
        worksheet.getRow(3).font = {
            name: '宋体',
            family: 4,
            size: 15,
            bold: true
        };
        worksheet.getRow(4).font = {
            name: '宋体',
            family: 4,
            size: 15,
            bold: true
        };
        let sumEnd = rows.length + 6
        //  // 合并单元格
        for (var i = 1; i <= sumEnd; i++) {

            if (i === 1) {

            } else {
                // worksheet.getRow(i).height = 30;
            }
            worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('K' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('L' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };





            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('I' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('J' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('K' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('L' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };




        }

        // 合并单元格
        worksheet.mergeCells('A1:J1');
        worksheet.mergeCells('A2:J2');
        worksheet.mergeCells('A3:C3');
        worksheet.mergeCells('D3:J3');
        worksheet.mergeCells('A4:J4');




        let finallyRow = worksheet.getRow(sumEnd);

        finallyRow.getCell(1).value = `制表人:${userName}`; // A1 的值设置为"收货统计表"
        finallyRow.getCell(5).value = `打印时间:${moment().format("YYYY-MM-DD HH:mm:ss")}`; // A1 的值设置为"收货统计表"
        worksheet.mergeCells(`A${sumEnd}:B${sumEnd}`)
        worksheet.mergeCells(`E${sumEnd}:J${sumEnd}`)

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: "middle", horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        worksheet.getCell(`A${sumEnd}`).alignment = { vertical: "middle", horizontal: "justify", wrapText: true };
        worksheet.getCell(`A3`).alignment = { vertical: "middle", horizontal: "left", wrapText: true };
        worksheet.getCell(`E3`).alignment = { vertical: "middle", horizontal: "right", wrapText: true };
        worksheet.getCell('A4').alignment = { vertical: "middle", horizontal: 'center', wrapText: true };
        //去除边框线
        worksheet.getRow(1).border = {};
        worksheet.getRow(2).border = {};
        worksheet.getRow(3).border = {};
        worksheet.getRow(4).border = {};

        worksheet.getRow(sumEnd).border = {};


        //  设置页脚(默认居中)
        // worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel

        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '出品部门统计.xlsx');
        })

    }


    /**销售明细统计 */
    if (apiId === "salesDetail") {
        console.log("该内容是" + apiId);
        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        let branchStoreName = localStorage.getItem("currentBranchStoreName")
        let brandName = localStorage.getItem("currentBrandName")
        //添加工作表
        const worksheet = workbook.addWorksheet('销售明细统计', {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        let { date, shopTypeName, sbName, userName, caCoMonStatusName, columnsHead } = queryCriteria;
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };


        // 添加列标题并定义列键和宽度
        worksheet.columns = columnsHead
        worksheet.addRows(rows)



        let startTime;
        let endTime;

        if (date.length > 0) {
            startTime = dayjs(date[0]).format("YYYY-MM-DD")
            endTime = dayjs(date[1]).format("YYYY-MM-DD")

        }
        // 将数据插入
        worksheet.insertRow(1, [`分类: ${shopTypeName},菜状态:${caCoMonStatusName}`]);
        worksheet.insertRow(1, [`单位:${brandName}${branchStoreName}`, '', `x`, ``, `市别:${sbName}`, '', '']);
        worksheet.insertRow(1, [` ${date ? `日期:${startTime}至${endTime}` : ""}`]);
        worksheet.insertRow(1, [`销售明细统计`]);

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;

        let sumEnd = rows.length + 6;
        worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
            row.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                cell.font = { size: 12, name: 'Arial' };
                cell.alignment = { horizontal: 'center', vertical: 'middle' };

            })
            if (rowNumber === 1) {
                row.font = { size: 25, name: 'Arial', bold: true };
                row.height = 40;
                row.alignment = { horizontal: 'center', vertical: 'middle' };
            } else if (rowNumber === 2) {
                row.font = { size: 20, name: 'Arial', bold: true };
                row.height = 40;
                row.alignment = { horizontal: 'left', vertical: 'middle' };
            } else if (rowNumber === 3) {
                row.font = { size: 18, name: 'Arial', bold: true };
                row.height = 30;
                row.alignment = { horizontal: 'left', vertical: 'middle' };
            }
            else if (rowNumber === 4) {
                row.font = { size: 16, name: 'Arial', bold: true };
                row.height = 20;
                row.alignment = { horizontal: 'left', vertical: 'middle' };
            }

        });
        // 合并单元格
        worksheet.mergeCells(`A1:${String.fromCharCode(columnsHead.length + 64)}1`);
        worksheet.mergeCells(`A2:${String.fromCharCode(columnsHead.length + 64)}2`);
        worksheet.mergeCells('A3:D3');
        worksheet.mergeCells(`E3:${String.fromCharCode(columnsHead.length + 64)}3`);


        worksheet.mergeCells(`A4:${String.fromCharCode(columnsHead.length + 64)}4`);



        let finallyRow = worksheet.getRow(sumEnd);
        // worksheet.ge
        finallyRow.getCell(1).value = `制表人:${userName}`; // A1 的值设置为"收货统计表"
        finallyRow.getCell(5).value = `打印时间:${moment().format("YYYY-MM-DD HH:mm:ss")}`; // A1 的值设置为"收货统计表"

        worksheet.mergeCells(`A${sumEnd}:D${sumEnd}`)
        worksheet.mergeCells(`E${sumEnd}:${String.fromCharCode(columnsHead.length + 64)}${sumEnd}`)


        //去除边框线
        worksheet.getRow(1).border = {};
        worksheet.getRow(2).border = {};
        worksheet.getRow(3).border = {};
        worksheet.getRow(4).border = {};


        worksheet.getRow(sumEnd).border = {};

        // 居中对齐并自动换行
        worksheet.getRow(1).alignment = { vertical: "middle", horizontal: 'center', wrapText: true };
        worksheet.getRow(2).alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        // worksheet.getRow(3).alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        worksheet.getRow(4).alignment = { vertical: "middle", horizontal: "center", wrapText: true };

        worksheet.getCell(`A3`).alignment = { vertical: "middle", horizontal: "left", wrapText: true };
        worksheet.getCell(`E3`).alignment = { vertical: "middle", horizontal: "right", wrapText: true };

        worksheet.getCell(`A${sumEnd}`).alignment = { vertical: "middle", horizontal: "left", wrapText: true };
        worksheet.getCell(`E${sumEnd}`).alignment = { vertical: "middle", horizontal: "right", wrapText: true };
        //  设置页脚(默认居中)
        // worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '销售明细统计.xlsx');
        })


    }

    /**结账方式统计 */
    if (apiId === "paymentMethodCount") {
        console.log("该内容是" + apiId);
        let branchStoreName = localStorage.getItem("currentBranchStoreName")
        let brandName = localStorage.getItem("currentBrandName")

        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('结账方式统计', {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 15 },
            { header: '', width: 15 },
            { header: '', width: 15 },
            { header: '', width: 15 },
            { header: '', width: 15 },



        ];
        // 数据
        let data = rows;


        let dataTable: any = [];
        data.map((item: any, index: number) => {
            dataTable.push([
                index + 1, item.paymentMethodName, item.amount, item.payAmount, item.refundAmount, item.cashRefundAmount, item.realIncome
            ])


        })
        let { date, sbName, userName, actualOrderCounts, orderCounts, refundOrderCounts } = queryCriteria;

        let startTime;
        let endTime;

        if (date.length > 0) {
            startTime = dayjs(date[0]).format("YYYY-MM-DD")
            endTime = dayjs(date[1]).format("YYYY-MM-DD")

        }
        // 将数据插入
        worksheet.addRow([`${date ? `日期:${startTime}至${endTime}` : ""}`]);
        worksheet.addRow([`单位:${brandName}${branchStoreName}`, "", "", "", `市别:${sbName}`]);

        worksheet.addRow([`订单笔数:${orderCounts}`, "", `退款笔数${refundOrderCounts}`, "", '', `成交笔数:${actualOrderCounts}`, '']);



        worksheet.addRow(['序号', '项目', '数量', "金额", "退款(原路)", "退款(现金)", "实际收入"]);
        worksheet.addRows(dataTable);
        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "结账方式统计"; // A1 的值设置为"收货统计表"


        worksheet.addRow([`单位:${branchStoreName}`, `市别:${sbName}`]);

        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;

        //获取第四行
        const firstRow = worksheet.getRow(4);
        firstRow.height = 30;

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };
        // 设置字体
        worksheet.getRow(2).font = {
            name: '宋体',
            family: 4,
            size: 15,
            bold: true
        };
        // 设置字体
        worksheet.getRow(3).font = {
            name: '宋体',
            family: 4,
            size: 15,
            bold: true
        };

        // 设置字体
        worksheet.getRow(4).font = {
            name: '宋体',
            family: 4,
            size: 15,
            bold: true,

        };

        let sumEnd = rows.length + 6;
        //  // 合并单元格
        for (var i = 1; i <= sumEnd; i++) {
            worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };


            // worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            // }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };


            // worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };


        }

        // 合并单元格
        worksheet.mergeCells('A1:G1');
        worksheet.mergeCells('A2:G2');


        worksheet.mergeCells('A3:D3');
        worksheet.mergeCells('E3:G3');

        worksheet.mergeCells('A4:B4');
        worksheet.mergeCells('C4:E4');

        worksheet.mergeCells('F4:G4');

        //去除边框线
        worksheet.getRow(1).border = {};
        worksheet.getRow(2).border = {};
        worksheet.getRow(3).border = {};
        worksheet.getRow(4).border = {};

        // worksheet.getRow(3).border = {};





        let finallyRow = worksheet.getRow(sumEnd);

        finallyRow.getCell(1).value = `制表人:${userName}`; // A1 的值设置为"收货统计表"
        finallyRow.getCell(3).value = `打印时间:${moment().format("YYYY-MM-DD HH:mm:ss")}`; // A1 的值设置为"收货统计表"

        worksheet.mergeCells(`A${sumEnd}:B${sumEnd}`)
        worksheet.mergeCells(`C${sumEnd}:G${sumEnd}`)

        worksheet.getRow(sumEnd).border = {};

        // 居中对齐并自动换行
        worksheet.getRow(1).alignment = { vertical: "middle", horizontal: 'center', wrapText: true };
        worksheet.getRow(2).alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        worksheet.getCell(`A${sumEnd}`).alignment = { vertical: "middle", horizontal: "left", wrapText: true };
        worksheet.getCell(`G${sumEnd}`).alignment = { vertical: "middle", horizontal: "right", wrapText: true };
        worksheet.getCell(`A3`).alignment = { vertical: "middle", horizontal: "left", wrapText: true };
        worksheet.getCell(`E3`).alignment = { vertical: "middle", horizontal: "right", wrapText: true };



        //  设置页脚(默认居中)
        // worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });


        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '结账方式统计.xlsx');
        })


    }

    /**房台统计 */
    if (apiId === "roomPlatformStatistics") {
        console.log("该内容是" + apiId);
        console.log("rows", rows);

        let branchStoreName = localStorage.getItem("currentBranchStoreName")
        let brandName = localStorage.getItem("currentBrandName")

        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('房台统计', {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });
        // 创建表头
        /**
         * header: 显示的内容
         * key: 设置一个唯一的key作为属性名，添加行数据时与之对应
         * width: 设置单元格的宽
         */

        // worksheet.addRow(["xxx"]);
        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度

        // 数据



        let { date, sbName, userName, roomTypeName, columnsHead } = queryCriteria;
        worksheet.columns = columnsHead

        let startTime;
        let endTime;

        if (date.length > 0) {
            startTime = dayjs(date[0]).format("YYYY-MM-DD")
            endTime = dayjs(date[1]).format("YYYY-MM-DD")

        }
        worksheet.addRows(rows);
        // 将数据插入
        worksheet.insertRow(1, [`房型:${roomTypeName ? roomTypeName : "所有"}`]);
        worksheet.insertRow(1, [`单位:${brandName}${branchStoreName}`, '', '', '', '', `市别:${sbName}`, '', '']);
        worksheet.insertRow(1, [`${date ? `日期:${startTime}至${endTime}` : ""} `]);
        worksheet.insertRow(1, ["房台统计"]);

        // 获取一个行对象。
        const titlerow = worksheet.getRow(1);

        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 40;


        // 设置字体
        worksheet.getRow(1).font = {
            name: '宋体',
            family: 4,
            size: 20,
            bold: true
        };
        // 设置字体
        worksheet.getRow(3).font = {
            name: '宋体',
            family: 4,
            size: 15,
            bold: true
        };
        let sumEnd = rows.length + 6;
        worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
            row.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                cell.font = { size: 12, name: 'Arial' };
                cell.alignment = { horizontal: 'center', vertical: 'middle' };

            })
            if (rowNumber === 1) {
                row.font = { size: 25, name: 'Arial', bold: true };
                row.height = 40;
                row.alignment = { horizontal: 'center', vertical: 'middle' };
            } else if (rowNumber === 2) {
                row.font = { size: 20, name: 'Arial', bold: true };
                row.height = 40;
                row.alignment = { horizontal: 'left', vertical: 'middle' };
            } else if (rowNumber === 3) {
                row.font = { size: 18, name: 'Arial', bold: true };
                row.height = 30;
                row.alignment = { horizontal: 'left', vertical: 'middle' };
            }
            else if (rowNumber === 4) {
                row.font = { size: 16, name: 'Arial', bold: true };
                row.height = 20;
                row.alignment = { horizontal: 'left', vertical: 'middle' };
            }

        });

        // 合并单元格
        worksheet.mergeCells(`A1:${String.fromCharCode(columnsHead.length + 64)}1`);
        worksheet.mergeCells(`A2:${String.fromCharCode(columnsHead.length + 64)}2`);
        worksheet.mergeCells(`A3:E3`);
        worksheet.mergeCells(`F3:${String.fromCharCode(columnsHead.length + 64)}3`);
        worksheet.mergeCells(`A4:${String.fromCharCode(columnsHead.length + 64)}4`);



        let finallyRow = worksheet.getRow(sumEnd);


        worksheet.mergeCells(`A${sumEnd}:F${sumEnd}`)
        worksheet.mergeCells(`G${sumEnd}:${String.fromCharCode(columnsHead.length + 64)}${sumEnd}`);

        finallyRow.getCell(1).value = `制表人:${userName}`;
        finallyRow.getCell(7).value = `打印时间:${moment().format("YYYY-MM-DD HH:mm:ss")}`;

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: "middle", horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        worksheet.getCell('A4').alignment = { vertical: "middle", horizontal: "center", wrapText: true };

        worksheet.getCell(`A${sumEnd}`).alignment = { vertical: "middle", horizontal: "justify", wrapText: true };
        worksheet.getCell(`A3`).alignment = { vertical: "middle", horizontal: "left", wrapText: true };
        worksheet.getCell(`F3`).alignment = { vertical: "middle", horizontal: "right", wrapText: true };




        //去除边框线
        worksheet.getRow(1).border = {};
        worksheet.getRow(2).border = {};
        worksheet.getRow(3).border = {};
        worksheet.getRow(4).border = {};

        worksheet.getRow(sumEnd).border = {};
        //  设置页脚(默认居中)
        // worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";
        // 下载 excel
        // workbook.xlsx.writeBuffer().then((buf) => {
        //     let blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        //     const downloadElement = document.createElement('a')
        //     let href = window.URL.createObjectURL(blob)
        //     downloadElement.href = href
        //     downloadElement.download = "申购表.xlsx"; // 文件名字
        //     document.body.appendChild(downloadElement)
        //     downloadElement.click()
        //     document.body.removeChild(downloadElement) // 下载完成移除元素
        //     window.URL.revokeObjectURL(href) // 释放掉blob对象
        // });


        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '房台统计.xlsx');
        })


    }

    /**加工厂统计 */
    if (apiId === "processingStatistics") {
        console.log("该内容是" + apiId);
        console.log(queryCriteria);

        const { time, columnsHead } = queryCriteria
        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('加工厂统计', {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });


        worksheet.columns = columnsHead;


        worksheet.addRows(rows)
        worksheet.insertRow(1, [`日期:${time}`]);
        worksheet.insertRow(1, ["切配中心下单表"]);
        worksheet.eachRow(function (row, rowNumber) {
            row.eachCell({ includeEmpty: true }, (cell, rowNumber) => {


                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                cell.font = { size: 12, name: 'Arial' };
                cell.alignment = { horizontal: 'center', vertical: 'middle' };

            })
            if (rowNumber === 1) {
                row.font = { size: 16, name: 'Arial', bold: true };
                row.height = 40;
                row.alignment = { horizontal: 'center', vertical: 'middle' };
            } else if (rowNumber === 2) {
                row.font = { size: 14, name: 'Arial', };
                row.alignment = { horizontal: 'left', vertical: 'middle' };
            }

        });


        worksheet.mergeCells(`A1:${String.fromCharCode(columnsHead.length + 64)}1`);
        worksheet.mergeCells(`A2:${String.fromCharCode(columnsHead.length + 64)}2`);


        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '切配中心.xlsx');
        })

    }

    /**加工厂申购统计 */
    if (apiId === "processingRequisition") {
        console.log("该内容是" + apiId);
        console.log(queryCriteria);
        console.log("rows", rows);

        const { time, columnsHead } = queryCriteria
        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('切配中心申购单', {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });


        worksheet.columns = columnsHead;


        worksheet.addRows(rows)
        worksheet.insertRow(1, [`日期:${time}`]);
        worksheet.insertRow(1, ["切配中心申购单"]);
        worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
            row.eachCell({ includeEmpty: true }, (cell, rowNumber) => {


                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                cell.font = { size: 12, name: 'Arial' };
                cell.alignment = { horizontal: 'center', vertical: 'middle' };

            })
            if (rowNumber === 1) {
                row.font = { size: 16, name: 'Arial', bold: true };
                row.height = 40;
                row.alignment = { horizontal: 'center', vertical: 'middle' };
            } else if (rowNumber === 2) {
                row.font = { size: 14, name: 'Arial', };
                row.alignment = { horizontal: 'left', vertical: 'middle' };
            }

        });


        worksheet.mergeCells(`A1:${String.fromCharCode(columnsHead.length + 64)}1`);
        worksheet.mergeCells(`A2:${String.fromCharCode(columnsHead.length + 64)}2`);


        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '切配中心申购单.xlsx');
        })

    }
    //菜谱头的所有菜式
    if (apiId === "menuHead") {
        // console.log("该内容是" + apiId);
        // console.log(rows);
        // console.log(queryCriteria);

        // 创建工作薄
        const workbook = new ExcelJS.Workbook();

        // 添加工作表并进行页面设置
        // 使用 A4 横向的页面设置创建新工作表
        const worksheet = workbook.addWorksheet(queryCriteria.branchStoreName + " - " + queryCriteria.menuHeadName, {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });

        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.4,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '序号', width: 5 },
            { header: '菜名', width: 45 },
            { header: '单位', width: 10 },
            { header: '规格', width: 20 },
            { header: '售价', width: 15 },
        ];

        // 数据
        let data = rows
        //console.log("获取表格内容", data)

        let productbegin: any = []     //  用来记录产品开始循环的位置
        let productend: any = []     //  用来记录产品结束循环的位置

        let j = 2

        let dataTable: any = []
        data.map((item: any, index: any) => {

            let i = 1;  // 由于productOrderDetails数据里面，最后的2条是没用的，为了防止通过map循环全部读取，这里设置一个i用了记录当前数据的位置
            let productCuror = 1  // 记录商品的指针，用于设置名称等内容

            productbegin.push(j);   // 将每一个数据开始的位置记录，方便用了合并单元格

            item.menuSpecs.map((productitem: any) => {

                if (i <= item.menuSpecs.length) {
                    // 在该订单内商品循环,并将内容push入数组
                    if (item.menuSpecs.length === 1) {
                        //productbegin.push(j);   // 将每一个数据开始的位置记录，方便用了合并单元格
                        dataTable.push([
                            index + 1, item.foodName, item.isPrintSpec ? item.branchStoreMenuWarehouse.productUnit ? item.branchStoreMenuWarehouse.productUnit.productUnitName : "" : "", productitem.menuSpecName, productitem.price
                        ])
                    }
                    else {
                        if (productCuror === 1) {
                            dataTable.push([
                                index + 1, item.foodName, item.isPrintSpec ? item.branchStoreMenuWarehouse.productUnit ? item.branchStoreMenuWarehouse.productUnit.productUnitName : "" : "", productitem.menuSpecName, productitem.price
                            ])

                            productCuror = productCuror + 1;
                        }
                        else {
                            dataTable.push([
                                "", "", "", productitem.menuSpecName, productitem.price
                            ])
                            productCuror = productCuror + 1;
                        }
                    }

                    // 判断是否已经将该订单内商品循环完了，如果循环完了就显示公共信息内容(如下单时间、备注)
                    if (i === item.menuSpecs.length) {
                        //console.log( item.id +'的到最后了');
                        if (item.menuSpecs.length === 1) {
                            productend.push(j);
                            j = j + 1;
                        }
                        else {
                            productend.push(j + item.menuSpecs.length - 1);   // 将每一个数据结束的位置记录，方便用了合并单元格
                            j = j + item.menuSpecs.length;
                        }
                    }
                    i = i + 1;
                }
            })
        })

        // 将数据插入
        worksheet.addRows(dataTable);

        // 设置第一行的行高
        worksheet.getRow(1).height = 25;

        // 设置第一行居中对齐
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('E1').alignment = { vertical: 'middle', horizontal: 'center' };

        // 设置第一行边框
        worksheet.getCell('A1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('B1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('C1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('D1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('E1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        //  设置边框、对齐、合并、行高等操作
        for (var curor = 2; curor <= productend[productend.length - 1]; curor++) {
            //  设置行高
            // console.log(curor);
            worksheet.getRow(curor).height = 20;
        }

        for (var k = 0, len = productbegin.length; k < len; k++) {
            if (productend[k] - productbegin[k] >= 0) {

                for (var productroll = productbegin[k]; productroll <= productend[k]; productroll++) {
                    //  设置对齐方式和是否自动换行
                    worksheet.getCell('A' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('B' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('C' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('D' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('E' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

                    // 设置边框
                    worksheet.getCell('A' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('B' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('C' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('D' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('E' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                }
            }
        }

        //  将除了(微信用户、品名、地址)的单元格都设置为居中
        for (var product = 0; product < productbegin.length; product++) {

            if (productend[product] - productbegin[product] > 0) {
                //  合并单元格
                worksheet.mergeCells('A' + productbegin[product] + ':A' + productend[product] + '');
                worksheet.mergeCells('B' + productbegin[product] + ':B' + productend[product] + '');
                worksheet.mergeCells('C' + productbegin[product] + ':C' + productend[product] + '');
            }
        }

        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";

        // 将工作薄导出为excel文件
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, queryCriteria.branchStoreName + " - " + queryCriteria.menuHeadName + moment().format('YYYY-MM-DD') + '.xlsx');
        })
    }

    /**票据数据 */
    if (apiId === "billQuery") {
        console.log("该内容是" + apiId);
        console.log(queryCriteria);

        const { columnsHead } = queryCriteria
        // 创建工作簿
        var workbook = new ExcelJS.Workbook();
        //添加工作表
        const worksheet = workbook.addWorksheet('票据订单', {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });


        worksheet.columns = columnsHead;


        worksheet.addRows(rows)
        worksheet.insertRow(1, ["票据订单"]);
        worksheet.eachRow(function (row, rowNumber) {
            row.eachCell({ includeEmpty: true }, (cell, rowNumber) => {


                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                cell.font = { size: 12, name: 'Arial' };
                cell.alignment = { horizontal: 'center', vertical: 'middle' };

            })
            if (rowNumber === 1) {
                row.font = { size: 16, name: 'Arial', bold: true };
                row.height = 40;
                row.alignment = { horizontal: 'center', vertical: 'middle' };
            } else if (rowNumber === 2) {
                row.font = { size: 14, name: 'Arial', };
                row.alignment = { horizontal: 'left', vertical: 'middle' };
            }

        });


        worksheet.mergeCells(`A1:${String.fromCharCode(columnsHead.length + 64)}1`);


        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '票据订单.xlsx');
        })

    }
}

export default DownloadExcel;