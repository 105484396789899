import EditComponent from './UserLoginDeviceEdit';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 
const WebComponents = (props: any) => {
    const { payload } = props;
    const { userId, handleOk } = payload;
    /** api服务id */
    const apiId = 'userLoginDevice';
    //表头
    const columnsHead = [
        {
            title: '用户',
            dataIndex: 'user',
            render: (text: any) => (text ? text.userName : ''),
        },
        {
            title: '设备',
            dataIndex: 'device',
            render: (text: any) => (text ? text.deviceName : ''),
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '用户登录设备',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        sendPageData: { userId },
        EditComponent
    };

    return (
        <>
            <AntDraggableModal
                title={'用户登录设备'}
                open={true}
                okText='确定'
                // cancelText='取消'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                // onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={750}>
                <WebUniversal props={extendProps} />
            </AntDraggableModal>
        </>
    )
}

export default WebComponents;
