import React, { useRef } from 'react'
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import { Button, Space, Tooltip } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import OrderProcessEdit from './OrderProcessEdit';

export default function OrderProcess(props: any) {
  const ref = useRef<any>(undefined);

  const { entrepotProcessId, closeModal } = props;
  
  //表头
  const columnsHead = [
    {
      title: '步骤',
      dataIndex: 'step',
      // render: (text: any) => (text ? text.entrepotName : "")
    },
    {
      title: '流程',
      dataIndex: 'totalOrderProcessName',
      // render: (text: any) => (text ? text.entrepotName : "")
    },
    {
      title: '部门',
      dataIndex: 'dept',
      render: (text: any) => (text ? text.deptName : "")
    },
   
  ];
  const columns = columnsheadId
    .concat(columnsHead as []);
  const handleOk = (e: any) => {
    closeModal(true);
  };
  const handleCancel = (e: any) => {
    closeModal(false)
  };
  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '订单流程',
    apiId: 'orderProcess',
    showStates: true,
    sendPageData: { entrepotProcessId },
    columns,
    pagination: true,
    EditComponent: OrderProcessEdit,
  };
  return (
    <>
      <AntDraggableModal
        title={'订单流程管理'}
        open={true}
        okText='确定'
        cancelText="取消"
        onCancel={handleCancel}
        onOk={handleOk}
        closable={false}
        maskClosable={false}
        width={800}>
        <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
      </AntDraggableModal>

    </>
  )
}
