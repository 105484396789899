import React, { useContext, useEffect, useState } from 'react';
import { Spin, Transfer } from 'antd';
import AntDraggableModal from '../../../../utils/extend/AntDraggableModal';
import system from "../../../../config/systematic";
import fetchApi from '../../../../utils/api/fetchApi';
import antMessage from '../../../../utils/extend/AntdNotification';
import { SocketContext } from '../../../../App';

const InventoryToEstimate = (props: any) => {
    /**后端数据wmService */
    const wmService = system.wmService;
    const { data, closeModal, modalTitle } = props;
    const [keys, setKeys] = useState<any>([]);
       
    const [allKeys, setAllKeys] = useState<any>([]);

    const [leftInitKeys, setLeftInitKeys] = useState<Array<any>>([]);
    const [rightInitKeys, setRightInitKeys] = useState<Array<any>>([]);

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    const [loadingData, setLoadingData] = useState<any>(false);
    const [rows, setRows] = useState<Array<any>>([]);
    
    useEffect(() => {
        getDataBaseData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataBaseData = () => {

        fetchApi.GET(wmService, {
            apiId: data.apiId,
            apiExtend: data.apiExtend,
            apiData:{
                branchStoreId:contextData?.currentBranchStoreId,
                mountDataOptions:{
                    userMount:{
                       isDept:true,
                    }
               },
            }
        }).then(res => {
            if (res.success) {
                //目标数据的keys数组
                const targetKeys = [];
                //待沽清数据
                const noEstimateData = [];
                //左边数据keys数组;
                const leftKeys = [];
                //所有key值
                const allKeys = [];
                for (let j = 0; j < res.data.length; j++) {
                    allKeys.push(res.data[j].id)
                    const newData = {
                        key: res.data[j].id.toString(),
                        id: res.data[j].id,
                        status: "origin",
                        direction: "left",
                        [data.name]: res.data[j][data.name],
                        chosen: res.data[j].isMenu,
                    };
                    if (newData.chosen) {
                        newData.direction = "right"
                        targetKeys.push(newData.key);
                    } else {
                        leftKeys.push(newData.key)
                    }
                    noEstimateData.push(newData);
                }
                setAllKeys(allKeys)
                setKeys(targetKeys);
                setRows(noEstimateData)
                setRightInitKeys(targetKeys)
                setLeftInitKeys(leftKeys)
                // setData(newFoodWarehouseData);
            }
        });
    }


    const handleOk = async (e: any) => {
        setLoadingData(true);
        let keys = rows.filter((item) => item.status === "update" && item.direction === "right");
        let leftKeys = rows.filter((item) => item.status === "update" && item.direction === "left");
        // let leftKeys = allKeys.filter((item: any) => !keys.includes(item));
        fetchApi.POST(wmService, {
            apiId: data.apiId,
            apiExtend: "estimateHandle",
            apiData: {
                keys,
                leftKeys,
                // tableName:data.name,
                branchStoreId: contextData?.currentBranchStoreId,
                mountDataOptions:{
                    userMount: {
                        isDept: true,
                        isUserLogin:true
                      }
                }
            }
        }).then((res) => {
            setLoadingData(false)
            closeModal(true)

        }
        ).catch((err) => {
            setLoadingData(false)
        })
    };

    // /**关闭窗口并刷新数据 */
    // const handleCancel = (status: Boolean) => {
    //     closeModal(false)
    //     if (status === true) {
    //         getData(true);
    //     }
    // }

    const onChange = (keys: any, direction: any, moveKeys: any) => {
        let newArray = rows.map(item => {
            if (direction === "left") {
                if (moveKeys.includes(item.id)) {
                    if (leftInitKeys.includes(item.id)) {
                        return { ...item, direction, status: "origin" };
                    } else {
                        return { ...item, direction, status: "update" };
                    }
                } else {
                    return {
                        ...item
                    };
                }
            }
            if (direction === "right") {
                if (moveKeys.includes(item.id)) {
                    if (rightInitKeys.includes(item.id)) {
                        return { ...item, direction, status: "origin" };
                    } else {
                        return { ...item, direction, status: "update" };
                    }
                } else {
                    return {
                        ...item
                    };
                }
            }
        });
        setRows(newArray)
        setKeys(keys);

    };

    return (
        <>
            <AntDraggableModal
                title={modalTitle}
                open={true}
                okText='确定'
                cancelText='取消'
                cancelButtonProps={{ disabled: loadingData }}
                okButtonProps={{ disabled: loadingData }}
                onOk={handleOk}
                onCancel={() => closeModal(false)}
                closable={false}
                maskClosable={false}
                confirmLoading={loadingData}
                width={700}
            >
                <Transfer
                    dataSource={rows}
                    titles={data.transferTitle}
                    showSearch
                    listStyle={{ width: 400 }}
                    targetKeys={keys}
                    onChange={onChange}
                    render={item => `${item[data.name]}`}
                    pagination
                />
            </AntDraggableModal>
        </>
    );
};

export default InventoryToEstimate;