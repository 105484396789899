import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import EntrepotEdit from './EntrepotEdit';
import { Button, Space, Image, Tooltip, Switch, InputNumber } from 'antd';
import { useEffect, useRef, useState } from 'react';
import Accountant from './Accountant';
import EntrepotProcess from './EntrepotProcess';
import numberHandle from '../../../utils/baseLib/numberHandle';
export default function Entrepot(props: any) {
    const ref = useRef<any>(undefined);
    const [entrepotId, setEntrepotId] = useState<string>();
    const [modalAccountant, setModalAccountanta] = useState<boolean>(false);
    const [modalEntrepotProcess, setModalEntrepotProcess] = useState<boolean>(false);


    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 刷新数据 */
    let initData: any;
    const handleModalAccountanta = (entrepotId: string) => {
        setEntrepotId(entrepotId);
        setModalAccountanta(true);
    };
    const handleEntrepotProcess = (entrepotId: string) => {
        setEntrepotId(entrepotId);
        setModalEntrepotProcess(true);
    };

    const onCloseModalAccountant = () => {
        setModalAccountanta(false);
    };

    const onCloseModalEntrepotProcess = (state: boolean) => {
        setModalEntrepotProcess(false);
        if (state) {
            setRefresh(numberHandle.rangeNumber(1, 9999));
        }
    };


    //表头
    const columnsHead = [
        {
            title: '仓库名称',
            dataIndex: 'entrepotName',
        },
        {
            title: '区域',
            dataIndex: 'area',
            render: (text: any) => (text ? text.areaName : "")
        },
        {
            title: '部门',
            dataIndex: 'dept',
            render: (text: any) => (text ? text.deptName : "")

        },
        {
            title: '分店',
            dataIndex: 'branchStoreName',

        },
        {
            title: '专属id',
            dataIndex: 'selfId',
        },
        {
            title: '会计期',
            dataIndex: 'accountantacc',
            render: (text: any, record: any) =>
                <Space size="small">

                    <Tooltip placement="topLeft" title={record.entrepotName + ' 会计期'}>
                        <Button size={"small"} type="primary" onClick={() => handleModalAccountanta(record.id)}>会计期</Button>
                    </Tooltip>
                </Space>
        },
        {
            title: '订单类型',
            render: (text: any, record: any) =>
                <Space size="small">

                    <Tooltip placement="topLeft" title={record.entrepotName + '订单类型'}>
                        <Button size={"small"} type="primary" onClick={() => handleEntrepotProcess(record.id)}>订单类型</Button>
                    </Tooltip>
                </Space>
        },
        {
            title: "默认",
            dataIndex: "isDefault",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isDefault', e, 'entrepot')}
                />
            )
        },

    ];


    const columns = columnsheadId
        .concat(columnsHead as []);
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        //同步获取数据 async - await
        // const fetchData = async () => {
        // }
        // fetchData();
        if (refresh !== 0) {
            const currentData: initDataType = {
                apiId: "entrepot"
            }
            initData(currentData);
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]); //初始化数据
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '仓库',
        apiId: 'entrepot',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: EntrepotEdit,
    };
    return (

        <> 
          
            <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
            {modalAccountant ? (<Accountant
                closeModal={onCloseModalAccountant}
                entrepotId={entrepotId}
            ></Accountant>) : null}

            {modalEntrepotProcess ? (<EntrepotProcess
                closeModal={onCloseModalEntrepotProcess}
                entrepotId={entrepotId}
            ></EntrepotProcess>) : null}


        </>

    )
}
