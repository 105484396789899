import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx from '../../system/WebUniversalNewEx';
import { useRef } from 'react';
import SpecialPrintConfigurationEdit from './SpecialPrintConfigurationEdit';
import { Switch } from 'antd';

//组件 
const SpecialPrintConfiguration = (props: any) => {

    /** api服务id */
    const apiId = 'specialPrintConfiguration';
    const ref = useRef<any>(undefined);


    //表头
    const columnsHead = [
        {
            title: '打印单类型',
            dataIndex: 'printTypeName',
        },
        {
            title: '菜品类型',
            dataIndex: 'foodTypeName',
        },
        {
            title: '打印机',
            dataIndex: 'printer',
            render: (text: any, record: any) => (<div>{text.printerName ? text.printerName : ""}</div>)

        },
        {
            title: '部门',
            dataIndex: 'dept',
            render: (text: any, record: any) => (<div>{text?.deptName ? text?.deptName : ""}</div>)

        },
        {
            title: '页数',
            dataIndex: 'copies',
        },
        {
            title: "静默打印",
            dataIndex: "isSilent",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isSilent', e, apiId)}
                />
            )
        },
        {
            title: "显示打印页设置",
            dataIndex: "isPrinterPage",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isPrinterPage', e, apiId)}
                />
            )
        },




    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '特殊打印配置',
        apiId,
        columns,

        showStates: true,
        pagination: true,
        EditComponent: SpecialPrintConfigurationEdit
    };
   
    return (

        <>
            <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>


        </>
    )
}

export default SpecialPrintConfiguration;