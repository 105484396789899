import React, { useContext, useEffect, useRef, useState } from 'react';
import BanquetEdit from './BanquetEdit';
import WebUniversal, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button } from 'antd';
import InvitationTemplate from './InvitationTemplate';
import PromoCategory from '../../internal/menu/PromoCategory';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import { SocketContext } from '../../../App';

//组件 Banquet 
const Banquet = (props: any) => {
    const ref = useRef<any>(undefined);
    /** api服务id */
    const apiId = 'banquet';
    const [banquetId, setBanquetId] = useState<string>();
    const [invitationTemplateModal, setInvitationTemplateModal] = useState(false);

    const [promoCategoryModal, setPromoCategoryModal] = useState(false);

    const useContextData = useContext(SocketContext);
    const {  contextData,setContextData } = useContextData;
    useEffect(() => {
        if (contextData?.currentBrandId && contextData?.refresh) {
            
            onRefresh({brandId:contextData.currentBrandId})
        }
    },[contextData?.currentBrandId])
    //表头
    const columnsHead = [
        {
            title: '宴名',
            dataIndex: 'banquetName',
        },
        {
            title: '品牌',
            dataIndex: 'brand',
            render: (text: any) => (text ? text.brandName : "")
        },
        {
            title: '邀请函模版',
            dataIndex: 'invitationTemplate',
            render: (text: any, record: any) => {
                return <Button onClick={() => handleInvitationTemplate(record)} size={"small"} type="primary" >查看模版</Button>
            }
        },
        {
            title: '推广分类',
            dataIndex: 'invitationTemplate',
            render: (text: any, record: any) => {
                return <Button onClick={() => handlePromoCategory(record)} size={"small"} type="primary" >查看推广分类</Button>
            }
        },
    ];
    /**查看模板 */
    const handleInvitationTemplate = (record: any) => {
        setBanquetId(record.id)
        setInvitationTemplateModal(true)

    }
    /**查看推广分类 */
    const handlePromoCategory = (record: any) => {
        setBanquetId(record.id)
        setPromoCategoryModal(true)

    }
    /** 子组件回调函数，关闭模版窗口 */
    const onCloseInvitationTemplate = () => {
        setInvitationTemplateModal(false);
    };
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '宴会',
        apiId,
        columns,
        showStates: true,
        showDefault: true,
        pagination: true,
        extendIdName: "brandId",
        mountDataOptions: {
            userMount: {
                isBrand: true,
            }
        },
        EditComponent: BanquetEdit,
        sendPageData:{brandId:contextData?.currentBrandId}
    };


    /** 子组件回调函数，关闭打印配置窗口 */
    const closePromoCategory = (status: boolean) => {
        setPromoCategoryModal(false);
    };
    const onRefresh = (item: any) => {
        const {brandId}=item;
        
        const currentData: initDataType = {
            apiId,
            sendPageData: { brandId }
        }
     
        ref.current.initData(currentData)
        setContextData({ ...contextData, refresh: false })
    }
    return (
        <>
      
            <WebUniversal ref={ref} props={extendProps} />
            {invitationTemplateModal ? (<InvitationTemplate
                closeModal={onCloseInvitationTemplate}
                banquetId={banquetId}
                isModel={true}
            ></InvitationTemplate>) : null}

            {promoCategoryModal ? (<PromoCategory
                closeModal={closePromoCategory}
                banquetId={banquetId}
                isModel={true}
            ></PromoCategory>) : null}
        </>
    )
}

export default Banquet;
