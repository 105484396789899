import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import MenuMemoEdit from './MenuMemoEdit';
import { SocketContext } from '../../../App';
import { useContext, useEffect, useRef } from 'react';

export default function MenuMemo(props: any) {

    const ref = useRef<any>(undefined);

    /** api服务id */
    const apiId = 'menuMemo';

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // handleChangeOne = ref.current.handleChangeOne;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                branchStoreId
            }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '适用范围',
            dataIndex: 'memoCondimentName',
        },
        {
            title: '类别',
            dataIndex: 'shopTypeName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.shopType ? record.shopType.shopTypeName : ""}</div>
                )
            }
        },
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStoreMenuWarehouse ? record.branchStoreMenuWarehouse.foodName : ''}</div>
                )
            }
        },
        {
            title: '备注',
            dataIndex: 'menuMemoName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式备注',
        apiId: 'menuMemo',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: MenuMemoEdit,
        // sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
        sendPageData: { branchStoreId: contextData?.currentBranchStoreId },
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
    )
}