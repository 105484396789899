import { DatePicker, Form, Input, InputNumber, Switch } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const { RangePicker } = DatePicker;

dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export default function FeastTeamMenuHeadEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    // const [areaData, setAreaData] = useState<any>();

    /**区域的状态 */
    // const [areaIdDisable, setAreaIdDisable] = useState<any>(false);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            // fetchApi.GET(wmService, {
            //     apiId: "area",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setAreaData(res.data)
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "feastTeamMenuWarehouse",
            //     apiExtend: 'getAreaId'
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.key !== 'manager') {
            //             setAreaIdDisable(true);
            //             form.setFieldsValue({ areaId: res.data.area.id });
            //         }
            //     }
            // });

            form.setFieldsValue({ feastTeamMenu_years: dayjs().year() });
            form.setFieldsValue({ feastTeamMenu_weeks: dayjs().week() });
            setDays(form, 1);
        }

        fetchData()
    }, [])

    // 日期转换，确定每年第一周第一天是哪一天
    const convertDate = (tmpDate: any) => {
        let dateOne: any;

        if (dayjs(tmpDate).week() > 50) {
            if (dayjs(tmpDate).day() === 0) {
                dateOne = dayjs(tmpDate).add(dayjs(tmpDate).day() + 1, 'day').format("YYYY-MM-DD");

                // console.log(dayjs(tmpDate).add(dayjs(tmpDate).day() + 1, 'day').format("YYYY-MM-DD") + "这个是第一个星期，而且是星期一");
                // console.log(dayjs(dayjs(tmpDate).add(dayjs(tmpDate).day() + 1, 'day')).week());
                // console.log(dayjs(dayjs(tmpDate).add(dayjs(tmpDate).day() + 1, 'day')).day());
            }
            else {
                dateOne = dayjs(tmpDate).add(8 - dayjs(tmpDate).day(), 'day').format("YYYY-MM-DD");

                // console.log(dayjs(tmpDate).add(8 - dayjs(tmpDate).day(), 'day').format("YYYY-MM-DD") + "这个是第一个星期，而且是星期一");
                // console.log(dayjs(dayjs(tmpDate).add(8 - dayjs(tmpDate).day(), 'day')).week());
                // console.log(dayjs(dayjs(tmpDate).add(8 - dayjs(tmpDate).day(), 'day')).day());
            }
        }
        else {
            if (dayjs(tmpDate).day() === 1) {
                dateOne = tmpDate;

                // console.log(tmpDate + "这个是第一个星期，而且是星期一");
                // console.log(dayjs(tmpDate).week());
                // console.log(dayjs(tmpDate).day());
            }
            else {
                if (dayjs(tmpDate).day() === 0) {
                    dateOne = dayjs(tmpDate).subtract(dayjs(tmpDate).day() - 6, 'day').format("YYYY-MM-DD");

                    // console.log(dayjs(tmpDate).subtract(dayjs(tmpDate).day() - 6, 'day').format("YYYY-MM-DD") + "这个是第一个星期，而且是星期一");
                    // console.log(dayjs(dayjs(tmpDate).subtract(dayjs(tmpDate).day() - 6, 'day')).week());
                    // console.log(dayjs(dayjs(tmpDate).subtract(dayjs(tmpDate).day() - 6, 'day')).day());
                }
                else {
                    dateOne = dayjs(tmpDate).subtract(dayjs(tmpDate).day() - 1, 'day').format("YYYY-MM-DD");

                    // console.log(dayjs(tmpDate).subtract(dayjs(tmpDate).day() - 1, 'day').format("YYYY-MM-DD") + "这个是第一个星期，而且是星期一");
                    // console.log(dayjs(dayjs(tmpDate).subtract(dayjs(tmpDate).day() - 1, 'day')).week());
                    // console.log(dayjs(dayjs(tmpDate).subtract(dayjs(tmpDate).day() - 1, 'day')).day());
                }
            }
        }
        return dateOne;
    }

    /**修改日期 */
    const setDays = (form: any, e: any) => {

        let year = form.getFieldValue("feastTeamMenu_years");
        let week = form.getFieldValue("feastTeamMenu_weeks");

        let tmpDate: any = year + "-01-01";
        let overDate: any = String(Number(year) + Number(1)) + "-01-01";
        let dateOne: any;
        let overDateOne: any;

        dateOne = convertDate(tmpDate);
        overDateOne = convertDate(overDate);

        form.setFieldsValue({ feastTeamMenu_days: [dayjs(dayjs(dayjs(dateOne).week(week)), 'YYYY-MM-DD'), dayjs(dayjs(dayjs(dateOne).week(week)).add(6, 'day'), 'YYYY-MM-DD')] });

        // 防止第53周的时候等于下年的第一周
        if (week >= 52) {
            if (dayjs(dayjs(dateOne).week(week)).format("YYYY-MM-DD") === dayjs(overDateOne).format("YYYY-MM-DD")) {
                // console.log(dayjs(dayjs(dateOne).week(week)).format("YYYY-MM-DD"));
                // console.log(dayjs(overDateOne).format("YYYY-MM-DD"));
                // console.log("相同了");
                form.setFieldsValue({ feastTeamMenu_years: Number(year) + Number(1) });
                form.setFieldsValue({ feastTeamMenu_weeks: 1 });
            }
        }
    }

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'feastTeamMenuHead',
        mountDataOptions: {
            userMount: {
                isBranchStore: true
            }
        }
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '福食周期'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="年份"
                        name="feastTeamMenu_years"
                        rules={[{ required: true, message: '请输入年份!' }]}>
                        <InputNumber min={dayjs().year()} onChange={(e) => setDays(form, e)} />
                    </Form.Item>

                    <Form.Item
                        label="第几周"
                        name="feastTeamMenu_weeks"
                        rules={[{ required: true, message: '请输入第几周!' }]}>
                        <InputNumber min={1} max={53} onChange={(e) => setDays(form, e)} />
                    </Form.Item>

                    <Form.Item
                        label="日期"
                        name="feastTeamMenu_days">
                        <RangePicker
                            value={[dayjs(dayjs(), 'YYYY-MM-DD'), dayjs(dayjs(), 'YYYY-MM-DD')]}
                            disabled
                        />
                    </Form.Item>

                    {/* <Form.Item
                        label="区域"
                        name="areaId"
                        rules={[{ required: true, message: '请选择区域!' }]}>
                        <Select
                            showSearch
                            disabled={areaIdDisable}
                            placeholder="区域"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(areaData, 'areaName')}
                        </Select>
                    </Form.Item> */}

                    <Form.Item
                        label="排序"
                        name="sortBy">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>
                </Form>
            </AntDraggableModal>
        </>
    )
}