import { Button, Card, Checkbox, Form, Input, List, Select, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import system from '../../../config/systematic';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';

import getList from '../../universal/getList';
import DateRoomDetail from './DateRoomDetail';
import dayjs from 'dayjs';
export default function ReplaceReserveDateRoom(props: any) {
    const { setModalChooseBookingDateopen, setModalReplaceReserveDateRoomopen, data, getData, closeModal } = props;



    const [form] = Form.useForm(); //form 

    // console.log("data", data);

    /** 数据刷新中 */
    let loading = false;

    /**后端数据vmService */
    const wmService = system.wmService;

    /**
 * 房间类型Id
 */
    const [roomTypeId, setRoomTypeId] = useState<any>("999")

    /**楼层Id */
    const [floorId, setfloorId] = useState<any>("998")
    const [currentData, setCurrentData] = useState(1);
    const [pageSizeData, setPageSizeData] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [queryData, setQueryData] = useState('');
    const [rows, setRows] = useState<any>();
    const [floorData, setFloorData] = useState<any>();
    /** 房间状态*/
    const [roomStatus, setRoomStatus] = useState<any>(-1)

    /**打开分店房间详情窗口 */
    const [modalReplaceReserveDateRoomDetailopen, setModalReplaceReserveDateRoomDetailopen] = useState<Boolean>(false)
    /**分店房间选择数据 */
    const [replaceReserveDateRoomDetailRoom, setReplaceReserveDateRoomDetailRoom] = useState<any>();
    /**房间类型数据 */
    const [roomType, setRoomType] = useState<any>();

    const [timeTypeName, setTimeTypeName] = useState<any>();

    const [replaceRoomCounts, setReplaceRoomCounts] = useState<number>(0);

    const [replaceRoomSitNumber, setReplaceRoomSitNumber] = useState<number>(0);

    const [bookingNum,setBookingNum]=useState<number>(0)

    const handleOk = async (e: any) => {

        //更换房间
        if (data.type === "replaceRoom") {
            setModalChooseBookingDateopen(false)
            setModalReplaceReserveDateRoomopen(false)
        }

        //分配房间
        if (data.type === "distribute") {
            closeModal(true)
            console.log("这是分配房间的ok");

        }


    };
    const handleCancel = (e: any) => {
        //更换房间
        if (data.type === "replaceRoom") {
            setModalChooseBookingDateopen(true)
            setModalReplaceReserveDateRoomopen(false)
        }

        //分配房间
        if (data.type === "distribute") {
            closeModal(false)
            console.log("这是分配房间的cancel");
        }
    };
    const onclose = (state: Boolean) => {
        if (data.type === "replaceRoom") {
            setModalReplaceReserveDateRoomDetailopen(false)
            if (state === true) {
                getData(
                    roomTypeId,
                    roomStatus,
                    floorId,
                    currentData,
                    pageSizeData,
                    queryData
                )
            }
        }
        if (data.type === "distribute") {
            setModalReplaceReserveDateRoomDetailopen(false)
            if (state === true) {
                closeModal(state)
                initDistributeRoom(data.customerReserveId)
            }
        }

    }
    useEffect(() => {
        const fetchData = async () => {
            InitData(
                roomTypeId,
                data.timeTypeId,
                floorId,
                currentData,
                pageSizeData,
                queryData
            )
            httpGet(wmService, {
                apiId: "branchStoreFloor",
                apiExtend: 'showList',
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "998", floor: "所有楼层" }, ...res.data]
                    setFloorData(newData)
                }
            })


            //查找房间类型数据
            httpGet(wmService, {
                apiId: "roomType",
                apiExtend: 'showList',
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                    extendObj: {
                        menuHeadsIdNum: -1,

                    }
                }
            }).then(res => {
                let newData = [{ id: "999", roomType: "所有类型" }, ...res.data]
                setRoomType(newData)

            })
        }
        //分配时，获取分配数
        if (data.type === "distribute") initDistributeRoom(data.customerReserveId)



        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //传入相关参数
    let sendData = {
        id: "",
        type: "",
        roomId: "",
        roomTypeId: "",
        roomName: "",
        roomNum: "",
        nowId: "",
        //原来的市别id
        originalTimeTypeId: data.originalTimeTypeId ? data.originalTimeTypeId : "",
        //原来的房间类型id
        //原来的房间id



        //顾客订单id
        customerReserveId: data.customerReserveId,
        timeTypeId: data.timeTypeId,
        reserveTempRoomId: "",

    }
    const handlebranchStoreRoom = (e: any) => {
        sendData.nowId = e.id;
        sendData.type = data.type;
        sendData.id = data.id;
        // sendData.originalBookingNum=e
        setModalReplaceReserveDateRoomDetailopen(true)
        setReplaceReserveDateRoomDetailRoom(sendData)

    }

    const floorChange = (e: any) => {
        setfloorId(e)
        InitData(
            roomTypeId,
            data.timeTypeId,
            e,
            1,
            pageSizeData,
            queryData
        )
    }

    const initDistributeRoom = (id: string) => {

        fetchApi.GET(wmService, {
            apiId: "tempRoom",
            apiExtend: "distributeRoom",
            apiData: {
                customerReserveId: id
            }
        }).then((res) => {
            const { counts, sitNumber,toBookingNum } = res.data;
            
            setReplaceRoomCounts(counts)
            setReplaceRoomSitNumber(sitNumber)
            setBookingNum(toBookingNum)
        }).catch((err) => {
            console.log("err", err);

        })

    }
    const roomTypeChange = (e: any) => {
        setRoomTypeId(e)
        InitData(
            e,
            data.timeTypeId,
            floorId,
            1,
            pageSizeData,
            queryData
        )

    }
    //获取数据
    const InitData = async (roomTypeId: string, timeTypeId: string, floorId: string, current: number,
        pageSize: number,
        queryString: string) => {
        if (loading === true) { return }
        loading = true;
        fetchApi.GET(
            wmService,
            {
                apiId: "tempRoom",
                apiExtend: 'changeRoom',
                apiData: {
                    roomTypeId: data.roomTypeId ? data.roomTypeId : roomTypeId,
                    timeTypeId,
                    floorId,
                    current,
                    pageSize,
                    queryString,
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }
            }).then(res => {

                if (res.success === true) {

                    loading = false;
                    setRows(res.data.rows);
                    setCurrentData(res.data.current);
                    setPageSizeData(res.data.pageSize);
                    setTotalData(res.data.total);
                    setQueryData(res.data.queryString)
                    if (res.data.rows.length > 0) {
                        setTimeTypeName(`${res.data.rows[0].timeType.bookingDate}(${dayjs(res.data.rows[0].timeType.bookingDate).format('ddd')})${res.data.rows[0].timeType.typeName}`)

                    }
                }

            })
    }
    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        // showQuickJumper: true,
        showTotal: () => `共${totalData}条`,
        pageSize: pageSizeData,
        current: currentData,
        total: totalData,
        onShowSizeChange: (current: number, pageSize: number) =>
            InitData(
                roomTypeId,
                data.timeTypeId,
                floorId,
                current,
                pageSize,
                queryData),
        onChange: (current: number) =>
            InitData(
                roomTypeId,
                data.timeTypeId,
                floorId,
                current,
                pageSizeData,
                queryData),
    };


    return (
        <>
            <AntDraggableModal
                title={<div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    <div>{timeTypeName}{bookingNum>0?`预约人数:${bookingNum}`:null}{replaceRoomCounts>0?`--已分配房间:${replaceRoomCounts}`:null}{replaceRoomSitNumber>0?`-已分配人数:${replaceRoomSitNumber}`:null}</div>
                    <div>
                        {
                            data.type === "distribute" ? null : <>
                                <label>房间类型 :  </label>
                                <Select
                                    showSearch
                                    onChange={roomTypeChange}
                                    placeholder="房间类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(roomType, "roomType")}
                                </Select>
                                <span> - </span>
                            </>
                        }
                        <label>房间楼层 :  </label>
                        <Select
                            showSearch
                            onChange={floorChange}
                            placeholder="楼层"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(floorData, "floor")}
                        </Select>

                        <span> - </span>
                        <label>查询 :  </label>
                        <Space>
                            <Input
                                style={{ width: 100 }}
                                onPressEnter={(e: any) => {
                                    if (e.key === 'Enter')
                                        InitData(
                                            roomTypeId,
                                            data.timeTypeId,
                                            floorId,
                                            currentData,
                                            pageSizeData,
                                            queryData)
                                }}
                                onChange={(e: any) => {

                                    setQueryData(e.target.value)
                                }
                                }
                            />
                        </Space>
                    </div>
                </div>}
                open={true}
                okText='确定'
                cancelText={data.type === "distribute" ? "取消" : "上一步"}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={true}
                maskClosable={true}
                width={"90%"}
            >


                <div style={{ marginTop: "20px", maxHeight: '800px', overflowY: 'auto', overflowX: "hidden" }}>
                    <List
                        pagination={paginationProps}
                        grid={{ gutter: 12, column: 4 }}
                        dataSource={rows}
                        renderItem={(item: any) => {

                            return (
                                <List.Item>
                                    <Card

                                        title={<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "60px" }}>

                                            <div>{`${item.room.roomNum}(${item.room.roomName})`}</div>
                                            <div>{item.room.roomType.roomType}</div>

                                        </div>}
                                        actions={[
                                            <div onClick={() => handlebranchStoreRoom(item)}>确认状态</div>,
                                        ]}
                                    >
                                        <div></div>
                                    </Card>
                                </List.Item>

                            )
                        }}

                    />
                </div>
                {modalReplaceReserveDateRoomDetailopen ? (<DateRoomDetail getData={getData} data={replaceReserveDateRoomDetailRoom} setModalChooseBookingDateopen={setModalChooseBookingDateopen} closeModal={onclose}
                />)
                    : null}
            </AntDraggableModal>
        </>
    )
}
