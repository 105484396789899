import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AreaProductEdit from './AreaProductEdit';

export default function AreaProduct(props: any) {
    const ref = useRef<any>(undefined);
    const { data, closeModal } = props;
    let wmService: any = '';
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始
    const handleOk = (e: any) => {
        closeModal(true);
        // ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        closeModal(false);
        // ref.current.handleCancel();
    };
       //表头
       const columnsHead = [
        {
            title: '区域',
            dataIndex: 'area',
            render:(text:any)=>(text?text.areaName:"")
        },
        {
            title: '品名',
            dataIndex: 'productName',
        },


    ];
    const columns = columnsheadId
        .concat(columnsHead as []);
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '区域产品名称',
        apiId: 'areaProduct',
        showStates: true,
        columns,
        pagination: true,
        sendPageData: { productId: data.id },
        EditComponent: AreaProductEdit,
    };
    return (
        <>
         <AntDraggableModal
                title={'区域产品'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1080}>
                 <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
            </AntDraggableModal>
        
        </>

    )
}
