import { Col, Form, Input, Row, Select, Switch, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const Edit = (props: any) => {
    const [dbBackupsData, setDbBackupsData] = useState<any>();
    const [form] = Form.useForm(); //form 
    const { data } = props;
    if (data) {
        const { sendPageData } = data;
        if (sendPageData) {
            const { dbBackupsId } = sendPageData;
            form.setFieldValue('dbBackupsId', dbBackupsId)
        }
    }
    /** 后端数据defaultService */
    let wmService: any = "";
    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: 'dbBackups',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success === true) {
                    setDbBackupsData(res.data);
                }
            })
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'dbTables'
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '数据表'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            // handleOk(e)
                        }
                    }}>
                    <Row >
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="数据库备份"
                                name="dbBackupsId">
                                <Select
                                    disabled={true}
                                    showSearch
                                    placeholder="数据库系统"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(dbBackupsData, {
                                        itemName: 'backupName'
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="源表"
                                name="sourceTableId"
                                rules={[{ required: true, message: '请输入源表!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="源数据库"
                                tooltip='源数据库，当前只支持源表及目标表在源数据库'
                                name="sourceDatabase">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="目标表"
                                name="targetTableId"
                                rules={[{ required: true, message: '请输入目标表!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="目标数据库"
                                tooltip='目标数据库，暂不支持'
                                name="targetDatabase">
                                <Input disabled/>
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="备份N天前数据"
                                name="backupDaysBefore"
                                rules={[{ required: true, message: '请输入数字!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="备份N天前数据的字段名"
                                tooltip='默认：createdAt'
                                name="backupDaysColumnName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="SqlWhere语句"
                                tooltip='如有需要，可以附加过滤目标数据库的SqlWhere语句'
                                name="sqlWhere">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="删除源表数据"
                                tooltip='删除源表数据，不可恢复'
                                name="isDeleteSourceTableData"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="检查表结构"
                                name="isCheckStructureMatch"
                                tooltip='检查源表与目标表结构是否匹配'
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default Edit;