import DbTablesEdit from './DbTablesEdit';
import WebUniversal, { WUExtendPropsType } from '../WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { useEffect, useRef } from 'react';
import { Button, Switch } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';

const DbBackups = (props: any) => {
    /** api服务id */
    const apiId = 'dbTables';
    const ref = useRef<any>(undefined);
    const { closeModal, data } = props;
    const { dbBackupsId } = data;

    /** 后端数据defaultService */
    let wmService: any = '';
    /** 刷新 */
    let initData: any;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        //同步获取数据 async - await
        const fetchData = async () => {
            // const res = await fetchApi.GET(defaultService,
            //     {
            //         apiId: 'configs',
            //         apiVariable: 'system',
            //         apiExtend: 'getNodeValue',
            //         apiData: {
            //             nodeId: 'companyFilePath',
            //             privatePar: false
            //         }
            //     });
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    //表头
    const columnsHead = [
        {
            title: '源表',
            dataIndex: 'sourceTableId',
        },
        {
            title: '目标表',
            dataIndex: 'targetTableId',
        },
        {
            title: '数据库备份',
            dataIndex: 'dbBackups',
            sorter: true,
            render: (text: any) => {
                if (text) {
                    const { backupName } = text;
                    return backupName;
                }
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
        {
            title: '删除源表数据',
            dataIndex: 'isDeleteSourceTableData',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record['id']}
                />
            )
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '数据表',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: DbTablesEdit,
        sendPageData: { dbBackupsId }
    };

    return (
        <>
            <AntDraggableModal
                title={'数据表'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={closeModal}
                closable={false}
                maskClosable={false}
                width={1080} >
                <WebUniversal props={extendProps} ref={ref} />
            </AntDraggableModal>

        </>
    )
}

export default DbBackups;
