import { Col, DatePicker, Form, Input, Row, Select, Switch, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import dayjs from 'dayjs';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const Edit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    /** 后端数据wmService */
    let wmService: any = "";

    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [jobsList, setJobsList] = useState<any>();

    const [taskTypeData, setTaskTypeData] = useState<any>();
    const [taskModeData, setTaskModeData] = useState<any>();
    const [taskProcessData, setTaskProcessData] = useState<any>();
    const [taskExecutionWayData, setTaskExecutionWayData] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'taskType@taskMode@taskProcess@taskExecutionWay',
                apiExtend: 'showLists'
            }).then(res => {
                if (res.success === true) {
                    const { taskType, taskMode, taskProcess, taskExecutionWay } = res.data;
                    setTaskTypeData(taskType);
                    setTaskModeData(taskMode);
                    setTaskProcessData(taskProcess);
                    setTaskExecutionWayData(taskExecutionWay);
                }
            })
            fetchApi.GET(wmService, {
                apiId: 'cronTasks',
                apiExtend: 'getJobsList'
            }).then(res => {
                if (res.success) {
                    setJobsList(res.data)
                }
            })
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'cronTasks',
        dateColumnName: ['startDate', 'endDate']
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '定时任务'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            // handleOk(e)
                        }
                    }}>
                    <Row >
                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="任务名"
                                name="taskName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="类型"
                                name="taskTypeId"
                                initialValue={0}>
                                <Select
                                    showSearch
                                    placeholder="任务类型"
                                    optionFilterProp="children"
                                    disabled={true}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(taskTypeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="作业id"
                                name="jobId"
                                rules={[{ required: true, message: '请选择作业id!' }]}>
                                <Select
                                    showSearch
                                    placeholder="作业id"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(jobsList, { itemId: 'jobId', itemName: 'jobName' })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                label="任务参数"
                                name="taskParameters">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Tooltip placement="top" title={'@使用 ( * * * * * * ) 秒：0-59；分钟：0-59；小时：0-23；天：1-31；月份：0-11（1月至12月）；星期几：0-6（周日至周六）；' +
                                '@提示 (*)为通配符；(-)为时间段连接符；(,)号为分隔符，可以在某一节输入多个值；(/)号为步进符；' +
                                '@例子1) (1 * * * * *)：每分钟第一秒执行；2) (*/3 * * * * *)：间隔3秒执行;'}>
                                <Form.Item
                                    label="定时方式"
                                    name="expression"
                                    rules={[{ required: true, message: '请输入定时方式!' }]}>
                                    <Input />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="开始时间"
                                name="startDate"
                                initialValue={dayjs(new Date())}>
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="结束时间"
                                name="endDate">
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="模式"
                                name="taskModeId"
                                rules={[{ required: true, message: '请选择任务模式!' }]}
                                initialValue={1}>
                                <Select
                                    showSearch
                                    placeholder="选择模式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(taskModeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="总执行数"
                                name="totalCount"
                                initialValue={0}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="已执行数"
                                name="exeCount"
                                initialValue={0}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="执行方法"
                                name="taskExecutionWayId"
                                rules={[{ required: true, message: '请选择执行方法!' }]}
                                initialValue={0}>
                                <Select
                                    showSearch
                                    placeholder="执行方法"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(taskExecutionWayData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="流程"
                                name="taskProcessId"
                                initialValue={0}>
                                <Select
                                    showSearch
                                    placeholder="选择流程"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(taskProcessData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="成功"
                                name="isExeSuccess"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default Edit;