import React, { useEffect, useRef } from 'react';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import MenuWarehouseSpecEdit from './MenuWarehouseSpecEdit';

export default function MenuWarehouseSpec(props: any) {

    const ref = useRef<any>(undefined);

    const { closeModal, data } = props;

    /** api服务id */
    const apiId = 'menuWarehouseSpec';

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) =>
                <div>{record.menuWarehouse.foodName}</div>
        },
        {
            title: '规格',
            dataIndex: 'menuWarehouseSpecName',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    // const handleOk = (status: Boolean) => {
    //     closeModal(false);
    //     if (status === true) {
    //         getData(true);
    //     }
    // };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式仓库规格',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: MenuWarehouseSpecEdit,
        sendPageData: { menuWarehouseId: data.id }
    };

    return (
        <AntDraggableModal
            title={'仓库菜式规格'}
            open={true}
            okText='关闭'
            // cancelText={stringHandle.isUndefined()}            
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={() => closeModal(true)}
            closable={false}
            maskClosable={false}
            width={900}>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
        </AntDraggableModal>
    )
}