import React from 'react'
import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import objectHandle from '../../../utils/baseLib/objectHandle';
export default function OrderProcessEdit(props: any) {
  const [form] = Form.useForm(); //form 
  const { data } = props;
  const ref = useRef<any>(undefined);
  const [deptData, setDeptData] = useState<any>();
  const [totalOrderProcessData, setTotalOrderProcess] = useState<any>();
  const [initialUsersId, setInitialUsersId] = useState<any>([]);
  const [userData, setUserData] = useState<any>();

  /** 后端数据Service */
  let wmService: any = "";
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const handleCancel = (e: any) => {
    ref.current.handleCancel();
  };
  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
      ref.current.row.then(async (res: any) => {
        if (typeof res === 'undefined') return
        if (res.success === true) {
          const userIds = res.data.userIds;
          let usersRows;
          if (objectHandle.isEmpty(userIds) === false) {
            usersRows = userIds.split('@');
          }
          setInitialUsersId(usersRows);
          form.setFieldsValue({
            userIds: usersRows
          })
        }
      })
    }
    const fetchData = async () => {

      httpGet(wmService, {
        apiId: "dept",
        apiExtend: 'showList',
        apiData:{
                    
        }
      }).then(res => {
        if (res.success) {
          // res.data.unshift({ id: "-1", deptName: "*" })
          setDeptData(res.data)
        }
      })
      httpGet(wmService, {
        apiId: "generalData",
        apiExtend: 'showList',
        apiVariable: "totalOrderProcess",
      }).then(res => {
        if (res.success) {
          setTotalOrderProcess(res.data)
        }
      })

      httpGet(wmService, {
        apiId: "user",
        apiExtend: 'showList',
      }).then(res => {
        if (res.success) {
          setUserData(res.data)
        }
      })

    }
    fetchData()
  }, [])
  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'orderProcess'
  };
  return (
    <>
      <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
      <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '订单流程'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
      >
        <Form
          form={form}
          name="Edit"
          onFinish={handleOk}
         onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}
        >
          <Row>
            <Col span={8}>
              <Form.Item
                label="id"
                name="id">
                <Input disabled={true} />
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                name="step"
                label="步骤"
                rules={[{ required: true, message: '步骤' }]}
              >
                <InputNumber min={0} placeholder="步骤" />
              </Form.Item>
            </Col>


            <Col span={12}>
              <Form.Item
                label="部门"
                name="deptId"
                rules={[{ required: true, message: '请选择部门!' }]}>
                <Select
                  showSearch
                  placeholder="请选择部门"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(deptData, 'deptName')}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="流程"
                name="totalOrderProcess"
                rules={[{ required: true, message: '请选择流程!' }]}>
                <Select
                  showSearch
                  placeholder="请选择流程"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(totalOrderProcessData, 'name')}
                </Select>
              </Form.Item>
            </Col>




          </Row>




        </Form>
      </AntDraggableModal>

    </>
  )
}
