import React, { useEffect, useRef, useState } from 'react';
import MenuWarehouseIngredientEdit from './MenuWarehouseIngredientEdit';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { Button, Space } from 'antd';
import CheckIngredient from './CheckIngredient';

//组件 MenuWarehouseIngredient 
export default function MenuWarehouseIngredient(props: any) {

    //console.log("111111111111111")
    //console.log(props)

    //查看配料表窗口
    const [checkIngredientVisible, setCheckIngredientVisible] = useState(false)

    //数据
    const [ingredientData, setIngredientData] = useState<any>();

    const ref = useRef<any>(undefined);
    const { data, closeModal } = props;
    const apiId = 'menuWarehouseIngredient';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps            
        }

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //  打开查看配料表
    const HandleCheckIngredient = (record: any) => {
        //console.log(record)
        sendData.id = record.id;
        setIngredientData(sendData);
        setCheckIngredientVisible(true);
    };

    //  传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
    }

    //  表头
    const columnsHead = [
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                if (record.menuWarehouseSpecId) {
                    return (
                        <div>{record.menuWarehouse.foodName}</div>
                    )
                }
                else {
                    return (
                        <div>{record.feastTeamMenuWarehouse.foodName}</div>
                    )
                }
            }
        },
        {
            title: '规格',
            dataIndex: 'spec',
            render: (text: any, record: any) => {
            
                if (record.menuWarehouseSpecId) {
                    return (
                        <div>{record.menuWarehouseSpec.menuWarehouseSpecName}</div>
                    )
                }
                else {
                    return (
                        <div>{record.productUnit.productUnitName}</div>
                    )
                }
            }
        },
        {
            title: '售价',
            dataIndex: 'sellPrice',
        },
        {
            title: '查看配料表',
            dataIndex: 'checkIngredient',
            key: 'checkIngredient',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => HandleCheckIngredient(record)}>查看配料表</Button>
                </Space>
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    const handleOk = (e: any) => {
        closeModal(false);
    };

    //  关闭查看主配料
    const onCloseModalCheckIngredientVisible = (state: boolean) => {
        setCheckIngredientVisible(false);
    };

    let sendPageStr: any
    if (data.path === "MenuWarehouse") {
        sendPageStr = {
            menuWarehouseId: data.id,
            path: data.path
        }
    }
    else {
        sendPageStr = {
            feastTeamMenuWarehouseId: data.id,
            productUnitId: props.data.productUnitId,
            path: data.path
        }
    }
    //console.log(sendPageStr);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '主配料信息',
        apiId,
        columns,
        showStates: true,
        EditComponent: MenuWarehouseIngredientEdit,
        sendPageData: sendPageStr,
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <>
            <AntDraggableModal
                title={'主配料'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}
                cancelButtonProps={{style:{display:"none"}}}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={900}>
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>

            {checkIngredientVisible
                ? (<CheckIngredient
                    closeModal={onCloseModalCheckIngredientVisible} data={ingredientData} />)
                : null}
        </>
    )
}