import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import IncomeStatementEdit from './IncomeStatementEdit';
import moment from 'moment';
import { Button, Tooltip } from 'antd';
import ProductEdit from './ProductEdit';
import { useRef, useState } from 'react';
export default function Accountant(props: any) {
    const ref = useRef<any>(undefined);
    /**打开商品窗口 */
    const [modalProductOpen, setModalProductOpen] = useState<boolean>(false);

    /**传递商品窗口的数据 */
    const [productData, setProductData] = useState<any>()
    //表头
    const columnsHead = [
        // {
        //     title: '会计期',
        //     dataIndex: "accountant",
        //     render: (text: any) => (text ? text.accountantName : "")
        // },
        {
            title: '仓库',
            dataIndex: 'entrepot',
            render: (text: any) => (text ? text.entrepotName : "")

        },

        {
            title: "日期",
            dataIndex: "sysDate",

            render: (val: any, record: any) =>
                <span>
                    {moment(val.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>,

        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);
    const expandableColumnsHead: Array<any> = [
        {
            title: "id",
            dataIndex: "id",
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 10 ? text.substring(0, 10) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: "商品名称",
            dataIndex: "product",
            render: (text: any) => (text ? text.productName : "")
        },
        {
            title: "商品单位",
            dataIndex: "product",
            render: (text: any) => (text ? text.productUnit.productUnitName : "")
        },
        {
            title: "规格",
            dataIndex: "productSpecifications"
        },
        {
            title: "数量",
            dataIndex: "bNum"
        },
        {
            title: "备注",
            dataIndex: "memo"
        },
  
    ]
    /**修改商品数据 */
    const onChangeProduct = (item: any, apiId: string, title: any) => {
        setProductData({ id: item.id, apiId, title })
        setModalProductOpen(true)

    }
    /**关闭商品窗口并刷新数据 */
    const onCloseProductOpen = (status: Boolean) => {
        setModalProductOpen(false)
        if (status === true) {
            ref.current.initData();
        }
    }
    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '溢损单',
        apiId: 'incomeStatement',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: IncomeStatementEdit,
        mountDataOptions:{
            userMount: {
                isEmployees: true,
                isDept:true,
                isUserAreas:true
            }
           },
        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'incomeStatementProducts',
    };
    /** 子表头内容 */

    return (
        <>
            <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>

            {modalProductOpen ? (<ProductEdit data={productData} closeModal={onCloseProductOpen}
            />)
                : null}
        </>

    )
}
