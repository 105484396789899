import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { Switch } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { useRef } from 'react';
import InvitationTemplateEdit from './InvitationTemplateEdit';

export default function Employees(props: any) {
    const ref = useRef<any>(undefined);
    const { banquetId, closeModal, } = props;
    let apiId="invitationTemplate"
    //表头
    const columnsHead = [
        {
            title: '模板名称',
            dataIndex: 'invitationTemplateName',

        },
       
        {
            title: "默认菜式推广",
            dataIndex: "isPromoMenu",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isPromoMenu', e, apiId)}
                />
            )
        },
        // {
        //     title: '身份证',
        //     dataIndex: 'IdCard',
        // },
      
    ];
    const handleOk = (e: any) => {
        closeModal(true);
    };
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '邀请函模版',
        apiId,
        showStates: true,
        extendIdName: "banquetId",
        sendPageData: { banquetId },
        columns,
        pagination: true,
        showDefault: true,
        EditComponent: InvitationTemplateEdit,
    };

    return (
        <>
           <AntDraggableModal
                title={'邀请函模版'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal> 
        </>

    )
}
