import { Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import React, { useContext, useEffect, useRef } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import system from "../../../config/systematic";
import { SocketContext } from '../../../App';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function CondimentWarehouseEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    /** 后端数据wmService */
    let wmService = system.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            form.setFieldsValue({ branchStoreId: contextData?.currentBranchStoreId });
            // form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {

                }
            })
        }
        const fetchData = async () => {

        }
        fetchData()
    }, [])

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'condimentWarehouse',
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '加料仓库'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="门店Id"
                                name="branchStoreId"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="加料"
                                name="condimentWarehouseName"
                                rules={[{ required: true, message: '请输入加料!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="价格"
                                name="price"
                                rules={[{ required: true, message: '请输入价格!' }]}>
                                <InputNumber min={0} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}