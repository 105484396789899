import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import AccountantEdit from './AccountantEdit';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import stringHandle from '../../../utils/baseLib/stringHandle';
import { useEffect, useRef } from 'react';
import { Switch } from 'antd';
export default function Accountant(props: any) {
    const apiId = 'accountant';
    const ref = useRef<any>(undefined);
    const { entrepotId, closeModal } = props;
    /** 更改一项数据 */
    let handleChangeOne: any;
    //表头
    const columnsHead = [
        {
            title: '期初日期',
            dataIndex: 'startData',
        },

        {
            title: '期末日期',
            dataIndex: 'endData',

        },
        {
            title: '会计名称',
            dataIndex: 'accountantName',

        },
        {
            title: '当期',
            dataIndex: 'isCurrent',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isCurrent', e, apiId)}
                />
            )

        },

    ];
    useEffect(() => {
        if (ref.current) {
            handleChangeOne = ref.current.handleChangeOne;

        }
    }, [])
    const columns = columnsheadId
        .concat(columnsHead as []);

    const handleOk = (e: any) => {
        closeModal(true);
    };
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '会计',
        apiId,
        showStates: true,
        sendPageData: { entrepotId },
        columns,
        pagination: true,
        EditComponent: AccountantEdit,
    };
    return (
        <>
            <AntDraggableModal
                title={'会计期管理'}
                open={true}
                okText='确定'
                cancelButtonProps={{style:{display:"none"}}}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={1000}>
                <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
            </AntDraggableModal>
        </>

    )
}
