import FilesServerEdit from './FilesServerEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import { Switch } from 'antd';
import { useEffect, useRef } from 'react';

//组件
const WebComponent = (props: any) => {
    const ref = useRef<any>(undefined);
    /** api服务id */
    const apiId = 'filesServer';
    let handleChangeOne: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps

            handleChangeOne = ref.current.handleChangeOne;
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据


    //表头
    const columnsHead = [
        {
            title: '服务器名称',
            dataIndex: 'serverName',
            sorter: true,
        },
        {
            title: '服务器地址',
            dataIndex: 'serverUrl',
        },
        {
            title: '服务器端口',
            dataIndex: 'port',
        },
        {
            title: '默认',
            dataIndex: 'isDefault',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record.id, 'isDefault', e, apiId) :
                            undefined
                    }
                />
            )
        },
        {
            title: '默认服务号',
            dataIndex: 'defaultServiceId',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '文件服务器',
        apiId,
        columns,
        showStates: true,
        EditComponent: FilesServerEdit
    };

    return (
        <WebUniversal props={extendProps} ref={ref} />
    )
}

export default WebComponent;
