import systemConfig from '../../../config/systematic';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import { handleData } from '../warehouse/EntrepotReport/DailyReport';
import antMessage from '../../../utils/extend/AntdNotification'
import DownloadExcel from '../../report/DownloadExcel';

const { RangePicker } = DatePicker;

export default function CostTrStatistics() {
      /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [form] = Form.useForm();

  const [user, setUser] = useState<any>("")
  const [deptData, setDeptData] = useState<any>();
  const[deptModel,setDeptModel]=useState<any>([
    {id:"exDept",name:"调出部门"},
    {id:"imDept",name:"调入部门"},

  ])

  const [queryCriteria, setQueryCriteria] = useState<any>("")

  const [rows, setRows] = useState<Array<any>>([]);



  useEffect(() => {
    const fetchData = async () => {
      form.setFieldValue("chooseTime",[dayjs(), dayjs()])
      let deptData = await fetchApi.GET(wmService, {
        apiId: "dept",
        apiExtend: 'showList',
        apiData:{
                    
        }
      })
      if (deptData.success) {
        setDeptData(deptData.data)
      }
    
     
    }
    fetchData()
  }, [])
  /** 表头内容 */
  let columnsHead: any = [
   
    {
      title: "品号",
      dataIndex: "selfId",
    },
   
   
   
    {
      title: "品名",
      dataIndex: "productName",
    },
    {
      title: "单位",
      dataIndex: "productUnitName",
    },
    
    {
      title: "数量",
      dataIndex: "bNum",
    },
    {
      title: "均价",
      dataIndex: "univalence",
    },
    {
      title: "金额",
      dataIndex: "amount",
    },
  
   
  ]
  const onFinish = () => {
    form.validateFields()
      .then(async (values: any) => {
    
        fetchApi.GET(wmService, {
          apiId: "costReport",
          apiExtend: 'costTrStatistics',
          apiData: {
            deptIds: handleData(values.deptIds, "deptId"),
            time: values.chooseTime ? {
              startTime: dayjs(values.chooseTime[0]).format("YYYY-MM-DD"),
              endTime: dayjs(values.chooseTime[1]).format("YYYY-MM-DD"),
            } : null,
            selfs: values.selfStart && values.selfEnd ? {
              selfStart: values.selfStart,
              selfEnd: values.selfEnd
            } : null,
            deptModel:values.deptModel
          }
        }).then((res: any) => {

          setRows(res.data.rows)
          setQueryCriteria(res.data.queryCriteria)
          setUser(res.data.user)
          antMessage('success', '搜索成功', 'success');

        }).catch((err: any) => {
          console.log("err", err);

          antMessage('error', '搜索失败', err.message + ' | status：' + err.errors.status);
        })
      })
      .catch((err) => {
        antMessage('warning', '无法通过数据校验', '请输入所需数据！');
      })





  }
  return (
    <>
    <Form
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="部门"
              name="deptIds"
              rules={[{ required: true, message: '请选择部门!' }]}
            >
              <Select
                // orderTypeData
                mode="multiple"
                showSearch
                placeholder="请选择部门!"
                optionFilterProp="children"

                onSearch={(e) => getList.search('dept', setDeptData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(deptData, 'deptName')}
              </Select>
            </Form.Item>
          </Col>

        
          <Col span={4}>
            <Form.Item
              label="部门模式"
              name="deptModel"
              rules={[{ required: true, message: '请选择部门模式!' }]}
            >
              <Select
                showSearch
                placeholder="部门模式"
                optionFilterProp="children"
            
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(deptModel, 'name')}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="品号"
              name="selfStart"
            >
              <Input
              // onChange={handleChange}
              // onBlur={handleBlur}
              // placeholder="Input a number"
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              colon={false}
              label="-"
              name="selfEnd"
            >
              <Input
              // onChange={handleChange}
              // onBlur={handleBlur}
              // placeholder="Input a number"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="chooseTime"
            >
              <RangePicker
                defaultValue={[dayjs(), dayjs()]}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  统计
                </Button >
                <Button onClick={() => DownloadExcel(rows, "costTrStatistics", queryCriteria)}>下载EXCEL文件</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div style={{ marginTop: "20px" }}>
        <Table
  scroll={{ x: "100%", y: 650 }}
          pagination={false}
          rowKey={(record: any,index:any) => {
            return index
          }}
          columns={columnsHead}
          dataSource={rows}

        >
        </Table>
        
      </div>
    </>
  )
}
