import ServiceChargeEdit from './ServiceChargeEdit';
import WebUniversal, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx'

import { columnsheadId } from '../../universal/columnshead';
import { SocketContext } from '../../../App';
import { useContext, useEffect, useRef } from 'react';

//组件 
const ServiceCharge = (props: any) => {

    /** api服务id */
    const apiId = 'serviceCharge';
    
    const ref = useRef<any>(undefined);
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;
    //表头
    const columnsHead = [
      
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any, record: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '附加折扣',
            dataIndex: 'serviceCharge',
        },
      
    ];
    useEffect(() => {
        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                const currentData: initDataType = {
                    apiId,
                    sendPageData: { branchStoreId:contextData?.currentBranchStoreId }
                }
                ref.current.initData(currentData);
                setContextData({ ...contextData, refresh: false })
                // onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }
    },[contextData?.currentBranchStoreId])
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '服务费',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        showDefault:true,
        extendIdName:"branchStoreId",
        EditComponent: ServiceChargeEdit,
        mountDataOptions:{
            userMount:{
               isDept:true,
            }
       },
       sendPageData: { branchStoreId: contextData?.currentBranchStoreId }
    };

    return (
        <WebUniversal ref={ref} props={extendProps} />
    )
}

export default ServiceCharge;
