import { Form, Input, InputNumber, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function MenuWarehouseTypeEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    /** 后端数据wmService */
    let wmService: any = "";

    const [menuWarehouseCategoryData, setMenuWarehouseCategoryData] = useState<any>()

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "menuWarehouseCategory",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseCategoryData(res.data)
                }
            })
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuWarehouseType'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜式仓库小分类'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    initialValues={{ "sortBy": 999 }}
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="菜式仓库大分类"
                        name="menuWarehouseCategoryId"
                        rules={[{ required: true, message: '请选择菜式仓库大分类!' }]}>
                        <Select
                            showSearch
                            placeholder="菜式仓库大分类"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.generalEx(menuWarehouseCategoryData, {
                                itemName: "menuWarehouseCategoryName",
                                includeNull: false
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="菜式仓库小分类"
                        name="menuWarehouseTypeName"
                        rules={[{ required: true, message: '请输入菜式仓库小分类!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="排序"
                        name="sortBy">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}