import React, { useContext, useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType, initDataType } from '../../../system/WebUniversalNewEx';
import { Button, Popconfirm, Space, Switch, Tooltip } from 'antd';
import fetchApi from '../../../../utils/api/fetchApi';
import system from "../../../../config/systematic";
import CondimentInventoryEdit from './CondimentInventoryEdit';
import InventoryToEstimate from './InventoryToEstimate';
import { SocketContext } from '../../../../App';

export default function MenuImSpecInventory(props: any) {
  const ref = useRef<any>(undefined);
  /**后端数据wmService */
  const wmService = system.wmService;
  const apiId = "condimentInventory";
  //沽清列表
  const [inventoryToEstimate, setInventoryToEstimate] = useState(false);
  const [estimateTransferData, setEstimateTransferDat] = useState<any>();
  const [isZero, setIsZero] = useState<boolean>(false);
  const useContextData = useContext(SocketContext);
  const { contextData, setContextData } = useContextData;
  useEffect(() => {
    if (contextData?.currentBranchStoreId) {
      if (contextData?.refresh) {
        onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
      }
    }

  }, [contextData?.currentBranchStoreId])
  //表头
  const columnsHead = [
    {
      title: '加料',
      dataIndex: 'condimentWarehouse',
      render: (text: any) => (text ? text.condimentWarehouseName : "")
    },


    {
      title: '数量(库存)',
      dataIndex: 'quantity',
    },
    {
      title: '价格',
      dataIndex: 'condimentWarehouse',
      render: (text: any) => (text ? text.price : "")
    },

  ];
  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '加料库存',
    apiId,
    // showAdd: false,
    columns,
    pagination: true,
    EditComponent: CondimentInventoryEdit,
    sendPageData: { isZero, branchStoreId: contextData?.currentBranchStoreId },
    mountDataOptions: {
      userMount: {
        isDept: true,
      }
    },
    showLogger: {
      logger: { "id": "opLogger" },
      loggerDisplay: true,
      loggerSingleDisplay: true,
      "loggerQueryTypeExtendId": contextData?.currentBranchStoreId,
      // queryTypeExtendId:
      // isScope:true
    }

  };
  const cleanMenuInventory = () => {


    fetchApi.POST(wmService, {
      apiId,
      apiExtend: "cleanMenuInventory",
      apiData: {
        branchStoreId: contextData?.currentBranchStoreId,
        mountDataOptions: {
          userMount: {
            isDept: true,
          }
        },
      }

    }).then((res) => {
      if (res.success) {
        const currentData: initDataType = {
          apiId,
          sendPageData: { isZero, branchStoreId: contextData?.currentBranchStoreId }
        }
        ref.current.initData(currentData)
      }


    }).catch((err) => {
      console.log("err", err);

    })
  }

  /**筛选是否库存为0的数据 */
  const changeIsZero = (e: any) => {
    setIsZero(e)
    // setCateringOrderModeData(e);
    const currentData: initDataType = {
      apiId,
      sendPageData: { isZero: e, branchStoreId: contextData?.currentBranchStoreId }
    }
    ref.current.initData(currentData)
  }
  // 沽清穿梭框处理
  const handleEstimateTransfer = () => {

    setEstimateTransferDat({
      apiId: "condimentInventory",
      apiExtend: "estimateToNoEstimate",
      name: "condimentWarehouseName",
      transferTitle: ["待沽清列表", "已(即将)沽清列表"]
    });
    setInventoryToEstimate(true);
  };

  //关闭穿梭框页面
  const onCloseEstimateTransfer = async (status: boolean) => {


    setInventoryToEstimate(false);
    if (status) {
      const currentData: initDataType = {
        apiId,
        sendPageData: { isZero, branchStoreId: contextData?.currentBranchStoreId }
      }
      ref.current.initData(currentData)
    }
  }
  const onRefresh = (item: any) => {

    const { branchStoreId } = item
    const currentData: initDataType = {
      apiId,
      sendPageData: { isZero, branchStoreId }
    }
    if (ref.current) {
      ref.current.initData(currentData)
      setContextData({ ...contextData, refresh: false })
    }
  }
  return (
    <>
      {/* <div style={{ width: "30%", display: "flex", justifyContent: "space-between" }}>
        <BranchStoreList onRefresh={onRefresh}  ></BranchStoreList>
      </div> */}
      <Space>
        <Popconfirm title="是否清空库存?" onConfirm={cleanMenuInventory}>
          <Button danger >清空库存</Button>
        </Popconfirm>
        <Button type="primary" onClick={handleEstimateTransfer}>沽清操作</Button>
        <Tooltip placement="top" title={"此按钮用于筛选库存为零的数据"}>  < Switch onChange={changeIsZero} checked={isZero}
          checkedChildren="是"
          unCheckedChildren="否" /></Tooltip>
      </Space>


      {inventoryToEstimate
        ? (<InventoryToEstimate
          closeModal={onCloseEstimateTransfer} data={estimateTransferData} />)
        : null}
      <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
    </>
  )
}
