import { Form, Input } from 'antd';
import React, { useRef } from 'react'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';

//布局
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
export default function ProductUnitEdit(props:any) {

  const [form] = Form.useForm(); //form 
  const { data } = props;
  const ref = useRef<any>(undefined);
  /** 后端数据Service */
  let Service: any = "";
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const handleCancel = (e: any) => {
    ref.current.handleCancel();
  };
  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'productUnit'
  };
  return (
    <>
       <WebEditUniversal ref={ref} props={extendProps} />

       <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '产品'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
      >
        <Form
          {...layout}
          form={form}
          name="Edit"
          onFinish={handleOk}
         onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}
        >
          <Form.Item
            label="id"
            name="id">
            <Input disabled={true} />
          </Form.Item>

          <Form.Item
            label="产品单位"
            name="productUnitName">
            <Input />
          </Form.Item>
        </Form>
      </AntDraggableModal>
    </>
  )
}
