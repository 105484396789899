import WebUniversal, { initDataType, WUExtendPropsType } from '../WebUniversalNewEx'
import { columnsheadId, showText } from '../../universal/columnshead';
import dayjs from 'dayjs';
import { Button, DatePicker, Select, Space, Tooltip } from 'antd';
import LoggerClear from './LoggerClear';
import { useEffect, useRef, useState } from 'react';
import getList from '../../universal/getList';

/** 日志类型枚举 */
enum loggerTypeEnum {
    /** 操作日志 */
    opLogger = 'opLogger',
    /** 系统日志 */
    systemLogger = 'systemLogger',
    /** 登录日志 */
    loginLogger = 'loginLogger',
    /** 菜单操作日志 */
    menuOpLogger = 'menuOpLogger',
};

/** 日志类型详情 */
export declare interface loggerTypeDetails {
    [loggerTypeEnum.opLogger]: {
        /** id */
        id: 'opLogger',
        /** 名称 */
        name?: '操作日志'
    },
    [loggerTypeEnum.systemLogger]: {
        /** id */
        id: 'systemLogger',
        /** 名称 */
        name?: '系统日志'
    },
    [loggerTypeEnum.loginLogger]: {
        /** id */
        id: 'loginLogger',
        /** 名称 */
        name?: '登录日志'
    },
    [loggerTypeEnum.menuOpLogger]: {
        /** id */
        id: 'menuOpLogger',
        /** 名称 */
        name?: '菜单操作日志'
    }
};

/** 日志类型 */
export declare type loggerType = loggerTypeDetails[loggerTypeEnum];

/**
 * 日志
 * @param props 
 * @param options 选项
 * @returns 
 */
const Logger = (props: any) => {
    /** 日志类型 */
    let { data } = props;
    if (typeof data === 'undefined') {
        data = {};
    }
    const ref = useRef<any>(undefined);
    let { isQuery, queryType, queryTypeExtendId, queryId, isScope, logger,
        isShowloggerClear, isLockLoggerTypeSelect, isLockLoggerDateSelect } = data;
    const [dateTime, setDateTime] = useState<any>();
    const [loggerTypeSelectDisabled, setLoggerTypeSelectDisabled] = useState(false);
    const [loggerDateSelectDisabled, setLoggerDateSelectDisabled] = useState(false);
    /** apiId */
    const apiId = 'logger';
    /** 日志id */
    const [loggerId, setLoggerId] = useState<string>('systemLogger');
    const [loggerTypeData, setLoggerTypeData] = useState<any>();
    if (typeof isQuery === 'undefined') {
        isQuery = true;
    }
    if (typeof isShowloggerClear === 'undefined') {
        isShowloggerClear = true;
    }
    const [modalVisibleLoggerClear, setModalVisibleLoggerClear] = useState(false);
    const loggerClearVisible = (visible: boolean) => {
        console.log('???????????????????');
        console.log(visible);

        setModalVisibleLoggerClear(visible);
    }

    useEffect(() => {
        let loggerList: Array<loggerType> = [{ id: 'systemLogger', name: '系统日志' }];
        for (const value of Object.values(loggerTypeEnum)) {
            switch (value) {
                case 'opLogger':
                    loggerList = loggerList.concat({
                        id: value,
                        name: '操作日志'
                    })
                    break;
                case 'loginLogger':
                    loggerList = loggerList.concat({
                        id: value,
                        name: '登录日志'
                    })
                    break;
                case 'menuOpLogger':
                    loggerList = loggerList.concat({
                        id: value,
                        name: '菜单操作日志'
                    })
                    break;
            }
        }
        setLoggerTypeData(loggerList);
        if (logger) {
            handleSelectLoggerType(logger.id);
            if (isLockLoggerTypeSelect === true) {
                setLoggerTypeSelectDisabled(true);
            }
            if (isLockLoggerDateSelect === true) {
                setLoggerDateSelectDisabled(true);
            }
        }
        else {
            handleSelectLoggerType(loggerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //表头
    const columnsHead = [
        {
            title: '时间',
            dataIndex: 'createdAt',
            render: (val: any) =>
                <span>
                    {dayjs(val).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
            sorter: true,
        },
        {
            title: '级别',
            dataIndex: 'logsLevel_Name',
            sorter: true,
            render: (text: any, record: any) => {
                let showText;
                switch (record.logsLevelId) {
                    case 0:
                        showText = (<span style={{ color: 'red' }}>{text}</span>);
                        break;
                    case 1:
                        showText = (<span style={{ color: 'yellow' }}>{text}</span>);
                        break;
                    case 2:
                        showText = (<span style={{ color: 'green' }}>{text}</span>);
                        break;
                    case 4:
                        showText = (<span style={{ color: 'blue' }}>{text}</span>);
                        break;
                    default:
                        <>{text}</>
                        break;
                }
                return showText;
            }
        },
        {
            title: '来源',
            dataIndex: 'scope',
            sorter: true,
            render: (text: any) => {
                return showText(text, 15, 'suffix');
            }
        },
        {
            title: '信息',
            dataIndex: 'info',
            sorter: true,
            render: (text: any) => {
                return showText(text, 20);
            }
        },
        {
            title: '详情',
            dataIndex: 'details',
            render: (text: any) => {
                return showText(text, 20);
            }
        },
        {
            title: '代码',
            dataIndex: 'errorCodeId',
        },
        {
            title: '设备',
            dataIndex: 'device',
            render: (text: any) => <Tooltip placement="topLeft" title={text ? text.deviceName : ''}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.deviceName.length >= 10 ? text.deviceName.substring(0, 10) + '......' : text.deviceName)
                    : ''
            }</Tooltip>
        },
        {
            title: '操作人',
            dataIndex: 'userName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 10 ? text.substring(0, 10) + '......' : text)
                    : ''
            }</Tooltip>
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '日志',
        apiId,
        columns,
        sendPageData: { loggerId, queryType, queryTypeExtendId, queryId, isScope, dateTime },
        showOperation: !isQuery,
        showAdd: false,
        showEdit: false,
        showQuery: isQuery,
        pagination: true,
        isInitialDisplayData: false,
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    /**
     * 刷新数据
     * @param options 选项
     */
    const refreshData = (options: {
        currentLoggerId?: string,
        currentSendPageData?: any
    }) => {
        let { currentLoggerId, currentSendPageData } = options;
        currentSendPageData = typeof currentSendPageData !== 'undefined' ? currentSendPageData : { loggerId, queryType, queryTypeExtendId, queryId, isScope, dateTime }
        if (typeof currentLoggerId !== 'undefined') {
            currentSendPageData = {
                ...currentSendPageData,
                loggerId: currentLoggerId
            }
        }
        const currentData: initDataType = {
            apiId,
            sendPageData: currentSendPageData
        }
        ref.current.initData(currentData);
    };

    /**
     * 选项日志类型
     * @param e 
     */
    const handleSelectLoggerType = (e: any) => {
        refreshData({
            currentLoggerId: e
        })
        setLoggerId(e);
    };

    /**
     * 根据日期回显数据
     * @param e 
     */
    const handleDate = (e: any) => {
        let orderDateEx;
        if (e) {
            orderDateEx = dayjs(e).format("YYYY-MM-DD")
            setDateTime(orderDateEx)
        } else {
            setDateTime(null)
        }
        refreshData({
            currentSendPageData: { loggerId, queryType, queryTypeExtendId, queryId, isScope, dateTime: orderDateEx }
        })
    };

    return (
        <>
            <Space>
                <Select
                    showSearch
                    placeholder="日志类型"
                    optionFilterProp="children"
                    defaultValue={'systemLogger'}
                    onChange={handleSelectLoggerType}
                    disabled={loggerTypeSelectDisabled}
                    value={loggerId}
                    style={{ width: 130 }}
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.generalEx(loggerTypeData)}
                </Select>
                <DatePicker
                    allowClear
                    format="YYYY-MM-DD"
                    disabled={loggerDateSelectDisabled}
                    onChange={handleDate} />
                {isShowloggerClear === true ? <Button onClick={() => loggerClearVisible(true)}>清除日志</Button> : null}
            </Space>
            <WebUniversal ref={ref} props={extendProps} />
            {modalVisibleLoggerClear ? (<LoggerClear apiId={apiId} onClose={loggerClearVisible} />) : null}

        </>
    )
}

export default Logger;


