import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewExEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import WebProductFormList from '../../generalComponents/WebProductFormList';
import dayjs from 'dayjs';
export default function ProcessingOrderEdit(props: any) {


    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    const apiId = 'processingOrder';
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [deleteData, setDeleteData] = useState<any>();

    /**分店数据 */
    const [branchStoreData, setBranchStoreData] = useState<any>();








    const [formList, setFormList] = useState<any>({ formName: "processingProducts", form, orderType: "processingOrder", type: data.type, ref, setDeleteData })
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {

        if (ref.current) {
            ref.current.row.then((res: any) => {
                if (typeof res === 'undefined') return
                if (res.success) {
                    form.setFieldsValue({
                        // userName: res.data.userLogin.userName,
                        orderTime: dayjs(res.data.sysDate.createdAt).format("YYYY-MM-DD")
                    })
                }
            })

            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

                let branchStoreData = await fetchApi.GET(wmService, {
                    apiId: "branchStore",
                    apiExtend: "showList"
                })
                if (branchStoreData.success) {
                    setBranchStoreData(branchStoreData.data)

                }


        }
        fetchData()
    }, [])




    const handleOk = (e: any) => {

        setLoading(true)
        if (ref.current?.handleOk) ref.current.handleOk();

    };

    const handleCancel = (e: any) => {

        ref.current.handleCancel();
    };
    // const onChangeEntrepotIdToBuyerData = () => {
    //     if (!selectAreaId) return false;


    //     httpGet(wmService, {
    //         apiId: "buyerType",
    //         apiExtend: 'getAreaIdToBuyer',
    //         areaId: selectAreaId
    //     }).then((res) => {
    //         setBuyerData(res.data)

    //     })
    //     form.resetFields(["buyerId"])

    // }




    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId,
        formListName: "processingProducts",
        changeOrderTime: {
            isTime: true,
            time: sessionStorage.getItem("date")
        }

    };

    // const children = (isLoading: boolean) => {
    //     return
    // }
    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} totalData={deleteData} setLoading={setLoading}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : data.type === "EDIT" ? "编辑" : "查看") + '订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                okButtonProps={{ disabled: data.type === 'VIEW' ? true : false }}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={"70%"}
                confirmLoading={loading}
            >
                <Form
                    form={form}
                    name="Edit"
                    onKeyDown={(e: any) => {
                        if (e.altKey) {
                            if (e.key === "s" && e.altKey) {
                                if (loading === false) {
                                    handleOk(e)

                                }
                            }
                        }

                    }}
                >
                    <Row >

                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={data.level === 1 ? false : true} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="开单人"
                                name="userName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="日期"
                                name="orderTime">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="分店"
                                name="branchStoreId"
                                rules={[{ required: true, message: '请输入分店!' }]}>
                                <Select
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    placeholder="分店"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.search('branchStore', setBranchStoreData, e)}
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}>
                                    {getList.general(branchStoreData, 'branchStoreName')}
                                </Select>
                            </Form.Item>
                        </Col>





                        <Col span={24}>
                            <WebProductFormList props={formList}></WebProductFormList>
                        </Col>


                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}


