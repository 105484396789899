import PrinterEdit from './PrinterEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebUniversalNewEx from '../../system/WebUniversalNewEx';
import { useRef } from 'react';

//组件 
const Printer = (props: any) => {
    
    /** api服务id */
    const apiId = 'printer';
    const ref = useRef<any>(undefined);

    const { deptId, closeModal, isModel } = props;
    
    //表头
    const columnsHead = [
        {
            title: '打印机名称',
            dataIndex: 'printerName',
        },
        {
            title: 'window打印机名称',
            dataIndex: 'windowsPrinterName',
        },
      
        {
            title: 'IP地址',
            dataIndex: 'ipAddress',
        },
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '最后修改人',
            dataIndex: 'userId',
            render: (text: any, record: any) => {
                return (
                    <div>{record.user.userName}</div>
                )
            }
        },
        {
            title: '最后修改时间',
            dataIndex: 'address',
            render: (text: any, record: any) => {
                return (
                    <div>{moment(record.sysDate.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                )
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '打印机',
        apiId,
        columns,
        sendPageData: { deptId },
        showStates: true,
        pagination: true,
        EditComponent: PrinterEdit,
        mountDataOptions:{
            userMount:{
               isDept:true,
            }
       },
    };
    const handleOk = (e: any) => {
        closeModal(true);
    };
    return (
        
        <>
        {isModel ? <AntDraggableModal
            title={'打印机'}
            open={true}
            okText='确定'
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={handleOk}
            closable={false}
            maskClosable={false}
            width={800}>
            <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
        </AntDraggableModal> : <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>}


    </>
    )
}

export default Printer;