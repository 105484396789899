import { Col, Form, Input, InputNumber, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';



const { TextArea } = Input;
export default function ParkingLotEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {


        ref.current.handleCancel();
    };
    /** 后端数据wmService */
    let wmService: any = "";
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'parkingLot'
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            // httpGet(wmService, {
            //     apiId: "floor",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {

            //         setFloor(res.data)
            //     }
            // })



        }
        fetchData()
    }, [])

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '分店楼层'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row >
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled />
                            </Form.Item>

                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="位置名"
                                name="locationName">
                                <Input  />
                            </Form.Item>

                        </Col>


                        <Col span={24}>
                            <Form.Item
                                label="详细地址"
                                name="address">
                                <TextArea
                                    placeholder="详细地址"
                                    autoSize={{ minRows: 1, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item
                                label="纬度"
                                name="latitude">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item
                                label="经度"
                                name="longitude" >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}


