import React, { useRef } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import BranchStoreFloorEdit from './BranchStoreFloorEdit';
import ParkingLotEdit from './ParkingLotEdit';

export default function BranchStoreFloor(props: any) {
  const { branchStoreId, closeModal } = props;
  const ref = useRef<any>(undefined);
  const handleOk = (e: any) => {
    closeModal(true);
  };
  //表头
  const columnsHead = [

    {
      title: '纬度',
      dataIndex: 'latitude',
    },
    {
        title: '经度',
        dataIndex: 'longitude',
      },
      {
        title: '详细地址',
        dataIndex: 'address',
      },
      {
        title: '位置名',
        dataIndex: 'locationName',
      },
    {
      title: '排序',
      dataIndex: 'sortBy',
    },




  ];
  const columns = columnsheadId
    .concat(columnsHead as []);
  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '停车场',
    apiId: 'parkingLot',
    showStates: true,
    sendPageData: { branchStoreId },
    columns,
    pagination: true,
    EditComponent: ParkingLotEdit,
  };
  return (
    <div>
      <AntDraggableModal
        open={true}
        okText='确定'
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        closable={false}
        maskClosable={false}
        width={800}>
        <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
      </AntDraggableModal>
    </div>
  )
}
