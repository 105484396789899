import { useRef, useState } from 'react';
import UserGroupEdit from './UserGroupEdit';
import UserGroupArea from './UserGroupArea';
import UserGroupBrand from './UserGroupBrand';
import UserGroupBranchStore from './UserGroupBranchStore';
import UserGroupEntrepot from './UserGroupEntrepot';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button, Space, Tooltip } from 'antd';

const ShowPageComponent = (props: any) => {

    const ref = useRef<any>(undefined);
    const [userGroupId, setUserGroupId] = useState<string>();
    const [modalUserGroupArea, setModalUserGroupArea] = useState(false);
    const [modalUserGroupBrand, setModalUserGroupBrand] = useState(false);
    const [modalUserGroupBranchStore, setModalUserGroupBranchStore] = useState(false);
    const [modalUserGroupEntrepot, setModalUserGroupEntrepot] = useState(false);

    const handleModalUserGroupArea = (userGroupId: string) => {
        setUserGroupId(userGroupId);
        setModalUserGroupArea(true);
    };

    const onCloseModalModalUserGroupArea = () => {
        setModalUserGroupArea(false);
    };

    const handleModalUserGroupBrand = (userGroupId: string) => {
        setUserGroupId(userGroupId);
        setModalUserGroupBrand(true);
    };

    const onCloseModalModalUserGroupBrand = () => {
        setModalUserGroupBrand(false);
    };

    const handleModalUserGroupBranchStore = (userGroupId: string) => {
        setUserGroupId(userGroupId);
        setModalUserGroupBranchStore(true);
    };

    const onCloseModalModalUserGroupBranchStore = () => {
        setModalUserGroupBranchStore(false);
    };

    const handleModalUserGroupEntrepot = (userGroupId: string) => {
        setUserGroupId(userGroupId);
        setModalUserGroupEntrepot(true);
    };

    const onCloseModalModalUserGroupEntrepot = () => {
        setModalUserGroupEntrepot(false);
    };
    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '用户组名',
            dataIndex: 'userGroupName',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
        {
            title: '地区',
            dataIndex: 'areaId',
            key: 'areaId',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Tooltip placement="topLeft" title={record.userGroupName + ' 地区管理'}>
                        <Button size={"small"} type="primary" onClick={() => handleModalUserGroupArea(record.id)}>地区</Button>
                    </Tooltip>
                </Space>
        },
        {
            title: '品牌',
            dataIndex: 'brandId',
            key: 'brandId',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Tooltip placement="topLeft" title={record.userGroupName + ' 品牌管理'}>
                        <Button size={"small"} type="primary" onClick={() => handleModalUserGroupBrand(record.id)}>品牌</Button>
                    </Tooltip>
                </Space>
        },
        {
            title: '分店',
            dataIndex: 'branchStoreId',
            key: 'branchStoreId',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Tooltip placement="topLeft" title={record.userGroupName + ' 分店管理'}>
                        <Button size={"small"} type="primary" onClick={() => handleModalUserGroupBranchStore(record.id)}>分店</Button>
                    </Tooltip>
                </Space>
        },
        {
            title: '仓库',
            dataIndex: 'entrepotId',
            key: 'entrepotId',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Tooltip placement="topLeft" title={record.userGroupName + ' 仓库管理'}>
                        <Button size={"small"} type="primary" onClick={() => handleModalUserGroupEntrepot(record.id)}>仓库</Button>
                    </Tooltip>
                </Space>
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '用户组',
        apiId: 'userGroup',
        columns,
        showStates: true,
        EditComponent: UserGroupEdit,
    };

    return (
        <>
            <WebUniversal
                props={extendProps}
                ref={ref} />

            {modalUserGroupArea ? (<UserGroupArea
                closeModal={onCloseModalModalUserGroupArea}
                userGroupId={userGroupId}
            ></UserGroupArea>) : null}

            {modalUserGroupBrand ? (<UserGroupBrand
                closeModal={onCloseModalModalUserGroupBrand}
                userGroupId={userGroupId}
            ></UserGroupBrand>) : null}

            {modalUserGroupBranchStore ? (<UserGroupBranchStore
                closeModal={onCloseModalModalUserGroupBranchStore}
                userGroupId={userGroupId}
            ></UserGroupBranchStore>) : null}

            {modalUserGroupEntrepot ? (<UserGroupEntrepot
                closeModal={onCloseModalModalUserGroupEntrepot}
                userGroupId={userGroupId}
            ></UserGroupEntrepot>) : null}
        </>
    )
}

export default ShowPageComponent;
