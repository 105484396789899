import { columnsheadId } from '../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../system/WebUniversalNewEx';
import MenuWarehouseEdit from '../internal/menu/MenuWarehouseEdit';
import MenuWarehouseIngredient from '../internal/menu/MenuWarehouseIngredient';
import MenuWarehouseSpec from '../internal/menu/MenuWarehouseSpec';
import MenuWarehouseUploadFile from '../internal/menu/MenuWarehouseUploadFile';
import { useEffect, useRef, useState } from 'react'
import { Button, Space, Tooltip } from 'antd';
import system from "../../config/systematic";

const MenuWarehouse = (props: any) => {


    /** 后端数据Service */
    let wmService = system.wmService;
    const ref = useRef<any>(undefined);
    const apiId = "menuWarehouse";

    //规格窗口
    const [menuWarehouseSpecVisible, setMenuWarehouseSpecVisible] = useState(false);

    //主配料窗口
    const [menuWarehouseIngredientVisible, setMenuWarehouseIngredientVisible] = useState(false);

    //规格数据
    const [specData, setSpecData] = useState<any>();

    //主配料数据
    const [ingredientData, setIngredientData] = useState<any>();

    useEffect(() => {
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        foodName: '',
        path: 'MenuWarehouse',
    }

    //打开添加规格
    const HandleMenuWarehouseSpec = (record: any) => {
        //console.log(record)
        sendData.id = record.id;
        sendData.foodName = record.foodName;
        setSpecData(sendData);
        setMenuWarehouseSpecVisible(true);
    };

    //打开添加主配料
    const HandleIngredient = (record: any) => {
        //console.log(record)
        sendData.id = record.id;
        sendData.foodName = record.foodName;
        setIngredientData(sendData);
        setMenuWarehouseIngredientVisible(true);
    };

    //表头
    const columnsHead = [
        {
            title: '品牌',
            dataIndex: 'brandName',
        },
        {
            title: '菜式仓库小分类',
            dataIndex: 'menuWarehouseType',
            sorter: true,
            render: (text: any, record: any) => {
                return (
                    <div>{text.menuWarehouseTypeName}</div>
                )
            }
        },
        {
            title: '烹调方法',
            dataIndex: 'cookMethod',
            sorter: true,
            render: (text: any, record: any) => {
                return (
                    <div>{text.cookMethodName}</div>
                )
            }
        },
        {
            title: '菜式名称',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" title={record.foodNameEn ? record.foodNameEn : ""}>
                        <div>{text}</div>
                    </Tooltip>
                )
            }
        },
        {
            title: '创作时间地区',
            dataIndex: 'created',
            render: (text: any, record: any) => {
                if (text) {
                    return (
                        <div>{text + "年创于" + record.areaName}</div>
                    )
                }
            }
        },
        {
            title: "单位",
            dataIndex: "productUnitName",
            render: (text: any, record: any) => {
                return (
                    <div>{record.productUnit.productUnitName}</div>
                )
            }
        },
        {
            title: '规格',
            dataIndex: '',
            key: 'menuWarehouseSpec',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => HandleMenuWarehouseSpec(record)}>规格</Button>
                </Space>
        },
        {
            title: '设置主配料',
            dataIndex: 'ingredient',
            key: 'ingredient',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => HandleIngredient(record)}>设置主配料</Button>
                </Space>
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式仓库',
        apiId,
        showImage: true,
        showUploadFile: true,
        showStates: false,
        disabledAdd: false,
        showOperation: true,
        showAdd: true,
        pagination: true,
        columns,
        EditComponent: MenuWarehouseEdit,
        arrayFilesName: 'menuWarehouseFiles',
        UploadFileComponent: MenuWarehouseUploadFile,
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>

            {menuWarehouseIngredientVisible
                ? (<MenuWarehouseIngredient
                    closeModal={setMenuWarehouseIngredientVisible} data={ingredientData} />)
                : null}

            {menuWarehouseSpecVisible
                ? (<MenuWarehouseSpec
                    closeModal={setMenuWarehouseSpecVisible} data={specData} />)
                : null}
        </>
    )
}

export default MenuWarehouse
// useEffect(() => {
//   if (ref.current) {
//       // eslint-disable-next-line react-hooks/exhaustive-deps
//       wmService = ref.current.wmService;
//   }
//   const fetchData = async () => {  
//     fetchApi.POST(wmService, {
//       apiId: "jellEight",
//       apiExtend: "create",
//       apiData: {
//         name:'榴莲雪糕', price:16, kind:'雪糕', recommend:true, specifications:''
//       }
//     }).then(async res => {
//       console.log(res);
//     })          
//   }
//   fetchData()
// }, [])