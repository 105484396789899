import { Col, Form, Input, InputNumber, Radio, RadioChangeEvent, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import objectHandle from '../../../utils/baseLib/objectHandle';



const { TextArea } = Input
export default function DiningMethodEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>(undefined);
    const [initialPrintOrderType, setInitialPrintOrderType] = useState<any>([]);
    const [printOrderTypeData,setPrintOrderTypeData]=useState<any>([])
    /**支付方式 先/后 */
    const [orderPaymentModeData, setOrderPaymentModeData] = useState<any>();

    const [diningData, setDiningData] = useState<any>();


    const [roomData, setRoomData] = useState<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {


        ref.current.handleCancel();
    };
    /** 后端数据wmService */
    let wmService: any = "";
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'diningMethod'
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') return
                if (res.success === true) {
                    const printOrderType = res.data.printOrderType;
                    let printOrderTypeRows;
                    if (objectHandle.isEmpty(printOrderType) === false) {
                        printOrderTypeRows = printOrderType.split('@');
                    }
                    setInitialPrintOrderType(printOrderTypeRows);
                    form.setFieldsValue({
                        printOrderType: printOrderTypeRows
                    })
                }
            })
        }
        const fetchData = async () => {


            fetchApi.GET(wmService, {
                apiId: "dining",
                apiExtend: 'showList',
            }).then(res => {
                if (res.success) {

                    setDiningData(res.data)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "room",
                apiExtend: 'showList',
                apiData: {
                    branchStoreId: data.sendPageData?.branchStoreId,
                    mountDataOptions:{
                        userMount:{
                           isDept:true,
                        }
                   },
                }
            }).then(res => {
                if (res.success) {
                    setRoomData(res.data)


                }
            })

            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'showLists',
                apiVariable: 'orderPaymentMode@printOrderType'
            }).then(res => {
                if (res.success) {
                    const {orderPaymentMode,printOrderType}=res.data
                    setOrderPaymentModeData(orderPaymentMode)
                    setPrintOrderTypeData(printOrderType)
                }
            })


        }
        fetchData()
    }, [])

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '分店就餐方式'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row >
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled />
                            </Form.Item>

                        </Col>
                        <Col span={12} >
                            <Form.Item
                                label="就餐方式"
                                name="diningId"
                                rules={[{ required: true, message: '选择就餐方式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="选择就餐方式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(diningData, "diningName")}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="付款模式"
                                name="orderPaymentModeId"
                                rules={[{ required: true, message: '请选择付款模式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="选择付款模式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(orderPaymentModeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="房台选择"
                                name="roomId"
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="房台"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(roomData, "roomName",undefined,undefined,true)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="描述"
                                name="description">
                                <TextArea
                                    placeholder="描述"
                                    autoSize={{ minRows: 1, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="订单类型"
                                name="printOrderType"
                                initialValue={initialPrintOrderType} >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="订单类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(printOrderTypeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="图标"
                                name="iconUrl">
                                <Input  />
                            </Form.Item>

                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="线上支付"
                                name="isOnlinePayment"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item
                                label="扫码支付"
                                name="isPayCodeOnlinePayment"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item
                                label="授权"
                                name="isAccredit"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber />
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}


