import EditComponent from './StoragePlaceEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 
const WebComponents = (props: any) => {

    /** api服务id */
    const apiId = 'sys_storagePlace';

    //表头
    const columnsHead = [
        {
            title: '区域',
            dataIndex: 'area',
            render: (text: any) => (text ? text.areaName : "")
        },
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '存放地点',
            dataIndex: 'storagePlaceName'
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '存放地点',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent,
        mountDataOptions: {
            userMount: {
                isDept: true,
            }
        },
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebComponents;
