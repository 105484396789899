import { Button, DatePicker, List, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi'
import systemConfig from '../../../config/systematic';
import moment from 'moment';
import dayjs from 'dayjs';

export default function TimeTypeList(props: any) {
    const { data, closeModal } = props;
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    //市别数据
    const [timeTypeData, setTimeTypeData] = useState<Array<any>>([])
    useEffect(() => {
        initData({ bookingDate: data.bookingDate })
        const fetchData = async () => {
        }
        fetchData()
    }, [])

    const initData = (apiData: any) => {
        const { bookingDate } = apiData;
        fetchApi.GET(wmService, {
            apiId: "roomReserve",
            apiExtend: 'getDateToTimeData',
            apiData: {
                bookingDate,
                mountDataOptions:{
                    userMount:{
                       isDept:true,
                    }
               },
            }
        }).then(res => {

            if (res.success === true) {

                setTimeTypeData(res.data.rows)
                //   console.log("res",res);


            }
        })
    }
    const onChangeDate=(e:any)=>{
        let bookingDate=e?dayjs(e).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD");
     
        initData({bookingDate})
        
    }
    return (
        <Modal

            title={<div style={{ fontSize: "30px", display: "flex", justifyContent: "space-between" }}>
                <div>请选择市别</div>
                <div>
                    <DatePicker onChange={onChangeDate} defaultValue={dayjs(data.bookingDate)} />
                </div>

            </div>}
            open={true}
            onCancel={() => closeModal(false)}
            onOk={() => closeModal(false)}
            footer={null}
            width={"60%"}
            centered
        >

            <List
                size="large"
                dataSource={timeTypeData}
                renderItem={(item) => (
                    <List.Item key={item.id}>
                        <List.Item.Meta
                            title={<div style={{ fontSize: "35px" }}>{item.typeName}</div>}
                        />
                        <Button type="primary" size="large" onClick={() => closeModal(true, { timeTypeId: item.id })}>
                            选择
                        </Button>
                    </List.Item>
                )}
            />
        </Modal>
    )
}
