import React, { useEffect, useRef, useState } from 'react'
import EntrepotProcessEdit from './EntrepotProcessEdit';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import { Button, Space, Tooltip,Image } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import OrderProcess from './OrderProcess';
import numberHandle from '../../../utils/baseLib/numberHandle';

export default function EntrepotProcess(props: any) {
    const ref = useRef<any>(undefined);
    const { entrepotId, closeModal } = props;
    const [entrepotProcessId, setEntrepotProcessId] = useState<any>()
    const [modalOrderProcess, setModalOrderProcess] = useState<boolean>(false);
    /** 刷新数据 */
    let initData: any;
    //刷新变量
    const [refresh, setRefresh] = useState(0);
    const handleModalOrderProcess = (entrepotProcessId: string) => {
        setEntrepotProcessId(entrepotProcessId);
        setModalOrderProcess(true);
    };

    const onCloseModalOrderProcess = (state: boolean) => {

        setModalOrderProcess(false)
        if (state) {
            setRefresh(numberHandle.rangeNumber(1, 9999));
        }
    }

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        if (refresh !== 0) {
            const currentData: initDataType = {
                apiId: "entrepotProcess"
            }
            initData(currentData);
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]); //初始化数据
    //表头
    const columnsHead = [
        {
            title: '仓库名称',
            dataIndex: 'entrepot',
            render: (text: any) => (text ? text.entrepotName : "")
        },
        {
            title: '抬头',
            dataIndex: 'head',
        },
        {
            title: '订单类型',
            dataIndex: 'orderTypeName',
        },
        {
            title: '订单流程',
            dataIndex: 'orderProcess',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Tooltip placement="topLeft" title={record.orderTypeName + ' 流程'}>
                        <Button size={"small"} type="primary" onClick={() => handleModalOrderProcess(record.id)} >订单流程</Button>
                    </Tooltip>
                </Space>
        },
        {
            title: '盖章图片',
            dataIndex: 'geReGoodsUrl',
            render: (text: any, record: any) => (
                < Image
                    src={text}
                    width={60}
                    height={60}
                    alt="图片" />
            )
        },

    ];

    const handleOk = (e: any) => {
        closeModal(false);
    };
    const handleCancel = (e: any) => {
        closeModal(false);
    };
    const columns = columnsheadId
        .concat(columnsHead as []);
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '仓库流程',
        apiId: 'entrepotProcess',
        showStates: true,
        sendPageData: { entrepotId },
        columns,
        pagination: true,
        EditComponent: EntrepotProcessEdit,
    };
    return (
        <>
            <AntDraggableModal
                title={'订单类型管理'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onCancel={handleCancel}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={1000}>
                <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
            </AntDraggableModal>
            {modalOrderProcess ? (<OrderProcess
                closeModal={onCloseModalOrderProcess}
                entrepotProcessId={entrepotProcessId}
            ></OrderProcess>) : null}
        </>
    )
}
