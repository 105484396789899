import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Switch, Typography } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';
import objectHandle from '../../../utils/baseLib/objectHandle';
import { SocketContext } from '../../../App';

const { Paragraph } = Typography;
const { TextArea } = Input;

export default function BranchStoreMenuWarehouseEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const [initialPrintOrderType, setInitialPrintOrderType] = useState<any>([]);
    const [initialWxPrintOrderType, setInitialWxPrintOrderType] = useState<any>([]);

    //菜谱英文窗口
    const [foodNameEnVisible, setFoodNameEnVisible] = useState(false);

    const [foodNameEnStr, setFoodNameEnStr] = useState<any>();

    const [deptData, setDeptData] = useState<any>();

    /** 菜品类型数据 */
    const [foodTypeData, setFoodTypeData] = useState<any>();

    /**单位数据 */
    const [productUnitData, setProductUnitData] = useState<any>();

    /** 菜品分类数据 */
    const [shopTypeData, setShopTypeData] = useState<any>();
    const [printOrderTypeData, setPrintOrderTypeData] = useState<any>([]);

    // const [allData, setAllData] = useState<any>();

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;
    
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {

                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    if (data.type === "EDIT") {
                        if (res.data.dept) {
                            form.setFieldsValue({ deptId: res.data.dept.id });
                        }
                        const noPrintOrder = res.data.noPrintOrder;
                        let wxNoPrintOrder = res.data.wxNoPrintOrder
                        let noPrintOrderRows;
                        let noWxPrintOrderRows;

                        if (objectHandle.isEmpty(noPrintOrder) === false) {
                            noPrintOrderRows = noPrintOrder.split('@');
                        }
                        setInitialPrintOrderType(noPrintOrderRows);
                        if (objectHandle.isEmpty(wxNoPrintOrder) === false) {
                            noWxPrintOrderRows = wxNoPrintOrder.split('@');
                        }
                        setInitialWxPrintOrderType(noWxPrintOrderRows);
                        form.setFieldsValue({
                            noPrintOrder: noPrintOrderRows,
                            wxNoPrintOrder: noWxPrintOrderRows
                        })
                        // console.log(res.data);
                        // setAllData(res.data);
                        form.setFieldsValue({ menuWarehouseId: res.data.menuWarehouseId });

                        let str: any = '';
                        if (res.data.menus.length > 0) {
                            for (var menus = 0; menus < res.data.menus.length; menus++) {
                                if (objectHandle.isEmpty(str) === true) {
                                    str = str + "\n【 " + res.data.menus[menus].menuHead.menuHeadName + " 】\n" + res.data.menus[menus].shopType.shopTypeName + "———— " + res.data.menus[menus].foodName + "\n" + res.data.menus[menus].foodNameEn
                                }
                                else {
                                    str = str + "\n\n【 " + res.data.menus[menus].menuHead.menuHeadName + " 】\n" + res.data.menus[menus].shopType.shopTypeName + "———— " + res.data.menus[menus].foodName + "\n" + res.data.menus[menus].foodNameEn
                                }
                            }
                            await setFoodNameEnStr(str);
                        }
                    }
                }
            })
        }

        const fetchData = async () => {
            // form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });
            form.setFieldsValue({ branchStoreId: contextData?.currentBranchStoreId });

            fetchApi.GET(wmService, {
                apiId: 'dept',
                apiExtend: 'showBranchDeptList',
                apiData: {
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    },
                }
            }).then(res => {
                if (res.success) {
                    setDeptData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "shopType",
                apiExtend: 'showList',
                apiData: {
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    },
                }
            }).then(res => {
                if (res.success) {
                    setShopTypeData(res.data)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "productUnit",
                apiExtend: 'showList'
            }).then(res => {
                setProductUnitData(res.data)
            });

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'foodType@printOrderType',
                apiExtend: 'showLists'
            }).then(res => {
                if (res.success) {
                    const { foodType, printOrderType } = res.data;
                    setPrintOrderTypeData(printOrderType)
                    setFoodTypeData(foodType);

                }
            });

        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'branchStoreMenuWarehouse',
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        },
    };

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /**
     * 同步英文到菜谱
     */
    const handleFoodNameEn = (branchStoreMenuWarehouseId: any, foodNameEnStr: any, foodNameEn: any) => {
        if (objectHandle.isEmpty(foodNameEnStr) === false) {
            fetchApi.POST(wmService, {
                apiId: "branchStoreMenuWarehouse",
                apiExtend: 'updateFoodNameEn',
                apiData: {
                    branchStoreMenuWarehouseId: branchStoreMenuWarehouseId,
                    foodNameEn: foodNameEn
                }
            }).then(res => {
                if (res.success) {
                    antMessage('success', '添加成功', 'success');
                }
            });
        }
        ref.current.handleCancel();
    };

    /**
     * 获取现有菜谱的英文
     */
    const openModal = () => {
        setFoodNameEnVisible(true);
    };

    const closeModal = () => {
        setFoodNameEnVisible(false);
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '门店菜式'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={750}
            >
                <Form
                    form={form}
                    name="Edit"
                    initialValues={{ "sortBy": 100 }}
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="仓库Id"
                                name="menuWarehouseId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="门店Id"
                                name="branchStoreId"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="菜式名称"
                                name="foodName"
                                rules={[{ required: true, message: '请输入菜式名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={foodNameEnStr ? 19 : 24}>
                            <Form.Item
                                label="菜式英文"
                                name="foodNameEn"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={foodNameEnStr ? 5 : 0}>
                            <Button size={"small"} type="primary" onClick={openModal}>同步英文到菜谱</Button>
                            <Modal
                                title="该菜式在其他菜谱上的英文如下："
                                open={foodNameEnVisible}
                                onOk={() => handleFoodNameEn(data.id, foodNameEnStr, form.getFieldValue('foodNameEn'))}
                                onCancel={closeModal}
                                width={800}
                            >
                                <Typography>
                                    <Paragraph style={{ whiteSpace: 'pre-wrap', fontSize: 20, fontWeight: 'bold' }}>
                                        {foodNameEnStr ? foodNameEnStr : <p style={{ color: "red" }}>当前该菜式还没有同步到其他菜谱。</p>}
                                    </Paragraph>

                                    {/* <Paragraph
                                        style={{ whiteSpace: 'pre-wrap', fontSize: 20, fontWeight: 'bold' }}
                                        editable={{
                                            icon: <EditOutlined />,
                                            onChange: setFoodNameEnStr,
                                        }}
                                    >
                                        {foodNameEnStr}
                                    </Paragraph> */}
                                </Typography>
                            </Modal>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="菜式描述"
                                name="description"
                            >
                                <Input placeholder="如：外脆内嫩 鲜甜香滑 / 精酿啤酒、椰子汁、姜汁" />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="所属分类"
                                name="shopTypeId"
                                rules={[{ required: true, message: '请选择菜品所属分类' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="所属分类"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(shopTypeData, {
                                        itemName: "shopTypeName",
                                        includeNull: false,
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="菜式简码"
                                name="selfId"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="菜品类型"
                                name="foodType"
                                tooltip="用来统计菜位用的，所以如果该菜式是茶位的话这里不能选错。"
                                rules={[{ required: true, message: '请选择菜品类型' }]}
                            >
                                <Select
                                    placeholder="菜品类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(foodTypeData, {
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="单位"
                                name="productUnitId"
                                tooltip="当多个规格是单位的情况下，这里不需要选择单位。譬如该菜式的规格有‘小份’和‘份’时，这个单位就不需要选择。"
                            >
                                <Select
                                    showSearch
                                    placeholder="单位"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(productUnitData, {
                                        itemName: "productUnitName",
                                        includeNull: true,
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="出单部门"
                                name="deptId"
                                rules={[{ required: true, message: '请选择出单部门' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="出单部门"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(deptData, {
                                        itemName: "deptName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="特殊出单"
                                name="specialDeptId">
                                <Select
                                    showSearch
                                    placeholder="特殊出单"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(deptData, {
                                        itemName: "deptName",
                                        includeNull: true
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="打印(收银)"
                                name="isPrint"
                                valuePropName="checked"
                                tooltip="收银下单时，初始化是否打印订单 堂口单，客看单，厨总单"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="上架"
                                    unCheckedChildren="下架" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="介绍"
                                name="introduction"
                            >
                                <TextArea
                                    placeholder="菜式介绍"
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="不打印订单(叫卖)"
                                name="noPrintOrder"
                                tooltip="叫卖模式 指定该菜不打印在某些单里，目前暂时只支持堂口单，客看单，厨总单"
                                initialValue={initialPrintOrderType} >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="订单类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(printOrderTypeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="不打印订单(微信)"
                                name="wxNoPrintOrder"
                                tooltip="微信下单模式 指定该菜不打印在某些单里，目前暂时只支持堂口单，客看单，厨总单"
                                initialValue={initialWxPrintOrderType} >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="订单类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(printOrderTypeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="排序" name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}