import {  Form, Input, InputNumber, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';

//布局
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const RoomReserveEdit = (props: any) => {
  const [form] = Form.useForm(); //form 
  const { data } = props;
  const [roomData, setRoomtData] = useState<any>();
  const ref = useRef<any>(undefined);
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const handleCancel = (e: any) => {
    ref.current.handleCancel();
  };
  /** 后端数据wmService */
  let wmService: any = "";
  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'roomReserve',
  };

  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
    }
    const fetchData = async () => {
      //查找分店名数据
      httpGet(wmService, {
        apiId: "room",
        apiExtend: 'getRoomData',
      }).then(res => {

        setRoomtData(res.data)

      })

    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <><WebEditUniversal ref={ref} props={extendProps} />
      <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '品牌'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
      >
        <Form
          {...layout}
          form={form}
          name="Edit"
          onFinish={handleOk}
         onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}>

          <Form.Item
            label="id"
            name="id">
            <Input disabled={true} />
          </Form.Item>



          <Form.Item
            label="客户预订单Id"
            name="customerReserveId"

            rules={[{ required: true, message: '请输入customerReserveId!' }]}>
            <Input disabled />
          </Form.Item>



{/* 
          <Form.Item
            label="房间"
            name="roomId"
            rules={[{ required: true, message: '请选择房间!' }]}>

            <Select
              disabled
              showSearch
              placeholder="房间"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(roomData, "roomName")}
            </Select>
          </Form.Item> */}

          <Form.Item
            label="状态"
            name="isUse"
            valuePropName="checked"
            initialValue={true}>
            < Switch checked={true}
              checkedChildren="启用"
              unCheckedChildren="关闭" />
          </Form.Item>

{/* 
          <Form.Item
            label="是否预定"
            name="isReserve"
            valuePropName="checked"
            initialValue={true}>
            < Switch checked={true}
              checkedChildren="启用"
              unCheckedChildren="关闭" />
          </Form.Item> */}

        </Form>
      </AntDraggableModal>
    </>
  )
}

export default RoomReserveEdit;