import Memos from '../../../components/basic/general/Memos';
import { SocketContext } from '../../../App';
import { useContext } from 'react';

//组件 
const WebComponents = () => {

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    return (
        <Memos sendPageData={{ memosTypeId: 'queuing', queryId: contextData?.currentBranchStoreId ? contextData?.currentBranchStoreId : 'isUseBranchStoreId' }} />
    )
}
export default WebComponents;
