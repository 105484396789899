import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import FeastTeamMenuEdit from './FeastTeamMenuEdit';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import CheckWeekMenu from './CheckWeekMenu';

import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { Button, Space, Switch } from 'antd';

export default function FeastTeamMenu(props: any) {

    const ref = useRef<any>(undefined);

    const { closeModal, data } = props;

    //查看当天福食菜单窗口
    const [checkWeekMenuVisible, setCheckWeekMenuVisible] = useState(false);

    //数据
    const [weekMenuData, setWeekMenuData] = useState<any>();

    const apiId = "feastTeamMenu"

    useEffect(() => {
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //  打开查看当天福食菜单
    const HandleCheckWeekMenu = (record: any) => {
        // console.log(props);
        sendData.id = record.id;
        sendData.selectDay = dayjs(record.selectDay).format("YYYY-MM-DD");
        sendData.areaId = data.areaId;
        sendData.areaName = data.areaName;

        setWeekMenuData(sendData);
        setCheckWeekMenuVisible(true);
    };

    //  关闭查看当天福食菜单
    const onCloseModalCheckWeekMenuVisible = (state: boolean) => {
        setCheckWeekMenuVisible(false);
    };

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        selectDay: '',
        areaId: '',
        areaName: '',
    }

    //表头
    const columnsHead = [
        {
            title: '日期',
            dataIndex: 'selectDay',
            key: 'selectDay',
            render: (text: any, record: any) => {
                let days: any
                if (dayjs(text).day() === 0) {
                    days = "星期日"
                }
                if (dayjs(text).day() === 1) {
                    days = "星期一"
                }
                if (dayjs(text).day() === 2) {
                    days = "星期二"
                }
                if (dayjs(text).day() === 3) {
                    days = "星期三"
                }
                if (dayjs(text).day() === 4) {
                    days = "星期四"
                }
                if (dayjs(text).day() === 5) {
                    days = "星期五"
                }
                if (dayjs(text).day() === 6) {
                    days = "星期六"
                }
                return (
                    <div>{text + " ( " + days + " )"}</div>
                )
            }
        },
        {
            title: '是否已添加菜式',
            dataIndex: 'isUse',
            render: (text: any, record: any) => (
                <Switch checkedChildren="已添加" unCheckedChildren="未添加"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '查看当天福食菜单',
            dataIndex: 'checkWeekMenu',
            key: 'checkWeekMenu',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => HandleCheckWeekMenu(record)}>查看当天福食菜单</Button>
                </Space>
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    const handleOk = (e: any) => {
        closeModal(false);
    };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '福食小组每日菜单',
        apiId,
        showImage: false,
        showUploadFile: false,
        showStates: false,
        showDelete: false,
        disabledAdd: false,
        showQuery: false,
        showOperation: true,
        showAdd: false,
        pagination: true,
        columns,
        EditComponent: FeastTeamMenuEdit,
        sendPageData: { feastTeamMenuHeadId: data.id }
    };

    return (
        <>
            <AntDraggableModal
                title={'福食小组每日菜单'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}            
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={900}>
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
                {checkWeekMenuVisible
                    ? (<CheckWeekMenu
                        closeModal={onCloseModalCheckWeekMenuVisible} data={weekMenuData} />)
                    : null}

            </AntDraggableModal>
        </>
    )
}