import React, { useContext, useEffect, useRef, useState } from 'react';
import WebUniversal, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';
import { Button, DatePicker, Select, Space, Switch, Tooltip } from 'antd';
import systemConfig from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import dayjs from 'dayjs';
import { SocketContext } from '../../../App';
//组件 CustomerReserve 
const ReserveMessage = (props: any) => {
    const ref = useRef<any>(undefined)

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;
    /** api服务id */
    const apiId = 'customerReserveBackup';
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    /**日期 */
    const [date, setDate] = useState<any>();
    const [reserveStatus, setReserveStatus] = useState<any>()
    const [selectReserveStatus, setSelectReserveStatusa] = useState();
    /**数据来源 */
    const [dataSource, setDataSource] = useState<any>();
    const [selectDataSourcs, setSelectDataSourcs] = useState<number>();

    //表头
    const columnsHead = [
        {
            title: '市别',
            dataIndex: 'timeType',
            render: (text: any, record: any) => (`${record.bookingDate}(${record.typeName})`),
        },
        {
            title: '到达时间',
            dataIndex: 'arriveTime',
            render: (text: any) => (text ? moment(text).format("HH:mm") : "")
        },
        {
            title: "宴会",
            dataIndex: "banquetName",

        },


        {
            title: '预订人数',
            dataIndex: 'bookingNum',

        },
        {
            title: '联系人',
            dataIndex: 'contact',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 5 ? text.substring(0, 5) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '联系电话',
            dataIndex: 'contactCall',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 12 ? text.substring(0, 12) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 15 ? text.substring(0, 15) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '备注',
            dataIndex: 'memo',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 15 ? text.substring(0, 15) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '经手人',
            dataIndex: 'handlers',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 10 ? text.substring(0, 10) + '......' : text)
                    : ''
            }</Tooltip>
        },

        {
            title: "预订状态",
            dataIndex: "reserveStatusName"
        },
        {
            title: "定金状态",
            dataIndex: "depositStatusName",
        },



        {
            title: "来源",
            dataIndex: "dataSourceName"
        },
    ];

    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        {
            title: "id",
            dataIndex: "id",
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 10 ? text.substring(0, 10) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: "房间名称",
            dataIndex: "roomName",
        },
        {
            title: "房间号",
            dataIndex: "roomNum",
        },
        {
            title: "预坐人数",
            dataIndex: "sitNumber",
            render: (text: any) => (text ? text : "")

        },
    ]

    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    useEffect(() => {
        if (ref.current) {
            wmService = ref.current.wmService;

        }
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'reserveStatus@dataSource',
                    apiExtend: 'showLists'
                }).then(res => {

                    if (res.success) {
                        const { reserveStatus, dataSource } = res.data
                        setReserveStatus(reserveStatus);
                        setDataSource(dataSource)
                    }
                })

        };
        fetchData();
        if (contextData?.currentBranchStoreId && contextData?.refresh) {
            const currentData: initDataType = {
              apiId,
              sendPageData: { bookingDate: date, reserveStatus: selectReserveStatus,dataSource: selectDataSourcs, branchStoreId: contextData?.currentBranchStoreId }
            }
            ref.current.initData(currentData);
      
            setContextData({ ...contextData, refresh: false })
            // onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
          }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]);

    /**选择数据来源 */
    const dataSourceChange = (e: any) => {
        setSelectDataSourcs(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { bookingDate: date, reserveStatus: selectReserveStatus, dataSource: e,branchStoreId: contextData?.currentBranchStoreId  }
        }
        ref.current.initData(currentData);

    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '预订历史',
        apiId,
        columns,
        showStates: false,
        pagination: true,
        // showDate: true,
        showOperation: false,
        showAdd: false,
        mountDataOptions: {
            userMount: {
                isDept: true,
            }
        },
        //子表格
        isExpandable: true,
        expandableDataName: 'tempRooms',
        expandableColumns,
        sendPageData: { reserveStatus: selectReserveStatus, bookingDate: date, dataSource: selectDataSourcs,branchStoreId: contextData?.currentBranchStoreId }
    };
    /**查看不同状态的预订状态 */
    const reserveStatusChang = (e: any) => {

        setSelectReserveStatusa(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { reserveStatus: e, bookingDate: date,branchStoreId: contextData?.currentBranchStoreId,dataSource: selectDataSourcs }
        }
        ref.current.initData(currentData);
    }
    /**选择日期 */
    const handleDate = (e: any) => {
        setDate(dayjs(e).format("YYYY-MM-DD"))

        if (e) {
            setDate(dayjs(e).format("YYYY-MM-DD"))
        } else {
            setDate(undefined)
        }
        const currentData: initDataType = {
            apiId,
            sendPageData: { bookingDate: e ? dayjs(e).format("YYYY-MM-DD") : undefined, reserveStatus: selectReserveStatus,branchStoreId: contextData?.currentBranchStoreId,dataSource: selectDataSourcs }
        }
        ref.current.initData(currentData);


    }
    return (
        <>
            <label>预订状态 :  </label>
            <Select
                popupMatchSelectWidth={100}
                showSearch
                allowClear
                onChange={reserveStatusChang}
                placeholder="预订状态"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(reserveStatus)}
            </Select>
            <span>   </span>
            <label>预订日期 :  </label>
            <DatePicker
                // defaultValue={moment(data.bookingDate)}
                // value={date}
                format="YYYY-MM-DD"
                onChange={handleDate}
            />
            <span>   </span>
            <label>来源 :  </label>
            <Select
                showSearch
                allowClear
                popupMatchSelectWidth={90}
                onChange={dataSourceChange}
                placeholder="来源"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(dataSource)}
            </Select>
            <span>   </span>
            <WebUniversal ref={ref} props={extendProps} />

        </>

    )
}

export default ReserveMessage;
