import { Form, Input, Row, Col, Switch, Select, InputNumber } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const { sendPageData } = data;
    let memosTypeId: any;
    if (sendPageData) {
        memosTypeId = sendPageData.memosTypeId;
    }
    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    const [memosTypeData, setMemosTypeData] = useState<any>();
    /** 后端数据wmService */
    let wmService: any = "";
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "sys_memosType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMemosTypeData(res.data);
                }
            })
        }
        fetchData();
        if (data.type === 'NEW') {
            if (typeof memosTypeId !== 'undefined') {
                form.setFieldValue('memosTypeId', memosTypeId);
            }
        }
    }, []);
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'sys_memos',
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '备注'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row gutter={[8, 0]}>

                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="类型"
                                name="memosTypeId"
                                rules={[{ required: (typeof memosTypeId === 'undefined') ? true : false, message: '类型!' }]}>
                                <Select
                                    showSearch
                                    placeholder="类型"
                                    optionFilterProp="children"
                                    disabled={(typeof memosTypeId === 'undefined') ? false : true}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(memosTypeData, {
                                        itemName: 'memosTypeName'
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="查询id"
                                name="queryId">
                                <Input
                                    disabled={(typeof memosTypeId === 'undefined') ? false : true}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={14}>
                            <Form.Item
                                label="内容"
                                name="content"
                                rules={[{ required: true, message: '内容!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}
export default EditComponent;