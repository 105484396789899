import { Form, Input, Switch, Row, Col, Select, InputNumber } from 'antd';
import { useRef, useState, useEffect } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const RoomTypeUploadFileEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);


    /** 后端数据wmService */
    let wmService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }


        // const fetchData = async () => {
        // }
        // fetchData();
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'roomTypeFile',
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '房间类型图片'}
                open={true}
                okText='确定'
                cancelText="取消"
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={5}>
                            <Form.Item
                                label="ID"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>



                        <Col span={8}>
                            <Form.Item
                                label="名称"
                                name="originalFileName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="文件"
                                name="fileName">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={14}>
                            <Form.Item
                                label="文件目录"
                                name="fileDestination">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="腾讯视频"
                                name="movieId">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="文件类型"
                                name="fileType">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="文件大小"
                                name="fileSize">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="图片宽度"
                                name="imageWidth">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="文件高度"
                                name="imageHeight">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>
                        
                        <Col span={6}>
                            <Form.Item
                                label="推广使用"
                                name="isPromo"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="默认显示"
                                name="isDefault"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default RoomTypeUploadFileEdit;



