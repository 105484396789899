import React, { useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewExEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewExEx';
import moment from 'moment';
import { Button, Tooltip, DatePicker} from 'antd';
import { httpGet } from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import getList from '../../universal/getList';
import orderCutKey from '../../../generalMethod/OrderCutKey';
import setOrderDate from '../../../generalMethod/setOrderDate';
import dayjs from 'dayjs';
import entrepotPrint from '../../../generalMethod/entrepotPrint';
import ProcessingOrderEdit from './ProcessingOrderEdit';
let wmService: any = systemConfig.wmService;

export default function ProcessingOrder(props: any) {


    /** api服务id */
    const apiId = 'processingOrder';
    const ref = useRef<any>(undefined);
    /** 后端数据wmService */

    let initData: any = ref.current?.initData;


    useEffect(() => {


        localStorage.setItem("num", "0")
        if (ref.current) {


            wmService = ref.current.wmService;
            initData = ref.current.initData;
        }
        const fetchData = async () => {
           
           
        }
        fetchData()
        window.addEventListener('keydown', (e) => orderCutKey.onKeyDown(e, ref, entrepotPrint.printOrder)); // 添加全局事件
        return () => {
            window.removeEventListener('keydown', (e) => orderCutKey.onKeyDown(e, ref, entrepotPrint.printOrder)); // 销毁
        };
    }, [])
    //表头
    const columnsHead = [
        {
            title: "门店",
            dataIndex: "branchStoreName",
           
            align: "center"
        },
     
    
        {
            title: "日期",
            dataIndex: "sysDate",
            align: "center",
            width: 80,
            render: (val: any, record: any) =>
                <span style={{ "fontSize": 12.5 }}>
                    {moment(val.createdAt).format('YYYY-MM-DD')}
                </span>,
        },
        {
            title: '制表',
            dataIndex: 'userName',
            align: "center",
            width: 60,
        },
       
       
    ];



    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        {
            title: "品号",
            dataIndex: "product",
            render: (text: any) => (text ? text.selfId ? text.selfId : "" : "")
        },
        // {
        //     title: "id",
        //     dataIndex: "id",
        //     render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        //         (typeof text !== "undefined" && text !== null) ?
        //             (text.length >= 10 ? text.substring(0, 10) + '......' : text)
        //             : ''
        //     }</Tooltip>
        // },
        {
            title: "品名",
            dataIndex: "product",
            render: (text: any) => (text ? `${text.productName}${text.area ? `(${text.area?.areaName})` : ""}` : "")
        },
        {
            title: "单位",
            dataIndex: "product",
            render: (text: any) => (text ? text.productUnit.productUnitName : "")
        },
      
        {
            title: "数量",
            dataIndex: "bNum",
            render: (text: any) => (text || text === Number(0) ? text.toFixed(2) : undefined)
        },
        {
            title: "起率",
            dataIndex: "miniRate",
        },
        {
            title: "输单时间",
            dataIndex: "sysDate",
            render: (text: any) => {
                return <>{text ? moment(text.createdAt).format("YYYY-MM-DD HH:mm:ss") : ""}</>
            }
        },

      
    ]
    /** 子表头 */
    const expandableColumns = expandableColumnsHead;

    const columns = columnsheadId
        .concat(columnsHead as []);






    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '加工订单',
        apiId,
        showStates: true,
        columns,
        pagination: true,
        EditComponent: ProcessingOrderEdit,
       mountDataOptions:{
        userMount: {
            isEmployees: true,
            isDept:true,
            isUserAreas:true
        }
       },

        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'processingProducts',
    };

 

 

 
    return (
        <>
           
            <label>订单日期 :  </label>
            <Tooltip placement="topLeft" title={"当前仅修改新建订单时间使用"}>
                <DatePicker defaultValue={dayjs(sessionStorage.getItem("date"))} onChange={setOrderDate.date} />
            </Tooltip>
            <span>   </span>
            <WebUniversalNewExEx ref={ref} props={extendProps}></WebUniversalNewExEx>

       
           
        </>

    )
}
