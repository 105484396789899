import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Select, Transfer } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import fetchApi from '../../../utils/api/fetchApi';
import objectHandle from '../../../utils/baseLib/objectHandle';
import antMessage from '../../../utils/extend/AntdNotification';
import systemConfig from '../../../config/systematic';

export default function MenuSalesman(props: any) {

    const ref = useRef<any>(undefined);
    const { data, closeModal } = props;
    const [form] = Form.useForm(); //form 

    /** api服务id */
    const apiId = 'menuSalesman';

    /**后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [userData, setUserData] = useState<any>('');
    const [usersData, setUsersData] = useState<any>();

    const [keys, setKeys] = useState<any>([]);

    const [allKeys, setAllKeys] = useState<any>([]);

    const [leftInitKeys, setLeftInitKeys] = useState<Array<any>>([]);
    const [rightInitKeys, setRightInitKeys] = useState<Array<any>>([]);

    const [loadingData, setLoadingData] = useState<any>(false);
    const [rows, setRows] = useState<Array<any>>([]);

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "menuSalesman",
                apiExtend: 'showUsersList',
                menuHeadId: data.menuHeadId,
            }).then(res => {
                if (res.success) {
                    setNewData(res.data, setUsersData, 'userId', 'userName');
                }
            })
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const getDataBaseData = (e: any) => {
        fetchApi.GET(wmService, {
            apiId,
            apiExtend: "menuToMenuSale",
            apiData: {
                menuHeadId: data.menuHeadId,
                userId: e,
            }
        }).then(res => {
            if (res.success) {
                //目标数据的keys数组
                const targetKeys = [];
                //所有菜式数据
                const menuData = [];
                //左边数据keys数组;
                const leftKeys = [];
                //所有key值
                const allKeys = [];
                for (let j = 0; j < res.data.length; j++) {
                    allKeys.push(res.data[j].id);
                    const newData = {
                        key: res.data[j].id.toString(),
                        id: res.data[j].id,
                        status: "origin",
                        direction: "left",
                        menuSpecName: res.data[j].menu.foodName + "（" + res.data[j].menuSpecName + "）",
                        chosen: res.data[j].isMenu,
                    };
                    if (newData.chosen) {
                        newData.direction = "right";
                        targetKeys.push(newData.key);
                    } else {
                        leftKeys.push(newData.key);
                    }
                    menuData.push(newData);
                }
                setAllKeys(allKeys);
                setKeys(targetKeys);
                setRows(menuData);
                setRightInitKeys(targetKeys);
                setLeftInitKeys(leftKeys);
            }
        });
    }

    const handleOk = async (e: any) => {
        setLoadingData(true);
        let keys = rows.filter((item) => item.status === "update" && item.direction === "right");
        let leftKeys = rows.filter((item) => item.status === "update" && item.direction === "left");
        fetchApi.POST(wmService, {
            apiId,
            apiExtend: "menuSaleHandle",
            apiData: {
                keys,
                leftKeys,
                userId: userData
            }
        }).then((res) => {
            antMessage('success', '操作成功', 'success');
            setLoadingData(false);
            closeModal(true);
        }
        ).catch((err) => {
            setLoadingData(false);
        })
    };

    const onChange = (keys: any, direction: any, moveKeys: any) => {
        let newArray = rows.map(item => {
            if (direction === "left") {
                if (moveKeys.includes(item.id)) {
                    if (leftInitKeys.includes(item.id)) {
                        return { ...item, direction, status: "origin" };
                    } else {
                        return { ...item, direction, status: "update" };
                    }
                } else {
                    return {
                        ...item
                    };
                }
            }
            if (direction === "right") {
                if (moveKeys.includes(item.id)) {
                    if (rightInitKeys.includes(item.id)) {
                        return { ...item, direction, status: "origin" };
                    } else {
                        return { ...item, direction, status: "update" };
                    }
                } else {
                    return {
                        ...item
                    };
                }
            }
        });
        setRows(newArray);
        setKeys(keys);
    };

    //选择销售人员
    const selectData = async (e: any) => {
        setUserData(e);
        getDataBaseData(e);
    }

    //查找数据
    const searchData = async (queryString: any) => {
        if (objectHandle.isEmpty(queryString) === true) {
            console.log('查询不能为空');
            return undefined;
        }
        const res = await fetchApi.GET(wmService, {
            apiId: 'user',
            apiExtend: 'showList',
            apiData: {
                queryString
            }
        })
        setNewData(res.data, setUsersData, 'id', 'userName');
    }

    const setNewData = async (rows: any, setData: any, value: any, label: any) => {
        let newDatas: Array<any> = [];
        for (var j = 0; j < rows.length; j++) {
            newDatas.push({
                value: rows[j][value],
                label: rows[j][label]
            })
        }
        setData(newDatas);
    }

    return (
        <>
            <AntDraggableModal
                title={"设置销售人员销售菜式"}
                open={true}
                okText='确定'
                cancelText='取消'
                cancelButtonProps={{ disabled: loadingData }}
                okButtonProps={{ disabled: loadingData }}
                onOk={handleOk}
                onCancel={() => closeModal(false)}
                closable={false}
                maskClosable={false}
                confirmLoading={loadingData}
                width={700}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="搜索并选择销售人员"
                                name="userId"
                                rules={[{ required: true, message: '请搜索并选择销售人员!' }]}
                            >
                                <Select
                                    onSearch={(e) => searchData(e)}
                                    onSelect={selectData}
                                    style={{ color: 'yellow' }}
                                    showSearch
                                    placeholder="请搜索并选择销售人员"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}
                                    labelRender={(item: any) => {
                                        return (
                                            <span style={{ color: 'yellow' }}>{item.label}</span>
                                        )
                                    }}
                                    options={usersData}
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Transfer
                                dataSource={rows}
                                titles={["所有菜式", "销售菜式"]}
                                showSearch
                                listStyle={{ width: 400 }}
                                targetKeys={keys}
                                onChange={onChange}
                                render={item => `${item.menuSpecName}`}
                                pagination
                            />
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    );
};