import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import WebProductFormList from '../../generalComponents/WebProductFormList';
export default function CostPurchaseOrderEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [deleteData, setDeleteData] = useState<any>();

    /**部门数据 */
    const [deptData, setDeptData] = useState<any>();




    /**采购商数据 */
    const [buyerData, setBuyerData] = useState<any>();



    const [formList, setFormList] = useState<any>({ formName: "costPurchaseProducts", form, type: data.type, ref, setDeleteData })


    useEffect(() => {
        let deptId = localStorage.getItem("deptId")


        if (deptId && data.type === "NEW") {
            form.setFieldsValue({
                "deptId": deptId
            })
            httpGet(wmService, {
                apiId: "dept",
                apiExtend: 'showList',
                apiData: {

                }
            }).then(res => {

                res.data.forEach((item: any) => {
                    if (item.id === deptId) {
                        setDeptData([item])
                    }
                })
            })
            httpGet(wmService, {
                apiId: "buyerType",
                apiExtend: 'getAreaIdToBuyer',
                apiData:{
                    mountDataOptions: {
                        userMount: {
                            isEmployees: true,
                        }
                    }
                }
              
            }).then((res1) => {
                setBuyerData(res1.data)

            })

        }

        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            // httpGet(wmService, {
            //     apiId: "entrepot",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     setEntrepotData(res.data)
            // })
            if (data.type !== "NEW") {
                httpGet(wmService, {
                    apiId: "buyer",
                    apiExtend: 'showList'
                }).then(res => {
                    setBuyerData(res.data)
                })
            }
            if (data.type !== "NEW") {
                httpGet(wmService, {
                    apiId: "dept",
                    apiExtend: 'showList',
                    apiData: {

                    }
                }).then(res => {

                    setDeptData(res.data)
                })
            }

        }
        fetchData()
    }, [])
    const handleOk = (e: any) => {
        ref.current.handleOk();

    };
    const handleCancel = (e: any) => {


        ref.current.handleCancel();
    };
    // const onChangeEntrepotIdToBuyerData = () => {
    //     if (!selectAreaId) return false;


    //     httpGet(wmService, {
    //         apiId: "buyerType",
    //         apiExtend: 'getAreaIdToBuyer',
    //         areaId: selectAreaId
    //     }).then((res) => {
    //         setBuyerData(res.data)

    //     })
    //     form.resetFields(["buyerId"])

    // }




    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'costPurchaseOrder',
        formListName: "costPurchaseProducts",
        changeOrderTime: {
            isTime: true,
            time: sessionStorage.getItem("date")
        },
        mountDataOptions: {
            userMount: {
                isEmployees: true,
                isDept: true,
                isUserAreas: true
            }
        },

    };


    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} totalData={deleteData}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : data.type === "EDIT" ? "编辑" : "查看") + '订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                okButtonProps={{ disabled: data.type === 'VIEW' ? true : false }}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={"70%"}

            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.altKey) {
                            if (e.key === "s" && e.altKey) {
                                handleOk(e)
                            }
                        }

                    }}
                >
                    <Row >

                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                label="部门"
                                name="deptId"
                                rules={[{ required: true, message: '请输入部门!' }]}>
                                <Select
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    placeholder="部门"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.searchEx('dept', setDeptData, e, undefined,
                                        {
                                            form,
                                            setObj: ["deptId"],
                                            setObjValue: ["id"],
                                        }

                                    )}
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}>
                                    {getList.general(deptData, 'deptName')}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                label="采购商"
                                name="buyerId"
                                rules={[{ required: true, message: '请输入采购商!' }]}>
                                <Select
                                    onSearch={(e) => getList.searchEx('buyer', setBuyerData, e, buyerData, {
                                        setObj: ["buyerId"],
                                        setObjValue: ["id"],
                                        form
                                    })}
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    placeholder="请输入采购商"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return true
                                    }
                                    }
                                    options={getList.generalSelcetOption(buyerData, "buyerName")}
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                            <Form.Item
                                label="仓库"
                                name="entrepotId"
                                rules={[{ required: true, message: '请输入仓库!' }]}>
                                <Select
                                    disabled={true}
                                    showSearch
                                    placeholder="仓库"
                                    // onChange={onChangeEntrepotIdToBuyerData}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>

                                    {getList.general(entrepotData, 'entrepotName')}
                                </Select>
                            </Form.Item>
                        </Col> */}


                        {/* {data.type === "NEW" ?
                            <>
                                <Col span={24}>
                                    <WebProductFormList props={formList}></WebProductFormList>
                                </Col>
                            </> : null

                        }
 */}

                        <Col span={24}>
                            <WebProductFormList props={formList}></WebProductFormList>
                        </Col>


                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}


