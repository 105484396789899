import { Form, Input, InputNumber, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import WebEditUniversal, { WEUExtendPropsType } from '../../components/system/WebEditUniversalNewEx'
import { httpGet } from '../../utils/api/fetchApi';
import objectHandle from '../../utils/baseLib/objectHandle';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import getList from '../universal/getList';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const MoonTypeEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    /** 后端数据wmService */
    let wmService: any = "";
    const [branchStoreData, setbranchStoreData] = useState<any>()
    const [initialbranchStoreData, setInitialbranchStoreData] = useState<any>([]);
    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'moonType',
    };
    useEffect(() => {

        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') return
                if (res.success === true) {
                    
                    const branchStoreStr = res.data.branchStoreStr;
                    let branchStoreRows;
                    if (objectHandle.isEmpty(branchStoreStr) === false) {
                        branchStoreRows = branchStoreStr.split('@');
                    }
                    
                    setInitialbranchStoreData(branchStoreRows);
                    form.setFieldsValue({
                        branchStores: branchStoreRows
                    })
                }
            })
        }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList',
            }).then(res => {
                if (res.success) {
                    setbranchStoreData(res.data)
                }
            })
        }
        fetchData()
    }, [])
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '票据'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="票号类型"
                        name="ticketNoType">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="票据类型"
                        name="moonType"
                        rules={[{ required: true, message: '请输入票据类型!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="票据价格"
                        name="amount"
                        rules={[{ required: true, message: '请输入票据价格!' }]}
                        >
                            
                        <InputNumber
                        precision={2}
                        />
                    </Form.Item>

                    <Form.Item
                        label="分店"
                        name="branchStores"
                        rules={[{ required: true, message: '请输入分店!' }]}>
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="选择分店"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(branchStoreData, "branchStoreName")}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>


                </Form>
            </AntDraggableModal>
        </>
    )
}

export default MoonTypeEdit;