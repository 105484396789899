import MemosEdit from './MemosEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Tooltip } from 'antd';
import objectHandle from '../../../utils/baseLib/objectHandle';

//组件 
const WebComponents = (props: any) => {
    const { sendPageData } = props;
    /** api服务id */
    const apiId = 'sys_memos';
    //表头
    const columnsHead = [
        {
            title: '类型',
            dataIndex: 'memosType',
            render: (text: any) => (text ? text.memosTypeName : ""),
            sorter: true,
        },
        {
            title: '查询id',
            dataIndex: 'queryId',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                objectHandle.isEmpty(text) !== true ?
                    (text.length >= 10 ? '......' + text.substring(0, 10) : text)
                    : ''
            }</Tooltip>,
        },
        {
            title: '内容',
            dataIndex: 'content'
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        }
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '备注',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: MemosEdit,
        sendPageData,
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };
    return (
        <WebUniversal props={extendProps} />
    )
}
export default WebComponents;
