import { Col, Form, Input, InputNumber, Row, Select, Switch, TimePicker } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import objectHandle from '../../../utils/baseLib/objectHandle';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';
import { SocketContext } from '../../../App';
//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const ScheduledTimeEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;
    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /**分店数据 */
    // const [branchStoreData, setbranchStoreData] = useState<any>()

    /**周期数据 */
    const [weekData, setWeekData] = useState<any>();

    /**大市别数据 */
    const [sbData, setSbData] = useState<any>();

    const [scheduledTimeStatusData, setScheduledTimeStatusData] = useState<any>()
    const [initialweekData, setInitialWeekData] = useState<any>([]);
    /** 后端数据wmService */
    let wmService: any = "";
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'scheduledTime',
        dateColumnName: ["startTime", "endTime", "guestArrTime"],
        // isDateMoment: true
    };
    useEffect(() => {

        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (res) {

                    if (res.success === true) {

                        const weeks = res.data.weeks;

                        let weeksRows;
                        if (objectHandle.isEmpty(weeks) === false) {
                            weeksRows = weeks.split('@');
                        }

                        setInitialWeekData(weeksRows);
                        form.setFieldsValue({
                            weeks: weeksRows
                        })
                    }
                }

            })
        }

        const fetchData = async () => {

           
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'scheduledTimeStatus@week',
                    apiExtend: 'showLists'
                }).then(res => {
                    if (res.success) {
                        const {scheduledTimeStatus,week}=res.data
                        setWeekData(week)
                        setScheduledTimeStatusData(scheduledTimeStatus);
                    }
                });
            fetchApi.GET(wmService,
                {
                    apiId: 'sb',
                    apiExtend: 'showList',
                    apiData: {
                        mountDataOptions: {
                            userMount: {
                                isDept: true,
                            }
                        },
                        branchStoreId:contextData?.currentBranchStoreId ,
                    }
                }).then(res => {
                    if (res.success) {

                        setSbData(res.data);
                    }
                });

        }
        fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '时间段'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >

                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="时间名称"
                                name="timeName"
                                rules={[{ required: true, message: '请输入时间名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="周期"
                                name="weeks"
                                rules={[{ required: true, message: '请选择周期!' }]}
                                initialValue={initialweekData}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择周期"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(weekData, "name")}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={8}>
                            <Form.Item
                                label="开始时间"
                                name="startTime"
                                rules={[{ required: true, message: '请选择开始时间!' }]}
                            >
                                <TimePicker
                                    minuteStep={15}
                                    format={"HH:mm"}
                                />
                            </Form.Item>
                        </Col>


                        <Col span={8}>
                            <Form.Item
                                label="结束时间"
                                name="endTime"
                                rules={[{ required: true, message: '请选择结束时间!' }]}
                            >
                                <TimePicker
                                    minuteStep={15}
                                    format={"HH:mm"}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="默认客人到达时间"
                                name="guestArrTime"
                                rules={[{ required: true, message: '请选择默认客人到达时间!' }]}
                            >
                                <TimePicker
                                    minuteStep={15}
                                    format={"HH:mm"}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="时间段状态"
                                name="scheduledTimeStatus"
                                rules={[{ required: true, message: '请选择时间段状态!' }]}
                                initialValue={initialweekData}
                            >
                                <Select
                                    showSearch
                                    placeholder="选择时间段状态"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(scheduledTimeStatusData, "name")}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                            <Form.Item
                                label="分店"
                                name="branchStoreId"
                                rules={[{ message: '请选择分店!', required: true }]}>
                                <Select
                                    showSearch
                                    placeholder="分店"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(branchStoreData, "branchStoreName")}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col span={8}>
                            <Form.Item
                                label="大市别"
                                name="sbId"
                                rules={[{ message: '请选择大市别!', required: true }]}>
                                <Select
                                    showSearch
                                    placeholder="大市别"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(sbData, "sbName")}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="延迟点餐时间"
                                name="delayedTime">
                                <InputNumber placeholder="设置提前拿号时间" min={0} addonAfter={"分钟"} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="是否预定"
                                name="isReserve"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>

            </AntDraggableModal>
        </>
    )
}

export default ScheduledTimeEdit;