import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import objectHandle from '../../utils/baseLib/objectHandle';
import AntDraggableModal from '../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx'
import getList from '../universal/getList';
import antMessage from '../../utils/extend/AntdNotification'

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    const rootDirectoryData = [{
        id: '/api',
        name: '/api'
    }, {
        id: '/test',
        name: '/test'
    }];
    const [defaultServiceIdData, setDefaultServiceIdData] = useState<any>();
    /** 地址模式类型 */
    type urlModelType = 'apiUrl' | 'manageApiUrl';
    /**
     * 获取文件服务器地址
     * @param obj
     * @param urlModel 地址模式类型
     */
    const getFilesServerUrl = (obj: any, urlModel?: urlModelType) => {
        
        const { serverUrl, port, rootDirectory, manageApiUrl, apiUrl } = obj;
        const filesServerManageUrl = serverUrl + (objectHandle.isEmpty(port, true) === false ? (':' + port) : '') +
            rootDirectory + (urlModel === 'apiUrl' ? apiUrl : manageApiUrl);
        fetchApi.GET(
            filesServerManageUrl,
            {
                apiId: 'service',
                apiExtend: 'showList'
            }).then(filesRes => {
                if (filesRes.success) {
                    setDefaultServiceIdData(filesRes.data);
                }
                else {
                    setDefaultServiceIdData(undefined);
                    antMessage('error', '错误', '无法获取服务号列表!');
                }
            }).catch((err) => {
                setDefaultServiceIdData(undefined);
                antMessage('error', '错误', '无法获取服务号列表!' + err.message);
            });
    };
    /**
     * 设置serviceIds
     */
    const setServiceIds = () => {
        const serverUrl = form.getFieldValue('serverUrl');
        const port = form.getFieldValue('port');
        const rootDirectory = form.getFieldValue('rootDirectory');
        const manageApiUrl = form.getFieldValue('manageApiUrl');
        const apiUrl = form.getFieldValue('apiUrl');
        getFilesServerUrl({
            serverUrl, port, rootDirectory, manageApiUrl, apiUrl
        }, 'manageApiUrl')
    }

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // defaultService = ref.current.defaultService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') return
                if (res.success === true) {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    getFilesServerUrl(res.data, 'manageApiUrl');
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'filesServer',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '文件服务器'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={600}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row gutter={[8, 0]}>

                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="名称"
                                name="serverName"
                                rules={[{ required: true, message: '请输入服务器名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="地址"
                                name="serverUrl">
                                <Input onChange={() => setServiceIds()} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="端口"
                                name="port">
                                <Input onChange={() => setServiceIds()} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="根目录"
                                name="rootDirectory"
                                rules={[{ required: true, message: '请选择根目录!' }]}>
                                <Select
                                    showSearch
                                    placeholder="根目录"
                                    optionFilterProp="children"
                                    onChange={() => setServiceIds()}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(rootDirectoryData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="apiUrl"
                                name="apiUrl">
                                <Input onChange={() => setServiceIds()} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="管理apiUrl"
                                name="manageApiUrl">
                                <Input onChange={() => setServiceIds()} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="默认服务号"
                                name="defaultServiceId">
                                <Select
                                    showSearch
                                    placeholder="默认服务号"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(defaultServiceIdData, { itemName: 'serviceName' })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="默认"
                                name="isDefault"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;