import React, { useContext, useEffect, useRef, useState } from 'react';
import RoomEdit from './RoomEdit';
import WebUniversal, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import RoomUploadFile from './RoomUploadFile';
import { Button } from 'antd';
import PrintConfiguration from '../../basic/printManagement/PrintConfiguration';
import { SocketContext } from '../../../App';

//组件 Brand 
const Room = (props: any) => {

  /** api服务id */
  const apiId = 'room';
  const [roomId, setRoomId] = useState<string>();
  const [modalPrintConfiguration, setModalPrintConfiguration] = useState(false);
  const useContextData = useContext(SocketContext);
  const { contextData, setContextData } = useContextData;
  const ref = useRef<any>(undefined);

  useEffect(() => {
    if (contextData?.currentBranchStoreId && contextData?.refresh) {
      const currentData: initDataType = {
        apiId,
        sendPageData: {branchStoreId: contextData?.currentBranchStoreId }
      }
      ref.current.initData(currentData);

      setContextData({ ...contextData, refresh: false })
      // onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextData?.currentBranchStoreId]);
  //表头
  const columnsHead = [
    {
      title: '房间类型',
      dataIndex: 'roomType',
      render: (text: any) => (text ? text.roomType : "")
    },
    {
      title: '房名',
      dataIndex: 'roomName',
    },
    {
      title: '房号',
      dataIndex: 'roomNum',
    },
    {
      title: '菜谱头',
      dataIndex: 'menuHeads_Name',
    },
    {
      title: '楼层',
      dataIndex: 'floor',
    },
    {
      title: '点餐模式',
      dataIndex: 'orderingModeName',
    },
    {
      title: '打印配置',
      render: (text: any, record: any) => {
        return <Button onClick={() => handlePrintConfiguration(record)} size={"small"} type="primary" >打印配置</Button>
      }

    },
    {
      title: '排序',
      dataIndex: 'roomSort',
      sorter: true,
    },
  ];
  const handlePrintConfiguration = (record: any) => {
    setRoomId(record.id)
    setModalPrintConfiguration(true)
  }

  const closePrintConfiguration = (status: boolean) => {
    setModalPrintConfiguration(false)
    if (status) {

    }
  }

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '房间',
    apiId,
    columns,
    showStates: true,
    pagination: true,
    showReserve: true,
    showImage: true,
    showUploadFile: true,
    EditComponent: RoomEdit,
    mountDataOptions:{
      userMount:{
         isDept:true,
      }
 },
    arrayFilesName: 'roomFiles',
    sendPageData: {branchStoreId: contextData?.currentBranchStoreId },
    UploadFileComponent: RoomUploadFile,

  };

  return (
    <>
      <WebUniversal ref={ref} props={extendProps} />
    

      {modalPrintConfiguration ? (<PrintConfiguration
        closeModal={closePrintConfiguration}
        roomId={roomId}
        isModel={true}
      ></PrintConfiguration>) : null}
    </>
  )
}

export default Room;
