import { Col, Form, Input, InputNumber, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';




export default function BranchStoreFloorEdit(props: any) {
  const [form] = Form.useForm(); //form 
  const { data } = props;

  const ref = useRef<any>(undefined);
  /**楼层数据 */
  const [floor, setFloor] = useState<any>()
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const [value, setValue] = useState<number>(1);
  const handleCancel = (e: any) => {


    ref.current.handleCancel();
  };
  /** 后端数据wmService */
  let wmService: any = "";
  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'branchStoreFloor'
  };
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
    }
    const fetchData = async () => {

      httpGet(wmService, {
        apiId: "floor",
        apiExtend: 'showList'
      }).then(res => {
        if (res.success) {
          setFloor(res.data)
        }
      })
    }
    fetchData()
  }, [])

  return (
    <>
      <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
      <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '分店楼层'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
      >
        <Form
          form={form}
          name="Edit"
          onFinish={handleOk}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}
        >
          <Row >
            <Col span={12}>
              <Form.Item
                label="id"
                name="id">
                <Input disabled />
              </Form.Item>

            </Col>


            <Col span={12}>
              <Form.Item
                label="楼层"
                name="floorId"
                rules={[{ required: true, message: '请输入楼层!' }]}>
                <Select
                  showSearch
                  placeholder="楼层"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(floor, 'floor')}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="排序"
                name="sortBy">
                <InputNumber min={1} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntDraggableModal>

    </>
  )
}


