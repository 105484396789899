import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, Space, Tooltip, Typography } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import system from "../../../config/systematic";
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import antMessage from '../../../utils/extend/AntdNotification';
// import dayjs from 'dayjs';
import { SocketContext } from '../../../App';

const { Paragraph } = Typography;

export default function ReserveMenuHistory(props: any) {

    const ref = useRef<any>(undefined);

    const { closeModal, isModel, customerReserveId } = props;

    // /** 预订房间数据 */
    // const [reserveRoomData, setReserveRoomData] = useState<any>();

    /** 预订菜式数据 */
    const [reserveMenuDetailData, setReserveMenuDetailData] = useState<any>();

    /** 显示预订菜式 */
    const [showReserveMenuDetailVisible, setShowReserveMenuDetailVisible] = useState<any>(false);

    /** 预订菜单名称 */
    const [reserveMenuNameData, setReserveMenuNameData] = useState<any>();

    /** api服务id */
    const apiId = 'reserveMenuBackup';

    /** 后端数据Service */
    let wmService = system.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            // fetchApi.POST(wmService, {
            //     apiId: "reserveMenuBackup",
            //     apiExtend: 'testBackup',
            //     apiVariable: '67298100197824990086'
            // }).then(res => {
            //     if (res.success) {
            //         console.log("123");
            //         console.log(res.data);
            //     }
            // })
        };

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    //打开订位信息
    // const handleShowReserve = (record: any) => {
    //     fetchApi.GET(wmService, {
    //         apiId: "customerReserve",
    //         apiExtend: 'showSingle',
    //         apiVariable: record.customerReserveId
    //     }).then(res => {
    //         if (res.success) {
    //             console.log(res.data);
    //         }
    //     })
    // };

    //关闭查看菜式
    const onCloseReserveMenuDetail = () => {
        setShowReserveMenuDetailVisible(false);
    }

    // useEffect(() => {
    //     if (ref.current) {
    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    //         wmService = ref.current.wmService;
    //     }
    //     const fetchData = async () => {
    //         fetchApi.POST(wmService, {
    //             apiId: "reserveMenuBackup",
    //             apiExtend: 'testBackup',
    //             apiVariable: '67298100197824990086'
    //         }).then(res => {
    //             if (res.success) {
    //                 console.log("123");
    //                 console.log(res.data);
    //             }
    //         })

    //     }
    //     fetchData()
    // }, [])

    //打开查看菜式
    const handleShowMenu = (record: any) => {
        fetchApi.GET(wmService, {
            apiId: "reserveMenuBackup",
            apiExtend: 'showReserveMenuDetail',
            apiVariable: record.id
        }).then(res => {
            if (res.success) {
                if (res.data.reserveMenuPrivateCategorys.length < 1) {
                    antMessage('error', '提示', "目前还没有菜式。");
                }
                else {
                    setReserveMenuDetailData(res.data);
                    setShowReserveMenuDetailVisible(true);
                }
            }
        })
        setReserveMenuNameData(record.reserveMenuName);
    };

    const showRoom = (rows: any) => {
        let roomNames = '';
        for (var i = 0, len = rows.length; i < len; i++) {
            if (roomNames !== '') {
                roomNames = roomNames + " | " + rows[i].roomName;
            }
            else {
                roomNames = rows[i].roomName;
            }
        }
        return roomNames;
    };

    const showData = (rows: any) => {
        if (typeof rows !== "undefined") {
            rows = rows;
            return (
                <Typography>
                    <Paragraph>
                        <div style={{ textAlign: 'center', color: 'red', fontSize: '30px' }}>{rows.title}</div>
                        <div style={{ textAlign: 'center', fontSize: '20px' }}>{rows.subtitle}</div>
                    </Paragraph>
                    {
                        rows.reserveMenuPrivateCategorys.map((item: any) => {
                            return (
                                <>
                                    <Paragraph>
                                        <div style={{ textAlign: 'center', color: 'yellow', fontSize: '20px' }}>{rows.showLanguageTypeId === 1 || rows.showLanguageTypeId === 3 ? item.reserveMenuCategoryName : ""}</div>
                                        <div style={{ textAlign: 'center', color: 'yellow', fontSize: '20px' }}>{rows.showLanguageTypeId === 2 ? item.reserveMenuCategoryNameEn : ""}</div>
                                        <div style={{ textAlign: 'center', color: 'yellow', fontSize: '12px' }}>{rows.showLanguageTypeId === 3 ? item.reserveMenuCategoryNameEn : ""}</div>
                                    </Paragraph>
                                    {
                                        item.reserveMenuDetails.map((items: any) => {
                                            return (
                                                <>
                                                    <Paragraph>
                                                        <Tooltip placement="right" overlayStyle={{ maxWidth: 'none' }} title={
                                                            <Paragraph style={{ whiteSpace: 'pre-wrap', fontSize: 20 }}>
                                                                {
                                                                    items.foodName + "      " + items.amount + " " + items.productUnitName + "\n\n" +
                                                                    ("规格：" + items.menuSpecName + "\n") +
                                                                    (items.menuSpecSpecialIds ? "特殊规格：" + items.menuSpecSpecialNames + "\n" : "") +
                                                                    (items.condimentIds ? "加料：" + items.condimentNames + "\n" : "") +
                                                                    (items.memoIdsh > 0 ? "备注：" + items.memoNames + "\n" : "") +
                                                                    ("\n操作人：" + items.userName)
                                                                }
                                                            </Paragraph >
                                                        } color='green'>
                                                            <div style={{ textAlign: 'center', fontSize: '15px' }}>{rows.showLanguageTypeId === 1 || rows.showLanguageTypeId === 3 ? (items.otherFoodName ? items.otherFoodName : items.foodName) : ""}</div>
                                                            <div style={{ textAlign: 'center', fontSize: '15px' }}>{rows.showLanguageTypeId === 2 ? (items.otherFoodNameEn ? items.otherFoodNameEn : items.foodNameEn) : ""}</div>
                                                            <div style={{ textAlign: 'center', fontSize: '12px' }}>{rows.showLanguageTypeId === 3 ? (items.otherFoodNameEn ? items.otherFoodNameEn : items.foodNameEn) : ""}</div>
                                                        </Tooltip>
                                                    </Paragraph >
                                                </>
                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    }
                    <Paragraph>
                        <div style={{ textAlign: 'center', fontSize: '10px' }}>{rows.footnote}</div>
                        <div style={{ textAlign: 'center', fontSize: '10px' }}>{rows.subFootnote}</div>
                    </Paragraph>
                </Typography >
            )
        }
    };

    //表头
    const columnsHead = [
        // {
        //     title: '所属分店',
        //     dataIndex: 'branchStoreName',
        // },
        {
            title: '预订菜单名称',
            dataIndex: 'reserveMenuName',
            render: (text: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" title={"备注：" + record.memo} color='green'>
                        <div>{text}</div>
                    </Tooltip>
                )
            }
        },
        {
            title: '订位信息',
            dataIndex: 'customerReserve',
            key: 'customerReserve',
            render: (text: any, record: any) => {
                if (record.customerReserveId) {
                    if (record.customerReserve) {
                        return (
                            <div>
                                <Typography>
                                    <Paragraph>
                                        <div style={{ textAlign: 'center' }}>{record.customerReserve.contact + " " + record.customerReserve.appellationName}</div>
                                        <div style={{ textAlign: 'center' }}>{record.customerReserve.contactCall}</div>
                                        <div style={{ textAlign: 'center' }}>{record.customerReserve.timeType.bookingDate}</div>
                                        <div style={{ textAlign: 'center' }}>{record.customerReserve.timeType.typeName}</div>
                                        <div style={{ textAlign: 'center' }}>{showRoom(record.customerReserve.tempRooms)}</div>
                                    </Paragraph>
                                </Typography >
                            </div>
                        )
                    }
                    else {
                        return (
                            <div>
                                <Typography>
                                    <Paragraph>
                                        <div style={{ color: 'orange', textAlign: 'center' }}>找不到订位信息</div>
                                        <div style={{ color: 'orange', textAlign: 'center' }}>(预订ID有误)</div>
                                    </Paragraph>
                                </Typography >
                            </div>
                        )
                    }
                }
                else {
                    return (
                        <div style={{ color: 'red' }}>目前还没绑定订位信息</div>
                    )
                }
            }
        },
        {
            title: '预订菜式',
            dataIndex: 'showMenu',
            key: 'showMenu',
            render: (text: any, record: any) => {
                return (
                    <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleShowMenu(record)}>预订菜式</Button>
                    </Space>
                )
            }
        },
        {
            title: '价格',
            dataIndex: 'originalPrice',
            render: (text: any, record: any) => {
                return (
                    <div style={{ fontSize: 20, color: 'yellow' }}>
                        {"¥ " + text}
                    </div>
                )
            }
        },
        {
            title: '菜单状态',
            dataIndex: 'reserveMenuState',
            render: (text: any, record: any) => {
                return (
                    <div>{record.reserveMenuStateName}</div>
                )
            }
        },
        // {
        //     title: '排序',
        //     dataIndex: 'sortBy',
        // },
        // {
        //     title: '允许他人修改',
        //     dataIndex: 'isModifyByOther',
        //     render: (text: any, record: any) => (
        //         <Switch checkedChildren="允许" unCheckedChildren="不允许"
        //             checked={text} key={record.id}
        //             onChange={(e) => handleChange(record.id, 'isModifyByOther', e)}
        //         />
        //     )
        // },
        // {
        //     title: '转订单',
        //     dataIndex: 'isConverted',
        //     render: (text: any, record: any) => (
        //         <Switch checkedChildren="已转" unCheckedChildren="未转"
        //             checked={text} key={record.id}
        //             onChange={(e) => handleChange(record.id, 'isConverted', e)}
        //         />
        //     )
        // },
        // {
        //     title: '操作人',
        //     dataIndex: 'userName',
        //     // render: (text: any, record: any) => {
        //     //     return (
        //     //         <Tooltip placement="topLeft" title={"更新于：" + dayjs(record.sysDate.updatedAt).format('YYYY-MM-DD HH:mm:ss')} color='red'>
        //     //             <div>{text}</div>
        //     //         </Tooltip>
        //     //     )
        //     // }
        // },
    ];

    // const initData = async () => {
    //     const currentData: initDataType = {
    //         apiId,
    //         sendPageData: {
    //             customerReserveId: customerReserveId,
    //             branchStoreId: localStorage.getItem("currentBranchStoreId")
    //         },
    //     }
    //     ref.current.initData(currentData);
    // }

    // //改变数据
    // const handleChange = async (id: any, columnName: string, isValue: boolean) => {
    //     const bodys = {
    //         apiId: "reserveMenu",
    //         apiVariable: id,
    //         apiExtend: 'updateOne',
    //         apiData: {
    //             columnName,
    //             value: isValue,
    //         }
    //     }
    //     fetchApi.PUT(wmService, bodys).then(res => {
    //         antMessage('success', '编辑成功', 'success');
    //         initData();
    //     }).catch(err => {
    //         antMessage('error', '删编辑失败', err.message);
    //     })
    // };

    const handleOk = (e: any) => {
        closeModal(true);
    };

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                customerReserveId: customerReserveId,
                branchStoreId
            }
        }
        ref.current.initData(currentData);

        // ref.current.changeLogger({
        //     isChangeLoggerQueryTypeExtendId: true,
        //     loggerQueryTypeExtendId: branchStoreId
        // })

        setContextData({ ...contextData, refresh: false })
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '预订菜单',
        apiId,
        showAdd: false,
        showOperation: false,
        // showStates: true,
        // showLogger: {
        //     logger: {
        //         id: 'menuOpLogger'
        //     },
        //     loggerDisplay: true,
        //     loggerSingleDisplay: true,
        //     loggerQueryType: 'reserveMenu',
        // //  loggerQueryTypeExtendId: localStorage.getItem("currentBranchStoreId")
        //     loggerQueryTypeExtendId: contextData?.currentBranchStoreId
        // },
        columns,
        pagination: true,
        sendPageData: {
            customerReserveId: customerReserveId,
            // branchStoreId: localStorage.getItem("currentBranchStoreId")
            branchStoreId: contextData?.currentBranchStoreId
        },
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <>
            {/* {!isModel ? <BranchStoreList onRefresh={onRefresh}></BranchStoreList> : ""} */}
            <br></br>
            <Modal
                title={"【 " + reserveMenuNameData + " 】的预订菜式"}
                open={showReserveMenuDetailVisible}
                okText='关闭'
                onOk={onCloseReserveMenuDetail}
                onCancel={onCloseReserveMenuDetail}
                cancelButtonProps={{ style: { display: "none" } }}
                closable={false}
                style={{ fontSize: '20px' }}
                width={500}
            >
                {showData(reserveMenuDetailData)}
            </Modal>
            <br></br>
            {isModel ? <AntDraggableModal
                title={'预订菜单'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={1000}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal> : <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>}
        </>
    )
}