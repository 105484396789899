import { Form, Input, Row, Col, Select, Switch, InputNumber } from 'antd';
import { useRef, useState, useEffect } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const ClientUserEdit = (props: any) => {
    const [form] = Form.useForm(); //form  
    const { data } = props;
    const ref = useRef<any>(undefined);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [userData, setUserData] = useState<any>();
    
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据defaultService */

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps

        }

        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'user',
                    apiExtend: 'showListEx'
                }).then(res => {
                    setUserData(res.data);
                    // if (res.success) {
                    //     setRoleData(res.data);
                    // }
                });
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'clientUser',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '客户'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label="unionId"
                                name="unionId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="昵称"
                                name="nickName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="手机号码"
                                name="mobile">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="在线人数"
                                name="sameTimeOnlines">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="关联用户"
                                name="userId">

                                <Select
                                    showSearch
                                    placeholder="选择用户"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(userData, 'userName', 'id', undefined, true)}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>




                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default ClientUserEdit;