import systemConfig from "../config/systematic";
import fetchApi from "../utils/api/fetchApi"
import antMessage from '../utils/extend/AntdNotification'

let wmService: any = systemConfig.wmService;

const entrepotPrint = () => {

}

//订单打印
entrepotPrint.printOrder = async (type: string, typeProducts: string, apiId: any, item?: any, id?: any, initData?: any) => {

    let newArr: Array<any> = [];
    let subsystemId = localStorage.getItem("subsystemId")


    let title;
    let productData: Array<any> = [];
    if (item) {
        title = item;
        productData = item[typeProducts]
    }

    if (id) {
        let data = await fetchApi.GET(
            wmService,
            {
                apiId,
                apiExtend: 'showSingle',
                apiVariable: id,
                apiData:{
                    mountDataOptions:{
                        userMount:{
                            isUserAreas:true,
                           isDept:true
                        }
                   },
                }
            }
        )
        if (data.success) {

            productData = data.data[typeProducts]
            title = data.data


        }
    }

    if (productData) {
        for (var i = 0; i < productData.length; i++) {
            if (productData[i].id !== "合计:") {
                newArr.push(productData[i])

            }
        }
    }



     await fetchApi.POST(wmService, {
        apiId: "entrepotOrder",
        apiExtend: 'printOrder',
        apiData: {
            data: {
                ...title,
                [typeProducts]: newArr
            },
            type,
            subsystemId
        },
    }).then((res)=>{
        if (res.success) {
            if (initData) {
                initData()
            }
        }
        
    }).catch((err)=>{
        antMessage('error', '打印失败!!', err.message);
        console.log(err);
        
    })

  



}

//打印仓库报表
entrepotPrint.printEnReport = (rows: any, type: string, queryCriteria?: any, user?: any) => {
    console.log("开始打印");
    console.log("打印仓库报表类型", type);
    console.log("打印数据", rows);
    console.log("参数", queryCriteria);
    console.log("制表人", user);
    if (rows.length === 0 || typeof rows === "undefined") return false


    let subsystemId = localStorage.getItem("subsystemId")

    let newArr: Array<any> = [];
    let sum: any;
    for (var i = 0; i < rows.length; i++) {
        if (rows[i].productSources !== "合计") {
            newArr.push(rows[i])

        }
        if (rows[i].productSources === "合计") {
            sum = rows[i];
        }
    }


    fetchApi.POST(wmService, {
        apiId: "entrepotOrder",
        apiExtend: 'printEnReport',
        apiData: {
            data: {
                sum,
                rows: newArr,
                queryCriteria,
                user
            },

            type,
            subsystemId
        }
    }).then((res: any) => {

    }).catch((err: any) => {
        console.log(err);

    })



}


export default entrepotPrint