import { Form, Input, Switch } from 'antd';
import React, { useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const PaymentMethodEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    
    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'paymentMethod',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '支付方式'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="id"
                        name="id">
                        <Input  />
                    </Form.Item>

                    <Form.Item
                        label="支付方式"
                        name="paymentMethodName"
                        rules={[{ required: true, message: '请输入支付方式!' }]}>
                        <Input />
                    </Form.Item>
     
                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

            
                   
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default PaymentMethodEdit;