import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { Button, InputNumber, Popconfirm, Space, Spin, Switch, Table } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import arrayHandle from '../../../utils/baseLib/arrayHandle';
import antMessage from '../../../utils/extend/AntdNotification';
import { SocketContext } from '../../../App';

export default function MenuShopType(props: any) {

    const { data, closeModal } = props;
    const [menuShopTypeData, setMenuShopTypeData] = useState<any>();
    const [loadingData, setLoadingData] = useState<any>(false);

    const [selectedData, setSelectedData] = useState<any>();
    const [inputVisable, setInputVisable] = useState<any>(false);
    const [inputData, setInputData] = useState<any>();
    const [inputMax, setInputMax] = useState<any>();

    const ref = useRef<any>(undefined);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // initData = ref.current.initData;
        }
        const fetchData = async () => {
            getShowList();
        }
        fetchData()
    }, [])

    const getShowList = () => {
        fetchApi.GET(wmService, {
            apiId: "menuShopType",
            apiExtend: 'showShopTypeList',
            apiData: {
                menuHeadId: data.menuHeadId,
                // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                branchStoreId: contextData?.currentBranchStoreId,
                mountDataOptions: {
                    userMount: {
                        isDept: true
                    }
                }
            }
        }).then(res => {
            if (res.success) {
                setMenuShopTypeData(res.data);
                setInputMax(res.data[res.data.length - 1].shopType_shortCode - 1);
            }
        })
    }

    const handleChange = (record: any, key: any, index: any, rows: any) => {

        let changeData: any = [];
        switch (key) {
            case 'up':
                if (index === 0) {
                    antMessage('error', '当前已在最顶端了', 'error');
                }
                else {
                    changeData.push(
                        {
                            shopTypeName: record.shopTypeName,
                            shopTypeId: record.id,
                            shopType_shortCode: rows[index - 1].shopType_shortCode
                        },
                        {
                            shopTypeName: rows[index - 1].shopTypeName,
                            shopTypeId: rows[index - 1].id,
                            shopType_shortCode: record.shopType_shortCode
                        }
                    )
                }
                break;
            case 'down':
                if (index === rows.length - 1 || !rows[index + 1].state) {
                    antMessage('error', '当前已在最底了', 'error');
                }
                else {
                    changeData.push({
                        shopTypeName: record.shopTypeName,
                        shopTypeId: record.id,
                        shopType_shortCode: rows[index + 1].shopType_shortCode
                    },
                        {
                            shopTypeName: rows[index + 1].shopTypeName,
                            shopTypeId: rows[index + 1].id,
                            shopType_shortCode: record.shopType_shortCode
                        })
                }
                break;
        };

        if (changeData.length > 0) {
            setLoadingData(true);
            fetchApi.PUT(wmService, {
                apiId: "menuShopType",
                apiExtend: 'changeShortCode',
                apiData: {
                    menuHeadId: data.menuHeadId,
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    changeData,
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    }
                }
            }).then(res => {
                if (res.success) {
                    getShowList();

                    setSelectedData("");
                    setInputData(0);
                    setInputVisable(false);
                    setLoadingData(false);
                }
            })
        }

        // setLoadingData(true);

        // let now_key: any;
        // let after_key: any;

        // for (var i = 0, len = rows.length; i < len; i++) {
        //     if (rows[i].id === record.id) {
        //         now_key = i;
        //         after_key = key ? (i - 1) < 0 ? 0 : i - 1 : (i + 1) === rows.length ? rows.length - 1 : i + 1;
        //     }
        // }

        // if (now_key !== after_key) {
        //     if (rows[now_key].state && rows[after_key].state) {
        //         fetchApi.PUT(wmService, {
        //             apiId: "menuShopType",
        //             apiExtend: 'changeShortCode',
        //             apiData: {
        //                 menuHeadId: data.menuHeadId,
        //                 branchStoreId: localStorage.getItem("currentBranchStoreId"),
        //                 now_key: rows[now_key].shopType_shortCode,
        //                 after_key: rows[after_key].shopType_shortCode,
        //                 mountDataOptions: {
        //                     userMount: {
        //                         isDept: true
        //                     }
        //                 }
        //             }
        //         }).then(res => {
        //             if (res.success) {
        //                 // const apiId = "menuShopType";
        //                 // const currentData: initDataType = {
        //                 //     apiId,
        //                 //     sendPageData: { menuHeadId: data.menuHeadId }
        //                 // }
        //                 // ref.current.initData(currentData);
        //                 getShowList();

        //                 // fetchApi.GET(wmService, {
        //                 //     apiId: "menuShopType",
        //                 //     apiExtend: 'showShopTypeList',
        //                 //     menuHeadId: data.menuHeadId
        //                 // }).then(ress => {
        //                 //     if (ress.success) {
        //                 //         console.log(ress.data);

        //                 //         // ref.current.initData(ress.data);
        //                 //         setMenuShopTypeData(ress.data);
        //                 //         // getShowList();
        //                 //     }
        //                 // })

        //                 setSelectedData("");
        //                 setInputData(0);
        //                 setInputVisable(false);
        //                 setLoadingData(false);
        //             }
        //         })
        //     }
        // }
    }

    //表头
    const columnsHead = [
        {
            title: '小分类',
            dataIndex: 'shopTypeName',
        },
        {
            title: '顺序',
            dataIndex: 'shopType_shortCode',
        },

        inputVisable ?
            {
                title: '调整排序',
                dataIndex: 'changeSortBy',
                render: (text: any, record: any) => {
                    if (record.id === selectedData.id && record.state) {
                        return (
                            <Space>
                                <InputNumber
                                    max={inputMax}
                                    min={1}
                                    style={{
                                        width: 50,
                                    }}
                                    controls={false}
                                    precision={0}
                                    onChange={(e: any) => handleInput(e)}
                                />
                                <Button onClick={() => handleFinish(inputData)}>确定</Button>
                            </Space>
                        )
                    }
                }
            } : {},

        {
            title: '是否有数据',
            dataIndex: 'state',
            render: (text: any, record: any) => (
                <Switch checkedChildren="有" unCheckedChildren="无"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '调整顺序',
            dataIndex: 'sort',
            render: (text: any, record: any, index: any) => {
                if (record.state) {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleChange(record, "up", index, menuShopTypeData)}>上</Button>
                            <Button size={"small"} type="primary" onClick={() => handleChange(record, "down", index, menuShopTypeData)}>下</Button>
                        </Space>
                    )
                }
            }
        },

        sessionStorage.getItem('isAdmin') === 'true' ?
            {
                title: '操作',
                dataIndex: 'action',
                render: (text: any, record: any) => {
                    if (record.state) {
                        return (
                            <Space>
                                <Popconfirm title="删除该分类下所有菜式菜式,是否执行?" onConfirm={() => handleDel(record)}>
                                    <Button type='primary' danger size={"small"}>删除</Button>
                                </Popconfirm>
                            </Space>
                        )
                    }
                }
            } : {},
    ];

    //删除分类下的菜式
    const handleDel = (record: any) => {
        fetchApi.PUT(wmService, {
            apiId: "menuShopType",
            apiExtend: 'delMenu',
            apiData: {
                shopTypeId: record.id,
                menuHeadId: data.menuHeadId,
            }
        }).then(res => {
            if (res.success === true) {
                getShowList();
            }
        })
    };

    const handleOk = (e: any) => {
        closeModal(true)
    };

    const shopTypeSortBy = (record: any) => {
        if (record.state) {
            setInputVisable(true);
            setInputData(0);
            setSelectedData(record);
        }
        else {
            setInputVisable(false);
        }
    }

    const handleInput = (e: any) => {
        setInputData(e);
    };

    const handleFinish = (e: any) => {
        if (e === 0) {
            antMessage('error', '提交失败', "请输入序号！！");
        }
        else {
            let newData: any = arrayHandle.findData(menuShopTypeData, "shopType_shortCode", e);
            if (newData.length === 0) {
                setLoadingData(true);

                fetchApi.PUT(wmService, {
                    apiId: "menuShopType",
                    apiExtend: 'updateShortCode',
                    apiData: {
                        menuHeadId: data.menuHeadId,
                        // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                        branchStoreId: contextData?.currentBranchStoreId,
                        new_key: e,
                        // old_key: selectedData.shopType_shortCode,
                        shopTypeId: selectedData.id,
                        mountDataOptions: {
                            userMount: {
                                isDept: true
                            }
                        }
                    }
                }).then(res => {
                    if (res.success) {
                        getShowList();
                        setSelectedData("");
                        setInputVisable(false);
                        setInputData(0);
                        setLoadingData(false);
                    }
                })
            }
            else {
                antMessage('error', '修改失败', "提交的序号，已存在，请重新输入！！");
            }
        }
    };

    return (
        <>
            <AntDraggableModal
                title={'【 ' + data.menuHeadName + ' 】 调整小分类顺序'}
                open={true}
                okText='关闭'
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ disabled: loadingData }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>

                {loadingData ? (
                    <Spin size="large" tip="Loading...">
                        <Table
                            pagination={false}
                            size="small"
                            rowKey={(record: any) => record.id}
                            columns={columnsHead}
                            dataSource={menuShopTypeData}
                        >
                        </Table>
                    </Spin>
                ) :
                    (
                        <Table
                            pagination={false}
                            size="small"
                            rowKey={(record: any) => record.id}
                            columns={columnsHead}
                            dataSource={menuShopTypeData}
                            onRow={(record) => {
                                return {
                                    onClick: () => shopTypeSortBy(record)
                                }
                            }}
                        >
                        </Table>
                    )}

            </AntDraggableModal >
        </>
    )
}