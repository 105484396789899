import { Col, Form, Input, InputNumber, Row, Select, Switch, TimePicker } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification';
import { SocketContext } from '../../../App';

export default function MenuSaleTimeEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    /**周期数据 */
    const [menuSalePeriodData, setMenuSalePeriodData] = useState<any>();

    /** 后端数据wmService */
    let wmService: any = "";

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    form.setFieldsValue({ menuSalePeriodId: res.data.menuSalePeriodId });
                }
            })
        }
        const fetchData = async () => {

            form.setFieldsValue({ menuId: data.sendPageData.menuId });
            form.setFieldsValue({ menuSalePeriodId: data.sendPageData.menuSalePeriodId });
            // form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });
            form.setFieldsValue({ branchStoreId: contextData?.currentBranchStoreId });

            fetchApi.GET(wmService, {
                apiId: "menuSalePeriod",
                apiExtend: 'showList',
                apiVariable: data.sendPageData.menuSalePeriodId
            }).then(res => {
                if (res.success) {
                    setMenuSalePeriodData(res.data);
                }
            })
        }
        fetchData();
    }, [])

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const changeDate = (e: any, key: any) => {
        if (key === 'startTime' && form.getFieldsValue()['endTime'] !== undefined) {
            if (dayjs(form.getFieldsValue()['endTime']).format("HH:mm") < dayjs(e).format("HH:mm")) {
                antMessage('error', '时间设置有误', '结束时间小于开始时间,请重新选择.');
                form.resetFields(['startTime']);
            }
        }

        if (key === 'endTime') {
            if (dayjs(form.getFieldsValue()['startTime']).format("HH:mm") > dayjs(e).format("HH:mm")) {
                antMessage('error', '时间设置有误', '开始时间大于结束时间,请重新选择.');
                form.resetFields(['endTime']);
            }
        }
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuSaleTime',
        dateColumnName: ['startTime', 'endTime'],
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '销售时段'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="branchStoreId"
                                name="branchStoreId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="menuId"
                                name="menuId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="周期"
                                name="menuSalePeriodId"
                                rules={[{ required: true, message: '请选择周期!' }]}
                            >
                                <Select
                                    disabled
                                    showSearch
                                    placeholder="选择周期"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {getList.general(menuSalePeriodData, "weekName")}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="开始时间"
                                name="startTime"
                                rules={[{
                                    required: true,
                                    message: '请选择开始时间',
                                }]}>
                                <TimePicker
                                    onChange={(e) => changeDate(e, 'startTime')}
                                    format="HH:mm" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="结束时间"
                                name="endTime"
                                rules={[{
                                    required: true,
                                    message: '请选择结束时间',
                                }]}>
                                <TimePicker
                                    onChange={(e) => changeDate(e, 'endTime')}
                                    format="HH:mm" />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}