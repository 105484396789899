import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useContext, useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import { SocketContext } from '../../../App';

export default function QueuingTypeEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    // const [branchStoreData, setBranchStoreData] = useState<any>();
    const [segmentData, setSegmentData] = useState<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

            if (data.type === "EDIT") {
                ref.current.row.then((res: any) => {
                    if (res.success === true) {
                        const { branchStore } = res.data;
                        const { id } = branchStore;
                        form.setFieldsValue({ branchStoreId: id });
                    }
                })
            }
            form.setFieldsValue({ branchStoreId: contextData?.currentBranchStoreId });
        }
        const fetchData = async () => {

            optionString();

            // fetchApi.GET(wmService, {
            //     apiId: "branchStore",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setBranchStoreData(res.data)
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "menuHead",
            //     apiExtend: 'getBranchStoreId'
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.key !== 'manager') {
            //             form.setFieldsValue({ branchStoreId: res.data.branchStore.id });
            //         }
            //     }
            // });

        }
        fetchData()
    }, [])

    const optionString = () => {
        let optionData: any = [];
        let newData: any = [];
        for (var aValue = 65; aValue < 91; aValue++) {
            let aValueString = String.fromCharCode(aValue);
            newData = {
                value: aValueString,
                label: aValueString
            }
            optionData.push(newData);
        }
        setSegmentData(optionData);
    };

    const checkPeople = (key: any, e: any) => {
        if (key === 'minPeople') {
            if (Number(form.getFieldsValue()['maxPeople']) < Number(e.target.defaultValue)) {
                form.setFieldsValue({ maxPeople: e.target.defaultValue });
            }
        }
        else {
            if (Number(form.getFieldsValue()['minPeople']) > Number(e.target.defaultValue)) {
                form.setFieldsValue({ minPeople: e.target.defaultValue });
            }
        }
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'queuingType',
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '排队类型'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="门店Id"
                                name="branchStoreId"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        {/* <Form.Item
                        label="所属门店"
                        name="branchStoreId"
                        rules={[{ required: true, message: '请选择所属门店!' }]}>
                        <Select
                            showSearch
                            disabled={true}
                            placeholder="所属门店"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(branchStoreData, 'branchStoreName')}
                        </Select>
                    </Form.Item> */}

                        <Col span={14}>
                            <Form.Item
                                label="类型名"
                                name="queuingTypeName"
                                rules={[{ required: true, message: '请输入排队类型名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="序号"
                                name="segment"
                                rules={[{ required: true, message: '请选择排队序号!' }]}>
                                <Select
                                    // style={{ width: 100 }}
                                    placeholder="排队序号"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    options={segmentData}>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="容纳人数">
                                <Form.Item
                                    name="minPeople"
                                    rules={[{ required: true, message: '请输入最低容纳人数!' }]}
                                    style={{ display: 'inline-block' }}
                                >
                                    <InputNumber min={0} precision={0} onBlur={(e) => checkPeople('minPeople', e)} />
                                </Form.Item>
                                &nbsp;
                                ~
                                &nbsp;
                                <Form.Item
                                    name="maxPeople"
                                    rules={[{ required: true, message: '请输入最高容纳人数!' }]}
                                    style={{ display: 'inline-block' }}
                                >
                                    <InputNumber min={0} precision={0} onBlur={(e) => checkPeople('maxPeople', e)} />
                                </Form.Item>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;叫号规则【标识】：[@queuingNum]:排队号码;
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="叫号规则"
                                name="callRules"
                            >
                                <TextArea
                                    placeholder="叫号规则:【标识】：[@queuingNum]:排队号码;"
                                    autoSize={{ minRows: 2, maxRows: 15 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo"
                            >
                                <TextArea
                                    placeholder="备注"
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="微信取号"
                                name="isWx"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}