import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import type { RangePickerProps } from 'antd/es/date-picker';
import getList from '../../universal/getList';
import system from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification'
import ReplaceReserveDateRoom from './ReplaceReserveDateRoom';
import dayjs from 'dayjs';

export default function ChooseBookingDate(props: any) {
    const { setModalChooseBookingDateopen, data, getData } = props
    
    const [form] = Form.useForm(); //form 
    const [rows, setRows] = useState<any>();


    /**打开分店房间选择窗口 */
    const [modalReplaceReserveDateRoomopen, setModalReplaceReserveDateRoomopen] = useState<Boolean>(false)
    /** 后端数据wmService */
    let wmService: any = system.wmService;
    /** 数据刷新中 */
    let loading = false;
    /**打开分店房间选择窗口 */
    /**分店房间选择数据 */
    const [replaceReserveDateRoom, setReplaceReserveDateRoom] = useState<any>();
    useEffect(() => {

        fetchApi.GET(wmService,
            {
                apiId: 'timeType',
                apiExtend: 'showListBybranchStore',
                apiData: {
                    bookingDate: data.bookingDate,
                    mountDataOptions:{
                        userMount:{
                           isDept:true,
                        }
                   },
                }
            }).then(res => {
                if (res.success) {

                    setRows(res.data)
                }
            })
        if (data) {
            form.setFieldsValue({ timeTypeId: data.originalTimeTypeId })
            form.setFieldsValue({ bookingDate: dayjs(data.bookingDate) })
        }
    }, []);

    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {
                

                const sendData = {
                    id: data.id,
                    customerReserveId: data.customerReserveId,
                    timeTypeId: values.timeTypeId,
                    originalTimeTypeId: data.originalTimeTypeId,
                    originalRoomTypeId: data.originalRoomTypeId,
                    type: "replaceRoom",
                    originalRoomId: data.originalRoomId,
                }

                setReplaceReserveDateRoom(sendData)
                setModalReplaceReserveDateRoomopen(true)


            })
            .catch((err) => {
                antMessage('warning', '无法通过数据校验', '请输入所需数据！');
            })
    };
    const handleCancel = (e: any) => {
        setModalChooseBookingDateopen()
    };

    const disabledDate: RangePickerProps['disabledDate'] = current => {


        // Can not select days before today and today
        return current && current < dayjs().subtract(1, 'days');
    };
    const handleDate = (e: any) => {


        initGetData(
            dayjs(e).format("YYYY-MM-DD"),
        )
        form.resetFields(["timeTypeId"])
    }
    //获取数据
    const initGetData = async (bookingDate: any,
    ) => {

        if (loading === true) { return }
        loading = true;
        fetchApi.GET(wmService, {
            apiId: "roomReserve",
            apiExtend: 'getDateToTimeData',
            apiData: {
                bookingDate,
                mountDataOptions:{
                    userMount:{
                       isDept:true,
                    }
               },
            }
        }).then(res => {
            if (res.success === true) {

                setRows(res.data.rows);
                loading = false
            }
        })
    }
    return (
        <>
            <AntDraggableModal
                title={`选择预订日期`}
                open={true}
                okText='下一步'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>

                        <Col span={10}>
                            <Form.Item
                                label="预订日期"
                                name="bookingDate"
                                rules={[{ message: '预订日期!', required: true }]}>
                                <DatePicker
                                    inputReadOnly
                                    disabledDate={disabledDate}
                                    // defaultValue={moment(data.bookingDate)}
                                    format="YYYY-MM-DD"
                                    onChange={handleDate}
                                />
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Form.Item
                                label="市别"
                                name="timeTypeId"
                                rules={[{ message: '请选择市别!', required: true }]}
                            >
                                <Select
                                    placeholder="选择市别"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(rows, "typeName")}
                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>
            </AntDraggableModal>
            {modalReplaceReserveDateRoomopen ? (<ReplaceReserveDateRoom getData={getData} data={replaceReserveDateRoom} setModalChooseBookingDateopen={setModalChooseBookingDateopen} setModalReplaceReserveDateRoomopen={setModalReplaceReserveDateRoomopen}
            />)
                : null}
        </>
    )
}
