import { Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import WebEditUniversal, { WEUExtendPropsType } from '../../components/system/WebEditUniversalNewEx'
import systemConfig from '../../config/systematic';
import { httpGet } from '../../utils/api/fetchApi';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import getList from '../universal/getList';

//布局
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const MoonEdit = (props: any) => {
  const [form] = Form.useForm(); //form 
  const { data } = props;

  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  /**票据类型数据 */
  const [moonTypeData, setMoonTypeData] = useState<any>()
  /**分店数据 */
  const [branchStoreData, setbranchStoreData] = useState<any>()
  /**票据状态数据 */
  const [moonStatusData, setMoonStatusData] = useState<any>()
  /**折扣数据 */
  const [disCountData, setDisCountData] = useState<any>()

  const ref = useRef<any>(undefined);
  useEffect(() => {
    let branchStoreId = sessionStorage.getItem("branchStoreId")
    
    form.setFieldsValue({ moonStatus: 1 })
    if (data.type === "NEW") {
      form.setFieldsValue({ saleBranchStore: branchStoreId })

    }


    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
    }
    const fetchData = async () => {


      httpGet(wmService, {
        apiId: "generalData",
        apiExtend: 'showList',
        apiVariable: "moonStatus",
      }).then(res => {
        if (res.success) {

          setMoonStatusData(res.data)
        }
      })
      httpGet(wmService, {
        apiId: "branchStore",
        apiExtend: 'showList'
      }).then(res => {

        setbranchStoreData(res.data)
      })

      if (data.type === "NEW") {
        httpGet(wmService, {
          apiId: "moonType",
          apiExtend: 'branchStoreMoonType',
          apiVariable: branchStoreId,
        }).then(res => {
          if (res.success) {
            setMoonTypeData(res.data)
          }
        })
      } else {

        httpGet(wmService, {
          apiId: "moonType",
          apiExtend: 'showList',
        }).then(res => {
          if (res.success) {
            setMoonTypeData(res.data)
          }
        })
      }



      httpGet(wmService, {
        apiId: "disCount",
        apiExtend: 'showList'
      }).then(res => {
        setDisCountData(res.data)
      })

    }
    fetchData()
  }, [])
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const handleCancel = (e: any) => {
    ref.current.handleCancel();
  };

  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'moon',
    mountDataOptions:{
      userMount:{
         isDept:true,
      }
 },
  };
  // const getbranchStoreRoomTypeData = (branchStoreId: any) => {
  //   console.log("branchStoreId", localStorage.getItem("branchStoreId"));

  //   if (data.type === "NEW") {
  //     httpGet(wmService, {
  //       apiId: "moonType",
  //       apiExtend: 'branchStoreMoonType',
  //       apiVariable: branchStoreId,
  //     }).then(res => {

  //       if (res.success) {
  //         setMoonTypeData(res.data)
  //       }
  //     })

  //   }
  // }

  return (
    <>
      <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '票据'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
      >
        <Form
          {...layout}
          form={form}
          name="Edit"
          onFinish={handleOk}
         onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}>

          <Form.Item
            label="id"
            name="id">
            <Input disabled={true} />
          </Form.Item>

          <Form.Item
            label="票号"
            name="ticketNo"

            rules={[{ required: true, message: '请输入票据票号!' }]}
          >
            <Input placeholder='请输入除票头后的6位数，列如输入:000001' />
          </Form.Item>

          <Form.Item
            label="售出分店"
            name="saleBranchStore"
          >
            <Select
              disabled
              showSearch
              // onSelect={getbranchStoreRoomTypeData}
              placeholder="售出分店"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(branchStoreData, 'branchStoreName')}
            </Select>
          </Form.Item>

          <Form.Item
            label="票据类型"
            name="moonTypeId"
            rules={[{ required: true, message: '请输入票据类型!' }]}>
            <Select
              showSearch
              placeholder="票据类型"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(moonTypeData, 'moonType')}
            </Select>
          </Form.Item>

          <Form.Item
            label="票据状态"
            name="moonStatus"
            rules={[{ required: true, message: '请输入票据状态!' }]}>
            <Select
              disabled
              showSearch
              placeholder="票据状态"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(moonStatusData, 'name')}
            </Select>
          </Form.Item>

          <Form.Item
            label="折扣"
            name="disCountId"
            rules={[{ required: true, message: '请输入票据折扣!' }]}>
            <Select

              showSearch
              placeholder="折扣"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(disCountData, 'disCountName')}
            </Select>
          </Form.Item>

          <Form.Item
            label="状态"
            name="isUse"
            valuePropName="checked"
            initialValue={true}>
            < Switch checked={true}
              checkedChildren="启用"
              unCheckedChildren="关闭" />
          </Form.Item>

        </Form>
      </AntDraggableModal>
      <WebEditUniversal ref={ref} props={extendProps} />

    </>
  )
}

export default MoonEdit;