import { Form, Input, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import objectHandle from '../../../utils/baseLib/objectHandle';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const DeptEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const [deptData, setDeptData] = useState<any>();
    const [branchStoreData, setBranchStoreData] = useState<any>();
    const [areaData, setAreaData] = useState<any>();
    const [printerData, setPrinterData] = useState<any>();
    const [initialSpecialPrintersId, setInitialSpecialPrintersId] = useState<any>([]);
    const [initialPrintersId, setInitialPrintersId] = useState<any>([]);


    const [defaultPrinterData, setDefaultPrinterData] = useState<any>();
    const [defaultSpecialPrinterData, setDefaultSpecialPrinterData] = useState<any>();

    /** 后端数据wmService */
    let wmService: any = "";
    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'dept',
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

            if (data.type === "EDIT") {
                ref.current.row.then(async (res: any) => {
                    if (res.success === true) {
                        const printersId = res.data.printersId;
                        const specialPrintersId = res.data.specialPrintersId;
                        let printersRows;
                        let specialPrintersRows;
                        if (objectHandle.isEmpty(printersId) === false) {
                            printersRows = printersId.split('@');
                        }
                        if (objectHandle.isEmpty(specialPrintersId) === false) {
                            specialPrintersRows = specialPrintersId.split('@');
                        }
                        setInitialPrintersId(printersRows);
                        setInitialSpecialPrintersId(specialPrintersRows);
                        form.setFieldsValue({
                            printersId: printersRows
                        })
                        form.setFieldsValue({
                            specialPrintersId: specialPrintersRows
                        })
                    }
                })
            }

        }
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "dept",
                apiExtend: 'showList',
                apiData:{
                    
                }
            }).then(res => {
                if (res.success) {
                    setDeptData(res.data);
                }
            })

            fetchApi.GET(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBranchStoreData(res.data);
                }
            })

            fetchApi.GET(wmService, {
                apiId: "area",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAreaData(res.data);
                }
            })

            fetchApi.GET(wmService,
                {
                    apiId: 'printer',
                    apiExtend: 'showList',
                    apiData:{
                        mountDataOptions:{
                            userMount: {
                                isDept:true
                            }
                           },
                    }
                }).then(res => {
                    if (res.success) {
                        setPrinterData(res.data);
                        setDefaultPrinterData(newData(form.getFieldsValue()['printersId'], res.data));
                        setDefaultSpecialPrinterData(newData(form.getFieldsValue()['specialPrintersId'], res.data));
                    }
                })
        };
        fetchData();
    }, []);

    const changePrinter = (e: any, key: any, setData: any) => {
        if (!inArray(form.getFieldsValue()[key], newData(e, printerData))) {
            form.resetFields([key]);
        }
        setData(newData(e, printerData));
    }

    const newData = (e: any, data: any) => {
        let newData: Array<any> = [];
        if (e) {
            for (var i = 0; i < e.length; i++) {
                for (var j = 0; j < data.length; j++) {
                    if (data[j].id === e[i]) {
                        newData.push({
                            id: e[i],
                            printerName: data[j].printerName,
                        })
                    }
                }
            }
        }

        return newData
    }

    const inArray = (search: any, array: any) => {
        for (var i in array) {
            if (array[i].id === search) {
                return true;
            }
        }
        return false;
    }

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '部门'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="专属id"
                        name="selfId" >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="区域"
                        name="areaId"
                        rules={[{ required: true, message: '请选择区域!' }]}
                    >
                        <Select
                            showSearch
                            placeholder="选择区域"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(areaData, 'areaName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="部门名称"
                        name="deptName"
                        rules={[{ required: true, message: '请输入部门名称!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="分店"
                        name="branchStoreId"
                        rules={[{ required: false, message: '请选择分店!' }]}>
                        <Select
                            showSearch
                            placeholder="选择分店"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(branchStoreData, 'branchStoreName',undefined,undefined,true)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="上级部门"
                        name="superiorDeptId"
                        rules={[{ message: '请选择上级部门!' }]}>
                        <Select
                            showSearch
                            placeholder="选择上级部门"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(deptData, 'deptName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="微信"
                        name="isWx"
                        valuePropName="checked"
                        initialValue={false}>
                        < Switch checked={false}
                            checkedChildren="是"
                            unCheckedChildren="否" />
                    </Form.Item>

                    {/* <Form.Item
                        label="打印机"
                        name="printersId"
                        initialValue={initialPrintersId}>
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="选择打印机"
                            optionFilterProp="children"
                            onChange={(e) => changePrinter(e, 'defaultPrinterId', setDefaultPrinterData)}
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(printerData, 'printerName', 'id', undefined, false)}
                        </Select>
                    </Form.Item> */}

                    {/* <Form.Item
                        label="默认打印机"
                        name="defaultPrinterId">
                        <Select
                            showSearch
                            placeholder="选择默认打印机"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(defaultPrinterData, 'printerName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="特殊打印机"
                        name="specialPrintersId"
                        initialValue={initialSpecialPrintersId} >
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="选择特殊打印机"
                            onChange={(e) => changePrinter(e, 'defaultSpecialPrinterId', setDefaultSpecialPrinterData)}
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(printerData, 'printerName', 'id', undefined, false)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="默认特殊打印机"
                        name="defaultSpecialPrinterId">
                        <Select
                            showSearch
                            placeholder="选择默认特殊打印机"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(defaultSpecialPrinterData, 'printerName')}
                        </Select>
                    </Form.Item> */}

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}

export default DeptEdit;