import React, { useEffect, useRef } from 'react';
import BsRoomConfigEdit from './BsRoomConfigEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';

//组件 Floor 
const BsRoomConfig = (props: any) => {

    /** api服务id */
    const apiId = 'bookingConfig';
    const ref = useRef<any>(undefined);
    /** 更改一项数据 */
    let handleChangeOne: any;

    /**wmService */
    let wmService: any;
    /** 刷新 */
    let initData: any;
    //表头
    // roomRuleName
    const columnsHead = [
        {
            title: '分店',
            dataIndex: "branchStore",
            render: (text: any, record: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '房名规则',
            dataIndex: "roomRuleName",
        },
        {
            title: '生成天数',
            dataIndex: "day"
        },
        {
            title: '手动生成',
            dataIndex: 'isDynamic',

            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record.id, 'isDynamic', e, apiId) :
                            undefined
                    }
                />
            )

        }
    ];
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '预订配置',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        showAutomatic: true,
        EditComponent: BsRoomConfigEdit
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default BsRoomConfig;
