
import { Button, Form, Input, Select, Upload, UploadProps, Image, Row, Col, Switch } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';


export default function EntrepotEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>(undefined);
    const [deptData, setDeptData] = useState<any>();
    const [areaData, setAreaData] = useState<any>();
    const [userData, setUserData] = useState<any>();
    const [branchStoreData, setBranchStoreData] = useState<any>();


    const [entrepotLevel, setEntrepotLevel] = useState<any>();

    // entrepotLevel




    /** 后端数据Service */
    let wmService: any = "";
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'entrepot'
    };






    useEffect(() => {
        if (ref.current) {

            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "dept",
                apiExtend: 'showList',
                apiData:{
                    
                }
            }).then(res => {
                if (res.success) {
                    setDeptData(res.data)
                }
            })
            httpGet(wmService, {
                apiId: "area",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAreaData(res.data)
                }
            })

            httpGet(wmService, {
                apiId: "user",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setUserData(res.data)
                }
            })
            httpGet(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBranchStoreData(res.data)
                }
            })

            httpGet(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'entrepotLevel',
                    apiExtend: 'showList'
                }).then(res => {

                    if (res.success) {
                        setEntrepotLevel(res.data)
                    }
                });


        }
        fetchData()
    }, [])

    const children = (isLoading: boolean) => {
        return <AntDraggableModal
            title={(data.type === 'NEW' ? '新增' : '编辑') + '仓库'}
            open={true}
            okText='确定'
            cancelText='取消'
            onOk={handleOk}
            onCancel={handleCancel}
            closable={false}
            maskClosable={false}
            confirmLoading={isLoading}
            width={"55%"}
        >
            <Form
                form={form}
                name="Edit"
                onFinish={handleOk}
               onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        handleOk(e)
                    }
                }}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="id"
                            name="id">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="专属id"
                            name="selfId"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="仓库名称"
                            name="entrepotName"
                            rules={[{ required: true, message: '请输入仓库名称!' }]}>
                            <Input />
                        </Form.Item>
                    </Col>



                    <Col span={8}>
                        <Form.Item
                            label="区域"
                            name="areaId"
                            rules={[{ required: true, message: '请选择区域!' }]}>
                            <Select
                                showSearch
                                placeholder="请选择区域"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={getList.generalSelcetOption(areaData, 'areaName')}
                            >
                                {/* {getList.general(areaData, 'areaName')} */}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="部门"
                            name="deptId"
                            rules={[{ required: true, message: '请选择部门!' }]}>
                            <Select
                                showSearch
                                placeholder="请选择部门!"
                                optionFilterProp="children"
                                onSearch={(e) => getList.search('dept', setDeptData, e)}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(deptData, 'deptName')}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="分店"
                            name="branchStoreId"
                        >
                            <Select
                                showSearch
                                placeholder="请选择分店!"
                                optionFilterProp="children"
                                onSearch={(e) => getList.search('dept', setDeptData, e)}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.generalEx(branchStoreData, {
                                    "includeNull":true,
                                    "itemName":"branchStoreName"
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="等级"
                            name="level"
                            rules={[{ required: true, message: '请选择等级!' }]}>
                            <Select
                                showSearch
                                placeholder="请选择等级"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={getList.generalSelcetOption(entrepotLevel, 'name')}
                            >
                                {/* {getList.general(areaData, 'areaName')} */}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="默认"
                            name="isDefault"
                            valuePropName="checked"
                            initialValue={true}
                        >
                            < Switch checked={true}
                                checkedChildren="是"
                                unCheckedChildren="否" />
                        </Form.Item>
                    </Col>


                    <Col span={8}>
                        <Form.Item
                            label="统计"
                            name="isStatistics"
                            valuePropName="checked"
                            initialValue={true}
                        >
                            < Switch checked={true}
                                checkedChildren="是"
                                unCheckedChildren="否" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="数据导入"
                            name="isImport"
                            valuePropName="checked"
                            initialValue={false}>
                            < Switch checked={false}
                                checkedChildren="是"
                                unCheckedChildren="否" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="跳过流程 "
                            name="isSkipProcess"
                            valuePropName="checked"
                            initialValue={false}>
                            < Switch checked={false}
                                checkedChildren="是"
                                unCheckedChildren="否" />
                        </Form.Item>
                    </Col>


                    <Col span={8}>
                        <Form.Item
                            label="跟随仓库盖章 "
                            name="isSealFromOri"
                            valuePropName="checked"
                            initialValue={false}>
                            < Switch checked={false}
                                checkedChildren="是"
                                unCheckedChildren="否" />
                        </Form.Item>
                    </Col>

                </Row>
            </Form>

        </AntDraggableModal>
    }
    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} children={children}></WebEditUniversal>


        </>
    )
}
