import React, { useContext, useEffect, useRef } from 'react'
import WebUniversal, { initDataType, WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import { columnsheadId } from '../../../universal/columnshead';
import dayjs from 'dayjs';
import systemConfig from '../../../../config/systematic';
import BdPayEdit from './BdPayEdit';
import { SocketContext } from '../../../../App';
export default function BdPay(props: any) {
    /** api服务id */
    const apiId = 'bdPay';
   let wmService: any = systemConfig.wmService;
   const ref = useRef<any>(undefined);
   const useContextData = useContext(SocketContext);
   const { contextData, setContextData } = useContextData;
    //表头
    const columnsHead = [
        {
            title: '值班人',
            dataIndex: 'user',
            render: (text: any) => (text ? text.userName : "")

        },
        {
            title: '登录时间',
            dataIndex: 'loginTime',
            render: (text: any) => (text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "")
        },
        {
            title: '登出时间',
            dataIndex: 'logoutTime',
            render: (text: any) => (text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "")

        },
        {
            title: '预支钱款',
            dataIndex: 'advancePayment',
        },

        {
            title: '实交价格',
            dataIndex: 'totalRealMoney',
        },
       
     

    ];
    useEffect(() => {

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                const currentData: initDataType = {
                    apiId,
                    sendPageData: { branchStoreId:contextData?.currentBranchStoreId }
                }
                ref.current.initData(currentData);
                setContextData({ ...contextData, refresh: false });
            }
        }

    },[contextData?.currentBranchStoreId])
  

    const columns = columnsheadId
        .concat(columnsHead as []);
    /** 子表头 */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '值班详情',
        apiId,
        columns,
        showAdd: false,
        mountDataOptions:{
             userMount:{
                isDept:true,
             }
        },
        // showEdit: false,
        showStates: false,
        pagination: true,
        showDelete: true,
        EditComponent: BdPayEdit,
        sendPageData: {branchStoreId: contextData?.currentBranchStoreId }
    };
  
    return (
        <WebUniversal ref={ref} props={extendProps} />
    )
}

