import { Form, Input, Select, Switch, TimePicker } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
    /** 后端数据wmService */
    let wmService: any = "";
    const [branchStoreData, setBranchStoreData] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBranchStoreData(res.data);
                }
            })
        };
        fetchData();
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'sb',
        dateColumnName: ['startTime', 'endTime'],
        mountDataOptions:{
            userMount:{
               isDept:true,
            }
       },
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '市别'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="名称"
                        name="sbName"
                        rules={[{ required: true, message: '请输入市别名称!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="分店"
                        name="branchStoreId"
                        rules={[{ required: false, message: '请选择分店!' }]}>
                        <Select
                            showSearch
                            disabled={true}
                            placeholder="选择分店"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(branchStoreData, 'branchStoreName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="开始时间"
                        name="startTime">
                        <TimePicker
                            format="HH:mm" />
                    </Form.Item>

                    <Form.Item
                        label="结束时间"
                        name="endTime">
                        <TimePicker
                            format="HH:mm" />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}
                        >
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;