import { Button, Space, Switch } from 'antd';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import QueuingSettingEdit from './QueuingSettingEdit';
import CallWay from './CallWay';
import { useContext, useEffect, useRef, useState } from 'react';
import { SocketContext } from '../../../App';

export default function QueuingSetting(props: any) {

    const ref = useRef<any>(undefined);

    // 叫号方式窗口
    const [callWayVisible, setCallWayVisible] = useState(false);

    // 叫号方式数据
    const [callWayData, setCallWayData] = useState<any>();

    /** api服务id */
    const apiId = 'queuingSetting';

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
        //同步获取数据 async - await
        const fetchData = async () => {
        };

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                branchStoreId
            }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    //  设置叫号方式
    const HandleCallWay = (record: any) => {
        sendData.queuingSettingId = record.id;
        setCallWayData(sendData);
        setCallWayVisible(true);
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '排队规则',
            dataIndex: 'rules',
        },
        {
            title: '叫号规则',
            dataIndex: 'callRules',
        },
        {
            title: '叫号方式',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => HandleCallWay(record)}>叫号方式</Button>
                    </Space>
                )
            }
        },
        {
            title: '微信拿号',
            dataIndex: 'isWx',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChange(record.id, 'isWx', e)}
                />
            )
        },
        {
            title: '跨市别拿号',
            dataIndex: 'isMoreDay',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChange(record.id, 'isMoreDay', e)}
                />
            )
        },
        {
            title: '提前拿号时间',
            dataIndex: 'earlyTime',
            render: (text: any, record: any) => {
                return (
                    <div>{record.earlyTime + "分钟"}</div>
                )
            }
        },
    ];

    //传关参数
    let sendData = {
        type: 'EDIT',
        queuingSettingId: '',
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '排队设置',
        apiId: 'queuingSetting',
        showStates: true,
        showQuery: false,
        columns,
        pagination: true,
        EditComponent: QueuingSettingEdit,
        sendPageData: { branchStoreId: contextData?.currentBranchStoreId },
        // sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>

            {callWayVisible
                ? (<CallWay
                    closeModal={setCallWayVisible} data={callWayData} />)
                : null}
        </>

    )
}