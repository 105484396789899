import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../../utils/extend/AntDraggableModal'
import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Select, Space } from 'antd';
import systemConfig from '../../../../config/systematic';
import fetchApi from '../../../../utils/api/fetchApi';

import antMessage from '../../../../utils/extend/AntdNotification'
import dayjs from 'dayjs';
export default function PersonnelShift(props: any) {
  const [form] = Form.useForm(); //form 
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [modalVisible, setModalVisible] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formListData = Form.useWatch("findData", form) as any;
 

  const handleOk = (e: any) => {
    
    form.validateFields() //数据校验
      .then(async (values: any) => {
        fetchApi.POST(wmService, {
          apiId: "beOnDuty",
          apiExtend: "personnelShift",
          apiData: {
            ...values,
            ...e,
            mountDataOptions:{
              userMount: {
                  isDept: true
                }
          }
          }
        }).then(res => {
           
          const { isPrint } = e
          let message = isPrint ? "打印成功" : "交班成功"
           if(!isPrint) setModalVisible(false)
          setIsLoading(false)
          antMessage('success', message, 'success');
        }).catch(err => {
          console.log("err",err);
          
          setIsLoading(false)
          // setLoading(false)
          antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
        })
      })
      .catch((err: any) => {

        console.log("err", err);

        antMessage('warning', '无法通过数据校验', '请输入所需数据！');
      });
  }
  useEffect(() => {
    initData()
  }, [])

  const initData = () => {

    fetchApi.POST(wmService, {
      apiId: "beOnDuty",
      apiExtend: "shiftData",
      apiData:{
        mountDataOptions:{
          userMount: {
              isDept: true
            }
      }
      }
    }).then(res => {
      form.setFieldsValue({
        ...res.data,
        "loginTime": dayjs(res.data.loginTime).format("YYYY-MM-DD HH:mm:ss"),
        "logoutTime": dayjs().format("YYYY-MM-DD HH:mm:ss"),
        // giftTotalMoney: res.data.giftTotalMoney + res.data.giftMenuMoney,
      })
      // setLoading(false)
    }).catch(err => {
      console.log("err", err);

      // setLoading(false)

    })
  }
  const handleCancel = (e: any) => {
    setModalVisible(false)

  }
  return (
    <>
      <AntDraggableModal
        title={"交班表"}
        open={modalVisible}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        width={"70%"}
        footer={
          <Space>
            <Button onClick={handleCancel}>取消</Button>
            {/* <Button onClick={() => handleOk({ isPrint: true,printType:"settlement" })}>结算总计</Button> */}
            <Button onClick={() => handleOk({ isPrint: true })}>打印</Button>

            <Popconfirm  placement={"rightBottom"} title={<div style={{ fontSize: "30px" }}>确定要交班吗？</div>} onConfirm={() => handleOk({ isPrint: false })} okButtonProps={{ size: "large", danger: true }} cancelButtonProps={{ size: "large", }} okText="确定" cancelText="取消">
              <Button >确认</Button>
            </Popconfirm>
          </Space>
        }

        confirmLoading={isLoading}

      >


        <Form
          form={form}
          name="Edit"
          onFinish={handleOk}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}>
          <Row>
            <Col span={12}>
              <Form.Item
                label="id"
                name="id">
                <Input readOnly></Input >
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="上班时间"
                name="loginTime">
                <Input readOnly></Input >
              </Form.Item>
            </Col>



            <Col span={12}>
              <Form.Item
                label="下班时间"
                name="logouTime">
                <Input readOnly ></Input>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="预支款数"
                name="advancePayment">
                <InputNumber precision={2} addonAfter={"元"} readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="结账单数"
                name="setCounts">
                <InputNumber readOnly ></InputNumber>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="未结账单数"
                name="uncompletedCounts">
                <InputNumber readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="客人人数"
                name="totalHeadCount">
                <InputNumber readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="原金额"
                name="totalMoney">
                <InputNumber precision={2} addonAfter={"元"} readOnly ></InputNumber>
              </Form.Item>
            </Col>



            <Col span={12}>
              <Form.Item
                label="折扣金额"
                name="totalAgioMoney">
                <InputNumber precision={2} addonAfter={"元"} readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="服务费"
                name="totalServiceCharge">
                <InputNumber precision={2} addonAfter={"元"} readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="应交金额"
                name="totalShouldMoney">
                <InputNumber precision={2} addonAfter={"元"} readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="实交金额"
                name="totalRealMoney">
                <InputNumber precision={2} addonAfter={"元"} readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="赠单单数"
                name="giftCounts">
                <InputNumber readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="赠送金额"
                name="giftTotalMoney">
                <InputNumber precision={2} readOnly ></InputNumber>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="退款金额"
                name="totalRefundAmount">
                <InputNumber precision={2} readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="实入金额"
                name="totalInAmount">
                <InputNumber precision={2} readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="涉及退款单数"
                name="refundCounts">
                <InputNumber readOnly ></InputNumber>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="实际完成单数"
                name="actualCounts">
                <InputNumber readOnly ></InputNumber>
              </Form.Item>
            </Col>

         

            <Col span={24}>
              <Form.List
                name={"findData"}

              >
                {(fields, { add, remove }) => {

                  return (
                    <div>
                      {fields.map(({ key, name, ...restField }) => {

                        return (
                          <div style={{ display: 'flex', marginBottom: 8, alignItems: "center" }}>
                            <Form.Item
                              label={formListData[name] ? formListData[name].paymentMethodName : ""}
                              name={[name, 'payAmount']}

                            >
                              <InputNumber precision={2} readOnly ></InputNumber>
                            </Form.Item>

                            <Form.Item
                              label="支付次数"
                              name={[name, 'payCounts']}

                            >
                              <InputNumber readOnly ></InputNumber>
                            </Form.Item>

                            <Form.Item
                              label="原路退"
                              name={[name, 'refundAmount']}

                            >
                              <InputNumber readOnly ></InputNumber>
                            </Form.Item>

                            <Form.Item
                              label="次数"
                              name={[name, 'refundCounts']}

                            >
                              <InputNumber readOnly ></InputNumber>
                            </Form.Item>

                            <Form.Item
                              label="现金退"
                              name={[name, 'cashRefundAmount']}

                            >
                              <InputNumber readOnly ></InputNumber>
                            </Form.Item>


                            <Form.Item
                              label="次数"
                              name={[name, 'cashRefundCounts']}

                            >
                              <InputNumber readOnly ></InputNumber>
                            </Form.Item>
                          </div>
                        )
                      })}


                    </div>
                  )
                }
                }
              </Form.List>
            </Col>




          </Row>
        </Form>




      </AntDraggableModal>
    </>
  )
}
