import React, { useEffect, useRef } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AliVoiceEdit from './AliVoiceEdit';
import { Switch } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification';
import systemConfig from '../../../config/systematic';

export default function AliVoice(props: any) {

    const ref = useRef<any>(undefined);

    /** api服务id */
    const apiId = 'aliVoice';

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const onRefresh = (status: Boolean) => {
        if (status === true) {
            const currentData: initDataType = {
                apiId,
            }
            ref.current.initData(currentData);
        }
    }

    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {

        const bodys = {
            apiId: "aliVoice",
            apiVariable: id,
            apiExtend: "updateSingle",
            apiData: {
                columnName,
                isValue
            }
        }
        fetchApi.PUT(wmService, bodys).then(res => {
            antMessage('success', '编辑成功', 'success');
            onRefresh(true);
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    //表头
    const columnsHead = [
        {
            title: 'voiceName',
            dataIndex: 'voiceName',
            // render: (text: any, record: any) =>
            //     <div>{record.voiceId}</div>
        },
        {
            title: 'voiceId',
            dataIndex: 'voiceId',
            // render: (text: any, record: any) =>
            //     <div>{record.voiceId}</div>
        },
        {
            title: '语种',
            dataIndex: 'languageTypeName',
            // render: (text: any, record: any) => {
            //     // console.log("text",text);
            //     // console.log("record",record);
            //     return (
            //         <div>{record.languageTypeName}</div>
            //     )
            // }
        },
        {
            title: '连接状态',
            dataIndex: 'isConnect',
            render: (text: any, record: any) => (
                <Switch checkedChildren="在线" unCheckedChildren="离线"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isConnect', e)}
                />
            )
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '阿里Voice',
        apiId,
        columns,
        // showImage: true,
        // showUploadFile: true,
        showStates: true,
        pagination: true,
        EditComponent: AliVoiceEdit,
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
        </>
    )
}