import { useEffect, useRef } from 'react'
import CallerCustomerEdit from './CallerCustomerEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { useLocation } from 'react-router';
import moment from 'moment';
import { Tooltip } from 'antd';

export default function Area(props: any) {
  /** api服务id */
  const apiId = 'callerCustomer';
  const ref = useRef<any>(undefined);
  const { state } = useLocation();

  //表头
  const columnsHead = [
    {
      title: "id",
      dataIndex: "id",
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text)
          : ''
      }</Tooltip>
    },
    {
      title: "公司名称",
      dataIndex: "companyName"
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
    },
    {
      title: '称呼',
      dataIndex: 'appellationName',
    },
    {
      title: '生日',
      dataIndex: 'birthday',
      render: (val: moment.MomentInput) =>
        <span>
          {moment(val).format('YYYY-MM-DD')}
        </span>
    },

    {
      title: '分店',
      dataIndex: 'branchStore',
      render: (text: any) => (text ? text.branchStoreName : "")
    },
    {
      title: '地址',
      dataIndex: 'address',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 8 ? text.substring(0, 8) + '......' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '邮编',
      dataIndex: 'postcode',
    },
    {
      title: '特征',
      dataIndex: 'features',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 8 ? text.substring(0, 8) + '......' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '喜好',
      dataIndex: 'preferences',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 8 ? text.substring(0, 8) + '......' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '备注',
      dataIndex: 'memos',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 8 ? text.substring(0, 8) + '......' : text)
          : ''
      }</Tooltip>
    },
  ];
  useEffect(() => {
    if (ref.current) {
      if (state) {
        const { phoneNumber, system, operation } = state as any;
        if (system === "wdm") {
          if (operation === "callerCustomerEdit") {
            if (phoneNumber) {
              ref.current.handleEdit({ id: phoneNumber });
            }
          }

        }

      }
      // eslint-disable-next-line react-hooks/exhaustive-deps

    }
    const fetchData = async () => {
    }
    fetchData();

  }, []);
  const columns = columnsHead;

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '电话客户',
    apiId,
    columns,
    showStates: true,
    pagination: true,
    EditComponent: CallerCustomerEdit,
    mountDataOptions:{
      userMount:{
         isDept:true,
      }
 },

  };

  return (
    <WebUniversal props={extendProps} ref={ref} />
  )
}

