import { useEffect, useRef, useState } from 'react';
import EditComponent from './AssetCardEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button, Checkbox, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import fetchApi from '../../../utils/api/fetchApi';
import AntdNotification from '../../../utils/extend/AntdNotification';
import arrayHandle from '../../../utils/baseLib/arrayHandle';

const WebComponent = (props: any) => {
    const apiId = 'assetCard';
    const { closeModal, data } = props;
    const { assetStatementId } = data;

    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    const [modalVisibleWxPayOrderEdit, setModalVisibleWxPayOrderEdit] = useState(false);
    const [modalData, setModalData] = useState({});
    /** 默认后台service */
    const [defaultService, setDefaultService] = useState<any>();
    /** 刷新 */
    const [initData, setInitData] = useState<any>();
    /** 标签打印数据 */
    const [labelPrintingData, setLabelPrintingData] = useState<any>([]);
    //子组件回调函数
    const onVisibleModalWxPayOrderEdit = (state: boolean) => {
        setModalVisibleWxPayOrderEdit(state);
    }

    useEffect(() => {
        if (ref.current) {
            setDefaultService(ref.current.defaultService);
            setInitData(() => ref.current.initData);
        }
        // const fetchData = async () => {
        // };
        // fetchData();
    }, []); //初始化数据

    /**
     * 标签打印数据处理
     * @param id 
     * @param checked 
     */
    const labelPrintingCheckedHandle = (id: string, checked: boolean) => {
        let oldRow = labelPrintingData;
        let row: Array<any> = [];
        if (checked === true) {
            row = row.concat({
                id
            });
            row = arrayHandle.merge(oldRow, row) as Array<any>;
        } else {
            row = arrayHandle.deleteValue(oldRow, 'id', id);
        }
        setLabelPrintingData(row);
    };

    /**
     * 打印标签
     */
    const printLabelHandle = () => {
        fetchApi.POST(defaultService, {
            apiId: 'assetCard',
            apiExtend: 'printLabel',
            apiData: labelPrintingData
        }).then(res => {
            const { success } = res;
            if (success === true) {
                AntdNotification('info', '打印成功');
            }
        })
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '资产',
            dataIndex: 'assetStatement',
            render: (text: any) => (text ? text.aliasName : "")
        },
        {
            title: '序列号',
            dataIndex: 'serialNumber',
            sorter: true
        },
        {
            title: '数量',
            dataIndex: 'amount'
        },
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '部门',
            dataIndex: 'dept',
            render: (text: any) => (text ? text.deptName : "")
        },
        {
            title: '购置日期',
            dataIndex: 'purchaseDate',
            render: (val: dayjs.ManipulateType) => <span>{dayjs(val).format('YYYY-MM-DD')}</span>,
        },
        {
            title: '资产状态',
            dataIndex: 'assetStatus',
            render: (text: any) => (text ? text.assetStatusName : "")
        },
        {
            title: <Tooltip placement="topLeft" title={'打印小标签'}>
                <Button onClick={() => {
                    printLabelHandle();
                }}>打印</Button>
            </Tooltip>,
            dataIndex: 'id',
            render: (text: any) => (
                <Checkbox onChange={(e) => { labelPrintingCheckedHandle(text, e.target.checked) }}></Checkbox>
            )
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '资产卡',
        apiId,
        columns,
        showStates: true,
        showQuery: false,
        showAdd: true,
        sendPageData: { assetStatementId },
        pagination: true,
        EditComponent
    };

    return (
        <>
            <AntDraggableModal
                title={'资产卡'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={closeModal}
                closable={false}
                maskClosable={false}
                width={1080} >
                <WebUniversal props={extendProps} ref={ref} />
            </AntDraggableModal>
        </>
    )
}

export default WebComponent;