import React, { useEffect, useRef, useState } from 'react'
import { columnsheadId } from "../universal/columnshead";

import WebUniversalNewEx, { WUExtendPropsType } from '../system/WebUniversalNewEx';
import moment from 'moment';
import { Button } from 'antd';
import DownloadExcel from '../report/DownloadExcel';
import DownloadExcel1 from '../report/DownloadExcel1';
export default function SuPurchaseApplication(props: any) {
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    const apiId="suPurchaseApplication"
    /** 刷新数据 */
    let initData: any;
    let wmService;
    const [refreshValue, setRefreshValue] = useState(0)
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // handleChangeOne = ref.current.handleChangeOne;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // 
        }
        if (refreshValue !== 0) {
            initData()
        }
    }, [])
    //表头
    const columnsHead = [
        {
            title: '供应商',
            dataIndex: 'supplierName',
        },
        {
            title:"下载",
            render:(text:any,record:any)=>{
                
              return  (
                <Button onClick={()=>DownloadExcel(record,apiId)}>下载EXCEL文件</Button>
            )
            }
        }

    ];
  
    const columns = columnsheadId
        .concat(columnsHead as []);
    /** 子表头内容 */
    const expandableColumnsHead = [
        {
            title: "部门",
            dataIndex: 'dept',
            render: (text: any, record: any) => (text ? text.deptName : "")
        },
        {
            title: '产品名称',
            dataIndex: 'product',
            render: (text: any, record: any) => (text ? text.productName : "")
        },
        {
            title:"单位",
            dataIndex:"product",
            render: (text: any, record: any) => (text ? text.productUnit.productUnitName : "")
        },
        {
            title: '购买次数',
            dataIndex: "payCount",
        },
        {
            title: "备注",
            dataIndex: "memo",
        },
        {
            title: "申购时间",
            dataIndex: "sysDate",
            render: (text: any, record: any) => <span>
                {moment(text.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </span>
        }

    ]
    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '供应商申购单',
        apiId,
        showStates: false,
        disabledAdd: true,
        showOperation: false,
        showPrint: true,
        showAdd: false,
        pagination: false,
        columns,

        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'depts',
    };
    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>

    )
}
