import AssetStatementEdit from './AssetStatementEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button } from 'antd';
import { useState } from 'react';
import AssetCard from './AssetCard';
import AssetStatementUploadFile from './AssetStatementUploadFile';

//组件 
const WebComponents = (props: any) => {

    /** api服务id */
    const apiId = 'assetStatement';
    const [modalData, setModalData] = useState({});
    const [modalVisibleAssetCard, setModalVisibleAssetCard] = useState(false);

    //子组件回调函数
    const onVisibleModalAssetCard = (state: boolean) => {
        setModalVisibleAssetCard(state);
    };
    //表头
    const columnsHead = [
        {
            title: '子类别',
            dataIndex: 'assetSubcategory',
            render: (text: any) => (text ? text.subcategoryName : "")
        },
        {
            title: '资产',
            dataIndex: 'product',
            render: (text: any) => (text ? text.productName : "")
        },
        {
            title: '别名',
            dataIndex: 'aliasName'
        },
        {
            title: '识别号',
            dataIndex: 'internalId',
            sorter: true
        },
        {
            title: '排序',
            dataIndex: 'sortBy'
        },
        {
            title: '',
            dataIndex: 'id',
            render: (text: any, record: any) => (
                <Button type="primary" onClick={
                    () => {
                        setModalData({
                            assetStatementId: record.id
                        });
                        setModalVisibleAssetCard(true);
                    }
                }>{'资产卡'}</Button>
            )
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '资产表',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        showOperation: true,
        showUploadFile: true,
        showImage: true,
        arrayFilesName: 'assetStatementFiles',
        UploadFileComponent: AssetStatementUploadFile,
        EditComponent: AssetStatementEdit,
        mountDataOptions:{
            userMount: {
                isDept:true,
            }
           },
    };

    return (
        <>
            <WebUniversal props={extendProps} />
            {modalVisibleAssetCard ? (<AssetCard closeModal={() => onVisibleModalAssetCard(false)} data={modalData} />) : null}
        </>
    )
}

export default WebComponents;
