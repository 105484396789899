import React, { useContext, useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType, initDataType } from '../../../system/WebUniversalNewEx';
import { Button, Popconfirm, Space, Switch, Tooltip } from 'antd';
import fetchApi from '../../../../utils/api/fetchApi';
import system from "../../../../config/systematic";
import MenuSpeSpecInventoryEdit from './MenuSpeSpecInventoryEdit';
import InventoryToEstimate from './InventoryToEstimate';
import { SocketContext } from '../../../../App';


export default function MenuSpeSpecInventory(props: any) {
  const ref = useRef<any>(undefined);
  const apiId = "menuSpeSpecInventory";
  /**后端数据wmService */
  const wmService = system.wmService;
  const [isZero, setIsZero] = useState<boolean>(false);
  const [inventoryToEstimate, setInventoryToEstimate] = useState(false);
  const [estimateTransferData, setEstimateTransferDat] = useState<any>();
  const useContextData = useContext(SocketContext);
  const { contextData,setContextData } = useContextData;

  useEffect(() => {
    if (contextData?.currentBranchStoreId) {
      if (contextData?.refresh) {
        onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
      }
    }

  }, [contextData?.currentBranchStoreId])
  //表头
  const columnsHead = [

    {
      title: '菜品',
      dataIndex: 'branchStoreMenuWarehouseSpecSpecial',
      render: (text: any) => (text ? text.branchStoreMenuWarehouseSpec.branchStoreMenuWarehouse.foodName : "")
    },
    {
      title: '特殊规格',
      dataIndex: 'branchStoreMenuWarehouseSpecSpecial',
      render: (text: any) => (text ? text.branchStoreMenuWarehouseSpecSpecialName : "")
    },
    {
      title: '数量(库存)',
      dataIndex: 'quantity',
    },

  ];
  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '菜品特殊规格库存',
    apiId,
    // showAdd: false,
    columns,
    pagination: true,
    EditComponent: MenuSpeSpecInventoryEdit,
    sendPageData: { isZero, branchStoreId: contextData?.currentBranchStoreId },
    mountDataOptions: {
      userMount: {
        isDept: true,
      }
    },
    showLogger: {
      logger: { "id": "opLogger" },
      loggerDisplay: true,
      loggerSingleDisplay: true,
      // isScope: true,
      "loggerQueryTypeExtendId": contextData?.currentBranchStoreId,
    }

  };
  const onRefresh = (item: any) => {

    const { branchStoreId } = item
    // setSbId(null)
    // setRoomIds([])
    const currentData: initDataType = {
      apiId,
      sendPageData: { isZero, branchStoreId }
    }
    if (ref.current) {
      ref.current.initData(currentData)
      setContextData({ ...contextData, refresh: false })
    }
  }
  const cleanMenuInventory = () => {
    fetchApi.POST(wmService, {
      apiId,
      apiExtend: "cleanMenuInventory",
      apiData: {
        branchStoreId: contextData?.currentBranchStoreId,
        mountDataOptions: {
          userMount: {
            isDept: true,
          }
        }
      }
    }).then((res) => {
      if (res.success) {
        const currentData: initDataType = {
          apiId,
          sendPageData: { isZero, branchStoreId: contextData?.currentBranchStoreId }
        }
        ref.current.initData(currentData)
      }


    }).catch((err) => {
      console.log("err", err);

    })
  }
  // 沽清穿梭框处理
  const handleEstimateTransfer = () => {

    setEstimateTransferDat({
      apiId: "menuSpeSpecInventory",
      apiExtend: "estimateToNoEstimate",
      name: "branchStoreMenuWarehouseSpecSpecialName",
      transferTitle: ["待沽清列表", "已(即将)沽清列表"]
    });
    setInventoryToEstimate(true);
  };

  //关闭穿梭框页面
  const onCloseEstimateTransfer = async (status: boolean) => {
    setInventoryToEstimate(false);
    if (status) {
      const currentData: initDataType = {
        apiId,
        sendPageData: { isZero, branchStoreId: contextData?.currentBranchStoreId }
      }
      ref.current.initData(currentData)
    }
  }
  /**筛选是否库存为0的数据 */
  const changeIsZero = (e: any) => {
    setIsZero(e)
    // setCateringOrderModeData(e);
    const currentData: initDataType = {
      apiId,
      sendPageData: { isZero: e,branchStoreId: contextData?.currentBranchStoreId }
    }
    ref.current.initData(currentData)
  }
  return (
    <>
     
      <Space>
        <Popconfirm title="是否清空库存?" onConfirm={cleanMenuInventory}>
          <Button danger >清空库存</Button>
        </Popconfirm>
        <Button type="primary" onClick={handleEstimateTransfer}>沽清操作</Button>
        <Tooltip placement="top" title={"此按钮用于筛选库存为零的数据"}>  < Switch onChange={changeIsZero} checked={isZero}
          checkedChildren="是"
          unCheckedChildren="否" /></Tooltip>
      </Space>
      <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>

      {inventoryToEstimate
        ? (<InventoryToEstimate
          closeModal={onCloseEstimateTransfer} data={estimateTransferData} />)
        : null}
    </>
  )
}
