import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';

const { TextArea } = Input;
export default function QrCodeEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);
       


    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            // fetchApi.GET(wmService, {
            //     apiId: 'generalData',
            //     apiVariable: 'languageType',
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setLanguageTypeData(res.data);
            //     }
            // });
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'qrCode'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '二维码类型'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input  disabled/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="扩展名"
                                name="qrExtend"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="扩展变量"
                                name="qrVariable"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        
                        <Col span={24}>
                            <Form.Item
                                label="扩展数据"
                                name="qrData">
                                <TextArea
                                    placeholder="扩展数据"
                                    autoSize={{ minRows: 10, maxRows: 14 }}
                                />
                            </Form.Item>
                        </Col>
                       
                        <Col span={12}>
                            <Form.Item
                                label="加密"
                                name="qrEncrypt"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}