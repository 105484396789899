import React, { useContext, useEffect, useRef } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AdEdit from './AdEdit';
import dayjs from 'dayjs';
import AdUploadFile from './AdUploadFile';
import { Switch } from 'antd';
import { SocketContext } from '../../../App';

export default function Ad(props: any) {

    const ref = useRef<any>(undefined);

    /** api服务id */
    const apiId = 'ad';
    
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // handleChangeOne = ref.current.handleChangeOne;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                branchStoreId
            }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                if (record.branchStore) {
                    return (
                        <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                    )
                }
            }
        },
        {
            title: '广告名称',
            dataIndex: 'adName',
        },
        {
            title: '广告类型',
            dataIndex: 'adTypeName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.adTypeName}</div>
                )
            }
        }, {
            title: '是否共用',
            dataIndex: 'isPublic',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '是否弹窗',
            dataIndex: 'isPopup',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            render: (text: any, record: any) => {
                return (
                    <div>{dayjs(record.startTime).format("YYYY-MM-DD")}</div>
                )
            }
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            render: (text: any, record: any) => {
                return (
                    <div>{dayjs(record.endTime).format("YYYY-MM-DD")}</div>
                )
            }
        },
    ];

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: ''
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '广告',
        apiId,
        columns,
        showQuery: false,
        showImage: true,
        showUploadFile: true,
        showStates: true,
        pagination: true,
        arrayFilesName: 'adFiles',
        EditComponent: AdEdit,
        UploadFileComponent: AdUploadFile,
        // sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
        sendPageData: { branchStoreId: contextData?.currentBranchStoreId },
        extendObj: {
            key: "adTypeId",
            values: 'pic'
        },
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
        // sendPageData: { 
        //     menuId: data.menuId,
        //     menuWarehouseId: data.menuWarehouseId
        // }
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
        </>
    )
}