import { Table, Button, Space, Input, Tooltip, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import { InitData } from '../../store/system/showPage';
import fetchApi from '../../utils/api/fetchApi';
import antMessage from '../../utils/extend/AntdNotification';
import WebBehaviors, { behaviorsDataType, deleteButtonConfigType } from './behaviors/WebBehaviorsNew';
import system from '../../config/systematic';
import dayjs from 'dayjs';
import WebUniversalLogger from './WebUniversalLogger';
import { mountDataOptionsType } from '../../types/general';
import { loggerType } from './logger/Logger';

/** serviceIdType */
// export declare type serviceIdType = 'bm' | 'pay' | 'message';

// export const getService = (serviceId: string) => {
//     /** 默认service */
//     var defaultService = systematic.bmService
//     if (serviceId) {
//         switch (serviceId) {
//             case 'pay':
//                 defaultService = systematic.payService
//                 break;
//             case 'message':
//                 defaultService = systematic.messageService
//                 break;
//         }
//     }
//     return defaultService;
// };
/** WebUniversal ExtendProps数据类型 */
export declare type WUExtendPropsType = {
    /** 初始化时显示数据，默认：true */
    isInitialDisplayData?: boolean,
    /** 图片数据数组名称 */
    arrayFilesName?: string,
    /**是否需要交预定金 */
    showDeposit?: boolean,
    /**是否显示发送选项 */
    showSend?: boolean,
    /**是否默认 */
    showDefault: boolean,
    /**是否自动生成 */
    showAutomatic?: boolean,
    /** 编辑组件 */
    EditComponent?: any,
    /**插入组件 */
    InsertComponent?: any
    /**批量更新组件 */
    UnDateComponent?: any
    /** 文件上传组件 */
    UploadFileComponent?: any,
    /** 标题 */
    titleName: string,
    /** api服务id */
    apiId: string,
    /** 主键名称 */
    primaryKeyName?: string,
    /** 是否显示文件上传按键 */
    showUploadFile?: boolean,
    /** 是否显示增加键 */
    showAdd?: boolean,
    /** 是否显示查询 */
    showQuery?: boolean,
    /** 查询的提示信息 */
    queryTitle?: string,
    /** 是否显示图片 */
    showImage?: boolean,
    /** 是否显示状态 */
    showStates?: boolean,
    /** 是否显示预定状态*/
    showReserve?: boolean,
    /** 是否显示操作区域 */
    showOperation?: boolean,
    /** 是否显示删除键 */
    showDelete?: boolean,
    /** 是否显示编辑键 */
    showEdit?: boolean,
    /** 是否显示打印键 */
    showPrint?: boolean,
    /** 显示日期*/
    showDate?: boolean
    /** 是否禁用增加键 */
    disabledAdd?: boolean,
    /** 是否禁用上传键 */
    disabledUpload?: boolean,
    /** 表头 */
    columns: Array<any>,
    /** 扩展参数 */
    extendedParameter?: any,
    /** 页面传送其它数据 */
    sendPageData?: any,
    /** 是否有子表格？
     * （表格内容较多，如参数expandableDataName：undefined时，可分两行展示）
     */
    isExpandable?: boolean,

    // expandableRowSelection?: TableRowSelection<any>,

    expandableRowSelection?: any,

    /**是否有子子表格 */
    // isIsExpandable?: boolean,
    /** 子子表格表头 */
    // isExpandableColumns?: Array<any>,
    /** 子表格表头 */
    expandableColumns?: Array<any>,
    /** 子子表格数据数组对象名称，当为undefined时，如表格内容较多，可分两行展示 */
    // isExpandableDataName?: any
    /** 子表格数据数组对象名称，当为undefined时，如表格内容较多，可分两行展示 */
    expandableDataName?: any,
    /** 总结栏 */
    summaryRender?: any,
    /** 是否展开所有数据栏 */
    // isExpandAllRows?: boolean,
    /** 删除按钮配置 */
    deleteButtonConfig?: deleteButtonConfigType,
    /** 删除按钮中的checkBox为true时执行方法(handleDelete执行之前执行) */
    beforeHandleDeleteCheckBox?: Function,
    /** 删除按钮中的checkBox为true时执行方法(handleDelete执行成功后才执行) */
    afterHandleDeleteCheckBox?: Function,
    /** 覆盖删除方法 */
    coverHandleDelete?: Function,
    /**批量插入 */
    bulkInsert?: boolean,
    /**批量更新 */
    bulkUpdate?: boolean
    pagination: boolean,
    extendIdName?: string,
    extendObj?: any,
    /** 显示日志按钮 */
    showLogger?: {
        /** 日志显示 */
        loggerDisplay?: boolean,
        /** 单个数据日志显示 */
        loggerSingleDisplay?: boolean,
        /** 日志 */
        logger?: loggerType,
        /** 日志查询类型 */
        loggerQueryType?: string,
        /** 日志查询类型扩展Id */
        loggerQueryTypeExtendId?: string,
        /**查询范围限制 */
        isScope?: boolean,
    },
    mountDataOptions?: mountDataOptionsType
};

/** 刷新数据的类型 */
export declare type initDataType = {
    /** 服务id */
    apiId?: string,
    /** 页面传送的数据 */
    sendPageData?: any
};

//Redux-React
const mapStateToProps = (state: {}) => {
    return {
        ...state,
    };
};
const mapDispatchToProps = { InitData }

/**
 * 通用Web组件 WebUniversal 
 * @param props 参数对象
 * @param ref 返回的对象
 * @returns 
 */
let WebUniversal = (props: any, ref: any) => {
    let extendProps: WUExtendPropsType;
    extendProps = props.props;
    let {
        isInitialDisplayData,
        arrayFilesName,
        EditComponent,
        InsertComponent,
        UnDateComponent,
        UploadFileComponent,
        titleName,
        apiId,
        primaryKeyName,
        showUploadFile,
        showAdd,
        showQuery,
        queryTitle,
        showImage,
        showStates,
        showReserve,
        showOperation,
        showDelete,
        showEdit,
        showPrint,
        disabledAdd,
        disabledUpload,
        sendPageData,
        extendedParameter,
        isExpandable,
        summaryRender,
        pagination,
        showDeposit,
        showAutomatic,
        showDefault,
        showSend,
        // isExpandAllRows,
        showDate,
        deleteButtonConfig,
        beforeHandleDeleteCheckBox,
        afterHandleDeleteCheckBox,
        coverHandleDelete,
        bulkInsert,
        bulkUpdate,
        extendIdName,
        extendObj,
        showLogger,
        mountDataOptions,
        expandableRowSelection,
    } = extendProps;
    if (typeof isInitialDisplayData === 'undefined') {
        isInitialDisplayData = true;
    }
    if (typeof showLogger === 'undefined') {
        showLogger = {};
    }
    const { loggerDisplay, loggerSingleDisplay, logger, loggerQueryType, loggerQueryTypeExtendId, isScope } = showLogger;
    const [isLoggerQuery, setIsLoggerQuery] = useState(true);
    const [modalLogger, setModalLogger] = useState(false);
    const [loggerQueryId, setLoggerQueryId] = useState<string>();
    const [currentQueryString, setCurrentQueryString] = useState<string>();

    /** 后端数据wmService */
    const wmService = system.wmService;
    /** 子表格表头 */
    const [expandableColumnsData, setExpandableColumnsData] = useState<any>();

    /** 子表格数据数组对象名称*/
    const expandableDataName = extendProps.expandableDataName;

    //子表格数据
    const [expandedData, setExpandedData] = useState<any>();

    // 黑白样式的数据
    const [cssLightData, setCssLightData] = useState<any>();

    const [cssDarkData, setCssDarkData] = useState<any>();
    //记录点击黑白切换的次数
    const [clickNumData, setClickNumData] = useState(0);

    /**
     * 获取子表格内容
     * @param item row
     * @returns 
     */
    const getExpandedRowRender = (item: any) => {

        if (typeof expandedData === 'undefined') {
            return undefined;
        }

        // TableRowSelection
        //合并新旧对象
        let expandedDataMerge = { ...expandedData, [item.id]: item[expandableDataName] }

        let rowSelection = expandableRowSelection ? {
            ...expandableRowSelection,
            onChange: expandableRowSelection?.onChange?.bind(props, item.id)
        } : undefined
        return (
            <Table
                columns={expandableColumnsData}
                rowSelection={rowSelection}
                rowKey={row => (primaryKeyName ? row[primaryKeyName] : row.id)}
                pagination={false}
                dataSource={
                    expandedDataMerge[item.id] ?
                        expandedDataMerge[item.id].constructor === Array ?
                            expandedDataMerge[item.id] :
                            [expandedDataMerge[item.id]]
                        : undefined}
            />
        )
    }

    /**
     * 展开时触发的回调
     * @param status 是否展开状态
     * @param item 当前一条数据
     * @returns 
     */
    const onExpandClick = (status: any, item: any) => {

        if (!status) return
        //传入相关数据，如果expandableDataName存在，则传入相关数据，
        //否则传入当前数据（用于当表格内容较多不能一次性完全展示）
        setExpandedData({
            ...expandedData,
            [item.id]: expandableDataName
                ? item[expandableDataName]
                : item
        })
    };

    // 参数
    const { InitData, showPageData } = props;

    /** 初始值 */
    let data: any = {
        pageSize: 10,
        current: 1,
        queryString: undefined,
        total: 0,
        rows: undefined,
        extendData: {},
    };

    try {
        if (typeof showPageData[apiId] !== "undefined") {

            data = showPageData[apiId].data;
        }
    } catch { }

    let { pageSize,
        current,
        total } = data;
    /** 数据集合 */
    let rows = data.rows;

    const [modalopen, setModalopen] = useState(false);
    const [insertModalopen, setInsertModalopen] = useState(false);
    const [updataModalopen, setUpdataModalopen] = useState(false);
    const [modalUploadopen, setModalUploadopen] = useState(false);
    const [insertModalData, setInsertModalData] = useState({})

    const [modalData, setModalData] = useState({});
    const [queryInput, setQueryInput] = useState(showPageData.queryString);
    const [uploadSendData, setUploadSendData] = useState<any>();
    /** 页面数据刷新状态 */
    let loading = false;
    let initialState = {
        apiId
    } as any;

    /** 初始化状态 */
    const initStartData = () => {
        if (typeof apiId !== "undefined") {
            initialState[apiId] = {
                data: {
                    current: 1,
                    pageSize: 10,
                    queryString: '',
                    orderByString: ''
                },
                fetchUrl: wmService,
                sendPageData,
                mountDataOptions,
            };
        }
    };

    if (total === 0) { rows = undefined };

    useEffect(() => {
        if (isInitialDisplayData === true) {
            initStartData();
            InitData(initialState);
        }
        //设置子表格表头
        setExpandableColumnsData(extendProps.expandableColumns)

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /**
     * 改变单一项数据
     * @param id 主键id
     * @param columnName 字段名称，例如：isUse，isCheck...
     * @param value 所要改变的内容
     * @param apiId 接口名称
     * @returns 
     */
    const handleChangeOne = async (id: any, columnName: string, value: boolean, apiId: string) => {
        const bodys = {
            apiId: apiId,
            apiVariable: id,
            apiExtend: 'updateOne',
            apiData: {
                columnName,
                value,
                branchStoreId: localStorage.getItem("currentBranchStoreId"),
                mountDataOptions: {
                    userMount: {
                        isUserLogin: true
                    }
                }
            }
        };
        fetchApi.PUT(wmService, bodys).then((res) => {
            if (res.success === true) {
                antMessage('success', 'updateOne编辑成功',
                    'apiId：' + bodys.apiId + '，columnName：' +
                    bodys.apiData.columnName + '，value：' + bodys.apiData.value);
                InitData({
                    ...showPageData,
                });
                return true;
            }
        }).catch((err) => {
            antMessage('error', '编辑失败', err.message);
            return false;
        })
    };

    const handleChangeDefault = async (record: any, apiId: string) => {
        const bodys = {
            apiId: apiId,
            apiExtend: 'changeDefault',
            apiData: {
                id: record.id,
                [extendIdName!]: record[extendIdName!],
                extendIdName,
                branchStoreId: localStorage.getItem("currentBranchStoreId")
            }
        };

        fetchApi.PUT(wmService, bodys).then((res) => {
            if (res.success === true) {
                antMessage('success', 'changeDefault编辑成功',
                    'apiId：' + bodys.apiId + '，columnName：' +
                    bodys.apiData.columnName + '，value：' + bodys.apiData.value);
                InitData({
                    ...showPageData,
                });
                return true;
            }
        }).catch((err) => {
            antMessage('error', '编辑失败', err.message);
            return false;
        })
    };
    //ref 可使用WebUniversal(父)组件函数
    useImperativeHandle(ref, () => (
        {
            /**
             * 更改一项数据
             * @param id 
             * @param columnName 
             * @param value 
             * @param urlName 
             * @returns 
             */
            handleChangeOne: (id: any, columnName: string, value: boolean, urlName: string) =>
                handleChangeOne(id, columnName, value, urlName),
            /**
             * 刷新数据
             * @param currentData 
             * @returns 
             */
            initData: (currentData: initDataType) => {
                if (isInitialDisplayData === false) {
                    initStartData();
                    showPageData[apiId] = initialState[apiId];
                }
                queryData(currentQueryString, { isDisableInitData: true });
                if (typeof currentData !== 'undefined') {
                    const currentApiId = currentData.apiId;
                    const currnetSendPageData = currentData.sendPageData;
                    if (typeof currentApiId !== 'undefined') {
                        showPageData.apiId = currentApiId;
                        if (typeof currnetSendPageData !== 'undefined') {
                            if (typeof showPageData[currentApiId] !== 'undefined') {
                                showPageData[currentApiId].sendPageData = currnetSendPageData;
                            }
                        }
                    }
                }
                InitData({
                    ...showPageData,
                })
            },
            /**
             * 传入子表格表头
             * @param exColumns 子表格表头
             */
            setExpandableColumns: (exColumns: any) => {
                setExpandableColumnsData(exColumns);
            },
            /**
             * 更改日志参数
             * @param options 选项
             */
            changeLogger: (options: {
                /** 日志查询类型，如为undefined时不更改 */
                loggerQueryType?: string,
                /** 是否更改loggerQueryTypeExtendId */
                isChangeLoggerQueryTypeExtendId?: boolean,
                /** 日志查询类型扩展id */
                loggerQueryTypeExtendId?: string
            }) => {
                const { loggerQueryType, isChangeLoggerQueryTypeExtendId, loggerQueryTypeExtendId } = options;
                if (typeof loggerQueryType !== 'undefined') {
                    showLogger.loggerQueryType = loggerQueryType;
                }
                if (isChangeLoggerQueryTypeExtendId === true) {
                    showLogger.loggerQueryTypeExtendId = loggerQueryTypeExtendId;
                }
            },
            /** 后端数据wmService */
            wmService,
            defaultService: wmService,
            handleNew: () => openModal('NEW', ''),
            openModal,
            handleEdit,
            queryData,
        }));

    /**
     * 删除动作
     * @param record 记录集合
     * @param isChecked checkBox checked
     * @returns 
     */
    const handleDelete = async (record: any, isChecked: boolean) => {

        if (typeof coverHandleDelete !== 'undefined') {
            coverHandleDelete(record, isChecked);
            return
        }
        let id = record.id;
        if (typeof primaryKeyName !== "undefined") {
            id = record[primaryKeyName]
        }
        if (isChecked === true) {
            if (typeof beforeHandleDeleteCheckBox !== 'undefined') {
                await beforeHandleDeleteCheckBox(record);
            }
        }
        fetchApi.DELETE(wmService, {
            apiId,
            apiVariable: id,
            apiExtend: "remove",
            apiData: {
                sendPageData,
                mountDataOptions: {
                    userMount: {
                        isUserLogin: true
                    }
                },
            }
        }).then(async res => {
            if (res.success === true) {
                if (isChecked === true) {
                    if (typeof afterHandleDeleteCheckBox !== 'undefined') {
                        await afterHandleDeleteCheckBox(record);
                    }
                }
                antMessage('success', '删除成功', 'success');
                //刷新表格内容
                InitData({
                    ...showPageData,
                });
            }
            else {
                antMessage('error', '删除失败', 'error');
            }
        }).catch(err => {

            antMessage('error', '删除失败', err.message + '，[' + err.errors.status + ']');
        })
    };

    //编辑
    const handleEdit = (recordData: any) => {
        let id = recordData.id;
        if (typeof primaryKeyName !== "undefined") {
            id = recordData[primaryKeyName]
        }
        openModal('EDIT', id);
    };

    //文件上传
    const hanldUpload = async (e: any) => {
        setUploadSendData({
            id: e,
            disabledUpload,
            extendObj
            // handleChangeOne,
        })
        setModalUploadopen(true);
    };

    //关闭上传
    const onCloseUpload = (state: boolean) => {
        setModalUploadopen(false);
        // if (state === true) {
        //刷新表格内容
        InitData({
            ...showPageData,
        });
        // }
    };

    //排序
    const handleSort = (sort: any) => {
        let orderByString = '';
        switch (sort.order) {
            case 'descend':
                orderByString = sort.field + '@DESC'
                break;
            case 'ascend':
                orderByString = sort.field + '@ASC'
                break;
            default:
                break;
        }
        //刷新表格内容
        if (loading === false) {
            initialState[apiId] = {
                data: {
                    orderByString,
                },
            };
            InitData(initialState);
            loading = true;
        }
    };

    let behaviorsData: behaviorsDataType = {
        showImage,
        showUploadFile,
        showStates,
        showOperation,
        showDeposit,
        showAutomatic,
        showDelete,
        showEdit,
        showDefault,
        hanldUpload,
        handleDelete,
        handleEdit,
        handleChangeOne,
        handleChangeDefault,
        showSend,
        apiId,
        extendObj,

        primaryKeyName,
        behaviorsColumns: props.props.columns,
        arrayFilesName,
        deleteButtonConfig,
        showReserve,
        loggerSingleDisplay,
        handleLoggerSingle: (id: string) => {
            setIsLoggerQuery(false);
            setLoggerQueryId(id);
            setModalLogger(true);
        }
    };

    let columns = WebBehaviors(behaviorsData);

    let sendData = {
        type: '',
        id: '',
        sendPageData: {},
        extendedParameter,
    }

    //打开[新增-编辑-复制]框
    const openModal = (type: string, id: string, ep?: any) => {
        sendData.type = type;
        sendData.id = id;
        sendData.sendPageData = sendPageData;
        sendData.extendedParameter = ep ? ep : extendedParameter;
        setModalData(sendData);
        setModalopen(true);
    }

    //子组件回调函数，关闭modal
    const onCloseModal = (state: boolean) => {
        setModalopen(false)
        if (state === true) {
            //刷新表格内容
            InitData({
                ...showPageData,
            });
        }
    }
    /**打开批量插入框 */
    const openInsertModal = () => {
        setInsertModalopen(true)
    }

    /**关闭批量插入框 */
    const onCloseInsertModal = (state: boolean) => {
        setInsertModalopen(false)
        if (state === true) {
            //刷新表格内容
            InitData({
                ...showPageData,
            });
        }
    }

    /**打开批量更新框 */
    const openUpdateModal = () => {
        setUpdataModalopen(true)
    }

    /**关闭批量更新框 */
    const onCloseUndataModal = (state: boolean) => {
        setUpdataModalopen(false)
        if (state === true) {
            //刷新表格内容
            InitData({
                ...showPageData,
            });
        }
    }

    /** 查询 */
    const handleQuery = (e: any) => {
        let value = e.target.value;
        setQueryInput(value);
    };

    /**
     * 查询数据
     * @param queryString 查询的内容
     * @param options 选项 
     */
    const queryData = (queryString: any,
        options?: {
            /** 是否禁用查询动作 */
            isDisableInitData?: boolean
        }) => {
        if (typeof options === 'undefined') {
            options = {};
        }
        const { isDisableInitData } = options;
        setCurrentQueryString(queryString);
        initialState[apiId] = {
            data: {
                queryString: typeof queryString === 'undefined' ? '' : queryString,
                current: data.queryString === queryString ? data.current : 1,
            },
        };
        if (isDisableInitData !== true) {
            InitData(initialState);
        }
    };

    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${total}条`,
        pageSize,
        current,
        total,
        onShowSizeChange: (current: number, pageSize: number) => changePageSize(pageSize, current),
        onChange: (current: number) => changePage(current, pageSize),
    };

    // 回调函数，切换页数
    const changePage = (current: number, pageSize: number) => {
        if (loading === false) {
            initialState[apiId] = {
                data: {
                    current,
                    pageSize,
                },
            };
            InitData(initialState);
            loading = true;
        }
    }

    // 回调函数,每页显示多少条
    const changePageSize = (pageSize: number, current: number) => {

        if (loading === false) {
            initialState[apiId] = {
                data: {
                    current,
                    pageSize,
                },
            };
            InitData(initialState);
            loading = true;
        }
    };

    /** 打印 */
    const handlePrint = (id: string) => {

        /** 获取\<head>\</head>里面的内容 */
        let head = document.getElementsByTagName("head")[0];
        /** 获取<head>下面所有<style></style>内容 */
        const getStyle = head.getElementsByTagName('style');

        /** 前提先console一下，黑色主题和白色主题所在位置(在数组里面的第几个节点) */
        if (getStyle.length > 0) {
            if (clickNumData > 1) {

                /** 由于前两次的点击已经将原来第3(白色)、第4(黑色)节点的样式删除，所以之后多次切换主题，都是在最后一个节点进行修改主题 */
                /** 删除最后一个节点的主题 */
                getStyle[getStyle.length - 1].remove();

                /** 在最后添加白色主题 */
                let styleDom = document.createElement("style");
                styleDom.innerHTML = cssLightData;
                head.appendChild(styleDom);
            }
            else {
                /** 当未点击过切换黑白主题时，直接删除黑色主题就可以了 */
                if (clickNumData === 0) {
                    /** 根据之前的console,知道黑色主题在getStyle[3](第4个节点) */
                    /** 由于App.css里是先 "import antd.css",再 "import antd.dark.css"，所以黑色样式在第4个节点(getStyle[3])，所以将这个样式移除就可以变成白色了 */
                    getStyle[3].remove();
                }
            }
        }
        window.document.body.innerHTML = window.document.getElementById(id)?.innerHTML!;
        window.print();
        window.location.reload();
    };

    //根据日期回显数据
    const handleDate = (e: any) => {

        initialState[apiId] = {
            data: {
                pageSize,
                current,
            },
            sendPageData: {
                bookingDate: dayjs(e).format("YYYY-MM-DD")
            }
        };

        InitData(initialState);
    }

    /** 切换白黑样式 */
    // const changeSkin = (checked: any) => {
    //     /** 记录点击黑白切换的次数，用于删除原来的黑白主题样式的位置，主要由于原来黑白主题的位置不方便修改，所以要将原来的删除并在最后添加以便修改 */
    //     setClickNumData(clickNumData + 1);
    //     /** 获取<head></head>里面的内容 */
    //     let head = document.getElementsByTagName("head")[0];
    //     /** 获取<head>下面所有<style></style>内容 */
    //     const getStyle = head.getElementsByTagName('style');

    //     const getLink = head.getElementsByTagName('link');
    //     console.log(getLink[3].href);
    //     console.log(getLink[4].href);
    //     getLink[4].remove();

    //     /** 前提先console一下，黑色主题和白色主题所在位置(在数组里面的第几个节点) */
    //     if (getStyle.length > 0) {
    //         if (clickNumData < 2) {         // 当点击黑白切换次数小于2则往下执行
    //             if (checked) {
    //                 /** 主要作用是当点击第一次时，将黑白数据保存，并将黑色主题删掉 */
    //                 if (clickNumData === 0) {
    //                     /** 白色主题样式set一下，记录好数据； */
    //                     setCssLightData(head.getElementsByTagName('style')[2].textContent);
    //                     /** 将黑色主题样式set一下，记录好数据； */
    //                     setCssDarkData(head.getElementsByTagName('style')[3].textContent);
    //                     /** 删除黑色的主题样式    根据之前的console,知道黑色主题在getStyle[3](第4个节点) */
    //                     getStyle[3].remove();
    //                 }
    //             }
    //             else {
    //                 /** 主要作用是当点击第二次时，准备将白色主题删掉，所以需要提前将黑色主题添加到最后，再删除白色主题 */
    //                 if (clickNumData === 1) {
    //                     /** 在最后添加黑色主题 */
    //                     let styleDom = document.createElement("style");
    //                     styleDom.innerHTML = cssDarkData;
    //                     head.appendChild(styleDom);

    //                     /** 删除白色的主题样式    根据之前的console,知道白色主题在getStyle[2](第3个节点) */
    //                     getStyle[2].remove();
    //                 }
    //             }
    //         }
    //         else {
    //             /** 由于前两次的点击已经将原来第3(白色)、第4(黑色)节点的样式删除，所以超过2次的点击切换主题后，都是在最后一个节点进行修改主题 */
    //             /** 删除最后一个节点的主题 */
    //             getStyle[getStyle.length - 1].remove();

    //             /** 在最后添加一个的主题(什么主题根据checked来判断) */
    //             let styleDom = document.createElement("style");
    //             styleDom.innerHTML = checked ? cssLightData : cssDarkData;
    //             head.appendChild(styleDom);
    //         }
    //     }
    // };

    return (
        <>
            <Space style={{ marginBottom: 16 }}>
                {showAdd === true || typeof showAdd === 'undefined' ?
                    <Button disabled={disabledAdd} onClick={() => openModal('NEW', '')}>新增{titleName}</Button>
                    : ''}
                {showQuery === true || typeof showQuery === 'undefined' ?
                    <>
                        <Tooltip placement="topLeft" title={queryTitle}>
                            <Input
                                placeholder="请输入查询内容"
                                defaultValue={queryInput}
                                onChange={(e: any) => handleQuery(e)}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter')
                                        queryData(queryInput)
                                }} />
                        </Tooltip>
                        <Button icon={<SearchOutlined />} onClick={() => queryData(queryInput)}>查询</Button>
                    </>
                    : ''}
                {showPrint ?
                    <>
                        <Button onClick={() => handlePrint('table')} >打印</Button>
                        {/* <Button onClick={() => DownloadExcel(props, rows)} >下载EXCEL文件</Button> */}
                    </>
                    : ''}
                {showDate ?
                    <>
                        <DatePicker
                            // defaultValue={moment(data.bookingDate)}
                            format="YYYY-MM-DD"
                            onChange={handleDate}
                        />
                    </>
                    : ''}
                {bulkInsert ?
                    <>
                        <Button onClick={() => openInsertModal()} >批量新增</Button>
                    </>
                    : ""
                }

                {bulkUpdate ?
                    <>
                        <Button onClick={() => openUpdateModal()} >批量兑换</Button>
                    </>
                    : ""
                }
                {loggerDisplay ? <Button onClick={() => {
                    setIsLoggerQuery(true);
                    setLoggerQueryId(undefined);
                    setModalLogger(true);
                }}>日志</Button> : ''}
            </Space>
            <Table
                id='table'
                rowKey={row => (primaryKeyName ? row[primaryKeyName] : row.id)}
                columns={columns}
                dataSource={rows}
                pagination={pagination === true ? paginationProps : false}
                size="small"
                onChange={(_a, _b, par) => handleSort(par)}
                //子表格
                expandable={
                    isExpandable ?
                        { expandedRowRender: getExpandedRowRender }
                        : undefined}
                // 展开时触发的回调
                onExpand={onExpandClick}
                // defaultExpandAllRows={true}
                //总结栏
                summary={() => {
                    if (typeof summaryRender === 'undefined') {
                        return null;
                    }
                    let sumData
                    if (data['extendData']) {
                        sumData = data['extendData'][summaryRender.sumDataName]
                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>总计:</Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    {sumData}
                                </Table.Summary.Cell >
                            </Table.Summary.Row>
                        )
                    }
                }}
            //
            />
            {modalopen ? (<EditComponent closeModal={onCloseModal} data={modalData} />) : null}
            {modalUploadopen ? (<UploadFileComponent onClose={onCloseUpload} data={uploadSendData} />) : null}
            {insertModalopen ? (<InsertComponent closeModal={onCloseInsertModal} data={insertModalData} />) : null}
            {updataModalopen ? (<UnDateComponent closeModal={onCloseUndataModal} data={insertModalData} />) : null}

            {modalLogger ? (<WebUniversalLogger closeModal={() => {
                setModalLogger(false)
            }} data={{
                logger,
                queryType: loggerQueryType ? loggerQueryType : apiId,
                queryTypeExtendId: loggerQueryTypeExtendId,
                queryId: loggerQueryId,
                isQuery: isLoggerQuery,
                isScope,
                isShowloggerClear: false,
                isLockLoggerTypeSelect: true
            }} />) : null}
        </>
    )
}

/**
 * 通用Web组件 WebUniversal 
 * @param props 
 * @param ref 
 * @returns 
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true })
    (forwardRef(WebUniversal));