import { Col, Form, Input, InputNumber, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import { columnsheadId } from '../../universal/columnshead';




export default function DeptProductEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const [productData, setProductData] = useState<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {


        ref.current.handleCancel();
    };
   
  
    /** 后端数据wmService */
    let wmService: any = "";
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'deptProduct',
      
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "product",
                apiExtend: 'showList',
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                            isUserAreas: true
                        }
                    }
                }
            }).then(res => {

                setProductData(res.data)
            })



        }
        fetchData()
    }, [])

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '部门产品'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row >
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="产品"
                                name="productId"
                                rules={[{ required: true, message: '请输入产品!' }]}>
                                <Select
                                    showSearch
                                    placeholder="产品"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.search("product", setProductData, e, {
                                        "andWhereObj": {
                                            mountDataOptions: {
                                                "userMount": {
                                                    "isUserAreas": true,
                                                    "isDept": true
                                                }
                                            },
                                        }
                                    })}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(productData, 'productName')}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item
                                tooltip={"加入购物车时，默认该产品加入的数量"}
                                label="默认数量"
                                name="defaultbNum">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}


