import AntDraggableModal from "../../../utils/extend/AntDraggableModal";
import { Descriptions } from 'antd';
import { useEffect, useRef, useState } from 'react'
import system from "../../../config/systematic";
import dayjs from 'dayjs';
import fetchApi from '../../../utils/api/fetchApi';

export default function CheckWeekMenu(props: any) {

    // console.log(props)
    const { data, closeModal } = props;
    const ref = useRef<any>(undefined);

    /** 一周的福食菜单数据*/
    const [weekMenuData, setWeekMenuData] = useState<any>([]);

    /** 口味数据*/
    const [flavorData, setFlavorData] = useState<any>([]);

    /** 后端数据Service */
    let wmService = system.wmService;

    /**数据 */
    useEffect(() => {
        // if (ref.current) {
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        //     wmService = ref.current.wmService;
        //     ref.current.row.then(async (res: any) => {
        //         if (typeof res === 'undefined') { return }
        //         if (res.success === true) {

        //         }

        //     })
        // }

        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'flavor',
                apiExtend: 'showList'
            }).then(res => {
                setFlavorData(res.data);
            });

            fetchApi.GET(wmService, {
                apiId: "feastTeamMenu",
                apiExtend: 'weekMenu',
                apiData: {
                    selectDay: props.data.selectDay,
                    startDate: props.data.startDate,
                    endDate: props.data.endDate,
                    mountDataOptions: {
                        userMount: {
                            isBranchStore: true
                        }
                    }
                }
            }).then(res => {
                if (res.success) {
                    //console.log(res.data)
                    setWeekMenuData(res.data);
                }
            });

        }
        fetchData()
    }, [])

    const setData = (rows: any, flavorData: any) => {
        if (typeof rows !== "undefined") {

            let new_arr = [];
            let finalData: any = [];

            new_arr = rows.map((item: any) => ({
                id: item.id,
                selectDay: item.selectDay,
                morningIsTogether: item.morningIsTogether,
                afternoonIsTogether: item.afternoonIsTogether,
                feastTeamMenuDetails: groupByData(item.feastTeamMenuDetails, item.morningIsTogether, item.afternoonIsTogether, flavorData),
                isUse: item.isUse,
                sortBy: item.sortBy,
                sysDate: item.sysDate,
            }));
            finalData.push(new_arr);
            return finalData;
        }
    }

    const groupByData = (rows: any, morningIsTogether: any, afternoonIsTogether: any, flavor_Data: any) => {
        if (typeof rows !== "undefined") {
            let morning: any = [];
            let afternoon: any = [];

            for (var flavorid = 0, flavorlen = flavor_Data.length; flavorid < flavorlen; flavorid++) {
                if (morningIsTogether) {
                    morning.push([]);
                }
                if (afternoonIsTogether) {
                    afternoon.push([]);
                }
            }

            for (var i = 0, len = rows.length; i < len; i++) {
                if (morningIsTogether) {
                    //console.log("多口味");
                    if (rows[i].periodTimeId === 0) {
                        morning[rows[i].flavorId].push(rows[i]);
                        //console.log("早上的");
                    }
                }
                else {
                    //console.log("单口味");
                    if (rows[i].periodTimeId === 0) {
                        //console.log("早上的");
                        morning.push(rows[i]);
                    }
                }

                if (afternoonIsTogether) {
                    //console.log("多口味");
                    if (rows[i].periodTimeId === 1) {
                        afternoon[rows[i].flavorId].push(rows[i]);
                        //console.log("下午的");
                    }
                }
                else {
                    //console.log("单口味");
                    if (rows[i].periodTimeId === 1) {
                        //console.log("下午的");
                        afternoon.push(rows[i]);
                    }
                }
            }
            rows = { morning, afternoon }
        }
        return rows
    }

    const CheckData = (rows: any) => {
        // console.log(...rows);
        if (typeof rows !== "undefined") {
            return (
                <Descriptions title={props.data.selectDay ? props.data.areaName + " 地区  " + props.data.selectDay + " 福食菜单" : props.data.areaName + " 地区  " + props.data.startDate + " 至 " + props.data.endDate + " 福食菜单"} style={{ textAlign: "center" }} bordered>
                    {
                        rows[0].map((item: any, index: any) => {
                            return (
                                <>
                                    <Descriptions.Item label={item.selectDay} span={3} labelStyle={{ textAlign: 'center', backgroundColor: "#7777ff" }} contentStyle={{ textAlign: 'center', backgroundColor: "#7777ff" }} >
                                        {dayjs(item.selectDay).format('dddd')}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="上午" span={3} style={{ width: '150px', textAlign: 'center' }}>
                                        {
                                            item.feastTeamMenuDetails.morning.map((items: any, indexs: any) => {
                                                //console.log(items);
                                                if (item.morningIsTogether) {
                                                    for (var i = 0, len = items.length; i < len; i++) {
                                                        return (
                                                            <Descriptions bordered>
                                                                {
                                                                    items.map((itemss: any, indexss: any) => {
                                                                        if (itemss.feastTeamMenuWarehouse.menuWarehouseIngredients.length !== 0) {
                                                                            if (itemss.feastTeamMenuWarehouse.menuWarehouseIngredients[0].menuWarehouseIngredientDetails.length !== 0) {
                                                                                if (indexss === 0) {
                                                                                    return (
                                                                                        <>
                                                                                            <Descriptions.Item span={3} contentStyle={{ backgroundColor: "#335f33" }} labelStyle={{ width: '1px', backgroundColor: "#335f33" }}>{itemss.flavorName}</Descriptions.Item>
                                                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                            <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>
                                                                                                {
                                                                                                    itemss.feastTeamMenuWarehouse.menuWarehouseIngredients[0].menuWarehouseIngredientDetails.map((itemsss: any, indexsss: any) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {itemsss.product.productName}&nbsp;&nbsp;{itemsss.amount} ;
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Descriptions.Item>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <>
                                                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                            <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>
                                                                                                {
                                                                                                    itemss.feastTeamMenuWarehouse.menuWarehouseIngredients[0].menuWarehouseIngredientDetails.map((itemsss: any, indexsss: any) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {itemsss.product.productName}&nbsp;&nbsp;{itemsss.amount} ;
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Descriptions.Item>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            }
                                                                            else {
                                                                                if (indexss === 0) {
                                                                                    return (
                                                                                        <>
                                                                                            <Descriptions.Item span={3} contentStyle={{ backgroundColor: "#335f33" }} labelStyle={{ width: '1px', backgroundColor: "#335f33" }}>{itemss.flavorName}</Descriptions.Item>
                                                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                            <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <>
                                                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                            <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                                                        </>
                                                                                    )

                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            if (indexss === 0) {
                                                                                return (
                                                                                    <>
                                                                                        <Descriptions.Item span={3} contentStyle={{ backgroundColor: "#335f33" }} labelStyle={{ width: '1px', backgroundColor: "#335f33" }}>{itemss.flavorName}</Descriptions.Item>
                                                                                        <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                        <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <>
                                                                                        <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                        <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                                                    </>
                                                                                )

                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            </Descriptions>
                                                        )
                                                    }
                                                }
                                                else {
                                                    return (
                                                        <Descriptions bordered>
                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                            <Descriptions.Item span={2} label={items.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                        </Descriptions>
                                                    )
                                                }
                                            })
                                        }

                                    </Descriptions.Item>

                                    <Descriptions.Item label="下午" span={3} style={{ width: '100px', textAlign: 'center' }}>
                                        {
                                            item.feastTeamMenuDetails.afternoon.map((items: any, indexs: any) => {
                                                //console.log(items);
                                                if (item.afternoonIsTogether) {
                                                    for (var i = 0, len = items.length; i < len; i++) {
                                                        return (
                                                            <Descriptions bordered>
                                                                {
                                                                    items.map((itemss: any, indexss: any) => {
                                                                        if (itemss.feastTeamMenuWarehouse.menuWarehouseIngredients.length !== 0) {
                                                                            if (itemss.feastTeamMenuWarehouse.menuWarehouseIngredients[0].menuWarehouseIngredientDetails.length !== 0) {
                                                                                if (indexss === 0) {
                                                                                    return (
                                                                                        <>
                                                                                            <Descriptions.Item span={3} contentStyle={{ backgroundColor: "#335f33" }} labelStyle={{ width: '1px', backgroundColor: "#335f33" }}>{itemss.flavorName}</Descriptions.Item>
                                                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                            <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>
                                                                                                {
                                                                                                    itemss.feastTeamMenuWarehouse.menuWarehouseIngredients[0].menuWarehouseIngredientDetails.map((itemsss: any, indexsss: any) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {itemsss.product.productName}&nbsp;&nbsp;{itemsss.amount} ;
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Descriptions.Item>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <>
                                                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                            <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>
                                                                                                {
                                                                                                    itemss.feastTeamMenuWarehouse.menuWarehouseIngredients[0].menuWarehouseIngredientDetails.map((itemsss: any, indexsss: any) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {itemsss.product.productName}&nbsp;&nbsp;{itemsss.amount} ;
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Descriptions.Item>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            }
                                                                            else {
                                                                                if (indexss === 0) {
                                                                                    return (
                                                                                        <>
                                                                                            <Descriptions.Item span={3} contentStyle={{ backgroundColor: "#335f33" }} labelStyle={{ width: '1px', backgroundColor: "#335f33" }}>{itemss.flavorName}</Descriptions.Item>
                                                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                            <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <>
                                                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                            <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                                                        </>
                                                                                    )

                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            if (indexss === 0) {
                                                                                return (
                                                                                    <>
                                                                                        <Descriptions.Item span={3} contentStyle={{ backgroundColor: "#335f33" }} labelStyle={{ width: '1px', backgroundColor: "#335f33" }}>{itemss.flavorName}</Descriptions.Item>
                                                                                        <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                        <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <>
                                                                                        <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                        <Descriptions.Item span={2} label={itemss.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                                                    </>
                                                                                )

                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            </Descriptions>
                                                        )
                                                    }
                                                }
                                                else {
                                                    return (
                                                        <Descriptions bordered>
                                                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                            <Descriptions.Item span={2} label={items.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '420px', textAlign: 'right' }} contentStyle={{ width: '420px', textAlign: 'left' }}>暂时未设置主配料</Descriptions.Item>
                                                        </Descriptions>
                                                    )
                                                }
                                            })
                                        }
                                    </Descriptions.Item>

                                    {/* {
                                        item.feastTeamMenuDetails.morning.map((items: any, indexs: any) => {
                                            if (items.periodTimeId === 0) {
                                                return (
                                                    <>
                                                        <Descriptions.Item label="上午" span={3} style={{ width: '100px', textAlign: 'center' }}>
                                                            {
                                                                items.feastTeamMenuWarehouse.menuWarehouseIngredients.map((itemss: any) => {
                                                                    if (item.morningIsTogether) {
                                                                        return (
                                                                            <>
                                                                                <Descriptions bordered>
                                                                                    <Descriptions.Item span={3} contentStyle={{ backgroundColor: "#335f33" }} labelStyle={{ width: '1px', backgroundColor: "#335f33" }}>{items.flavorName}</Descriptions.Item>
                                                                                    <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                    <Descriptions.Item span={2} label={items.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '450px', textAlign: 'right' }} contentStyle={{ width: '400px', textAlign: 'left' }}>
                                                                                        {
                                                                                            itemss.menuWarehouseIngredientDetails.map((itemsss: any) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {itemsss.productName}&nbsp;&nbsp;{itemsss.amount};
                                                                                                    </>
                                                                                                )

                                                                                            })
                                                                                        }
                                                                                    </Descriptions.Item>
                                                                                </Descriptions>
                                                                                <br />
                                                                            </>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <>
                                                                                <Descriptions bordered>
                                                                                    <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                                                                                    <Descriptions.Item span={2} label={items.feastTeamMenuWarehouse.foodName} labelStyle={{ width: '450px', textAlign: 'right' }} contentStyle={{ width: '400px', textAlign: 'left' }}>
                                                                                        {
                                                                                            itemss.menuWarehouseIngredientDetails.map((itemsss: any) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {itemsss.productName}&nbsp;&nbsp;{itemsss.amount};
                                                                                                    </>
                                                                                                )

                                                                                            })
                                                                                        }
                                                                                    </Descriptions.Item>
                                                                                </Descriptions>
                                                                                <br />
                                                                            </>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </Descriptions.Item>
                                                    </>
                                                )
                                            }
                                        })
                                    } */}
                                </>
                            )
                        })
                    }

                    {/* <Descriptions.Item label="7月19日" span={3} labelStyle={{ backgroundColor: "#7777ff" }} contentStyle={{ backgroundColor: "#7777ff" }} >
                        星期一
                    </Descriptions.Item>
                    <Descriptions.Item label="上午" span={3} style={{ width: '100px', textAlign: 'center' }}>
                        <Descriptions bordered>
                            <Descriptions.Item span={3} contentStyle={{ backgroundColor: "#335f33" }} labelStyle={{ width: '1px', backgroundColor: "#335f33" }}>口味1</Descriptions.Item>
                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                            <Descriptions.Item span={2} label="菜式1" labelStyle={{ width: '450px', textAlign: 'right' }} contentStyle={{ width: '400px', textAlign: 'left' }}>份量1</Descriptions.Item>

                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                            <Descriptions.Item span={2} label="菜式2" labelStyle={{ width: '450px', textAlign: 'right' }} contentStyle={{ width: '400px', textAlign: 'left' }}>份量2</Descriptions.Item>

                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                            <Descriptions.Item span={2} label="菜式3" labelStyle={{ width: '450px', textAlign: 'right' }} contentStyle={{ width: '400px', textAlign: 'left' }}>份量3</Descriptions.Item>

                            <Descriptions.Item labelStyle={{ width: '1px', backgroundColor: "#282828" }} contentStyle={{ width: '1px', backgroundColor: "#282828" }}> </Descriptions.Item>
                            <Descriptions.Item span={2} label="菜式4" labelStyle={{ width: '450px', textAlign: 'right' }} contentStyle={{ width: '400px', textAlign: 'left' }}>份量4</Descriptions.Item>
                        </Descriptions>
                        <br />
                    </Descriptions.Item> */}

                </Descriptions>
            )
        }
    };

    const handleOk = (e: any) => {
        closeModal(true);
    };

    return (
        <>
            <AntDraggableModal
                //title={'每周福食菜单'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                //onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1200}>
                {CheckData(setData(weekMenuData, flavorData))}
            </AntDraggableModal>
        </>
    )
}